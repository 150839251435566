import { Button, Input, Modal } from 'antd';

type Props = {
  handleCancel?: () => void;
  handleUpdatePass?: () => void;
  onChange: (value: any) => void;
  modalResetPass: boolean;
};

export const ModalResetPassword = ({
  handleCancel,
  handleUpdatePass,
  onChange,
  modalResetPass,
}: Props) => {
  return (
    <Modal open={modalResetPass} footer={null} closable={false}>
      <div>
        <p>Nhập mật khẩu mới: </p>
        <Input placeholder="Nhập mật khẩu" onChange={onChange} />
        <div
          style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 30 }}
        >
          <Button onClick={handleCancel}>Huỷ bỏ</Button>
          <Button
            type="primary"
            style={{ marginLeft: 10 }}
            onClick={handleUpdatePass}
          >
            Cập nhật
          </Button>
        </div>
      </div>
    </Modal>
  );
};
