import React from 'react';

import UploadC from '../Upload';
import { Col, Form, Input, Row, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { usePostContext } from 'containers/posts/context';
import AudioPlayer from 'components/AudioPlayer';

type Props = {
  category: string;
  duration: number;
};
const ViewAudio: React.FC<Props> = ({ category, duration }) => {
  const { videoUrl, setVideoUrl } = usePostContext();

  const onDeleteVideo = () => {
    setVideoUrl && setVideoUrl(undefined);
  };
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item
          name="postTime"
          label="Thời gian (giây)"
          rules={[{ required: true, message: 'Vui lòng nhập thời gian!' }]}
        >
          <Input placeholder="Nhập thời gian..." />
        </Form.Item>
      </Col>

      <Col span={12}>
        {videoUrl ? (
          <div>
            <p>* Audio</p>
            <Space>
              <AudioPlayer url={videoUrl} duration={duration.toString()} />
              <DeleteOutlined
                style={{ fontSize: '1.5em' }}
                onClick={onDeleteVideo}
              />
            </Space>
          </div>
        ) : (
          <UploadC
            category={category}
            name="attachmentIds"
            type={'AUDIO' as any}
            accept="audio/*"
            maxSize={100 * 1000 * 10000}
            description="(Tải file mp3... Kích thước không quá 100MB...)"
          />
        )}
      </Col>
    </Row>
  );
};

export default ViewAudio;
