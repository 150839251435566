import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const get = (params?: { size?: number; page?: number }) => {
  deleteParamsNotUsing(params);
  const url = getQueryString('api/vouchers', params);
  return request(url);
};

const details = (id: number | undefined) => {
  const url = `api/vouchers` + `/${id}`;
  return request(url, 'get');
};

const create = (params?: VoucherParams) => {
  const url = 'api/vouchers';
  return request(url, 'post', params);
};
const update = (id: number | undefined, body?: VoucherParams) => {
  const url = `api/vouchers` + `/${id}`;
  return request(url, 'put', body);
};

const active = (id: number | undefined, status: boolean) => {
  let params = {
    activated: status,
  };
  const url = `api/vouchers` + `/${id}` + `/activated`;
  return request(url, 'patch', params);
};

export const voucherService = {
  get,
  create,
  update,
  details,
  active,
};
