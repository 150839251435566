const columns = (): any => {
  return [
    {
      title: 'STT',
      dataIndex: 'index',
      width: '3%',
    },
    {
      title: 'Id khách hàng',
      dataIndex: 'userId',
      width: '7%',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'userName',
      width: '10%',
    },
    {
      title: 'Ngày dự sinh',
      dataIndex: 'dueDate',
      width: '13%',
    },
    {
      title: 'Gói đăng kí',
      dataIndex: 'vipPackageName',
      width: '16%',
    },
    {
      title: 'Ngày kích hoạt',
      dataIndex: 'activatedTime',
      width: '13%',
    },
    {
      title: 'Số lần đăng kí lại',
      dataIndex: 'renewCount',
      width: '8%',
    },
    {
      title: 'Số ngày đọc',
      dataIndex: 'numberOfDaysRead',
      width: '7%',
    },
    {
      title: 'Số lần đọc',
      dataIndex: 'readCount',
      width: '7%',
    },
    {
      title: 'Số bài viết đã đọc',
      dataIndex: 'numberOfPostRead',
      width: '9%',
    },
  ];
};

export default columns;
