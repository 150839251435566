import { Button, Form, message, Modal, notification } from 'antd';
import { pregnantEducationService } from 'network/services/pregnantEducation';
import { LANDSCAPE } from 'containers/posts/constants';
import { PostContext } from 'containers/posts/context';
import { FC, useEffect, useState } from 'react';
import { getCDNUrl } from 'utils';
import FormPost from '../FormPost';

type CreatePostState = {
  loading: boolean;
};

type CreatePostInput = {
  postFormat: number;
  postName: string;
  image: string;
  categoryId: number;
  topicId: number;
  hashtag: string;
  description?: string;
  postTime: number;
  attachmentIds: string[];
  content: {
    html: string;
    markdown: string;
  };
};

type Props = {
  data: any;
  onHideModal: () => void;
  onReload: () => void;
};

const ModalCreateEducationPost: FC<Props> = ({
  data,
  onHideModal,
  onReload,
}) => {
  const [formCreatePost] = Form.useForm();
  const [idStr, setIdStr] = useState<string>();
  const [attachmentIds, setAttachmentIds] = useState<string[]>();
  const [orientation, setOrientation] = useState<string>();
  const [videoUrl, setVideoUrl] = useState<string>();

  const typeModal = data ? 'Chỉnh sửa' : 'Thêm mới';

  const initialValues = {
    postFormat: data?.postFormat ?? 1,
    postName: data?.postName ?? '',
    hashtag: data?.hashtag ?? undefined,
    description: data?.description ?? '',
    categoryId: data?.category?.id ?? 1,
    topicId: data?.topic?.id ?? undefined,
    postTime: data?.postTime ?? undefined,
    orientation: data?.attachments?.[0]?.orientation ?? LANDSCAPE,
    content: data?.content
      ? {
          html: data?.content,
        }
      : undefined,
  };
  const formName = 'create-post';

  const [createPostState, setCreatePostState] = useState<CreatePostState>({
    loading: false,
  });

  const handleCreatePost = async (values: CreatePostInput) => {
    const { hashtag, ...rest } = values || {};
    const params = {
      ...rest,
      content: values?.content?.html,
      image: idStr,
      attachmentIds,
      hashtag: hashtag?.toString(),
    };

    console.log('Modal Create Education', data);
    let response = null;
    if (data) {
      response = await pregnantEducationService.update(data.id, params);
    } else {
      response = await pregnantEducationService.create(params);
    }
    if (response.status === 200) {
      onHideModal();
      onReload();
      notification.success({
        message: 'Thành công',
        description: (
          <>
            {typeModal} post <strong>{response?.data?.postName}</strong> thành
            công
          </>
        ),
      });
      formCreatePost?.resetFields();
    } else {
      notification.error({
        message: 'Thất bại',
      });
    }
    setCreatePostState((prev) => ({ ...prev, loading: false }));
  };

  const onFinish = (values: CreatePostInput) => {
    setCreatePostState((prev) => ({ ...prev, loading: true }));
    handleCreatePost(values);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFinishFailed = (_errorInfo: any) => {
    message.warning('Vui lòng nhập đầy đủ thông tin!');
  };

  useEffect(() => {
    if (data) {
      const imgId = data?.image?.split('attachments/');
      setIdStr(imgId[1]);
      if (data?.attachments?.[0]?.idStr) {
        setVideoUrl(getCDNUrl(data?.attachments?.[0]?.idStr));
      }
    }
  }, [data]);

  return (
    <PostContext.Provider
      value={{
        postId: data?.id,
        formCreatePost,
        idStr,
        attachmentIds,
        orientation,
        videoUrl,
        setVideoUrl,
        setIdStr,
        setAttachmentIds,
        setOrientation,
      }}
    >
      <Modal
        title={`${typeModal} bài Post`}
        open={true}
        centered
        width={'80%'}
        bodyStyle={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          height: '80vh',
          paddingRight: 24,
        }}
        footer={[
          <Form.Item
            key="submit"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              type="primary"
              form={formName}
              htmlType="submit"
              loading={createPostState.loading}
            >
              {typeModal}
            </Button>
          </Form.Item>,
        ]}
        onOk={onHideModal}
        onCancel={onHideModal}
      >
        <FormPost
          name={formName}
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          uploadAttachment={'pregnant_education_post'}
          setAttachmentIds={setAttachmentIds}
        />
      </Modal>
    </PostContext.Provider>
  );
};

export default ModalCreateEducationPost;
