import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TOOLBAR_OPTIONS = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ align: [] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ direction: 'rtl' }],
  [{ color: [] }, { background: [] }],
  ['link', 'image', 'video'],
  ['clean'],
  ['emoji'],
];

const TextEditor: React.FC<ReactQuillProps> = ({ ...restProps }) => {
  return (
    <>
      <ReactQuill
        theme="snow"
        className="bg-white"
        {...restProps}
        modules={{
          'toolbar': {
            container: TOOLBAR_OPTIONS,
          },
          'emoji-toolbar': true,
          'emoji-textarea': false,
          'emoji-shortname': true,
        }}
      />
    </>
  );
};

export default TextEditor;
