import { Table } from 'antd';
import moment from 'moment';
import { roadMap280Service } from 'network/services/roadMap280';
import React, { useEffect, useState } from 'react';
import { DEFAULT_PAGE_SIZE, GLOBAL_HEIGHT, PAGE_SIZE_LIST } from 'utils/constants';

import AdminIcon from 'assets/image/adminIcon.jpg';
import { useWindowSize } from 'hook';
import debounce from 'lodash/debounce';
import { Helmet } from 'react-helmet';
import Actions from './components/Actions';
import ModalCreate from './components/ModalCreate';

type UIState = {
  data: any[];
  loading: boolean;
  metadata: any;
  filters: any;
  isModalOpen: boolean;
  editDayInfo: any;
};

const Practices: React.FC = () => {
  const defaultFilters = {
    page: 0,
    size: DEFAULT_PAGE_SIZE,
    dayPractice: undefined,
  };
  const windowSize = useWindowSize();
  const [UIState, setUIState] = useState<UIState>({
    data: [],
    loading: true,
    metadata: undefined,
    filters: defaultFilters,
    isModalOpen: false,
    editDayInfo: undefined,
  });

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '3%',
    },
    {
      title: 'Ngày thực hành thai giáo',
      dataIndex: 'dayPractice',
      width: '15%',
      render: (cell: number) => 'Ngày thực hành thứ ' + cell,
    },
    {
      title: 'Số hoạt động trong ngày',
      dataIndex: 'activityCount',
      width: '10%',
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '10%',
      render: (cell: number) =>
        cell ? moment(cell).format('DD/MM/YYYY HH:mm:ss') : '--',
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'modifiedBy',
      width: '10%',
    },
    {
      title: 'Thao tác',
      dataIndex: 'id',
      width: '5%',
      render: (cell: any) => <a onClick={() => onEdit(cell)}>Chỉnh sửa</a>,
    },
  ];

  const onEdit = async (id: any) => {
    const response = await roadMap280Service.detailPractice(id);
    setUIState((cur) => ({
      ...cur,
      isModalOpen: true,
      editDayInfo: response.data,
    }));
  };

  const onFetchData = async () => {
    const response = await roadMap280Service.routePregnancy(UIState.filters);
    const { data, headers } = response;
    if (response?.status === 200) {
      setUIState((cur) => ({
        ...cur,
        loading: false,
        data,
        metadata: {
          pageCount: headers['x-page-count'],
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
      }));
    } else {
      setUIState((cur) => ({
        ...cur,
        loading: false,
      }));
    }
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
      filters: {
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const onHideModal = () => {
    setUIState((cur) => ({
      ...cur,
      isModalOpen: false,
      editDayInfo: undefined,
    }));
  };

  const onOpenModal = () => {
    setUIState((cur) => ({
      ...cur,
      isModalOpen: true,
      editDayInfo: undefined,
    }));
  };

  const onSearch = debounce((num: string | null) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        dayPractice: num,
      },
      loading: true,
    }));
  }, 300);

  const onReload = () => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
    }));
  };

  useEffect(() => {
    if (UIState.loading) {
      onFetchData();
    }
  }, [UIState.loading]);

  return (
    <div id="road-map-practices">
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={AdminIcon} />
        <title>MomEdu - Lộ trình thai giáo</title>
        <meta name="description" content="Lộ trình thai giáo" />
      </Helmet>
      <Actions onOpenModal={onOpenModal} onSearch={onSearch} />
      <Table
        rowKey={'id'}
        dataSource={UIState.data}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: UIState.filters.page + 1,
          total: UIState?.metadata?.total,
          defaultPageSize: UIState.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{
          y: windowSize?.height - GLOBAL_HEIGHT - 40,
          x: 1400,
        }}
      />
      {UIState.isModalOpen && (
        <ModalCreate
          onHideModal={onHideModal}
          data={UIState.editDayInfo}
          onReload={onReload}
        />
      )}
    </div>
  );
};

export default Practices;
