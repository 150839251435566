import { Button, Modal } from 'antd';

type Props = {
  isModalOpen: boolean;
  handleChangeModal: (value: any) => void;
  handleSubmitDelete: () => void;
};

const ModalDelete = ({
  isModalOpen,
  handleChangeModal,
  handleSubmitDelete,
}: Props) => {
  return (
    <Modal
      open={isModalOpen}
      onCancel={handleChangeModal}
      footer={<></>}
      closeIcon={<></>}
      centered
    >
      <div style={{ fontSize: '20px', textAlign: 'center' }}>
        Bạn chắc chắn muốn xoá bài viết chứ ?{' '}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 15,
          marginTop: '25px',
        }}
      >
        <Button onClick={handleChangeModal}>Huỷ bỏ</Button>
        <Button type="primary" onClick={handleSubmitDelete}>
          Đồng ý
        </Button>
      </div>
    </Modal>
  );
};

export default ModalDelete;
