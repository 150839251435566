import { Button, Modal } from 'antd';

type Props = {
  onHideModal?: () => void;
  onFinishCancel?: () => void;
};

const ModalCancel = ({ onHideModal, onFinishCancel }: Props) => {
  const onFinish = () => {
    onFinishCancel?.();
  };
  return (
    <>
      <Modal
        open={true}
        footer={null}
        closable={false}
        width={350}
        onOk={onHideModal}
        onCancel={onHideModal}
        centered
      >
        <div className="text-base font-semibold text-center my-1">
          HUỶ ĐƠN HÀNG
        </div>
        <div className="text-sm text-normal text-center my-5">
          Bạn đã kiểm tra và chắc chắn xác nhận đơn hàng này khách hàng đã từ
          chối thanh toán?
        </div>
        <div className="flex justify-center">
          <Button type="default" onClick={onHideModal}>
            Huỷ tác vụ
          </Button>
          <div className="w-5" />
          <Button type="primary" onClick={onFinish}>
            Xác nhận huỷ đơn hàng
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalCancel;
