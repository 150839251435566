import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const get = (params?: DefaultFiltersInterface) => {
  deleteParamsNotUsing(params);
  const url = getQueryString('/api/course/reference-person/filter', params);
  return request(url);
};

export const personalService = {
  get,
};
