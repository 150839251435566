import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CategoryOfManuals from 'containers/categoryManager';
import AppLayout from '../layouts';
import Banners from 'containers/Banners';
import Customers from 'containers/Customers';
import Notifications from 'containers/Notifications';
import PostNewVersion from 'containers/postVer1.1';
import Popup from 'containers/Popup';
import HotLead from 'containers/hotLead';
import Orders from 'containers/Orders';
import Courses from 'containers/Course';
import PostNews from 'containers/postNew';
import Packages from 'containers/packages';
import LoginPage from 'containers/login/pages/Login';
import HomePage from 'containers/Home';
import Accounts from 'containers/Accounts';
import RoadMapPractices from 'containers/posts/pages/Practices';
import PostsTG280N from 'containers/posts/pages/PostsTG280N';
import PregnantEducation from 'containers/posts/pages/PregnantEducation';
import Voucher from 'containers/voucher';
import Report from 'containers/report';
import Dashboard from 'containers/Dashboard';
import { ROUTERS } from 'utils/routers';
import { isHaveAccessPermission } from 'utils';
import Page404 from 'containers/Page404';
import ManagerPostsWebsite from 'containers/ManagerPostsWebsite';
import CategoryWeb from 'containers/CategoryWeb';
import AppPregnancyTracker from 'containers/AppPregnancyTracker';
import { AppBabyTracker } from 'containers/AppBabyTracker';

const Router = (isLogin: boolean, role?: string) => {
  return (
    <BrowserRouter>
      <Routes>
        {!isLogin ? (
          <Route>
            <Route path={ROUTERS.LOGIN.path} element={<LoginPage />} />
          </Route>
        ) : (
          <Route path={ROUTERS.HOME.path} element={<AppLayout />}>
            <Route path={ROUTERS.HOME.path} element={<HomePage />} />
            {isHaveAccessPermission(ROUTERS.DASHBOARD.key, role) && (
              <Route path={ROUTERS.DASHBOARD.path} element={<Dashboard />} />
            )}

            {isHaveAccessPermission(ROUTERS.ACCOUNTS.key, role) && (
              <Route path={ROUTERS.ACCOUNTS.path} element={<Accounts />} />
            )}

            {isHaveAccessPermission(ROUTERS.ROAD_MAP_PRACTICES.key, role) && (
              <Route
                path={ROUTERS.ROAD_MAP_PRACTICES.path}
                element={<RoadMapPractices />}
              />
            )}

            {isHaveAccessPermission(ROUTERS.POSTS_TG_280N.key, role) && (
              <Route
                path={ROUTERS.POSTS_TG_280N.path}
                element={<PostsTG280N />}
              />
            )}

            {isHaveAccessPermission(ROUTERS.PREGNANT_EDUCATION.key, role) && (
              <Route
                path={ROUTERS.PREGNANT_EDUCATION.path}
                element={<PregnantEducation />}
              />
            )}

            {isHaveAccessPermission(
              ROUTERS.CATEGORIES_PREGNANT_EDUCATION.key,
              role
            ) && (
              <Route
                path={ROUTERS.CATEGORIES_PREGNANT_EDUCATION.path}
                element={<CategoryOfManuals />}
              />
            )}

            {isHaveAccessPermission(ROUTERS.NOTIFICATIONS.key, role) && (
              <Route
                path={ROUTERS.NOTIFICATIONS.path}
                element={<Notifications />}
              />
            )}

            {isHaveAccessPermission(ROUTERS.BANNER.key, role) && (
              <Route path={ROUTERS.BANNER.path} element={<Banners />} />
            )}

            {isHaveAccessPermission(ROUTERS.CUSTOMERS.key, role) && (
              <Route path={ROUTERS.CUSTOMERS.path} element={<Customers />} />
            )}

            {isHaveAccessPermission(ROUTERS.HOT_LEAD.key, role) && (
              <Route path={ROUTERS.HOT_LEAD.path} element={<HotLead />} />
            )}

            {isHaveAccessPermission(ROUTERS.COURSES.key, role) && (
              <Route path={ROUTERS.COURSES.path} element={<Courses />} />
            )}

            {isHaveAccessPermission(ROUTERS.POSTS.key, role) && (
              <Route path={ROUTERS.POSTS.path} element={<PostNewVersion />} />
            )}

            {isHaveAccessPermission(ROUTERS.POP_UP.key, role) && (
              <Route path={ROUTERS.POP_UP.path} element={<Popup />} />
            )}

            {isHaveAccessPermission(ROUTERS.ORDERS.key, role) && (
              <Route path={ROUTERS.ORDERS.path} element={<Orders />} />
            )}

            {isHaveAccessPermission(ROUTERS.POSTS_NEW.key, role) && (
              <Route
                path={ROUTERS.POSTS_NEW.path}
                element={<PostNews platform="app" key={'app'} />}
              />
            )}

            {isHaveAccessPermission(ROUTERS.PACKAGES.key, role) && (
              <Route path={ROUTERS.PACKAGES.path} element={<Packages />} />
            )}

            {isHaveAccessPermission(ROUTERS.VOUCHERS.key, role) && (
              <Route path={ROUTERS.VOUCHERS.path} element={<Voucher />} />
            )}

            <Route
              path={ROUTERS.PREGNANCY_TRACKER.path}
              element={<AppPregnancyTracker />}
            />
            <Route
              path={ROUTERS.BABY_TRACKER.path}
              element={<AppBabyTracker />}
            />
            {isHaveAccessPermission(ROUTERS.REPORTS.key, role) && (
              <Route path={ROUTERS.REPORTS.path} element={<Report />} />
            )}

            {isHaveAccessPermission(
              ROUTERS.MANAGER_POSTS_WEBSITE.key,
              role
            ) && (
              <>
                <Route
                  path={ROUTERS.MANAGER_POSTS_WEBSITE.path}
                  element={<ManagerPostsWebsite />}
                />
                <Route
                  path={ROUTERS.MANAGER_CATEGORY_WEBSITE.path}
                  element={<CategoryWeb />}
                />
              </>
            )}
          </Route>
        )}
        <Route path={ROUTERS.DEFAULT.path} element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
