import { BackwardOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, Radio, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import UploadFileChunks from 'components/UploadFileChunks';
import { EditorContentChanged } from 'containers/posts/pages/PostsTG280N/components/Editor';
import { useEffect } from 'react';
import { getCDNUrl } from 'utils';
import ViewAudio from '../ViewAudio';
import ViewPost from '../ViewPosts';
import ViewVideo from '../ViewVideo';
import FooterModalPosts from './Footer';

type Props = {
  isOpenModal: boolean;
  handleOnChangeModal: () => void;
  type: 'Chỉnh sửa' | 'Thêm mới';
  formatValues: number;
  imagesValues: string;
  onDeleteImage: () => void;
  uploadFileSuccess: (file: any) => void;
  listDataCourse: { id: number; name: string }[];
  listDatacategories: any[];
  listDataHashTag: HashTag[];
  listDataTopics: any[];
  topicId: number | undefined;
  categoriesId: number;
  content: EditorContentChanged;
  handleChangeOrientation: (value: 'landscape' | 'portrait') => void;
  onDeleteVideo: () => void;
  uploadFileSuccessVideo: (value: any) => void;
  orientation: 'landscape' | 'portrait';
  videoUrl: string | undefined;
  audioUrl: string | undefined;
  hashTagsValues: string[];
  onDeleteAudio: () => void;
  uploadFileSuccessAudio: (value: any) => void;
  postTime: number | undefined;
  onSubmit: (value: any) => void;
  dataPostDetail: any;
  position: number;
  handleOnChangeCourse: (value: any) => void;
  onChangeCategory: (value: any) => void;
  handleOnChangeFormatValues: (value: any) => void;
  onChangeHashTag: (values: any[]) => void;
};

const ModalPostsNewVer = ({
  isOpenModal,
  handleOnChangeModal,
  type,
  formatValues,
  imagesValues,
  onDeleteImage,
  uploadFileSuccess,
  listDataCourse,
  listDatacategories,
  listDataHashTag,
  listDataTopics,
  content,
  handleChangeOrientation,
  onDeleteVideo,
  orientation,
  uploadFileSuccessVideo,
  videoUrl,
  audioUrl,
  onDeleteAudio,
  uploadFileSuccessAudio,
  postTime,
  onSubmit,
  dataPostDetail,
  handleOnChangeCourse,
  onChangeCategory,
  handleOnChangeFormatValues,
  topicId,
  onChangeHashTag,
}: Props) => {
  const [form] = useForm();

  console.log('ModalPostsNewVer', isOpenModal, topicId);
  const validateNumber = (_rule: any, value: any, callback: any) => {
    if (isNaN(value) || value <= 0) {
      callback('Vui lòng nhập thứ tự bài viết là số nguyên dương');
    } else {
      callback();
    }
  };

  const renderViewByFormat = (format?: number) => {
    switch (format) {
      case 1:
        return (
          <ViewPost
            type={type}
            html={dataPostDetail?.content}
            id={dataPostDetail?.id}
            isPressConvert={dataPostDetail?.isPressConvert}
            linkTTS={dataPostDetail?.linkTTS}
          />
        );
      case 2:
        return (
          <ViewVideo
            category="general_post"
            handleChangeOrientation={handleChangeOrientation}
            onDeleteVideo={onDeleteVideo}
            uploadFileSuccessVideo={uploadFileSuccessVideo}
            videoUrl={videoUrl}
            orientation={orientation}
          />
        );
      case 3:
        return (
          <ViewAudio
            category={'general_post'}
            duration={postTime as any}
            audioUrl={audioUrl}
            onDeleteAudio={onDeleteAudio}
            uploadFileSuccessAudio={uploadFileSuccessAudio}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (type === 'Chỉnh sửa' && Object.keys(dataPostDetail).length !== 0) {
      form.setFieldsValue({
        content:
          formatValues === 1
            ? {
                html: content,
              }
            : undefined,
        orientation: dataPostDetail?.attachments[0]?.orientation,
        categoryId: dataPostDetail?.category?.id,
        courseId: dataPostDetail?.course?.id,
        postFormat: dataPostDetail?.postFormat,
        topicId: dataPostDetail?.topic?.id,
        hashtag:
          dataPostDetail?.hashtag === ''
            ? undefined
            : dataPostDetail?.hashtag?.split(','),
        description: dataPostDetail?.description,
        position: dataPostDetail?.position,
        postName: dataPostDetail?.postName,
        postTime: dataPostDetail?.postTime,
      });
    } else {
      form.resetFields();
      form.setFieldsValue({
        postFormat: 1,
      });
    }
  }, [dataPostDetail, type]);

  useEffect(() => {
    if (dataPostDetail?.postFormat !== formatValues) {
      return form.setFieldsValue({
        postTime: undefined,
      });
    }

    form.setFieldsValue({
      postTime: dataPostDetail?.postTime,
    });
  }, [formatValues]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          marginBottom: '20px',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={() => {
            handleOnChangeModal();
          }}
        >
          <BackwardOutlined />
        </Button>
        <div
          style={{
            fontSize: '20px',
            fontWeight: '700',
          }}
        >
          {type} bài viết
        </div>
      </div>

      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <div
          style={{
            height: '100%',
            overflow: 'auto',
            paddingRight: '24px',
            paddingLeft: '24px',
          }}
        >
          <Form.Item
            label="Định dạng"
            name={'postFormat'}
            rules={[{ required: true, message: 'Vui lòng chọn định dạng' }]}
          >
            <Radio.Group
              onChange={(e) => handleOnChangeFormatValues(e.target.value)}
            >
              <Radio value={1}>Bài viết</Radio>
              <Radio value={2}>Video</Radio>
              <Radio value={3}>Audio</Radio>
            </Radio.Group>
          </Form.Item>
          {/* ///////// */}
          <Form.Item
            label="Tiêu đề"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tiêu đề',
              },
            ]}
            name="postName"
          >
            <Input placeholder="Nhập tiêu đề..." />
          </Form.Item>
          {/* //////// */}
          <Form.Item
            label="Khoá học"
            name={'courseId'}
            rules={[{ required: true, message: 'Vui lòng chọn khoá học' }]}
          >
            <Select
              placeholder="Chọn khoá học"
              options={listDataCourse.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })}
              onChange={handleOnChangeCourse}
              disabled={type === 'Chỉnh sửa'}
            />
          </Form.Item>
          {/* ///////// */}
          <Form.Item
            label="Chuyên mục"
            style={{
              width: '100%',
            }}
            name="categoryId"
          >
            <Form.Item
              name={'categoryId'}
              rules={[{ required: true, message: 'Vui lòng chọn chuyên mục' }]}
              style={{
                display: 'inline-block',
                width: 'calc(35%)',
                marginRight: '20px',
              }}
            >
              <Select
                placeholder="Chọn chuyên mục"
                options={listDatacategories.map((item: any) => {
                  return {
                    label: item.originalName,
                    value: item.id,
                  };
                })}
                onChange={onChangeCategory}
                // disabled={type === 'Chỉnh sửa'}
              />
            </Form.Item>
            <div
              style={{
                display: 'inline-block',
                width: 'calc(60% - 8px)',
              }}
            >
              {imagesValues ? (
                <div>
                  <p>Icon *</p>
                  <Space>
                    <Image width={100} src={getCDNUrl(imagesValues)} />
                    <DeleteOutlined
                      style={{ fontSize: '44px' }}
                      onClick={onDeleteImage}
                    />
                  </Space>
                </div>
              ) : (
                <UploadFileChunks
                  name="icon"
                  type={'IMAGE' as any}
                  accept="image/*"
                  description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
                  category={'general_post'}
                  uploadFileSuccess={uploadFileSuccess}
                  title="Icon"
                />
              )}
            </div>
          </Form.Item>
          {/* ///////// */}
          <Form.Item
            label="Chủ đề"
            name={'topicId'}
            rules={[{ required: true, message: 'Vui lòng chọn chủ đề' }]}
          >
            <Select
              placeholder="Chọn chủ đề "
              options={listDataTopics.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })}
              // disabled={type === 'Chỉnh sửa'}
            />
          </Form.Item>
          {/* ///////// */}
          <Form.Item label="Hashtag" name="hashtag">
            <Select
              placeholder="Nhập hashtag..."
              mode="tags"
              options={listDataHashTag.map((item: HashTag) => {
                return {
                  label: item.name,
                  value: item.name,
                };
              })}
              onChange={onChangeHashTag}
            />
          </Form.Item>
          {/* ///////// */}
          <Form.Item label="Mô tả" name="description">
            <Input.TextArea rows={2} placeholder="Nhập mô tả..." />
          </Form.Item>
          {/* ////////// */}
          {renderViewByFormat(formatValues)}

          <Form.Item
            label="Thứ tự bài viết"
            name={'position'}
            rules={[
              {
                validator: validateNumber,
              },
            ]}
          >
            <Input placeholder="Nhập thứ tự..." />
          </Form.Item>
        </div>

        <FooterModalPosts type={type} />
      </Form>
    </div>
  );
};

export default ModalPostsNewVer;
