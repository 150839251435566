import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const get = (params?: { size?: number; page?: number }) => {
  if (!params?.page || !params?.size) {
    params = { ...params, page: 0, size: 1000 };
  }
  deleteParamsNotUsing(params);
  const url = getQueryString('/api/categories/vip-packages/list', params);
  return request(url);
};

const create = (params?: PackageParams) => {
  const url = '/api/categories/vip-packages';
  return request(url, 'post', params);
};
const update = (id: number | undefined, body?: PackageParams) => {
  const url = `/api/categories/vip-packages` + `/${id}`;
  return request(url, 'put', body);
};

export const packageService = {
  get,
  create,
  update,
};
