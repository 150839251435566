import { Col, DatePicker, Input, Row } from 'antd';

type Props = {
  onChangeDate?: (_: any, value: string[]) => void;
  onChangeReadDate?: (_: any, value: string[]) => void;
  onChangePhoneNumber?: (e: any) => void;
};

export const HeaderReport = ({
  onChangeDate,
  onChangeReadDate,
  onChangePhoneNumber,
}: Props) => {
  return (
    <div className="flex flex-col my-5">
      <div>
        <div className="text-xl font-bold">Báo cáo</div>
        <div className="text-sm font-normal decoration-[#9d9d9d] my-1.5">
          Thống kê hành động khách hàng
        </div>
      </div>

      <Row>
        <Col span={6}>
          <div className="mr-8">
            <Input onChange={onChangePhoneNumber} placeholder="Số điện thoại" />
          </div>
        </Col>
        <Col span={9}>
          <div className="flex items-center">
            <p className="mr-2 text-sm font-bold">Khoảng thời gian kích hoạt</p>
            <DatePicker.RangePicker
              allowClear
              format={'YYYY-MM-DD'}
              placeholder={['Từ ngày', 'Đến ngày']}
              onChange={onChangeDate}
            />
          </div>
        </Col>
        <Col span={9}>
          <div className="flex items-center">
            <p className="mr-2 text-sm font-bold">
              Khoảng thời gian đọc bài
            </p>
            <DatePicker.RangePicker
              allowClear
              format={'YYYY-MM-DD'}
              placeholder={['Từ ngày', 'Đến ngày']}
              onChange={onChangeReadDate}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
