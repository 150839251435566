import { Api } from '..';

export const login = (
  variables: LoginInput,
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) => Api.post('/api/auth/login', variables, successCallback, failCallback);

export const logout = (
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) => Api.get('/api/auth/logout', undefined, successCallback, failCallback);

export const authService = { login, logout };
