export const ROUTERS = {
  LOGIN: {
    path: '/',
    key: 'login',
    name: '',
  },
  DEFAULT: {
    path: '*',
    key: 'default',
    name: '',
  },
  HOME: {
    path: '/',
    key: 'home',
    name: '',
  },
  CMS: {
    path: '',
    key: 'cms',
    name: '',
  },
  CMS_WEB: {
    path: '',
    key: 'cms-web',
    name: '',
  },
  CRM: {
    path: '',
    key: 'crm',
    name: '',
  },
  POSTS_MANAGER: {
    path: '',
    key: 'posts-manager',
    name: 'Quản lý bài viết',
  },
  AFFILIATE: {
    path: '',
    key: 'affiliate',
    name: '',
  },
  DASHBOARD: {
    path: '/dashboard',
    key: 'dashboard',
    name: 'Dashboard',
  },
  ACCOUNTS: {
    path: '/accounts',
    key: 'accounts',
    name: 'Quản lý tài khoản',
  },
  ROAD_MAP_PRACTICES: {
    path: '/road-map-practices',
    key: 'road-map-practices',
    name: 'Cấu hình thai giáo',
  },
  POSTS_TG_280N: {
    path: '/posts-tg280n',
    key: 'posts-tg280n',
    name: 'Thai giáo trực tiếp',
  },
  PREGNANT_EDUCATION: {
    path: '/pregnant-education',
    key: 'pregnant-education',
    name: '',
  },
  CATEGORIES_PREGNANT_EDUCATION: {
    path: '/categories-pregnant-education',
    key: 'categories-pregnant-education',
    name: '',
  },
  NOTIFICATIONS: {
    path: '/notifications',
    key: 'notifications',
    name: '',
  },
  BANNER: {
    path: '/banners',
    key: 'banners',
    name: '',
  },
  CUSTOMERS: {
    path: '/customers',
    key: 'customers',
    name: '',
  },
  HOT_LEAD: {
    path: '/hot-lead',
    key: 'hot-lead',
    name: '',
  },
  COURSES: {
    path: '/courses',
    key: 'courses',
    name: 'Quản lý khoá học',
  },
  POSTS: {
    path: '/posts',
    key: 'posts',
    name: 'Thai giáo gián tiếp',
  },
  POP_UP: {
    path: '/pop-up',
    key: 'pop-up',
    name: 'Pop-up',
  },
  ORDERS: {
    path: '/orders',
    key: 'orders',
    name: 'Đơn hàng',
  },
  POSTS_TG: {
    path: '',
    key: 'posts-tg',
    name: 'Khoá TH Thai giáo',
  },
  POSTS_NEW: {
    path: '/posts-new',
    key: 'posts-new',
    name: 'Bài học App',
  },
  PACKAGES: {
    path: '/packages',
    key: 'packages',
    name: 'Quản lý giá gói khoá học',
  },
  VOUCHERS: {
    path: '/vouchers',
    key: 'vouchers',
    name: '',
  },
  REPORTS: {
    path: '/reports',
    key: 'reports',
    name: 'Báo cáo',
  },
  MANAGER_POSTS_WEBSITE: {
    path: '/posts-in-website',
    key: 'posts-in-website',
    name: 'Quản lý bài viết',
  },
  MANAGER_CATEGORY_WEBSITE: {
    path: '/categry-in-website',
    key: 'categry-in-website',
    name: 'Quản lý danh mục',
  },
  PREGNANCY_TRACKER: {
    path: '/app-pregnancy-tracker',
    key: 'app-pregnancy-tracker',
    name: 'App Pregnancy Tracker'
  },
  BABY_TRACKER: {
    path: '/app-baby-tracker',
    key: 'app-baby-tracker',
    name: 'App Baby Tracker'
  },
};
