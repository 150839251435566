import { Button, Checkbox, Col, Form, Row, Select, notification } from 'antd';
import React from 'react';
import Editor from '../Editor';
import { SPEED, VOICE } from 'utils/enum';
import useHtmlToText from 'hook/useHtmlToText';
import { useForm } from 'antd/es/form/Form';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { postRoadMap280Service } from 'network/services/postRoadMap280';

interface Props {
  type: string;
  html: string | undefined;
  id?: string;
  isPressConvert?: boolean;
  linkTTS?: string;
}

const ViewPost = ({ type, html, id, isPressConvert, linkTTS }: Props) => {
  const [form] = useForm();
  const [checked, setChecked] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const text = useHtmlToText(html || '');

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const handleConvertTextToSpeech = async (value: any) => {
    setLoading(true);
    const params = {
      ...value,
      id: id,
      text: text,
    };
    const resp = await postRoadMap280Service.textToAudio(params);
    if (resp.status === 200) {
      notification.success({ message: 'Chuyển Text To Speech thành công' });
      setLoading(false);
      form.resetFields();
      setChecked(false);
    } else {
      notification.error({ message: 'Chuyển Text To Speech thất bại' });
      setLoading(false);
    }
  };

  const isDisabled = () => {
    return isPressConvert === true && linkTTS === null;
  };

  const renderTextToSpeech = () => {
    if (type === 'Chỉnh sửa') {
      return (
        <div style={{ marginBottom: 20 }}>
          <Form onFinish={handleConvertTextToSpeech} form={form}>
            <Checkbox
              disabled={isDisabled()}
              checked={checked}
              onChange={onChange}
            >
              Chuyển đổi Text to Speech
            </Checkbox>
            {checked && (
              <div style={{ marginTop: 10 }}>
                <Row gutter={[12, 12]}>
                  <Col span={5}>
                    <Form.Item name="voice" label="Giọng đọc">
                      <Select
                        allowClear
                        placeholder="Chọn giọng đọc"
                        options={VOICE}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="speed" label="Tốc độ">
                      <Select
                        allowClear
                        placeholder="Chọn tốc độ"
                        options={SPEED}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ marginTop: 20 }}>
                  <Button loading={loading} onClick={form.submit}>
                    Chuyển
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </div>
      );
    }
  };

  return (
    <div>
      <Form.Item
        name="content"
        label="Nội dung"
        rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}
      >
        <Editor />
      </Form.Item>
      {renderTextToSpeech()}
    </div>
  );
};

export default ViewPost;
