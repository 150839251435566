import { request } from '..';

const uploadFile = (params?: any) => {
  return request('/api/attachments/upload', 'post', params);
};

const uploadChunk = (params?: any) => {
  return request('/api/attachments/chunks', 'post', params);
};

export const systemsServices = {
  uploadFile,
  uploadChunk,
};
