import { Form, Select } from 'antd';

type Props = {
  allPosts: any;
  name: string;
  label?: string;
};

const SearchInput = ({ allPosts, name, label }: Props) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: true,

          message: 'Vui lòng chọn bài viết liên quan',
        },
      ]}
    >
      <Select
        placeholder="Chọn bài viết liên quan..."
        options={allPosts.map((item: any) => ({
          label: item.name,
          value: item.id,
        }))}
        filterOption={(input, option) =>
          (option as { value: number; label: string }).label
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        showSearch
      />
    </Form.Item>
  );
};

export default SearchInput;
