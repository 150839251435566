import { LOCAL_STORAGE_KEYS, ROUTERS_ROLE } from './constants';
import { POST_FORMAT, ROLES } from './enum';
import { isNullOrEmpty } from './stringUtils';

export const getPostFormat = (type: number) => {
  switch (type) {
    case POST_FORMAT.post:
      return 'Bài viết';
    case POST_FORMAT.video:
      return 'Video';
    case POST_FORMAT.autio:
      return 'Audio';
    default:
      return '';
  }
};

export const getCDNUrl = (imgId?: string) => {
  if (imgId) {
    return process.env.REACT_APP_BASE_URL + '/api/attachments/' + imgId;
  }
  return undefined;
};

export function makePassWord(length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const isHaveAccessPermission = (
  pageKey: string,
  role?: string
): boolean => {
  const pageInfo = ROUTERS_ROLE.find((element) => element.scene === pageKey);
  if (isNullOrEmpty(pageInfo)) {
    return false;
  }
  return pageInfo?.roles?.includes(role ?? '') ?? true;
};

export const isAdmin = (): boolean => {
  const userInfo: any = JSON?.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.userInfo) ?? '{}'
  );
  return userInfo?.role?.code === ROLES.ADMIN;
};
