import axios from 'axios';

import { DEFAULT_TIME_OUT } from './constants';
import { LOCAL_STORAGE_KEYS } from 'utils/constants';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const defaults = {
  headers: {
    // 'Content-Type': 'application/json',
  },
  error: {
    code: 'INTERNAL_ERROR',
    message:
      'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

// const forceLogout = () => {
//   localStorage.clear();
//   axios.defaults.headers.common['Authorization'] = '';
//   if (!window.location.pathname.includes('login')) {
//     window.location.href = '/login';
//   }
// };

const api = async (
  method: string,
  url: string,
  variables?: any,
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) => {
  return await axios({
    url: url,
    method,
    headers: {
      ...defaults.headers,
      Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEYS.accessToken)}`,
    },
    params: method === 'get' ? variables : undefined,
    data: method !== 'get' ? variables : undefined,
    timeout: DEFAULT_TIME_OUT,
  })
    .then((response) => {
      // if (response && response.status === STATUS_CODE.AUTHENTICATE) {
      //   forceLogout();
      // }
      successCallback && successCallback(response);
    })
    .catch((error) => {
      if (error.response) {
        // if (error.response.status === STATUS_CODE.AUTHENTICATE) {
        //   forceLogout();
        // }
        failCallback && failCallback(error);
      } else {
        failCallback && failCallback(defaults.error);
      }
    });
};

export const Api = {
  get: (
    url: string,
    variables?: any,
    successCallback?: (res: any) => void,
    failCallback?: (err: any) => void
  ) => api('get', url, variables, successCallback, failCallback),
  post: (
    url: string,
    variables?: any,
    successCallback?: (res: any) => void,
    failCallback?: (err: any) => void
  ) => api('post', url, variables, successCallback, failCallback),
  put: (
    url: string,
    variables?: any,
    successCallback?: (res: any) => void,
    failCallback?: (err: any) => void
  ) => api('put', url, variables, successCallback, failCallback),
  patch: (
    url: string,
    variables?: any,
    successCallback?: (res: any) => void,
    failCallback?: (err: any) => void
  ) => api('patch', url, variables, successCallback, failCallback),
  delete: (
    url: string,
    variables?: any,
    successCallback?: (res: any) => void,
    failCallback?: (err: any) => void
  ) => api('delete', url, variables, successCallback, failCallback),
};

export const initApi = (token?: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const config = {
  timeout: 60000,
  username: '',
  password: '',
  contentType: 'application/json',
  version: 2,
};

export async function request(
  url: string,
  method?: string,
  data?: any,
  options?: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _forkException?: boolean
): Promise<any> {
  const headers: { [key: string]: string } = {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  };
  const source = axios.CancelToken.source();
  const abortTimeout = setTimeout(() => {
    source.cancel(`Timeout of ${config.timeout}ms.`);
  }, config.timeout);

  return new Promise((resolve) => {
    const requestMethod = method ?? 'GET';
    axios({
      url,
      method: requestMethod,
      headers,
      data,
      cancelToken: source.token,
      ...options,
    })
      .then((response) => {
        clearTimeout(abortTimeout);
        resolve(response);
      })
      .catch((error: any) => {
        console.log('response error', error);
        resolve(error.response);
        if (error?.response?.status === 401) {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.accessToken);
          window.location.href = '/login';
        }
        // if (forkException) {
        //   resolve(error.response);
        // } else {
        //   reject(error.response);
        // }
      });
  });
}
