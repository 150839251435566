import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  notification,
  Select,
  Switch,
  TimePicker,
} from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { RangePickerProps } from 'antd/es/date-picker';
import TextArea from 'antd/es/input/TextArea';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import { notificationServices } from 'network/services/notificationNew';
import { ReactNode, useEffect, useState } from 'react';

import { ModalHelp } from './modalHelp/modalHelp';
import React from 'react';
import { packageService } from 'network/services/package';

type Props = {
  onHideModal: () => void;
  itemSelected: number | undefined;
  onReload: () => void;
};

const OBJECT_SELECT: DefaultOptionType[] = [
  {
    label: 'Free',
    value: 'free',
  },
  // {
  //   label: 'Trial',
  //   value: 'trial',
  // },
  {
    label: 'Vip',
    value: 'vip',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
  {
    label: 'Dùng thử 1 ngày',
    value: 'trial_1d',
  },
  {
    label: 'Dùng thử 2 ngày',
    value: 'trial_2d',
  },
  {
    label: 'Dùng thử 3 ngày',
    value: 'trial_3d',
  },
  {
    label: 'Expired ngày 1',
    value: 'trial_4d',
  },
  {
    label: 'Expired ngày 2',
    value: 'trial_5d',
  },
  // {
  //   label: 'Khách hàng cụ thể',
  //   value: 'specific',
  // },
  {
    label: 'Khách hàng chưa đăng ký',
    value: 'unregister',
  },
];

const optionsGoto: DefaultOptionType[] = [
  {
    label: 'Màn home',
    value: 'home',
  },
  // {
  //   label: 'Khoá học',
  //   value: 'course_package',
  // },
  // {
  //   label: 'Chỉ số',
  //   value: 'statistic',
  // },
  // {
  //   label: 'Màn cẩm nang',
  //   value: 'insights',
  // },
  // {
  //   label: 'Báo cáo',
  //   value: 'report',
  // },
  {
    label: 'Web view',
    value: 'webview',
  },
  {
    label: 'Chi tiết thông báo',
    value: 'notification',
  },
  {
    label: 'Màn hình khóa học',
    value: 'course',
  },
  {
    label: 'Màn hình lộ trình 280 ngày',
    value: 'roadmap280',
  },
];

const optionsRepeat = [
  {
    label: 'Không lặp lại',
    value: 'none',
  },
  {
    label: 'Hằng ngày',
    value: 'daily',
  },
  {
    label: 'Hàng tuần',
    value: 'weekly',
  },
  {
    label: 'Hàng tháng',
    value: 'monthly',
  },
];

const optionsCheckbox = [
  { label: 'Ngoài màn hình', value: 'on_screen' },
  { label: 'In-app', value: 'in_app' },
];

const renderTitle = (title: string) => {
  return <div style={{ fontSize: '24px' }}>{title}</div>;
};

const renderItemForm = (
  subTitle: string,
  typeInput: ReactNode,
  numberPx: number = 0
) => {
  return (
    <div style={{ display: 'flex', gap: '15px', marginTop: `${numberPx}px` }}>
      <div style={{ width: '220px', fontSize: '16px', fontWeight: 400 }}>
        {subTitle}
      </div>
      {typeInput}
    </div>
  );
};

const boxDynamicsWidth = (
  width: string,
  children: ReactNode,
  cusStyle?: any
) => {
  return <div style={{ width, ...cusStyle }}>{children}</div>;
};

const renderContentsContainer = (children: ReactNode) => (
  <div
    style={{
      marginLeft: '100px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    }}
  >
    {children}
  </div>
);

const ConfigNotiPage = ({ onHideModal, itemSelected, onReload }: Props) => {
  const [form] = Form.useForm();
  const [checkedValue, setCheckedValue] = useState<CheckboxValueType[] | any>(
    undefined
  );
  const [timePicker, setTimePicker] = useState<any>(undefined);
  const [datePicker, setDatePicker] = useState<any>(undefined);
  const [combineTime, setCombineTime] = useState<undefined | string>(undefined);
  const [valueChangeGoto, setValueChangeGoto] = useState();
  const [isShowModalHelp, setIsShowModalHelp] = useState<boolean>(false);
  const [course, setCourse] = React.useState<PackageInterface[]>([]);
  const [isSpecific, setIsSpecific] = React.useState<boolean>(false);

  const fetchDataCourse = async () => {
    const response = await packageService.get();
    const { data } = response;
    if (response.status === 200) {
      const courseResp: PackageInterface[] = data?.content;
      setCourse(courseResp.filter((item) => item?.course?.type !== '280_DAYS'));
    }
  };

  React.useEffect(() => {
    fetchDataCourse();
  }, [valueChangeGoto]);

  const onConfirm = () => {
    setIsShowModalHelp(false);
  };

  const onHideModalConfirm = () => {
    setIsShowModalHelp(false);
  };

  const onShowModalHelp = () => {
    setIsShowModalHelp(true);
  };

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setCheckedValue(checkedValues);
  };

  const handleTimeChange = (_time: any, timeString: string) => {
    setTimePicker(timeString);
  };

  const handleDateChange = (_date: any, dateString: string) => {
    setDatePicker(dateString);
  };

  const onFinish = async (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sendingDate, ...newValues } = values;
    const convertData = {
      ...newValues,
      displayType: checkedValue,
      sendingTime: combineTime,
      description: values.description,
      customerTypes: isSpecific ? ['specific'] : values.customerTypes,
    };

    if (checkedValue !== undefined && checkedValue?.length !== 0) {
      if (itemSelected) {
        const response = await notificationServices.update(
          itemSelected,
          convertData
        );

        if (response.status === 200) {
          notification.success({
            message: 'Sửa thông báo thành công',
          });
          onHideModal();
          onReload();
        } else {
          notification.error({
            message: 'Sửa thông báo thất bại',
          });
        }
      } else {
        const response = await notificationServices.create(convertData);

        if (response.status === 200) {
          notification.success({
            message: 'Tạo thông báo thành công',
          });
          onHideModal();
          onReload();
        } else {
          notification.error({
            message: 'Tạo thông báo thất bại',
          });
        }
      }
    } else {
      form.setFields([
        {
          name: 'displayType',
          errors: ['Vui lòng chọn nơi hiển thị'],
        },
      ]);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    if (!checkedValue) {
      form.setFields([
        {
          name: 'displayType',
          errors: ['Vui lòng chọn nơi hiển thị'],
        },
      ]);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current <= dayjs().startOf('day');
  };

  const fetchDetailID = async () => {
    const resp = await notificationServices.detail(itemSelected!);
    const { data, status } = resp;
    if (status === 200) {
      setCheckedValue(data.displayType.split(','));
      setTimePicker(dayjs(data.sendingTime).format('HH:mm'));
      setDatePicker(dayjs(data.sendingTime).format('YYYY-MM-DD'));
      setValueChangeGoto(data.goTo);
      setIsSpecific(data.customerTypes.split(',').includes('specific'));
      form.setFieldsValue({
        customerTypes: data.customerTypes
          .split(',')
          .filter((item: string) => item !== 'specific'),
        repetitionInterval: data.repetitionInterval,
        title: data.title,
        description: data.description,
        goTo: data.goTo,
        sendingDate: dayjs(data.sendingTime),
        sendingTime: dayjs(data.sendingTime),
        displayType: checkedValue,
        openScreen:
          data.goTo === 'course' ? Number(data.openScreen) : data.openScreen,
        phoneNumbers: data.phoneNumbers,
      });
    }
  };

  useEffect(() => {
    if (datePicker && timePicker) {
      const dateTime = new Date(`${datePicker} ${timePicker}`).toISOString();
      setCombineTime(dateTime);
    }
  }, [datePicker, timePicker, timePicker]);

  useEffect(() => {
    if (itemSelected) {
      fetchDetailID();
    } else {
      setCheckedValue([optionsCheckbox[0].value, optionsCheckbox[1].value]);
    }
  }, [itemSelected]);

  useEffect(() => {
    form.setFields([
      {
        name: 'displayType',
        errors:
          checkedValue?.length === 0 ? ['Vui lòng chọn nơi hiển thị'] : [],
      },
    ]);
  }, [checkedValue]);

  const handleToggleTarget = (value: boolean) => {
    setIsSpecific(value);
  };

  return (
    <div
      style={{
        padding: '0px 118px 28px 68px',
      }}
    >
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
        {renderTitle('Cấu hình thông báo')}
        {renderContentsContainer(
          <>
            {/* Đối tượng nhận thông báo */}
            {renderItemForm(
              'Gửi cho khách hàng cụ thể',
              <div style={{ width: '70%' }}>
                <Form.Item>
                  <Switch checked={isSpecific} onChange={handleToggleTarget} />
                </Form.Item>
              </div>
            )}
            {!isSpecific
              ? renderItemForm(
                  'Đối tượng nhận thông báo',
                  <div style={{ width: '70%' }}>
                    <Form.Item
                      name="customerTypes"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn đối tượng nhận thông báo',
                        },
                      ]}
                    >
                      <Select
                        options={OBJECT_SELECT}
                        mode="multiple"
                        placeholder="Chọn đối tượng..."
                      />
                    </Form.Item>
                  </div>,
                  31
                )
              : renderItemForm(
                  'Số điện thoại khách hàng',
                  <div style={{ width: '70%' }}>
                    <Form.Item
                      name="phoneNumbers"
                      rules={[
                        {
                          required: true,
                          pattern: /^\d{10}(?:,\d{10})*$/,
                          message:
                            'Vui lòng nhập số điện thoại gồm 10 số và phân cách bởi dấu phẩy',
                        },
                      ]}
                    >
                      <Input placeholder="Nhập số điện thoại..." />
                    </Form.Item>
                  </div>,
                  31
                )}
            {/* Hiển thị */}
            {renderItemForm(
              'Hiện thị',
              <Form.Item name="displayType" initialValue={checkedValue}>
                {boxDynamicsWidth(
                  '234px',
                  <Checkbox.Group
                    options={optionsCheckbox}
                    onChange={onChange}
                    value={checkedValue}
                  />
                )}
              </Form.Item>
            )}
            {/* Thời gian gửi */}
            {renderItemForm(
              'Thời gian gửi',
              <>
                <Form.Item
                  name="sendingDate"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ngày',
                    },
                  ]}
                >
                  <DatePicker
                    onChange={handleDateChange}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  name="sendingTime"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn thời gian gửi',
                    },
                  ]}
                >
                  <TimePicker format={'HH:mm'} onChange={handleTimeChange} />
                </Form.Item>
              </>
            )}
            {/* Lặp lại */}
            {renderItemForm(
              'Lặp lại',
              <div style={{ width: '70%' }}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn chế độ lặp lại',
                    },
                  ]}
                  name="repetitionInterval"
                >
                  <Select
                    options={optionsRepeat}
                    placeholder="Chọn đế chộ lặp lại"
                  />
                </Form.Item>
              </div>
            )}
          </>
        )}
        {/*  */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {renderTitle('Nội dung thông báo')}
          <Button onClick={onShowModalHelp}>
            <div>Xem các từ khóa dùng để cá nhân hóa</div>
          </Button>
        </div>

        {renderContentsContainer(
          <>
            {/* Tiêu đề */}
            {renderItemForm(
              'Tiêu đề',
              <div style={{ width: '70%' }}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập tiêu đề',
                    },
                  ]}
                >
                  <Input placeholder="Nhập tiêu đề..." />
                </Form.Item>
              </div>,
              31
            )}

            {/* Mô tả */}
            {renderItemForm(
              'Mô tả',
              <div style={{ width: '70%' }}>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mô tả',
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Nhập mô tả..." />
                </Form.Item>
              </div>
            )}

            {/* GO TO */}
            {renderItemForm(
              'Go to',
              <div style={{ width: '70%' }}>
                <Form.Item
                  name="goTo"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn đích đến',
                    },
                  ]}
                >
                  <Select
                    options={optionsGoto}
                    placeholder="Chọn đích đến"
                    onChange={(e) => {
                      setValueChangeGoto(e);
                    }}
                  />
                </Form.Item>
              </div>
            )}
            {valueChangeGoto === 'webview' && (
              <>
                {renderItemForm(
                  'Link Go to',
                  <div style={{ width: '70%' }}>
                    <Form.Item
                      name="openScreen"
                      rules={[
                        {
                          required: valueChangeGoto === 'webview',
                          message: 'Vui lòng nhập link',
                        },

                        {
                          required: true,
                          pattern: /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/,
                          message: 'Link URL phải có định dạng là một Link!',
                        },
                      ]}
                    >
                      <Input placeholder="Nhập link" />
                    </Form.Item>
                  </div>
                )}
              </>
            )}
            {valueChangeGoto === 'course' && (
              <>
                {renderItemForm(
                  'Khóa học đích đến',
                  <div style={{ width: '70%' }}>
                    <Form.Item
                      name="openScreen"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn khóa học đích đến',
                        },
                      ]}
                    >
                      <Select
                        options={course?.map((item: PackageInterface) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        placeholder="Chọn đích đến"
                      />
                    </Form.Item>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {/* Function */}
        {renderContentsContainer(
          <>
            {renderItemForm(
              '',
              <div style={{ display: 'flex', gap: '30px' }}>
                <Button type="primary" onClick={onHideModal}>
                  Huỷ
                </Button>
                <Button type="primary" htmlType="submit">
                  {itemSelected ? 'Sửa thông báo' : 'Tạo thông báo'}
                </Button>
              </div>
            )}
          </>
        )}
      </Form>
      {isShowModalHelp && (
        <ModalHelp
          onHideModal={onHideModalConfirm}
          onFinishConfirm={onConfirm}
        />
      )}
    </div>
  );
};

export default ConfigNotiPage;
