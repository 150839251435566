import { PauseOutlined, PlayCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';

type Props = {
  url: string;
  duration?: string;
};

const AudioPlayer: FC<Props> = ({ url, duration }) => {
  const audioRef = useRef<any>(null);
  const [isPlay, setPlay] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(0);

  const onPlayPause = () => {
    setPlay(!isPlay);
  };

  const handleSeekChange = (event: any) => {
    setCurrentTime(event.target.value);
    audioRef.current.currentTime = event.target.value;
  };

  const convertToMmSs = (seconds: number) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = Math.floor(seconds % 60);
    return remainingSeconds > 0
      ? `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
      : '00:00';
  };

  // const handleDownload = () => {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'audio.mp3');
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // };

  useEffect(() => {
    if (isPlay) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlay]);

  return (
    <Space
      align="center"
      style={{
        background: '#E2E2E2',
        borderRadius: '16px',
        paddingRight: 8,
        alignItems: 'center',
      }}
    >
      <audio
        ref={audioRef}
        src={url}
        onTimeUpdate={() => setCurrentTime(audioRef.current?.currentTime)}
        onEnded={() => setPlay(false)}
      />
      <div
        onClick={onPlayPause}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {isPlay ? (
          <PauseOutlined style={{ color: '#277CBE', fontSize: 14 }} />
        ) : (
          <PlayCircleFilled style={{ color: '#277CBE', fontSize: 14 }} />
        )}
      </div>
      <div>
        {convertToMmSs(audioRef.current?.currentTime)}/{duration?.slice(3, 8)}
      </div>
      <input
        style={{ width: 120, marginTop: 2 }}
        type="range"
        id="seek-slider"
        min={0}
        max={audioRef.current && audioRef.current.duration}
        step={0.01}
        value={currentTime}
        onChange={handleSeekChange}
      />
      {/* <DownloadOutlined onClick={handleDownload} style={{ fontSize: 16 }} /> */}
    </Space>
  );
};

export default AudioPlayer;
