import { BackwardOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  Select,
  Space,
  Switch,
  notification,
} from 'antd';
import UploadFileChunks from 'components/UploadFileChunks';
import { courseService } from 'network/services/course';
import React from 'react';
import { getCDNUrl } from 'utils';
import { TYPE_POST_COURSE } from 'utils/enum';
import { EditInput } from './edit';

type Props = {
  onHideModal: () => void;
  onReload: () => void;
  modalType?: string;
  phase: [];
  dataDetail: CourseInterface | undefined;
  personal: PersonalInterface[];
};

export type CourseParams = {
  id: number;
  banner?: string;
  code: string;
  description?: any;
  icon?: string;
  information?: any;
  isDisable?: boolean;
  isImportantCourse?: boolean;
  name?: string;
  personInCharge?: number;
  stageId?: number;
  video?: string;
  type?: string;
};
export const ModalCreateCourse: React.FC<Props> = ({
  onHideModal,
  dataDetail,
  onReload,
  phase,
  modalType,
  personal,
}) => {
  const [form] = Form.useForm();
  const [imageIdStr, setImageIdStr] = React.useState<string>('');
  const [imageBanner, setImageBanner] = React.useState<string>('');
  const [videoUrl, setVideoUrl] = React.useState<string | undefined>(undefined);
  const [showImportant, setShowImportant] = React.useState<boolean>(false);
  const [attachment, setAttachment] = React.useState<any>();
  const [selectedPersonal, setSelectedPersonal] = React.useState<number>();

  const initialValues = {
    code: dataDetail?.code ?? undefined,
    name: dataDetail?.name ?? undefined,
    stageId: dataDetail?.stage?.id ?? undefined,
    description: { html: dataDetail?.description } ?? undefined,
    information: { html: dataDetail?.information } ?? undefined,
    type: dataDetail?.type ?? undefined,
    personInCharge: dataDetail?.personInCharge ?? undefined,
  };
  console.log('dataDetail?.personInCharge', dataDetail?.personInCharge);

  React.useEffect(() => {
    if (dataDetail) {
      setImageIdStr(dataDetail?.icon);
      setImageBanner(dataDetail?.banner);
      setShowImportant(dataDetail?.isImportantCourse);
      setVideoUrl(dataDetail?.video);
      setSelectedPersonal(dataDetail?.personInCharge);
    }
  }, [dataDetail]);

  const uploadFileSuccess = (file: { linkUrl: string }) => {
    setImageIdStr(file.linkUrl);
  };

  const uploadBannerSuccess = (file: { linkUrl: string }) => {
    setImageBanner(file.linkUrl);
  };

  const onDeleteVideo = () => {
    setVideoUrl('');
    setAttachment('');
  };

  const onDeleteImage = () => {
    setImageIdStr('');
  };

  const onDeleteBanner = () => {
    setImageBanner('');
  };

  const uploadFileSuccessVideo = (value: any) => {
    setVideoUrl(getCDNUrl(value.idStr));
    setAttachment(value);
  };

  const handleSelectImportant = (value: boolean) => {
    setShowImportant(value);
  };

  const handleSelectPersonal = (value: number) => {
    setSelectedPersonal(value);
  };

  const onSubmit = async (value: CourseParams) => {
    const params = {
      ...value,
      banner: imageBanner,
      icon: imageIdStr,
      description: value?.description?.html,
      information: value?.information.html,
      isDisable: false,
      isImportantCourse: showImportant,
      personInCharge: selectedPersonal,
      video: videoUrl,
      videoAttachment: attachment || null,
    };
    if (modalType === 'Thêm mới') {
      const response = await courseService.create(params);
      if (response.status === 201) {
        notification.success({ message: `${modalType} Khoá học thành công` });
        onHideModal();
        onReload();
      }
    } else {
      const response = await courseService.update(dataDetail?.id, params);
      if (response.status === 200) {
        notification.success({ message: `${modalType} Khoá học thành công` });
        onHideModal();
        onReload();
      }
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          marginBottom: '20px',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={() => {
            onHideModal();
          }}
        >
          <BackwardOutlined />
        </Button>
        <div
          style={{
            fontSize: '20px',
            fontWeight: '700',
          }}
        >
          {modalType} khoá học
        </div>
      </div>
      <Form
        initialValues={initialValues}
        form={form}
        layout="vertical"
        onFinish={onSubmit}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ flex: 1, marginRight: 10 }}>
            <Form.Item
              label="Mã khoá học"
              name="code"
              rules={[
                { message: 'Vui lòng nhập mã khoá học!', required: true },
              ]}
            >
              <Input
                placeholder="Nhập mã khoá học..."
                maxLength={15}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              label="Tên khoá học"
              name="name"
              rules={[
                { message: 'Vui lòng nhập tên khoá học!', required: true },
              ]}
            >
              <Input
                placeholder="Nhập tên khoá học..."
                maxLength={250}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              label="Chọn danh mục khoá học"
              name="stageId"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn danh mục khoá học!',
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Chọn danh mục"
                options={phase.map((item: PhaseInterface) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
            {imageIdStr ? (
              <div>
                <p>Icon</p>
                <Space>
                  <Image width={200} src={imageIdStr} />
                  <DeleteOutlined
                    style={{ fontSize: '44px' }}
                    onClick={onDeleteImage}
                  />
                </Space>
              </div>
            ) : (
              <UploadFileChunks
                isRequired={true}
                title={'Icon'}
                name="icon"
                type={'IMAGE' as any}
                accept="image/*"
                description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
                category={'banner'}
                uploadFileSuccess={uploadFileSuccess}
              />
            )}
            {imageBanner ? (
              <div>
                <p>Ảnh banner</p>
                <Space>
                  <Image width={200} src={imageBanner} />
                  <DeleteOutlined
                    style={{ fontSize: '44px' }}
                    onClick={onDeleteBanner}
                  />
                </Space>
              </div>
            ) : (
              <UploadFileChunks
                title={'Ảnh Banner'}
                name="banner"
                type={'IMAGE' as any}
                accept="image/*"
                description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
                category={'banner'}
                uploadFileSuccess={uploadBannerSuccess}
              />
            )}
          </div>
          <div style={{ flex: 1, marginLeft: 10 }}>
            <div>
              {videoUrl ? (
                <div
                  style={{
                    width: '600px',
                    display: 'flex',
                    height: '300px',
                    marginBottom: '60px',
                  }}
                >
                  <div>
                    <p>* Video giới thiệu</p>{' '}
                    <video style={{ width: '100%', height: '100%' }} controls>
                      <source src={videoUrl} type="video/mp4" />
                    </video>
                  </div>
                  <Space>
                    <DeleteOutlined
                      style={{ fontSize: '44px' }}
                      onClick={onDeleteVideo}
                    />
                  </Space>
                </div>
              ) : (
                <UploadFileChunks
                  name="video"
                  type={'VIDEO' as any}
                  accept="video/*"
                  maxSize={500 * 1000 * 10000}
                  description="(Tải file mp4... Kích thước không quá 500MB...)"
                  category={'general_post'}
                  uploadFileSuccess={uploadFileSuccessVideo}
                  title="Video giới thiệu"
                  isRequired
                />
              )}
            </div>
            <Form.Item name="isImportantCourse" label={'Khoá học nổi bật ?'}>
              <Switch
                checked={showImportant}
                onChange={handleSelectImportant}
              />
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng type khoá học!',
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Chọn type"
                options={TYPE_POST_COURSE}
              />
            </Form.Item>

            <Form.Item
              label="Chuyên gia/Bác sĩ/Giảng viên"
              name="personInCharge"
            >
              <Select
                allowClear
                placeholder="Chọn chuyên gia/Bác sĩ/Giảng viên"
                onChange={handleSelectPersonal}
                options={personal.map((item) => ({
                  value: item.id,
                  label: item.title + ' - ' + item.fullName,
                }))}
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item name="description" label="Mô tả">
          <EditInput />
        </Form.Item>
        <Form.Item name="information" label="Thông tin sản phẩm">
          <EditInput />
        </Form.Item>
        <div className={'center-item'}>
          <Button type="primary" htmlType="submit">
            {modalType}
          </Button>
        </div>
      </Form>
    </div>
  );
};
