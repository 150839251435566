import { Button, Form, InputNumber, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { roadMap280Service } from 'network/services/roadMap280';
import PracticesFormList from '../PracticesFormList';
import { ROADMAP_280_TYPE } from 'utils/enum';

type CreateRoadMapPractices = {
  loading: boolean;
};

type Post = {
  postId: number;
  position: number;
  type: number;
};

type CreatePracticeInput = {
  dayPractice: number;
  posts: Post[];
};

type Props = {
  data: any;
  onHideModal: () => void;
  onReload: () => void;
};

const ModalCreate: React.FC<Props> = ({ data, onHideModal, onReload }) => {
  const formName = 'create-practice';
  const initialValues = {
    posts: [{}],
  };
  const [lengthPractices, setLengthPrectices] = useState(0);

  console.log('lengthPractices', lengthPractices);
  const [form] = Form.useForm();
  const [createPractices, setCreatePractices] =
    useState<CreateRoadMapPractices>({
      loading: false,
    });

  const typeModal = data ? 'Chỉnh sửa' : 'Thêm mới';

  const handleCreatePractice = async (values: CreatePracticeInput) => {
    const { posts, ...rest } = values || {};
    const newPosts = posts?.map(({ postId, type, position }: Post) => ({
      postId,
      position: Number(position),
      type: type === ROADMAP_280_TYPE.TG_TT ? 'TG_TT' : 'TG_GT',
    }));
    const params = { ...rest, posts: newPosts };

    let response = null;
    if (data) {
      response = await roadMap280Service.updatePractice(data.id, params);
    } else {
      response = await roadMap280Service.createPractice(params);
    }

    if (response.status === 200) {
      form.setFields([
        {
          name: 'dayPractice',
          validating: false,
        },
      ]);
      onHideModal();
      onReload();
      message.success(`${typeModal} ngày thực hành thai giáo thành công`);
    } else {
      const typeMsg = data ? 1 : 0;
      if (typeMsg === 0) {
        form.setFields([
          {
            name: 'dayPractice',
            errors: [`Ngày ${values?.dayPractice} đã tồn tại`],
            validating: false,
          },
        ]);
        message.error(`Ngày ${values?.dayPractice} đã tồn tại`);
      } else {
        message.error(response?.data);
      }
    }
    setCreatePractices((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const onFinish = (values: CreatePracticeInput) => {
    setCreatePractices((prev) => ({
      ...prev,
      loading: true,
    }));
    form.setFields([
      {
        name: 'dayPractice',
        validating: true,
      },
    ]);
    handleCreatePractice(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        dayPractice: data?.dayPractice,
        posts: data?.posts
          ?.map((post: any) => ({
            categoryId: post?.category?.id,
            topicId: post?.topic?.id,
            postId: post?.id,
            position: post?.position,
            type:
              post?.type === 'TG_TT'
                ? ROADMAP_280_TYPE.TG_TT
                : ROADMAP_280_TYPE.TG_GT,
          }))
          .sort((a: any, b: any) => a.position - b.position),
      });
    }
  }, [data]);

  const handleSetLengthPractices = (value: number) => {
    setLengthPrectices(value as any);
  };
  return (
    <Modal
      title={`${typeModal} ngày thực hành thai giáo`}
      open={true}
      centered
      width={'80%'}
      bodyStyle={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        paddingRight: 60,
        paddingLeft: 60,
      }}
      onOk={onHideModal}
      onCancel={onHideModal}
      footer={[
        <Form.Item key="submit">
          <div className={'center-item'}>
            <Button
              type="primary"
              form={formName}
              htmlType="submit"
              loading={createPractices.loading}
            >
              {typeModal}
            </Button>
          </div>
        </Form.Item>,
      ]}
    >
      <Form
        name={formName}
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="dayPractice"
          label="Ngày thực hành thai giáo thứ (nhập số)"
          hasFeedback
          rules={[{ required: true, message: 'Vui lòng nhập ngày!' }]}
        >
          <InputNumber
            controls={false}
            min={0}
            placeholder="Nhập ngày..."
            style={{ width: '100%' }}
          />
        </Form.Item>

        <PracticesFormList
          posts={data?.posts}
          handleSetLengthPractices={handleSetLengthPractices}
        />
      </Form>
    </Modal>
  );
};

export default ModalCreate;
