import { getQueryString } from 'utils/stringUtils';
import { request } from '..';

export const create = (params: any) => {
  const url = '/api/pregnant-education/posts';
  return request(url, 'post', params);
};

const posts = (params: any) => {
  const url = getQueryString('/api/pregnant-education/posts', params);
  return request(url);
};

const deletePost = (id: number) => {
  return request(`/api/pregnant-education/posts/${id}`, 'delete');
};

const update = (id: number, params: any) => {
  const url = `/api/pregnant-education/posts/${id}`;
  return request(url, 'patch', params);
};

const detail = (id: number) => {
  return request(`/api/pregnant-education/posts/${id}`);
};

export const pregnantEducationService = {
  create,
  posts,
  update,
  detail,
  deletePost,
};
