import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  notification,
} from 'antd';
import UploadFileChunks from 'components/UploadFileChunks';
import { phaseService } from 'network/services/phase';
import React from 'react';
import { PREGNANT_STAGE } from 'utils/enum';

type Props = {
  dataDetail: PhaseInterface | undefined;
  onHideModal: () => void;
  onReload: () => void;
  type?: string;
};

export type PhaseParams = {
  banner?: string;
  code?: string;
  description?: string;
  icon?: string;
  isDisable?: boolean;
  name?: string;
  platform?: string;
  pregnantStage?: string;
};
export const ModalCreatePhase: React.FC<Props> = ({
  dataDetail,
  onHideModal,
  onReload,
  type,
}) => {
  const [form] = Form.useForm();
  const [imageIdStr, setImageIdStr] = React.useState<string | undefined>('');
  const [imageIdStrBanner, setImageIdStrBanner] = React.useState<
    string | undefined
  >('');
  const [showApp, setShowApp] = React.useState<boolean>(true);
  const [showWeb, setShowWeb] = React.useState<boolean>(false);
  const [pregnantStage, setPregnantStage] = React.useState<string>('');

  React.useEffect(() => {
    if (type === 'Chỉnh sửa') {
      form.setFieldsValue({
        code: dataDetail?.code,
        name: dataDetail?.name,
        description: dataDetail?.description,
        pregnantStage: dataDetail?.pregnantStage,
      });
      setImageIdStrBanner(dataDetail?.banner);
      setImageIdStr(dataDetail?.icon);
      if (dataDetail?.platform === 'web') {
        setShowWeb(true);
      } else if (dataDetail?.platform === 'app') {
        setShowApp(true);
      } else if (dataDetail?.platform === 'all') {
        setShowApp(true);
        setShowWeb(true);
      }
    } else {
      form.resetFields();
    }
  }, [dataDetail]);

  const onDeleteImage = () => {
    setImageIdStr('');
  };

  const onDeleteImageBanner = () => {
    setImageIdStrBanner('');
  };

  const uploadFileSuccess = (file: { linkUrl: string }) => {
    setImageIdStr(file.linkUrl);
  };

  const uploadFileSuccessBanner = (file: { linkUrl: string }) => {
    setImageIdStrBanner(file.linkUrl);
  };

  const handleSelectApp = (value: boolean) => {
    setShowApp(value);
  };
  const handleSelectWeb = (value: boolean) => {
    setShowWeb(value);
  };
  const renderTitleHeader = () => {
    return (
      <div className="flex justify-center bg-[#1890FF] decoration-white m-[-24px] mb-[20px] p-[15px] rounded-t-lg">
        {`${type} Danh mục`}
      </div>
    );
  };

  const onSubmit = async (value: PhaseInterface) => {
    const renderPlatform = () => {
      if (showApp && !showWeb) {
        return 'app';
      } else if (showWeb && !showApp) {
        return 'web';
      } else if (showApp && showWeb) {
        return 'all';
      }
    };
    const params = {
      ...value,
      banner: imageIdStrBanner,
      icon: imageIdStr,
      isDisable: true,
      pregnantStage: pregnantStage,
      platform: renderPlatform(),
    };
    if (type === 'Thêm mới') {
      const response = await phaseService.create(params);
      if (response.status === 201) {
        notification.success({ message: `${type} Danh mục thành công` });
        onHideModal();
        onReload();
      }
    } else {
      const response = await phaseService.update(dataDetail?.id, params);
      if (response.status === 200) {
        notification.success({ message: `${type} Danh mục thành công` });
        onHideModal();
        onReload();
      }
    }
  };

  const onChangePregnantStage = (value: string) => {
    setPregnantStage(value);
  };

  return (
    <Modal
      title={renderTitleHeader()}
      open={true}
      footer={null}
      onOk={onHideModal}
      onCancel={onHideModal}
      width={500}
      closeIcon={<CloseOutlined style={{ color: '#fff', fontSize: '20px' }} />}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          label="Mã danh mục"
          name="code"
          rules={[{ message: 'Vui lòng nhập mã danh mục!', required: true }]}
        >
          <Input
            placeholder="Nhập mã danh mục..."
            maxLength={250}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="Tên giai đoạn"
          name="name"
          rules={[{ message: 'Vui lòng nhập tên giai đoạn!', required: true }]}
        >
          <Input
            placeholder="Nhập tên giai đoạn..."
            maxLength={250}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item required label="Chọn giai đoạn" name="pregnantStage">
          <Select
            allowClear
            placeholder="Chọn giai đoạn"
            onChange={onChangePregnantStage}
            options={PREGNANT_STAGE.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
          />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <span style={{ marginRight: '8px' }}>Hiển thị trên: </span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ marginRight: '8px' }}>App</label>
            <Switch checked={showApp} onChange={handleSelectApp} />
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}
          >
            <label style={{ marginRight: '8px' }}>Web</label>
            <Switch checked={showWeb} onChange={handleSelectWeb} />
          </div>
        </div>

        {imageIdStr ? (
          <div>
            <p>Icon</p>
            <Space>
              <Image width={200} src={imageIdStr} />
              <DeleteOutlined
                style={{ fontSize: '44px' }}
                onClick={onDeleteImage}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            isRequired={true}
            title={'Icon'}
            name="images"
            type={'IMAGE' as any}
            accept="image/*"
            description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
            category={'banner'}
            uploadFileSuccess={uploadFileSuccess}
          />
        )}

        {imageIdStrBanner ? (
          <div>
            <p>Ảnh Banner *</p>
            <Space>
              <Image width={200} src={imageIdStrBanner} />
              <DeleteOutlined
                style={{ fontSize: '44px' }}
                onClick={onDeleteImageBanner}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            name="banner"
            type={'IMAGE' as any}
            accept="image/*"
            description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
            category={'general_topic'}
            uploadFileSuccess={uploadFileSuccessBanner}
            title="Ảnh Banner"
            isRequired={true}
          />
        )}

        <Form.Item label="Mô tả" name="description">
          <Input.TextArea
            placeholder="Nhập mô tả không quá 150 ký tự..."
            maxLength={150}
            style={{ width: '100%', height: 100 }}
          />
        </Form.Item>
        <div className={'center-item'}>
          <Button type="primary" htmlType="submit">
            {type}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
