import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const posts = (params?: any) => {
  deleteParamsNotUsing(params)
  const url = getQueryString('/api/roadmap-280day/posts', params);
  return request(url);
};

const create = (params: any) => {
  const url = '/api/roadmap-280day/posts';
  return request(url, 'post', params);
};

const edit = (id: number, params: any) => {
  const url = `/api/roadmap-280day/posts/${id}`;
  return request(url, 'patch', params);
};

const deleteP = (id: number) => {
  return request(`/api/roadmap-280day/posts/${id}`, 'delete');
};

const detail = (id: number) => {
  const url = `/api/roadmap-280day/posts/${id}`;
  return request(url);
};

const textToAudio = (params: AudioParams) => {
  const url = `/api/roadmap-280day/posts/text-to-speech`;
  return request(url, 'post', params);
};

export const postRoadMap280Service = {
  posts,
  create,
  edit,
  detail,
  deleteP,
  textToAudio,
};
