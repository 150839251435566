import { request } from '..';
import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';

const get = (params: FilterPostsType) => {
  deleteParamsNotUsing(params);
  const url = getQueryString('/api/course/content/filter', params);
  return request(url);
};
const create = (params?: ContentParams) => {
  const url = '/api/course/content/create';
  return request(url, 'post', params);
};
const update = (id: number | undefined, body: ContentParams) => {
  const url = `/api/course/content/update` + `/${id}`;
  return request(url, 'put', body);
};

const details = (id: number | undefined) => {
  const url = `/api/course/content/detail` + `/${id}`;
  return request(url, 'get');
};

const textToAudio = (params: AudioParams) => {
  const url = `/api/course/content/text-to-speech`;
  return request(url, 'post', params);
};

const disable = (id: number) => {
  const url = `/api/course/content` + `/${id}` + `/disable`;
  return request(url, 'patch');
};

const enable = (id: number) => {
  const url = `/api/course/content` + `/${id}` + `/enable`;
  return request(url, 'patch');
};

const uploadBase64Img = (body: { base64: string; category: string }) => {
  const url = `/api/attachments/uploadBase64`;
  return request(url, 'post', body);
};
const getAllPosts = () => {
  const url = '/api/course/content/get-website-posts';
  return request(url, 'get');
};

export const postContentService = {
  get,
  create,
  update,
  details,
  textToAudio,
  disable,
  enable,
  uploadBase64Img,
  getAllPosts,
};
