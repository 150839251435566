type Props = {
  handleEditModal: (value: any) => void;
  value: any;
  activeVoucher: (value: any) => void;
};

const BoxActions = ({ handleEditModal, value, activeVoucher }: Props) => {
  return (
    <div>
      <div
        style={{
          cursor: 'pointer',
        }}
      >
        <div
          style={{ color: value.activated ? '#1890FF' : '#569C30' }}
          onClick={() => activeVoucher(value)}
        >
          {value.activated ? 'Ngưng voucher' : 'Kích hoạt voucher'}
        </div>
      </div>
      <div
        style={{
          cursor: 'pointer',
          color: 'blue',
        }}
        onClick={() => handleEditModal(value)}
      >
        Chỉnh sửa
      </div>
    </div>
  );
};

export default BoxActions;
