import Sider from 'antd/es/layout/Sider';
import AdminIcon from 'assets/image/adminIcon.jpg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MomeduLogo from './logo/MomeduLogo.png';

import { HomeOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import AffiliateLogo from 'components/Sider/logo/Affiliate';
import CMSLogo from 'components/Sider/logo/CMS';
import CRMLogo from 'components/Sider/logo/CRM';
import { useWindowSize } from 'hook';
import { Link } from 'react-router-dom';
import {
  DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE,
  LOCAL_STORAGE_KEYS,
  ROUTERS_ROLE,
} from 'utils/constants';
import { ROUTERS } from 'utils/routers';
import AccountManage from './logo/AccountManage';
import './style.scss';

const SiderApp = () => {
  const { width } = useWindowSize();

  const userInfo: any = JSON?.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.userInfo) || '{}'
  );
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const navigate = useNavigate();
  const packageData = require('../../../package.json');

  const Logo = () => (
    <div className="logo">
      {collapsed ? (
        <img src={AdminIcon} />
      ) : (
        <div
          className="flex justify-center cursor-pointer p-5 pb-2"
          onClick={() => {
            navigate('/');
          }}
        >
          <img src={MomeduLogo} />
        </div>
      )}
    </div>
  );

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    path?: string,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label: path ? (
        <Link to={path} className="text-white">
          {label}
        </Link>
      ) : (
        label
      ),
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem(
      'Trang chủ',
      ROUTERS.HOME.key,
      ROUTERS.HOME.path,
      <div className="w-7">
        <HomeOutlined style={{ fontSize: '24px', color: '#fff' }} />
      </div>,
      [
        getItem(
          ROUTERS.REPORTS.name,
          ROUTERS.REPORTS.key,
          ROUTERS.REPORTS.path
        ),
        getItem(
          ROUTERS.DASHBOARD.name,
          ROUTERS.DASHBOARD.key,
          ROUTERS.DASHBOARD.path
        ),
      ]
    ),
    getItem(
      'CMS',
      ROUTERS.CMS.key,
      ROUTERS.CMS.path,
      <div className="w-7">
        <CMSLogo />
      </div>,
      [
        getItem(
          ROUTERS.COURSES.name,
          ROUTERS.COURSES.key,
          ROUTERS.COURSES.path
        ),
        getItem(
          ROUTERS.PACKAGES.name,
          ROUTERS.PACKAGES.key,
          ROUTERS.PACKAGES.path
        ),
        getItem(
          ROUTERS.POSTS_MANAGER.name,
          ROUTERS.POSTS_MANAGER.key,
          ROUTERS.POSTS_MANAGER.path,
          '',
          [
            getItem(
              ROUTERS.POSTS_NEW.name,
              ROUTERS.POSTS_NEW.key,
              ROUTERS.POSTS_NEW.path
            ),
            getItem(
              ROUTERS.POSTS_TG.name,
              ROUTERS.POSTS_TG.key,
              ROUTERS.POSTS_TG.path,
              '',
              [
                getItem(
                  ROUTERS.POSTS_TG_280N.name,
                  ROUTERS.POSTS_TG_280N.key,
                  ROUTERS.POSTS_TG_280N.path
                ),
                getItem(
                  ROUTERS.POSTS.name,
                  ROUTERS.POSTS.key,
                  ROUTERS.POSTS.path
                ),
                getItem(
                  ROUTERS.ROAD_MAP_PRACTICES.name,
                  ROUTERS.ROAD_MAP_PRACTICES.key,
                  ROUTERS.ROAD_MAP_PRACTICES.path
                ),
              ]
            ),
          ]
        ),
        getItem('Pop-up', ROUTERS.POP_UP.key, ROUTERS.POP_UP.path),
        getItem(
          'Thông báo',
          ROUTERS.NOTIFICATIONS.key,
          ROUTERS.NOTIFICATIONS.path
        ),
        getItem('Banner', ROUTERS.BANNER.key, ROUTERS.BANNER.path),
      ]
    ),
    getItem(
      'CMS-WEB',
      ROUTERS.CMS_WEB.key,
      ROUTERS.CMS_WEB.path,
      <div className="w-7">
        <CMSLogo />
      </div>,
      [
        getItem(
          ROUTERS.MANAGER_POSTS_WEBSITE.name,
          ROUTERS.MANAGER_POSTS_WEBSITE.key,
          ROUTERS.MANAGER_POSTS_WEBSITE.path
        ),
        getItem(
          ROUTERS.MANAGER_CATEGORY_WEBSITE.name,
          ROUTERS.MANAGER_CATEGORY_WEBSITE.key,
          ROUTERS.MANAGER_CATEGORY_WEBSITE.path
        ),
      ]
    ),
    getItem(
      'CRM',
      ROUTERS.CRM.key,
      ROUTERS.CRM.path,
      <div className="w-7">
        <CRMLogo />
      </div>,
      [
        getItem('Đơn hàng', ROUTERS.ORDERS.key, ROUTERS.ORDERS.path),
        getItem('Khách hàng', ROUTERS.CUSTOMERS.key, ROUTERS.CUSTOMERS.path),
        getItem('Hot Lead', ROUTERS.HOT_LEAD.key, ROUTERS.HOT_LEAD.path),
      ]
    ),
    getItem(
      'Affiliate',
      ROUTERS.AFFILIATE.key,
      ROUTERS.AFFILIATE.path,
      <div className="w-7">
        <AffiliateLogo />
      </div>,
      [getItem('Voucher', ROUTERS.VOUCHERS.key, ROUTERS.VOUCHERS.path)]
    ),
    getItem(
      'Quản lý tài khoản',
      ROUTERS.ACCOUNTS.key,
      ROUTERS.ACCOUNTS.path,
      <div className="w-7">
        <AccountManage />
      </div>
    ),
    getItem(
      'App Pregnancy Tracker',
      ROUTERS.PREGNANCY_TRACKER.key,
      ROUTERS.PREGNANCY_TRACKER.path,
      <div className="w-7">
        <AccountManage />
      </div>
    ),
    getItem(
      'App Baby Tracker',
      ROUTERS.BABY_TRACKER.key,
      ROUTERS.BABY_TRACKER.path,
      <div className="w-7">
        <AccountManage />
      </div>
    ),
  ];

  const getRoutersWithPermission = (arr: any[], role: string): MenuItem[] => {
    return arr
      .filter((item) => {
        const routerFound = ROUTERS_ROLE.find(
          (element) => element.scene === item.key
        );
        if (routerFound?.roles?.includes(role)) {
          if (Array.isArray(item.children)) {
            item.children = getRoutersWithPermission(item.children, role);
          }
          return true;
        }
        return false;
      })
      .map((item) => {
        if (Array.isArray(item.children)) {
          item.children = getRoutersWithPermission(item.children, role);
        }
        return item;
      });
  };

  const newArr = getRoutersWithPermission(items, userInfo?.role?.code);

  const isMobile = width < DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE;

  if (isMobile) {
    return (
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[ROUTERS.HOME.key]}
        items={newArr}
      />
    );
  }
  return (
    <Sider
      id="app-layout-sider"
      collapsible
      reverseArrow
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      className="my-menu"
    >
      {Logo()}
      <div className="flex w-full text-white items-center justify-center pb-4">
        {packageData.version}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[ROUTERS.HOME.key]}
        items={newArr}
      />
    </Sider>
  );
};

export default SiderApp;
