import { isNullOrEmpty } from './stringUtils';

export const validateNumber = (_rule: any, value: any, callback: any) => {
  if (isNullOrEmpty(value)) {
    callback('Vui lòng nhập số nguyên dương');
  } else if (value <= 0) {
    callback('Vui lòng nhập số lớn hơn 0');
  } else {
    callback();
  }
};

export const validateNumberPrice = (_rule: any, value: any, callback: any) => {
  const numericValue = parseFloat(value.replace(/,/g, '').replace(/\./g, ''));
  if (isNaN(numericValue) || numericValue <= 0) {
    callback('Vui lòng nhập số nguyên dương');
  } else {
    callback();
  }
};

export const formatPrice = (price: number | undefined): string | undefined => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const validateNumberVoucher = (
  _rule: any,
  value: any,
  callback: (error?: string | undefined) => void
) => {
  if (isNaN(value) || value <= 0 || value > 100) {
    callback('Vui lòng nhập số nguyên dương từ 1 đến 100');
  } else {
    callback();
  }
};

export const validateEmail = (_: any, value: any, callback: any) => {
  const nameRegex = /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
  if (value && !nameRegex.test(value)) {
    callback('Vui lòng nhập đúng định dạng Tên đăng nhập!');
  } else {
    callback();
  }
};

export const validateName = (_: any, value: any, callback: any) => {
  const nameRegex =
    /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]+$/; // regular expression pattern to match letters only
  if (value && !nameRegex.test(value)) {
    callback('Vui lòng chỉ nhập chữ');
  } else {
    callback();
  }
};
