import { DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Space } from 'antd';
import AudioPlayer from 'components/AudioPlayer';
import UploadFileChunks from 'components/UploadFileChunks';
import React from 'react';

type Props = {
  category: string;
  duration: number;
  uploadFileSuccessAudio: (value: any) => void;
  onDeleteAudio: () => void;
  audioUrl: string | undefined;
};

const ViewAudio: React.FC<Props> = ({
  category,
  duration,
  audioUrl,
  onDeleteAudio,
  uploadFileSuccessAudio,
}) => {
  // const validateNumber = (rule: any, value: any, callback: any) => {
  //   if (isNaN(value) || value <= 0) {
  //     callback('Vui lòng nhập thời gian là số nguyên dương');
  //   } else {
  //     callback();
  //   }
  // };

  return (
    <Row gutter={[8, 8]}>
      <Col span={8}>
        <Form.Item
          name="postTime"
          label="Thời gian (giây)"
          // rules={[{ validator: validateNumber }]}
          initialValue={duration}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col>
        {audioUrl ? (
          <div>
            <p>* Audio</p>
            <Space>
              <AudioPlayer url={audioUrl} />
              <DeleteOutlined
                style={{ fontSize: '1.5em' }}
                onClick={onDeleteAudio}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            name="audio"
            type={'AUDIO' as any}
            accept="audio/*"
            maxSize={100 * 1000 * 10000}
            description="(Tải file mp3... Kích thước không quá 100MB...)"
            category={category}
            uploadFileSuccess={uploadFileSuccessAudio}
            title="Audio"
            isRequired
          />
        )}
      </Col>
    </Row>
  );
};

export default ViewAudio;
