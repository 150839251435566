import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { Api, request } from '..';

const STAFFS_URL = '/api/staffs';

const staffs = (params: StaffsParamsInterface) => {
  deleteParamsNotUsing(params);
  const url = getQueryString(STAFFS_URL, params);
  return request(url);
};

export const getStaffDetail = (
  query: string,
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) => Api.get(`/api/staffs/${query}`, undefined, successCallback, failCallback);

const addStaff = (body: ActionAccountInput) => {
  return request('/api/staffs', 'post', body);
};

const editStaff = (id: number, params: ActionAccountInput) => {
  const url = `/api/staffs/${id}`;
  return request(url, 'patch', params);
};

export const resetPasswordStaff = (
  query: string,
  variables: { newPassword: string },
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) =>
  Api.post(
    `api/staffs/${query}/reset-password`,
    variables,
    successCallback,
    failCallback
  );

export const getRoleUser = () => {
  const url = `api/roles`;
  return request(url, 'get');
};

export const accountsService = {
  staffs,
  getStaffDetail,
  addStaff,
  editStaff,
  resetPasswordStaff,
  getRoleUser,
};
