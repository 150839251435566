import { BackwardOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Cascader,
  Col,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import UploadFileChunks from 'components/UploadFileChunks';
import { RESPONSE_CODE } from 'network/constants';
import { postContentService } from 'network/services/postContent';
import React, { useMemo } from 'react';
import { getCDNUrl } from 'utils';
import { validateNumber } from 'utils/helper';
import { isNullOrEmpty } from 'utils/stringUtils';
import ViewAudio from './audio';
import ViewPost from './post';
import SearchInput from './searchInput';
import './styles.scss';
import ViewVideo from './video';

type Props = {
  platform: 'app' | 'web';
  dataDetail: PostDetailsInterface | undefined;
  itemDetails: PostDetailsInterface | undefined;
  onHideModal: () => void;
  onReload: () => void;
  type?: string;
  handleOnChangeModal: () => void;
  listDataHashTag: HashTag[];
  onChangeHashTag?: (values: string[]) => void;
  categoryStructure: CategoryStructure[];
  allPosts: WebsitePostProps[];
};

export const ModalAddNews: React.FC<Props> = ({
  platform,
  dataDetail,
  itemDetails,
  onHideModal,
  onReload,
  type,
  handleOnChangeModal,
  listDataHashTag,
  onChangeHashTag,
  categoryStructure,
  allPosts,
}) => {
  const [form] = useForm();
  const [formatValues, setFormatValues] = React.useState<number | undefined>(1);
  const [imagesValues, setImagesValues] = React.useState<string | undefined>(
    ''
  );
  const [thumbnail, setThumbnail] = React.useState<string | undefined>('');
  const [videoUrl, setVideoUrl] = React.useState<string | undefined>(undefined);
  const [audioUrl, setAudioUrl] = React.useState<string | undefined>(undefined);
  const [attachment, setAttachment] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const postTime = 0;
  const [isFree, setIsFree] = React.useState<boolean | undefined>(false);

  const filterPostById = (id?: number) => {
    if (isNullOrEmpty(id)) {
      return null;
    }
    const result = allPosts?.filter((item) => item?.id === id);
    if (!isNullOrEmpty(result)) {
      return result[0]?.id;
    } else {
      return null;
    }
  };

  const initialValues = useMemo(() => {
    return {
      format: dataDetail?.format,
      name: dataDetail?.courseContent?.name,
      topicSelected: itemDetails?.courseSection?.id
        ? [
            itemDetails?.stage?.id,
            itemDetails?.course?.id,
            itemDetails?.category?.id,
            itemDetails?.courseSection?.id,
          ]
        : undefined,
      hashTag: dataDetail?.courseContent.hashtags ?? undefined,
      description: dataDetail?.courseContent.description ?? undefined,
      orderNumberInSection: dataDetail?.orderNumberInSection ?? undefined,
      content: dataDetail?.courseContent.content ?? '',
      duration: dataDetail?.duration ?? undefined,
      status: dataDetail?.status ?? 0,
      metadataDescription: dataDetail?.metadataDescription ?? undefined,
      relatedPost1: filterPostById(dataDetail?.relatedPosts?.[0]) ?? undefined,
      relatedPost2: filterPostById(dataDetail?.relatedPosts?.[1]) ?? undefined,
      relatedPost3: filterPostById(dataDetail?.relatedPosts?.[2]) ?? undefined,
      relatedPost4: filterPostById(dataDetail?.relatedPosts?.[3]) ?? undefined,
      relatedPost5: filterPostById(dataDetail?.relatedPosts?.[4]) ?? undefined,
      relatedPost6: filterPostById(dataDetail?.relatedPosts?.[5]) ?? undefined,
    };
  }, [dataDetail]);

  React.useEffect(() => {
    if (type === 'Chỉnh sửa') {
      setFormatValues(dataDetail?.format);
      setImagesValues(dataDetail?.icon);
      setThumbnail(dataDetail?.thumbnail);
      setVideoUrl(dataDetail?.courseContent?.attachments[0]?.uri);
      setAudioUrl(dataDetail?.courseContent?.attachments[0]?.uri);
      setIsFree(itemDetails?.freeToRead);
      setAttachment(dataDetail?.courseContent?.attachments[0]);
    }
  }, [dataDetail, allPosts]);

  React.useEffect(() => {
    if (type === 'Chỉnh sửa') {
    } else {
      form.setFieldsValue({
        format: 1,
      });
    }
  }, [type]);

  const uploadFileSuccess = (file: { linkUrl: string }) => {
    setImagesValues(file.linkUrl);
  };

  const uploadFileThumbnailSuccess = (file: { linkUrl: string }) => {
    setThumbnail(file.linkUrl);
  };

  const handleOnChangeFormatValues = (value: number) => {
    setFormatValues(value);
  };

  const uploadFileSuccessVideo = (value: any) => {
    setAttachment(value);
    setVideoUrl(getCDNUrl(value.idStr));
  };

  const onDeleteVideo = () => {
    setVideoUrl(undefined);
  };

  const uploadFileSuccessAudio = (value: any) => {
    setAttachment(value);
    setAudioUrl(getCDNUrl(value.idStr));
  };

  const onDeleteAudio = () => {
    setAudioUrl(undefined);
  };

  const onDeleteImage = () => {
    setImagesValues('');
  };

  const onDeleteThumbnail = () => {
    setThumbnail('');
  };

  const handleSelect = (value: boolean) => {
    setIsFree(value);
  };

  const attachments = () => {
    if (formatValues === 2) {
      return videoUrl;
    } else if (formatValues === 3) {
      return audioUrl;
    }
  };

  const hasDuplicate = (arr: number[]) => {
    const seen = new Set();
    for (const element of arr) {
      if (seen.has(element)) {
        return true;
      }
      seen.add(element);
    }
    return false;
  };

  const uploadBase64Img = async (data?: string, maxRetries = 3) => {
    if (!data) {
      return;
    }
    let retries = 0;

    while (retries < maxRetries) {
      const res = await postContentService.uploadBase64Img({
        base64: data,
        category: 'general_post',
      });

      if (res?.status === RESPONSE_CODE.SUCCESS) {
        return res?.data?.linkUrl;
      }

      retries++;
    }

    notification.error({ message: 'Có lỗi xảy ra. Vui lòng thử lại' });
    return null;
  };

  function replaceImgTagsWithUrls(htmlString: string, arr: string[]) {
    let counter = 0;

    const replacedHtmlString = htmlString.replace(/<img\b[^>]*>/g, () => {
      const replacement = arr[counter];
      counter++;
      return replacement;
    });

    return replacedHtmlString;
  }

  const getImgData = async (htmlString: string) => {
    setIsLoading(true);
    let finalData = [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const imgTags = doc.querySelectorAll('img');
    const imgSrcArray: any = Array.from(imgTags).map((img) =>
      img.getAttribute('src')
    );

    if (!isNullOrEmpty(imgSrcArray)) {
      for (let i = 0; i < imgSrcArray.length; i++) {
        if (imgSrcArray[i]?.includes('data:image')) {
          const result = await uploadBase64Img(imgSrcArray[i]);
          if (!isNullOrEmpty(result)) {
            finalData.push(`<img src=${result}/>`);
          } else {
            break;
          }
        } else {
          finalData.push(`<img src=${imgSrcArray[i]}/>`);
        }
      }
    } else {
      setIsLoading(false);
      return htmlString;
    }

    if (finalData?.length > 0) {
      const finalContent = replaceImgTagsWithUrls(htmlString, finalData);
      setIsLoading(false);
      return finalContent;
    }
    setIsLoading(false);

    return null;
  };

  const onSubmit = async (value: ContentParams) => {
    const relatedPostData: any[] = [
      value?.relatedPost1,
      value?.relatedPost2,
      value?.relatedPost3,
      value?.relatedPost4,
      value?.relatedPost5,
      value?.relatedPost6,
    ];

    if (hasDuplicate(relatedPostData)) {
      notification.error({
        message: 'Các bài viết liên quan không được trung nhau',
      });
      return;
    }

    const content = await getImgData(value?.content);

    if (!isNullOrEmpty(content)) {
      let params = {
        ...value,
        stageId: value.topicSelected?.[0],
        courseId: value.topicSelected?.[1],
        categoryId: value.topicSelected?.[2],
        sectionId: value.topicSelected?.[3],
        format: value?.format ?? 1,
        content: formatValues === 1 ? content : undefined,
        code: '',
        icon: imagesValues,
        thumbnail: thumbnail,
        header: '',
        personRefer: [0],
        attachments:
          formatValues !== 1
            ? [
                {
                  format: formatValues,
                  position: 0,
                  type: '',
                  linkUrl: attachments(),
                  ...attachment,
                },
              ]
            : [],
        freeToRead: isFree,
        relatedPosts: relatedPostData,
      };
      setIsLoading(true);
      if (type === 'Thêm mới') {
        const resp = await postContentService.create(params);
        if (resp.status === 200) {
          onHideModal();
          onReload();
          notification.success({ message: 'Thêm mới bài viết thành công' });
        } else {
          notification.error({ message: 'Thêm mới bài viết thất bại' });
        }
      } else {
        const resp = await postContentService.update(itemDetails?.id, params);
        if (resp.status === 200) {
          onHideModal();
          onReload();
          notification.success({ message: 'Chỉnh sửa bài viết thành công' });
        }
      }
      setIsLoading(false);
    } else {
      return;
    }
  };

  const convertArray = (arr: any) =>
    arr.map((item: any) => ({
      value: item.id,
      label: item.name,
      code: item.code,
      children: item.children ? convertArray(item.children) : [],
    }));

  const renderViewByFormat = (format?: number) => {
    switch (format) {
      case 1:
        return (
          <ViewPost
            type={type}
            platform={platform}
            uuid={dataDetail?.uuid}
            html={dataDetail?.courseContent.content}
            isPressConvert={dataDetail?.isPressConvert}
            attachments={dataDetail?.courseContent.attachments}
            className={platform ? 'flex-1' : ''}
          />
        );
      case 2:
        return (
          <ViewVideo
            category="general_post"
            onDeleteVideo={onDeleteVideo}
            uploadFileSuccessVideo={uploadFileSuccessVideo}
            videoUrl={videoUrl}
          />
        );
      case 3:
        return (
          <ViewAudio
            category={'general_post'}
            duration={postTime as any}
            audioUrl={audioUrl}
            onDeleteAudio={onDeleteAudio}
            uploadFileSuccessAudio={uploadFileSuccessAudio}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="w-full addNewContainer">
      <div
        className={['flex  mb-5 justify-between header pb-[10px]'].join(' ')}
      >
        <div className="flex gap-2.5">
          <Button onClick={handleOnChangeModal}>
            <BackwardOutlined />
          </Button>
          <div className="text-xl font-bold">{type} bài viết</div>
        </div>
      </div>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        initialValues={initialValues}
        className="pt-[50px]"
      >
        <div className="px-5 overflow-auto h-full">
          {platform === 'app' && (
            <Form.Item
              label="Định dạng"
              name={'format'}
              rules={[{ required: true, message: 'Vui lòng chọn định dạng' }]}
            >
              <Radio.Group
                onChange={(e) => handleOnChangeFormatValues(e.target.value)}
                disabled={type === 'Chỉnh sửa'}
              >
                <Radio value={1}>Bài viết</Radio>
                <Radio value={2}>Video</Radio>
                <Radio value={3}>Audio</Radio>
              </Radio.Group>
            </Form.Item>
          )}

          <Row gutter={24}>
            <Col className="gutter-row" span={20}>
              <Form.Item
                label="Chọn chủ đề"
                className="w-full"
                name={'topicSelected'}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (value?.length === 4) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Vui lòng chọn chủ đề'));
                    },
                  },
                ]}
              >
                <Cascader
                  className="w-full"
                  options={convertArray(categoryStructure)}
                  maxTagCount="responsive"
                  placeholder={'Chọn chủ đề cho bài viết'}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={4}>
              <Form.Item
                label="Trạng thái"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập trạng thái',
                  },
                ]}
              >
                <Select
                  options={[
                    { label: 'Hiển thị', value: 0 },
                    { label: 'Ẩn', value: 1 },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Tiêu đề bài viết"
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tiêu đề',
              },
            ]}
          >
            <Input placeholder="Nhập tiêu đề..." />
          </Form.Item>

          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <div
                style={{
                  display: 'inline-block',
                  width: 'calc(60% - 8px)',
                }}
              >
                {imagesValues ? (
                  <div>
                    <p>Ảnh Icon *</p>
                    <Space>
                      <Image width={100} src={imagesValues} />
                      <DeleteOutlined
                        style={{ fontSize: '44px' }}
                        onClick={onDeleteImage}
                      />
                    </Space>
                  </div>
                ) : (
                  <UploadFileChunks
                    key={'image-icon'}
                    name="icon"
                    type={'IMAGE' as any}
                    accept="image/*"
                    description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
                    category={'general_post'}
                    uploadFileSuccess={uploadFileSuccess}
                    title="Ảnh Icon"
                  />
                )}
              </div>
            </Col>

            {platform === 'web' && (
              <Col className="gutter-row" span={12}>
                <div
                  style={{
                    display: 'inline-block',
                    width: 'calc(60% - 8px)',
                  }}
                >
                  {thumbnail ? (
                    <div>
                      <p>Ảnh Thumbnail *</p>
                      <Space>
                        <Image width={100} src={thumbnail} />
                        <DeleteOutlined
                          style={{ fontSize: '44px' }}
                          onClick={onDeleteThumbnail}
                        />
                      </Space>
                    </div>
                  ) : (
                    <UploadFileChunks
                      key={'image-thumbnail'}
                      name="thumbnail"
                      type={'IMAGE' as any}
                      accept="image/*"
                      description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
                      category={'general_post'}
                      uploadFileSuccess={uploadFileThumbnailSuccess}
                      title="Ảnh Thumbnail"
                    />
                  )}
                </div>
              </Col>
            )}
          </Row>

          <Form.Item
            label="Mô tả"
            className="mt-2"
            name="description"
            rules={
              platform === 'web'
                ? [
                    {
                      required: true,
                      message: 'Vui lòng nhập mô tả',
                    },
                  ]
                : undefined
            }
          >
            <Input.TextArea rows={2} placeholder="Nhập mô tả..." />
          </Form.Item>

          <div className="flex">{renderViewByFormat(formatValues)}</div>

          {platform === 'app' && (
            <Col className="gutter-row" span={12}>
              <Form.Item
                name={'freeToRead'}
                label="Xem miễn phí"
                className="mt-3"
              >
                <Switch checked={isFree} onChange={handleSelect} />
              </Form.Item>
            </Col>
          )}

          {platform === 'app' && (
            <Form.Item
              label="Thứ tự bài viết"
              name={'orderNumberInSection'}
              rules={[{ required: true, validator: validateNumber }]}
            >
              <Input placeholder="Nhập thứ tự..." />
            </Form.Item>
          )}

          {platform === 'web' && (
            <Form.Item
              label="Metadata Description"
              name="metadataDescription"
              className="mt-4"
            >
              <Input placeholder="Nhập metadata mô tả..." />
            </Form.Item>
          )}

          <Form.Item label="Hashtag" name="hashTag">
            <Select
              placeholder="Nhập hashtag..."
              mode="tags"
              options={listDataHashTag?.map((item: HashTag) => {
                return {
                  label: item.name,
                  value: item.name,
                };
              })}
              onChange={onChangeHashTag}
            />
          </Form.Item>

          <Form.Item label="Ghi chú" name="note">
            <Input placeholder="Nhập ghi chú..." />
          </Form.Item>

          <div className="ant-form-item-required pb-[8px]">
            <label className="text-[#ff4d4f] mr-[4px]">*</label>
            Bài viết liên quan
          </div>
          <SearchInput allPosts={allPosts} name={'relatedPost1'} />
          <SearchInput allPosts={allPosts} name={'relatedPost2'} />
          <SearchInput allPosts={allPosts} name={'relatedPost3'} />
          <SearchInput allPosts={allPosts} name={'relatedPost4'} />
          <SearchInput allPosts={allPosts} name={'relatedPost5'} />
          <SearchInput allPosts={allPosts} name={'relatedPost6'} />
        </div>
        <div className="flex justify-center">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {type}
          </Button>
        </div>
      </Form>
    </div>
  );
};
