import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const ORDER_URL = '/api/order';

const get = (params?: any) => {
  deleteParamsNotUsing(params);
  const url = getQueryString('/api/order/filter', params);
  return request(url);
};

const getInfo = () => {
  const url = getQueryString('/api/profile');
  return request(url);
};

const confirm = (id: number) => {
  const body = {
    status: 2, // confirm thì đẩy lên mặc định là 2 =))
  };
  const url = getQueryString(`${ORDER_URL}/${id}`);
  return request(url, 'patch', body);
};

const cancel = (id: number) => {
  const body = {
    status: 3, // cancel thì đẩy lên mặc định là 3 =))
  };
  const url = getQueryString(`${ORDER_URL}/${id}`);
  return request(url, 'patch', body);
};

const changeCustomerCareStatus = (id: number, status: number) => {
  const body = {
    customerCareStatus: status,
  };
  const url = getQueryString(`${ORDER_URL}/${id}/customer-care`);
  return request(url, 'patch', body);
};

export const ordersServices = {
  get,
  getInfo,
  confirm,
  cancel,
  changeCustomerCareStatus,
};
