import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Modal,
  Row,
  Col,
  Select,
  Switch,
  message,
} from 'antd';
import { customersServices } from 'network/services/customers';
import React, { FC, useState } from 'react';
import ModalSubmit from '../ModalSubmit';
import { useWindowSize } from 'hook';
import { DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE } from 'utils/constants';

type Props = {
  itemSelected: any;
  onHideModal: () => void;
  onReload: () => void;
  course: PackageInterface[];
};
const ModalUpgradeAccount: FC<Props> = ({
  itemSelected,
  onHideModal,
  onReload,
  course,
}) => {
  const [form] = Form.useForm();
  const [isModalSubmit, setIsModalSubmit] = useState(false);
  const [valueCourse, setValueCourse] = useState('0');
  const [params, setParams] = useState([]);
  const [vipPackages, setVipPackages] = useState<ItemVipPackage[]>([]);
  const { width } = useWindowSize();

  const handleIsModalSubmit = () => {
    setIsModalSubmit(!isModalSubmit);
  };

  const infos = [
    { id: 1, label: 'User ID:', value: itemSelected.id },
    { id: 2, label: 'Họ và tên:', value: itemSelected.fullname },
    { id: 3, label: 'Số điện thoại:', value: itemSelected.phoneNumber },
  ];

  const handleInputChange = (value: string) => {
    setValueCourse(value);
  };

  const onFinish = (values: any) => {
    const courseIdSet = new Set();
    let isDuplicate = false;

    values.listPackage.forEach((course: any) => {
      if (courseIdSet.has(course.courseId)) {
        isDuplicate = true;
        return;
      } else {
        courseIdSet.add(course.courseId);
      }
    });

    if (isDuplicate) {
      message.error('Trùng khóa học');
    } else {
      const newArray = values.listPackage.map((item: any) => ({
        activatedTime: new Date(item.activatedTime).toISOString(),
        vipPackageId: item.vipPackageId,
        isLifeTime: item.isLifeTime ? item.isLifeTime : false,
      }));
      if (values.listPackage.length > 0) {
        setIsModalSubmit(true);
        setParams(newArray);
      }
    }
  };

  React.useEffect(() => {
    fetchVipPackages();
  }, [valueCourse]);

  const fetchVipPackages = async () => {
    const response = await customersServices.vipPackages(valueCourse);
    setVipPackages(response.data?.data ?? []);
  };

  const initialValues = {
    listPackage: [{}, {}],
  };

  const isMobile = width < DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE;

  return (
    <>
      <Modal
        title={'KÍCH HOẠT KHOÁ HỌC'}
        open={true}
        footer={null}
        onOk={onHideModal}
        onCancel={onHideModal}
        width={isMobile ? 'auto' : 1000}
        closable={false}
        centered
      >
        <div className="mt-5">
          {infos.map((item) => (
            <div className="flex flex-row" key={item.id}>
              <p style={{ width: 120 }}>{item.label}</p>
              <p className="font-bold">{item.value}</p>
            </div>
          ))}
          <p className="font-bold py-2">Chọn khoá học và chọn gói đăng ký</p>

          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={initialValues}
          >
            <Form.List name="listPackage">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row
                      key={'item' + key}
                      gutter={24}
                      className="border border-slate-400 rounded-md border-solid mb-4 items-center p-2"
                    >
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          name={[name, 'courseId']}
                          label="Chọn khoá học"
                          rules={[{ required: true, message: 'Chọn khoá học' }]}
                        >
                          <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Chọn khoá học"
                            onChange={handleInputChange}
                            options={course.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'activatedTime']}
                          label="Ngày kích hoạt"
                          rules={[{ required: true, message: 'Chọn ngày' }]}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            format={'DD/MM/YYYY'}
                            placeholder={'Chọn ngày'}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={11}>
                        <Form.Item
                          {...restField}
                          label="Chọn gói"
                          name={[name, 'vipPackageId']}
                          rules={[{ required: true, message: 'Chọn gói' }]}
                        >
                          <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Chọn gói"
                            options={vipPackages.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, 'isLifeTime']}
                          label="Gói trọn đời"
                        >
                          <Switch />
                        </Form.Item>
                      </Col>
                      <DeleteOutlined
                        className="iconDelete"
                        type="delete"
                        onClick={() => remove(name)}
                      />
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Kích hoạt thêm khoá học khác
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="center-item">
              <Button
                type="default"
                htmlType="submit"
                style={{ marginRight: 30 }}
                className="mr-6"
                onClick={onHideModal}
              >
                Huỷ
              </Button>
              <Button type="primary" htmlType="submit" onClick={form.submit}>
                Kích hoạt
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      {isModalSubmit && (
        <ModalSubmit
          params={params}
          handleIsModalSubmit={handleIsModalSubmit}
          phoneNumber={itemSelected.phoneNumber}
          id={itemSelected.id}
          onHideModal={onHideModal}
          onReload={onReload}
          vipPackages={vipPackages}
        />
      )}
    </>
  );
};

export default ModalUpgradeAccount;
