import React, { useEffect } from 'react';
import { Parser } from 'htmlparser2';

const useHtmlToText = (html: string) => {
  const [text, setText] = React.useState('');

  useEffect(() => {
    const parser = new Parser(
      {
        ontext: (text) => {
          setText((prevText) => prevText + text);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onopentag: (tagname, _attribs) => {
          if (tagname === 'img') {
            // Bỏ qua  thẻ ảnh
            return;
          }
        },
      },
      { decodeEntities: true }
    );
    parser.write(html);
    parser.end();
  }, [html]);

  return text;
};

export default useHtmlToText;
