import React, { useEffect, useState } from 'react';
import { Divider, notification, Popover, Space, Table } from 'antd';
import ModalCreatePostTG280N from './components/ModalCreatePostTG280N';
import Actions from './components/Actions';
import { DEFAULT_PAGE_SIZE, GLOBAL_HEIGHT, PAGE_SIZE_LIST } from 'utils/constants';
import { getPostFormat } from 'utils';
import moment from 'moment';
import { postRoadMap280Service } from 'network/services/postRoadMap280';
import { useWindowSize } from 'hook';
import { Helmet } from 'react-helmet';
import { roadMap280Service } from 'network/services/roadMap280';

type UIState = {
  data: PostTG280[];
  loading: boolean;
  metadata: any;
  filters: any;
  isOpenModal: boolean;
  postEditData: any;
  categories: any[] | undefined;
  topics: any[] | undefined;
  postFormat: number | undefined;
  category: number;
};

const PostsTG280N: React.FC = () => {
  const defaultFilters = {
    page: 0,
    size: DEFAULT_PAGE_SIZE,
    postName: '',
  };
  const windowSize = useWindowSize();
  const [UIState, setUIState] = useState<UIState>({
    data: [],
    loading: true,
    metadata: undefined,
    filters: defaultFilters,
    isOpenModal: false,
    postEditData: undefined,
    categories: [],
    postFormat: 1,
    topics: [],
    category: 1,
  });

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '3%',
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'postName',
      width: '15%',
      render: (cell: any) => (
        <>
          {cell.length > 40 ? (
            <Popover content={<div style={{ width: '300px' }}>{cell}</div>}>
              <div
                style={{
                  width: '90%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {cell}
              </div>
            </Popover>
          ) : (
            <div>{cell}</div>
          )}{' '}
        </>
      ),
    },
    {
      title: 'Chuyên mục',
      dataIndex: 'category',
      width: '8%',
      render: (cell: any) => cell?.name,
    },
    {
      title: 'Chủ đề',
      dataIndex: 'topic',
      width: '7%',
      render: (cell: any) => cell?.name,
    },
    {
      title: 'Định dạng',
      dataIndex: 'postFormat',
      width: '6%',
      render: (cell: number) => getPostFormat(cell),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '9%',
      render: (cell: number) =>
        cell ? moment(cell).format('DD/MM/YYYY HH:mm:ss') : '--',
    },
    {
      title: 'Thao tác',
      dataIndex: 'id',
      width: '8%',
      render: (cell: any) => (
        <Space>
          <a onClick={() => onEdit(cell)}>Chỉnh sửa</a>
          <Divider type="vertical" style={{ borderColor: '#1677ff' }} />
          <a onClick={() => onDeletePost(cell)}>Xóa</a>
        </Space>
      ),
    },
  ];

  const onEdit = async (postId: number) => {
    const response = await postRoadMap280Service.detail(postId);
    setUIState((cur) => ({
      ...cur,
      isOpenModal: true,
      postEditData: response?.data,
    }));
  };

  const onDeletePost = async (postId: number) => {
    const response = await postRoadMap280Service.deleteP(postId);
    if (response.status === 204) {
      notification.success({
        message: 'Xóa bài viết thành công',
      });
      onReload();
    } else {
      notification.error({
        message: 'Xóa bài viết thất bại',
      });
    }
  };

  const fetchCategories = async () => {
    const successCallback = (res: any = {}) => {
      setUIState((prev) => ({ ...prev, categories: res?.data }));
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const failCallback = (_err: any) => {};
    await roadMap280Service.getCategories(successCallback, failCallback);
  };

  const onFetchData = async () => {
    const response = await postRoadMap280Service.posts(UIState.filters);
    const { data, headers } = response;
    if (response?.status === 200) {
      setUIState((cur) => ({
        ...cur,
        loading: false,
        data,
        metadata: {
          pageCount: headers['x-page-count'],
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
      }));
    } else {
      setUIState((cur) => ({
        ...cur,
        loading: false,
      }));
    }
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
      filters: {
        ...cur.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const onShowModal = () => {
    setUIState((cur) => ({
      ...cur,
      isOpenModal: true,
    }));
  };

  const onHideModal = () => {
    setUIState((cur) => ({
      ...cur,
      isOpenModal: false,
      postEditData: undefined,
    }));
  };

  const onSearch = (e: any) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        postName: e.target.value.replace(/^\s+/, ''),
        page: 0,
      },
    }));
  };

  const handleOnKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      setUIState((cur) => ({
        ...cur,
        loading: true,
      }));
    }
  };

  const onReload = () => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
    }));
  };

  const onFiltered = () => {
    setUIState((pre) => ({
      ...pre,
      loading: true,
      filters: {
        ...pre.filters,
        page: 0,
      },
    }));
  };

  const handleChangeCategory = (value: number) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        categoryId: value,
      },
      category: value,
    }));
  };

  const handleChangeTopic = (value: number) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        topicId: value,
      },
    }));
  };
  const handleChangePostFormat = (value: number) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        postFormat: value,
      },
    }));
  };

  const onReset = () => {
    setUIState((cur) => ({
      ...cur,
      filters: defaultFilters,
      loading: true,
    }));
  };

  useEffect(() => {
    if (UIState.loading) {
      onFetchData();
    }
  }, [UIState.loading]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    (async function () {
      const successCallback = (res: any = {}) => {
        setUIState((prev) => ({ ...prev, topics: res?.data }));
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const failCallback = () => {};
      await roadMap280Service.getTopics(
        { categoryId: UIState.category },
        successCallback,
        failCallback
      );
    })();
  }, [UIState.category]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Bài post lộ trình</title>
        <meta name="description" content="Bài post lộ trình" />
      </Helmet>
      <Actions
        onShowModal={onShowModal}
        onSearch={onSearch}
        categories={UIState.categories}
        topics={UIState.topics}
        handleChangeCategory={handleChangeCategory}
        handleChangeTopic={handleChangeTopic}
        handleChangePostFormat={handleChangePostFormat}
        onReset={onReset}
        dataFilter={UIState.filters}
        onFiltered={onFiltered}
        handleOnKeyDown={handleOnKeyDown}
      />
      <Table
        rowKey={'id'}
        dataSource={UIState.data}
        columns={columns}
        scroll={{
          y: windowSize?.height - GLOBAL_HEIGHT - 40,
          x: 1400,
        }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: UIState.filters.page + 1,
          total: UIState?.metadata?.total,
          defaultPageSize: UIState.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
      />
      {UIState.isOpenModal && (
        <ModalCreatePostTG280N
          data={UIState.postEditData}
          onHideModal={onHideModal}
          onReload={onReload}
        />
      )}
    </div>
  );
};

export default PostsTG280N;
