import { Button, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useWindowSize } from 'hook';
import { customersServices } from 'network/services/customers';
import React from 'react';

type Props = {
  onClose?: () => void;
  id: number;
};

type ItemQuestion = {
  question: string;
  source: string;
};

export const ModalQuestion = ({ onClose, id = 0 }: Props) => {
  const [listQuestion, setListQuestion] = React.useState<ItemQuestion[]>([]);
  const windowSize = useWindowSize();
  const getListQuestion = async () => {
    const resp = await customersServices.getListQuestion(id);

    setListQuestion(
      resp?.data.map((item: any, index: number) => ({
        id: index,
        question: item?.question,
        source: item?.source,
      }))
    );
  };

  React.useEffect(() => {
    getListQuestion();
  }, [id]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'id',
      width: '10%',
      render: (index: number) => <div>{index + 1}</div>,
    },
    {
      title: 'Câu hỏi',
      dataIndex: 'question',
      width: '60%',
      render: (question: any) => <div>{question}</div>,
    },
    {
      title: 'Nguồn',
      dataIndex: 'source',
      width: '40%',
      render: (source: any) => <div>{source}</div>,
    },
  ];
  return (
    <Modal
      open={true}
      destroyOnClose
      closable={false}
      width={1200}
      centered
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <Table
        dataSource={listQuestion}
        columns={columns}
        scroll={{
          y: windowSize?.height,
          x: 1200,
        }}
      />
    </Modal>
  );
};
