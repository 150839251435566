export default function CRMLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
    >
      <path
        d="M16 4C16 1.832 12.337 0 8 0C3.663 0 0 1.832 0 4V6C0 8.168 3.663 10 8 10C12.337 10 16 8.168 16 6V4ZM8 17C3.663 17 0 15.168 0 13V16C0 18.168 3.663 20 8 20C12.337 20 16 18.168 16 16V13C16 15.168 12.337 17 8 17Z"
        fill="white"
      />
    </svg>
  );
}
