import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const POPUP_URI = '/api/popups';

const getListPopup = (params: {
  page?: number;
  size?: number;
  categoryId?: number;
  courseId?: number;
}) => {
  deleteParamsNotUsing(params);
  const url = getQueryString(`${POPUP_URI}`, params);
  return request(url);
};

const addPopupToList = (body: DataBodyTopics) => {
  return request(`${POPUP_URI}`, 'POST', body);
};

const editPopupToList = (body: DataBodyTopics, id: number) => {
  return request(`${POPUP_URI}/${id}`, 'PATCH', body);
};

const detailPopupToList = (id: number) => {
  return request(`${POPUP_URI}/${id}`, 'GET');
};

const uploadFile = (body: ParamsUpload) => {
  return request('/api/attachments/upload', 'POST', body);
};

export const popupService = {
  getListPopup,
  uploadFile,
  addPopupToList,
  editPopupToList,
  detailPopupToList,
};
