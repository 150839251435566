import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const getCategoryNewsAndBlog = () => {
  const url = getQueryString('/api/course/content/category-structure');
  return request(url);
};

const addNewCategory = (params: { name: string; courseId: number }) => {
  deleteParamsNotUsing(params);
  const url = getQueryString('/api/course/content/v1_2/category');
  return request(url, 'POST', params);
};

const addNewTopic = (params: {
  name: string;
  courseId: number;
  categoryId: number;
}) => {
  const url = getQueryString('/api/course/content/v1_2/section');
  return request(url, 'POST', params);
};

export const categoryWebServices = {
  getCategoryNewsAndBlog,
  addNewCategory,
  addNewTopic,
};
