import { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import * as Emoji from 'quill-emoji';
import { htmlToMarkdown } from './Parser';

import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';

Quill.register('modules/emoji', Emoji);

export interface EditorContentChanged {
  html: string;
  markdown?: string;
}

export interface EditorProps {
  value?: EditorContentChanged;
  onChange?: (changes: EditorContentChanged) => void;
}

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  ['emoji'],
  ['clean'],
  ['image'], // Added image option
];

export default function Editor(props: EditorProps) {
  const [value, setValue] = useState<string | undefined>('');
  const reactQuillRef = useRef<ReactQuill>(null);

  const onChange = (content: string) => {
    setValue(content);

    if (props.onChange) {
      props.onChange({
        html: content,
        markdown: htmlToMarkdown(content),
      });
    }
  };

  useEffect(() => {
    setValue(props?.value?.html);
  }, [props?.value?.html]);

  return (
    <ReactQuill
      ref={reactQuillRef}
      theme="snow"
      placeholder="Nhập nội dung..."
      modules={{
        'toolbar': {
          container: TOOLBAR_OPTIONS,
        },
        'emoji-toolbar': true,
        'emoji-textarea': false,
        'emoji-shortname': true,
      }}
      value={value}
      onChange={onChange}
    />
  );
}
