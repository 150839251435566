import { Categories } from './Tabs/categories';
import { Course } from './Tabs/course';
import { Phase } from './Tabs/phase';
import { Topic } from './Tabs/topic';
import { Tabs } from 'antd';
import './styless.scss';

const Courses = () => {
  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="Quản lý danh mục khóa học" key="1">
        <Phase />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Quản lý khóa học" key="2">
        <Course />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Quản lý chuyên mục" key="3">
        <Categories />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Quản lý chủ đề" key="4">
        <Topic />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Courses;
