import { Button, Popover } from 'antd';
import { authService } from 'network/services/auth';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from 'utils/constants';
import { ROUTERS } from 'utils/routers';
import AvatarLogo from './logo/Avatar';
import './style.scss';

const Header: React.FC = () => {
  const userInfo: any = JSON?.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.userInfo) || '{}'
  );
  const location = useLocation();
  // const navigate = useNavigate();
  const handleLogout = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const successCallback = () => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.accessToken);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.userInfo);
      window.location.href = '/';
    };

    const failCallback = (err: any) => {
      const {
        response: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          data: {},
        },
      } = err || {};
    };

    await authService.logout(successCallback, failCallback);
  };

  // const handleAccountManager = () => {
  //   navigate('/accounts');
  // };

  // const contentSetting = (
  //   <Button type="primary" onClick={handleAccountManager}>
  //     Quản lý tài khoản
  //   </Button>
  // );

  const content = (
    <div
      style={{
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ marginBottom: '5px' }}>
        Username: <strong>{userInfo?.username}</strong>
      </div>
      <Button type="link" danger onClick={handleLogout}>
        Đăng xuất
      </Button>
    </div>
  );

  const getPageName = (path: string) => {
    return (
      Object.entries(ROUTERS)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, route]) => route.path === path)
        .map(([, route]) => route?.name)
    );
  };

  return (
    <div id="header" className="flex items-center h-10">
      <div className="w-full font-bold text-xl p-2">
        {getPageName(location.pathname)?.[0]?.toUpperCase()}
      </div>
      <div
        className="options"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {/* {userInfo?.role?.name === Role.ADMIN && (
          <Popover content={contentSetting}>
            <SettingOutlined
              style={{
                fontSize: '25px',
                marginRight: '16px',
                cursor: 'pointer',
              }}
            />
          </Popover>
        )} */}
        <Popover content={content}>
          <div
            style={{ marginRight: '50px', cursor: 'pointer', height: '30px' }}
          >
            <AvatarLogo />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default Header;
