import { notification, Table } from 'antd';
import { EditorContentChanged } from 'containers/posts/pages/PostsTG280N/components/Editor';
import { useWindowSize } from 'hook';
import useHandleListCourse from 'hook/useHandleListCourse';
import moment from 'moment';
import { hashTagsServices } from 'network/services/hashtagServices';
import { postsServices } from 'network/services/postsNewVersion';
import { topicsService } from 'network/services/topics';
import React, { useEffect, useState } from 'react';
import useHandleListCategories from 'hook/useHandleListCategories';
import { getCDNUrl } from 'utils';
import { PAGE_SIZE_LIST } from 'utils/constants';
import BoxActions from './BoxActions';
import BoxFilter from './BoxFilter';
import ModalDelete from './ModalDelete';
import ModalPostsNewVer from './ModalPostsNewVer';
import './styles.scss';

export type FilterInterface = PaginationWithSortInterface & {
  postName?: string;
  postFormat?: number;
  categoryId?: number;
  topicId?: number;
};

const defaultFilters = {
  page: 0,
  size: 20,
  sort: 'id:desc',
};

const PostNewVersion: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<'Thêm mới' | 'Chỉnh sửa'>(
    'Thêm mới'
  );
  const windowSize = useWindowSize();
  const [isLoadingAPI, setLoadingAPI] = useState(true);
  const [listDataPosts, setListDataPosts] = useState([]);
  const [listDataTopics, setListDataTopics] = useState([]);
  const [listDataHashTag, setListDataHashTag] = useState<HashTag[]>([]);
  const [formatValues, setFormatValues] = useState<number>(1);

  const [imagesValues, setImagesValues] = useState<string>('');
  const [hashTagsValues, setHashtagsValues] = useState<string[]>([]);

  const [courseId, setCourseId] = useState<number>(0);
  const [categoriesId, setCategoriesId] = useState<number>(0);
  const [content, setContent] = useState<EditorContentChanged>({
    html: '',
    markdown: '',
  });
  const [orientation, setOrientation] = useState<'landscape' | 'portrait'>(
    'landscape'
  );
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [audioUrl, setAudioUrl] = useState<string | undefined>(undefined);
  const [attachmentIds, setAttachmentIds] = useState<number[]>();
  const [postTime, setPostTime] = useState<number>(0);
  const [idDetail, setIdDetail] = useState<number>(0);
  const [idDelete, setIdDelete] = useState<number>(0);
  const [dataPostDetail, setDataPostDetail] = useState<any>({});
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );

  const [filterListPosts, setFilterListPosts] =
    useState<FilterInterface>(defaultFilters);

  const onPageChange = (mPage: number, mSize: number) => {
    setLoadingAPI(true);
    setFilterListPosts((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  // const onSubmitFilter = (values: any) => {
  //   // postFormat, categoryId, topicId
  //   setLoadingAPI(true);
  //   setFilterListPosts((cur) => ({
  //     ...cur,
  //     ...values,
  //     page: 0,
  //     size: 20,
  //   }));
  // };

  // const handleChangeIsOpenFilter = () => {
  //   setIsOpenFilter(!isOpenFilter);
  // };

  // const onChangeSearchPost = (value: string) => {
  //   setSearchPost(value);
  // };

  const { listDataCourse } = useHandleListCourse({
    loadingAPI: isLoadingAPI,
    setLoadingAPI,
  });

  const { listDatacategories } = useHandleListCategories({
    setLoadingAPI,
    courseId,
  });

  const handleSubmitDelete = async () => {
    const resp = await postsServices.deletePost(idDelete);
    if (resp.status === 204) {
      setIsOpenDelete(false);
      setIdDelete(0);
      setLoadingAPI(true);
      notification.success({ message: 'Xoá bài viết thành công' });
    }
  };

  const fetchHashtags = async () => {
    const response = await hashTagsServices.get();
    if (response?.status === 200) {
      setListDataHashTag(response.data);
    }
  };

  const handleChangeOrientation = (value: 'landscape' | 'portrait') => {
    setOrientation(value);
  };

  const uploadFileSuccessVideo = (value: any) => {
    setAttachmentIds([value.id]);
    setVideoUrl(getCDNUrl(value.idStr));
  };

  const uploadFileSuccessAudio = (value: any) => {
    setAttachmentIds([value.id]);
    setAudioUrl(getCDNUrl(value.idStr));
  };

  const onDeleteVideo = () => {
    setVideoUrl(undefined);
  };

  const onDeleteAudio = () => {
    setAudioUrl(undefined);
  };

  const fetchListTopics = async (isSearch: boolean) => {
    const resp = await topicsService.getListTopics(
      isSearch
        ? categoriesId !== 0
          ? { categoryId: categoriesId }
          : {}
        : {
            courseId,
            categoryId: categoriesId,
          }
    );
    setLoadingAPI(false);
    setListDataTopics(resp.data);
  };

  const fetchPostDetail = async () => {
    const resp = await postsServices.detail(idDetail);
    handleOnChangeModal();
    setDataPostDetail(resp.data);
    setImagesValues(resp.data.image.split('/')[5]);
    setFormatValues(resp.data.postFormat);
    setCategoriesId(resp.data.category.id);
    setCourseId(resp.data.course.id);
    setPostTime(resp.data.postTime);
    setAttachmentIds([resp?.data?.attachments[0]?.id]);
    setContent(resp.data.content);
    setVideoUrl(
      getCDNUrl(
        resp?.data?.attachments[0]?.contentType?.includes('video')
          ? resp.data.attachments[0]?.idStr
          : ''
      )
    );
    setOrientation(resp.data.attachments[0]?.orientation);
    setAudioUrl(
      getCDNUrl(
        resp.data.attachments[0]?.contentType?.includes('audio')
          ? resp.data.attachments[0]?.idStr
          : ''
      )
    );
  };

  const handleOnChangeModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleOnChangeFormatValues = (value: number) => {
    setFormatValues(value);
  };

  const handleFetchAPIListPosts = async (filters: FilterInterface) => {
    const resp = await postsServices.get(filters);
    setListDataPosts(resp.data);
    setLoadingAPI(false);
    setMetadata({
      pageCount: resp.headers['x-page-count'],
      page: resp.headers['x-page-number'],
      size: resp.headers['x-page-size'],
      total: resp.headers['x-total-count'],
    });
  };

  const renderFormat = (type: number) => {
    switch (type) {
      case 1:
        return <>Bài viết</>;
      case 2:
        return <>Video</>;
      case 3:
        return <>Audio</>;

      default:
        break;
    }
  };

  const onDeleteImage = () => {
    setImagesValues('');
  };

  const handleOnChangeCourse = (id: number) => {
    setCourseId(id);
  };

  const onChangeCategory = (id: number) => {
    setCategoriesId(id);
  };

  const onSearchPostName = (event: any) => {
    setFilterListPosts((prev) => ({
      ...prev,
      ...defaultFilters,
      postName: event.target.value,
    }));
  };

  const onSelectCategory = (id: number) => {
    setLoadingAPI(true);
    setFilterListPosts((prev) => ({
      ...prev,
      ...defaultFilters,
      categoryId: id,
    }));
  };

  const onSelectTopic = (id: number) => {
    setLoadingAPI(true);
    setFilterListPosts((prev) => ({
      ...prev,
      ...defaultFilters,
      topicId: id,
    }));
  };

  const onSelectPostFormat = (id: number) => {
    setLoadingAPI(true);
    setFilterListPosts((prev) => ({
      ...prev,
      ...defaultFilters,
      postFormat: id,
    }));
  };

  const onSearch = () => {
    setLoadingAPI(true);
  };

  const handleOnKeyDown = (
    e: React.KeyboardEvent<HTMLImageElement> | undefined
  ) => {
    if (e?.keyCode === 13) {
      setLoadingAPI(true);
    }
  };

  const uploadFileSuccess = (file: any) => {
    setImagesValues(file.idStr);
  };

  const createHashtag = async (hashtag: string) => {
    await hashTagsServices.create({
      name: hashtag,
    });
  };

  const onChangeHashTag = (value: any[]) => {
    for (const tag of value) {
      if (listDataHashTag?.indexOf(tag) == -1) {
        createHashtag(tag);
      }
    }
    setHashtagsValues(value);
  };

  const onResetFilter = () => {
    setLoadingAPI(true);
    setFilterListPosts(defaultFilters);
  };
  const onSubmit = async (value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { orientation, ...rest } = value;
    const data = {
      ...rest,
      hashtag: value?.hashtag?.join(',') || '',
      attachmentIds: formatValues === 1 ? undefined : attachmentIds,
      content: value?.content?.html,
      position:
        +value.position === dataPostDetail.position
          ? undefined
          : +value.position,
      image: imagesValues,
      postTime: +value.postTime,
    };

    if (modalType === 'Thêm mới') {
      const resp = await postsServices.create(data);
      if (resp.status === 200) {
        setIsOpenModal(false);
        setLoadingAPI(true);
        notification.success({ message: 'Thêm mới bài viết thành công' });
      } else {
        notification.error({ message: resp.data });
      }
    } else {
      const resp = await postsServices.update(idDetail, data);
      if (resp.status === 200) {
        setIsOpenModal(false);
        setLoadingAPI(true);
        notification.success({ message: 'Chỉnh sửa bài viết thành công' });
      } else {
        notification.error({ message: resp.data });
      }
    }
  };

  const handleEditModal = (value: any) => {
    setModalType('Chỉnh sửa');
    setIdDetail(value.id);
  };

  const handleDeleteModal = (value: any) => {
    setIdDelete(value.id);
    setIsOpenDelete(!isOpenDelete);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '1%',
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'postName',
      key: 'postName',
      width: '2%',
      ellipsis: true,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '2%',
      // render: (value: string) => <div className="text-esclipse">{value}</div>,
      ellipsis: true,
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      width: '2%',
      render: (value: string) => <div className="text-esclipse">{value}</div>,
      ellipsis: true,
    },
    {
      title: 'Định dạng',
      dataIndex: 'postFormat',
      key: 'postFormat',
      width: '2%',
      render: (value: any) => renderFormat(value),
    },
    {
      title: 'Chủ đề',
      dataIndex: 'topic',
      key: 'topic',
      width: '2%',
      render: (topic: any) => <>{topic?.name}</>,
    },
    {
      title: 'Chuyên mục',
      dataIndex: 'category',
      key: 'category',
      width: '2%',
      render: (category: Category) => <>{category.originalName}</>,
    },
    {
      title: 'Khoá học',
      dataIndex: 'course',
      key: 'course',
      width: '2%',
      render: (course: Course) => <>{course?.name}</>,
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      width: '2%',
      render: (value: string) => (
        <>{moment(value).format('DD/MM/YYYY HH:mm')}</>
      ),
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '2%',
      render: (value: string) => <>{value}</>,
    },
    {
      title: 'Thao tác',
      width: '2%',
      render: (value: string) => {
        return (
          <BoxActions
            handleEditModal={handleEditModal}
            value={value}
            handleDeleteModal={handleDeleteModal}
          />
        );
      },
    },
  ];

  const onCreatePost = () => {
    handleOnChangeModal();
    setModalType('Thêm mới');
  };

  useEffect(() => {
    if (isLoadingAPI) {
      handleFetchAPIListPosts(filterListPosts);
    }
  }, [isLoadingAPI, filterListPosts]);

  useEffect(() => {
    if (courseId !== 0 && categoriesId !== 0) {
      fetchListTopics(false);
    } else {
      fetchListTopics(true);
    }
  }, [courseId, categoriesId]);

  useEffect(() => {
    if (!isOpenModal) {
      setIdDetail(0);
      setAudioUrl('');
      setVideoUrl('');
      setImagesValues('');
      setAttachmentIds([]);
      setDataPostDetail({});
      setFormatValues(1);
      setPostTime(0);
      setCourseId(0);
      setCategoriesId(0);
    } else {
      fetchHashtags();
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (idDetail !== 0) fetchPostDetail();
  }, [idDetail]);

  useEffect(() => {
    setVideoUrl('');
  }, [orientation]);

  return (
    <div>
      {isOpenModal ? (
        <ModalPostsNewVer
          isOpenModal={isOpenModal}
          handleOnChangeModal={handleOnChangeModal}
          type={modalType}
          formatValues={formatValues}
          imagesValues={imagesValues}
          onDeleteImage={onDeleteImage}
          uploadFileSuccess={uploadFileSuccess}
          listDataCourse={listDataCourse}
          listDatacategories={listDatacategories}
          handleOnChangeCourse={handleOnChangeCourse}
          listDataHashTag={listDataHashTag}
          onChangeCategory={onChangeCategory}
          listDataTopics={listDataTopics}
          topicId={undefined}
          categoriesId={categoriesId}
          content={content}
          handleChangeOrientation={handleChangeOrientation}
          onDeleteVideo={onDeleteVideo}
          onDeleteAudio={onDeleteAudio}
          uploadFileSuccessVideo={uploadFileSuccessVideo}
          uploadFileSuccessAudio={uploadFileSuccessAudio}
          orientation={orientation}
          videoUrl={videoUrl}
          audioUrl={audioUrl}
          hashTagsValues={hashTagsValues}
          postTime={postTime}
          onSubmit={onSubmit}
          dataPostDetail={dataPostDetail}
          position={0}
          handleOnChangeFormatValues={handleOnChangeFormatValues}
          onChangeHashTag={onChangeHashTag}
        />
      ) : (
        <>
          <BoxFilter
            categories={listDatacategories}
            topics={listDataTopics}
            filters={filterListPosts}
            onReset={onResetFilter}
            onSearchPostName={onSearchPostName}
            onSelectCategory={onSelectCategory}
            onSelectTopic={onSelectTopic}
            onSelectPostFormat={onSelectPostFormat}
            onSearch={onSearch}
            handleOnKeyDown={handleOnKeyDown}
            onShowModal={onCreatePost}
          />

          <Table
            rowKey={'id'}
            dataSource={listDataPosts}
            columns={columns as any}
            style={{
              marginTop: '10px',
            }}
            className="myTablePost"
            scroll={{
              y: windowSize?.height - 270,
              x: 1400,
            }}
            pagination={{
              size: 'small',
              showSizeChanger: true,
              current: filterListPosts.page + 1,
              total: metadata?.total,
              defaultPageSize: filterListPosts.size,
              pageSizeOptions: PAGE_SIZE_LIST,
              onChange: onPageChange,
              showTotal: (total) => (
                <div>Tổng số bản ghi {total.toLocaleString()}</div>
              ),
            }}
          />
        </>
      )}

      <ModalDelete
        isModalOpen={isOpenDelete}
        handleChangeModal={handleDeleteModal}
        handleSubmitDelete={handleSubmitDelete}
      />
    </div>
  );
};

export default PostNewVersion;
