import { CloudUploadOutlined } from '@ant-design/icons';
import { Form, message, Space, Upload } from 'antd';
import React from 'react';

import { roadMap280Service } from 'network/services/roadMap280';
import { LANDSCAPE } from 'containers/posts/constants';
import { usePostContext } from 'containers/posts/context';
import './style.scss';

enum Type {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

type Props = {
  name: string;
  type: Type;
  accept?: string;
  maxSize?: number;
  description?: string;
  category: string;
};

const UploadC: React.FC<Props> = ({
  name,
  type,
  accept,
  category,
  maxSize = 10 * 1000 * 1000,
  description,
}) => {
  const { postId, orientation, setIdStr, setAttachmentIds } = usePostContext();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleCustomRequest = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const isValidFile = file.size <= maxSize;
    if (isValidFile) {
      const formData = new FormData();
      if (category) {
        formData.append('category', category);
      }
      formData.append('file', file);
      postId && formData.append('targetId', postId + '');
      if (type === 'VIDEO') {
        formData.append('orientation', orientation || LANDSCAPE);
      }

      const successCallback = (res: any = {}) => {
        const idStr = res?.data?.idStr;
        const id = res?.data?.id;

        console.log('upload callback', `type=${type} idStr=${idStr}`);
        if (type === 'IMAGE') {
          setIdStr(idStr);
        } else {
          setAttachmentIds([id]);
        }
        onSuccess('Success');
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const failCallback = () => {
        onError('Error');
      };

      await roadMap280Service.uploadAttachments(
        formData,
        successCallback,
        failCallback
      );
    } else {
      onError('Error');
    }
  };

  const renderLabel = () => {
    const title =
      type === 'IMAGE' ? 'Ảnh' : type === 'VIDEO' ? 'Video' : 'Audio';

    return (
      <Space size={8}>
        <span>{title}</span>
        <CloudUploadOutlined />
      </Space>
    );
  };

  const onChange = (info: any) => {
    const {
      file: { status, name, size },
    } = info || {};
    switch (status) {
      case 'done':
        message.success(`File ${name} đã được tải lên thành công`);
        break;
      case 'error':
        message.error(
          size <= maxSize
            ? `File ${name} đã được tải lên thất bại`
            : `File ${name} có kích thước ảnh vượt quá 100KB`
        );
        break;
      default:
        break;
    }
  };

  const propsUpload = {
    accept,
    onChange,
    customRequest: handleCustomRequest,
  };

  return (
    <Form.Item
      name={name}
      label={renderLabel()}
      valuePropName="fileList"
      rules={[{ required: true, message: 'Vui lòng tải lên file ảnh!' }]}
      getValueFromEvent={normFile}
    >
      <Upload.Dragger {...propsUpload}>
        <p>{description}</p>
      </Upload.Dragger>
    </Form.Item>
  );
};

export default UploadC;
