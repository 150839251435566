import React from 'react';
import HeaderPackage from './header';
import { ModalCreatePackage } from './modal';
import { packageService } from 'network/services/package';
import { Table } from 'antd';
import { useWindowSize } from 'hook';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST } from 'utils/constants';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import BoxActions from './actios';
import { courseService } from 'network/services/course';
import { formatPrice } from 'utils/helper';
import { PACKAGES } from 'utils/enum';

const defaultFilters = {
  size: DEFAULT_PAGE_SIZE,
  page: 0,
};

type ColumnsProps = {
  title?: string;
  dataIndex?: string;
  width?: string;
  fixed?: string;
  render?: () => void;
};

const Packages: React.FC = () => {
  const windowSize = useWindowSize();
  const [modalType, setModalType] = React.useState<'Thêm mới' | 'Chỉnh sửa'>(
    'Thêm mới'
  );
  const [filter, setFilter] = React.useState(defaultFilters);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<[]>([]);
  const [course, setCourse] = React.useState<[]>([]);
  const [metadata, setMetadata] = React.useState<MetadataInterface | undefined>(
    undefined
  );
  const [itemSelected, setItemSelected] = React.useState<
    PackageInterface | undefined
  >(undefined);

  const handleOnChangeModal = () => {
    setModalType('Thêm mới');
    setIsOpenModal(!isOpenModal);
  };

  const onHideModal = () => {
    setItemSelected(undefined);
    setIsOpenModal(false);
  };

  const handleEditModal = (value: PackageInterface) => {
    setItemSelected(value);
    setIsOpenModal(true);
    setModalType('Chỉnh sửa');
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilter((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const handleDeleteModal = async () => {};

  const onReload = () => {
    setIsLoading(true);
  };

  const fetchdata = async () => {
    const response = await packageService.get(filter);
    const { data } = response;
    if (response?.status === 200) {
      setMetadata({
        pageCount: data?.totalPages,
        page: data?.number,
        size: data?.size,
        total: data?.totalElements,
      });
      setData(data?.content);
      setIsLoading(false);
    }
  };

  const fetchDataCourse = async () => {
    const response = await courseService.get();
    const { data } = response;
    setCourse(data?.data);
  };

  React.useEffect(() => {
    fetchDataCourse();
  }, []);

  React.useEffect(() => {
    if (isLoading) {
      fetchdata();
    }
  }, [isLoading]);

  const columns: ColumnsType<ColumnsProps> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '3%',
    },
    {
      title: 'Mã gói',
      dataIndex: 'code',
      width: '10%',
    },
    {
      title: 'Tên gói',
      dataIndex: 'name',
      width: '15%',
    },
    {
      title: 'Khoá học',
      dataIndex: 'course',
      width: '15%',
      render: (course: Course) => <>{course?.name}</>,
    },
    {
      title: 'Giá gốc (VNĐ)',
      dataIndex: 'originalPrice',
      width: '10%',
      render: (originalPrice: number) => {
        return `${formatPrice(originalPrice)} VNĐ`;
      },
    },
    {
      title: 'Giá bán (VNĐ)',
      dataIndex: 'actualPrice',
      width: '10%',
      render: (actualPrice: number) => {
        return `${formatPrice(actualPrice)} VNĐ`;
      },
    },
    {
      title: 'In-App (VNĐ)',
      dataIndex: 'inAppPrice',
      width: '10%',
      render: (inAppPrice: number) => {
        return `${formatPrice(inAppPrice)} VNĐ`;
      },
    },
    {
      title: 'Thời gian sử dụng',
      dataIndex: 'duration',
      width: '12%',
      render: (duration) => {
        const packageItem = PACKAGES.find((item) => item.id === duration);
        return packageItem ? packageItem.value : '';
      },
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '15%',
      render: (modifiedDate: string) => {
        return moment(modifiedDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'modifiedBy',
      width: '10%',
    },
    {
      title: 'Thao tác',
      width: '8%',
      render: (value: string) => (
        <BoxActions
          handleEditModal={handleEditModal}
          handleDeleteModal={handleDeleteModal}
          value={value}
        />
      ),
    },
  ];

  return (
    <div>
      <HeaderPackage
        handleOnChangeModal={handleOnChangeModal}
        setTypeModal={setModalType}
      />
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={data}
        scroll={{
          y: windowSize?.height - 230,
          x: 1400,
        }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filter.page + 1,
          total: metadata?.total,
          defaultPageSize: filter.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
      />
      {isOpenModal && (
        <ModalCreatePackage
          onReload={onReload}
          onHideModal={onHideModal}
          type={modalType}
          dataDetail={itemSelected}
          course={course}
        />
      )}
    </div>
  );
};

export default Packages;
