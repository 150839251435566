import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { Api, request } from '..';

const DASHBOARD_URL = '/api/dashboard';

type DashboardReportParams = {
  page: number;
  size: number;
  fromDate?: string;
  toDate?: string;
};

const get = (unitDate: string) => {
  const params = {
    unitDate,
  };
  deleteParamsNotUsing(params);
  const url = getQueryString(DASHBOARD_URL, params);
  return request(url);
};

const getReport = (params: DashboardReportParams) => {
  deleteParamsNotUsing(params);
  const url = getQueryString(DASHBOARD_URL + '/report', params);
  return request(url);
};

const refresh = () => {
  Api.delete(DASHBOARD_URL);
};

export const dashboardService = {
  get,
  getReport,
  refresh,
};
