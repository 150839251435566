import { DrawerForm } from '@ant-design/pro-components';
import { Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { forwardRef, useImperativeHandle, useState } from 'react';

export interface BoxFilterRef {
  setOpenModal: (isOpen: boolean) => void;
  onReset: () => void;
}

type FilterProps = {
  onSubmitFilter: (values: SubmitFormInterface) => Promise<boolean | void>;
  courses: [];
  phases: [];
  categories: [];
  topics: [];
  onClose: () => void;
  platform: 'app' | 'web';
};

export const BoxFilter = forwardRef<BoxFilterRef, FilterProps>((props, ref) => {
  const { onSubmitFilter, courses, phases, categories, topics, onClose, platform } =
    props;

  // const [platform, setPlatform] = useState<string>('');
  const [stageId, setStageId] = useState<string | null>(null);
  const [courseId, setCourseId] = useState<string | null>(null);
  const [categoryId, setCategoryId] = useState<string | null>(null);

  const [form] = useForm();

  useImperativeHandle(ref, () => ({
    setOpenModal,
    onReset,
  }));

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const setOpenModal = (isOpen: boolean) => {
    setIsOpenModal(isOpen);
  };

  // const onSelectPlatform = (value: string) => {
  //   setPlatform(value);
  // };

  const onChangeStage = (value: string) => {
    setStageId(value);
  };

  const onChangeCourse = (value: string) => {
    setCourseId(value);
  };

  const onChangeCategory = (value: string) => {
    setCategoryId(value);
  };

  const onReset = () => {
    form.setFieldsValue({
      sectionId: undefined,
      categoryId: undefined,
      courseId: undefined,
      stageId: undefined,
      courseContentPlatform: undefined,
    });
  };

  return (
    <DrawerForm
      onOpenChange={setIsOpenModal}
      title={'Bộ lọc'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      form={form}
      open={isOpenModal}
      drawerProps={{
        onClose,
      }}
      submitTimeout={2000}
      onFinish={onSubmitFilter}
    >
      {/* <Form.Item label="Platform" name={'courseContentPlatform'}>
        <Select
          allowClear
          placeholder="Tất cả"
          options={[
            { label: 'App', value: 'app' },
            { label: 'Web', value: 'web' },
          ]}
          onChange={onSelectPlatform}
        />
      </Form.Item> */}
      <Form.Item label="Danh mục" name={'stageId'}>
        <Select
          allowClear
          placeholder="Tất cả"
          onChange={onChangeStage}
          options={phases
            .filter((item: any) => {
              if (platform) {
                return item.platform === platform;
              } else {
                return true;
              }
            })
            .map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
        />
      </Form.Item>

      <Form.Item label="Khoá học" name={'courseId'}>
        <Select
          allowClear
          placeholder="Tất cả"
          onChange={onChangeCourse}
          options={courses
            .filter((item: any) => {
              if (stageId) {
                return item.stage.id == stageId;
              } else {
                return true;
              }
            })
            .map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
        />
      </Form.Item>

      <Form.Item label="Chuyên mục" name={'categoryId'}>
        <Select
          allowClear
          placeholder="Tất cả"
          onChange={onChangeCategory}
          options={categories
            .filter((item: any) => {
              if (courseId || stageId) {
                if (courseId) {
                  return item.course.id == courseId;
                } else {
                  return item.stage.id == stageId;
                }
              } else {
                return true;
              }
            })
            .map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
        />
      </Form.Item>

      <Form.Item label="Chủ đề" name={'sectionId'}>
        <Select
          allowClear
          placeholder="Tất cả"
          options={topics
            .filter((item: any) => {
              if (categoryId) {
                return item.category.id == categoryId;
              } else {
                return true;
              }
            })
            .map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
        />
      </Form.Item>
    </DrawerForm>
  );
});
