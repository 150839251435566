import { DrawerForm } from '@ant-design/pro-components';
import { Col, Form, Row, Select, DatePicker, Input } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { PLATFORM } from 'utils/enum';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/constants';
// import { isNullOrEmpty } from 'utils/stringUtils';

export interface CustomerFiltersRef {
  setOpenModal: (isOpen: boolean) => void;
  onReset: () => void;
}

export interface SubmitFormInterface {
  rangeRegisterDate: any;
  rangeActiveDate: any;
  fetalAge: number;
  type: string;
  refBy: string;
  platform: string;
  registerFrom: string | undefined;
  registerTo: string | undefined;
  activatedTimeFrom: string | undefined;
  activatedTimeTo: string | undefined;
}

interface CustomerFiltersProps {
  onSubmitFilter: (values: SubmitFormInterface) => void;
}
export const CustomerFilters = forwardRef<
  CustomerFiltersRef,
  CustomerFiltersProps
>((props, ref) => {
  const { onSubmitFilter } = props;
  const [form] = useForm();

  useImperativeHandle(ref, () => ({
    setOpenModal,
    onReset,
  }));

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [registerFrom, setRegisterFrom] = useState<string | undefined>(
    undefined
  );
  const [registerTo, setRegisterTo] = useState<string | undefined>(undefined);
  const [activatedTimeFrom, setActivatedTimeFrom] = useState<
    string | undefined
  >(undefined);
  const [activatedTimeTo, setActivatedTimeTo] = useState<string | undefined>(
    undefined
  );

  const onReset = () => {
    form.setFieldsValue({
      rangeRegisterDate: undefined,
      rangeActiveDate: undefined,
      fetalAge: undefined,
      type: undefined,
      refBy: undefined,
      platform: undefined,
    });
  };

  const setOpenModal = (isOpen: boolean) => {
    setIsOpenModal(isOpen);
  };

  const onChangeRegisterDate = (values: any, formatString: any) => {
    if (values) {
      setRegisterFrom(
        moment(formatString[0], DATE_FORMAT)
          .startOf('day')
          ?.format('YYYY-MM-DDTHH:mm:ss[Z]')
          .toString()
      );
      setRegisterTo(
        moment(formatString[1], DATE_FORMAT)
          .endOf('day')
          ?.format('YYYY-MM-DDTHH:mm:ss[Z]')
          .toString()
      );
    } else {
      setRegisterFrom(undefined);
      setRegisterTo(undefined);
    }
  };

  const onChangeActiveDate = (values: any, formatString: any) => {
    if (values) {
      setActivatedTimeFrom(
        moment(formatString[0], DATE_FORMAT)
          .startOf('day')
          ?.format('YYYY-MM-DDTHH:mm:ss[Z]')
      );
      setActivatedTimeTo(
        moment(formatString[1], DATE_FORMAT)
          .endOf('day')
          ?.format('YYYY-MM-DDTHH:mm:ss[Z]')
      );
    } else {
      setActivatedTimeFrom(undefined);
      setRegisterTo(undefined);
    }
  };

  const onSelectPlatForm = (value: string) => {
    if (!value) {
      form.resetFields(['platform']);
      form.setFieldsValue({ platform: '' });
    }
  };

  const onSelectAge = (value: string) => {
    if (!value) {
      form.resetFields(['fetalAge']);
      form.setFieldsValue({ fetalAge: '' });
    }
  };

  const onFinish = async (values: SubmitFormInterface) => {
    onSubmitFilter({
      ...values,
      registerFrom,
      registerTo,
      activatedTimeFrom,
      activatedTimeTo,
    });
  };

  return (
    <DrawerForm
      onOpenChange={setIsOpenModal}
      title={'Bộ lọc'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      form={form}
      open={isOpenModal}
      submitTimeout={2000}
      onFinish={onFinish}
    >
      <Form.Item label="Ngày đăng ký tài khoản" name={'rangeRegisterDate'}>
        <DatePicker.RangePicker
          allowClear
          format={DATE_FORMAT}
          placeholder={['Từ', 'Đến']}
          className="w-80"
          onChange={onChangeRegisterDate}
        />
      </Form.Item>

      <Form.Item label="Ngày kích hoạt tài khoản" name={'rangeActiveDate'}>
        <DatePicker.RangePicker
          allowClear
          format={DATE_FORMAT}
          placeholder={['Từ', 'Đến']}
          className="w-80"
          onChange={onChangeActiveDate}
        />
      </Form.Item>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Chọn tuổi thai" name={'fetalAge'}>
            <Select allowClear className="w-48" placeholder="Chọn tuổi thai" onChange={onSelectAge}>
              {Array(10)
                .fill(null)
                .map((_, index) => (
                  <Select.Option key={index} value={index}>
                    {index} tháng
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Người giới thiệu" name={'refBy'}>
            <Input placeholder="Nhập người giới thiệu" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Nền tảng" name={'platform'}>
            <Select
              allowClear
              placeholder="Chọn nền tảng"
              options={PLATFORM}
              onChange={onSelectPlatForm}
            />
          </Form.Item>
        </Col>
      </Row>
    </DrawerForm>
  );
});
