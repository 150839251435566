import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import React from 'react';
import { FORMAT_TYPE_280N } from 'utils/enum';

type Props = {
  onShowModal: () => void;
  onSearch: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  categories: any[] | undefined;
  topics: any[] | undefined;
  handleChangeCategory: (value: number) => void;
  handleChangeTopic: (value: number) => void;
  handleChangePostFormat: (value: number) => void;
  onReset: () => void;
  dataFilter: any;
  onFiltered: () => void;
  handleOnKeyDown: (e: any) => void;
};

const Actions: React.FC<Props> = ({
  onShowModal,
  onSearch,
  categories,
  topics,
  handleChangeCategory,
  handleChangePostFormat,
  handleChangeTopic,
  onReset,
  dataFilter,
  onFiltered,
  handleOnKeyDown,
}) => {
  return (
    <div id="posts-actions" className="mb-2 2xl:flex justify-between py-2">
      <div className="flex w-full">
        <div className="w-96 pr-2">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Tên bài post"
            className="w-full"
            onChange={onSearch}
            value={dataFilter.postName}
            onKeyDown={handleOnKeyDown}
          />
        </div>

        <Select
          className="w-60 pr-2"
          allowClear
          options={categories?.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          placeholder={'Chọn chuyên mục'}
          value={dataFilter.categoryId}
          onChange={(e) => handleChangeCategory(e)}
        />
        <Select
          className="w-60 pr-2"
          allowClear
          options={topics?.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          placeholder={'Chọn chủ đề'}
          value={dataFilter.topicId}
          onChange={(e) => handleChangeTopic(e)}
        />
        <Select
          className="w-48 pr-2"
          allowClear
          options={FORMAT_TYPE_280N.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          placeholder={'Chọn định dạng'}
          value={dataFilter.postFormat}
          onChange={(value) => handleChangePostFormat(value)}
        />

        <Button type="primary" onClick={onFiltered} className="mr-2">
          Cập nhật
        </Button>
        <Button type="default" onClick={onReset}>
          Reset
        </Button>
      </div>
      <Button type="primary" onClick={onShowModal} className='max-2xl:mt-2'>
        Thêm bài post
      </Button>
    </div>
  );
};

export default Actions;
