import { FC, useEffect, useRef, useState } from 'react';
import { Button, Input, Table, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useWindowSize } from 'hook';
import BoxActions from './BoxAction';
import { hashTagsServices } from 'network/services/hashtagServices';
import { courseService } from 'network/services/course';
import { phaseService } from 'network/services/phase';
import { categorieServices } from 'network/services/categorie';
import { topicService } from 'network/services/topic';
import { GLOBAL_HEIGHT, PAGE_SIZE_LIST } from 'utils/constants';
import { postContentService } from 'network/services/postContent';
import moment from 'moment';
import { BoxFilter, BoxFilterRef } from './ModalAddNews/filter';
import { SearchOutlined } from '@ant-design/icons';
import { ModalAddNews } from './ModalAddNews/ModalAddNew';
import { categoryServices } from 'network/services/categories';
import { RESPONSE_CODE } from 'network/constants';

type columnsType = {
  title: string;
  dataIndex: string;
  key: string;
  width: string;
  fixed: string;
};

const defaultFilters = {
  page: 0,
  size: 20,
  sort: 'id:desc',
  query: '',
};

type Props = {
  platform: 'app' | 'web'; // courseContentPlatform
};

const PostNews: FC<Props> = ({ platform = 'app' }) => {
  const windowSize = useWindowSize();
  const [filterListPosts, setFilterListPosts] =
    useState<FilterPostsType>(defaultFilters);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<'Thêm mới' | 'Chỉnh sửa'>(
    'Thêm mới'
  );
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [data, setData] = useState<[]>([]);
  const [postDetail, setPostDetail] = useState<
    PostDetailsInterface | undefined
  >(undefined);
  const [itemSelected, setItemSelected] = useState<
    PostDetailsInterface | undefined
  >(undefined);
  const [listDataHashTag, setListDataHashTag] = useState<HashTag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [phase, setPhase] = useState<[]>([]);
  const [course, setCourse] = useState<[]>([]);
  const [categories, setCategories] = useState<[]>([]);
  const [topic, setTopic] = useState<[]>([]);
  const [idDetail, setIdDetail] = useState<number>(0);
  const [allPosts, setAllPost] = useState<WebsitePostProps[]>([]);
  const [categoryStructure, setCategoryStructure] = useState<
    CategoryStructure[]
  >([]);

  const boxFilterRef = useRef<BoxFilterRef>(null);

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilterListPosts((cur: FilterPostsType) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const handleOnChangeModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const onOpenModalFilter = () => {
    boxFilterRef.current?.setOpenModal(true);
  };

  const onCloseModalFilter = () => {
    boxFilterRef.current?.setOpenModal(false);
  };

  const handleCloseModal = () => {
    setIsOpenModal(!isOpenModal);
    setItemSelected(undefined);
    setPostDetail(undefined);
    setIdDetail(0);
  };

  const handleEditModal = (value: any) => {
    setIdDetail(value.id);
    setModalType('Chỉnh sửa');
    setItemSelected(value);
  };

  const getAllPosts = async () => {
    setIsLoading(true);
    const res = await postContentService.getAllPosts();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setAllPost(res?.data);
    }
    setIsLoading(false);
  };

  const handleDeleteModal = async (value: any) => {
    const isDisable = value?.status === 1 ? true : false;
    if (isDisable) {
      const response = await postContentService.enable(value?.id);
      if (response?.status === 204) {
        notification.success({ message: 'Hiển thị bài viết thành công' });
        onReload();
      } else {
        notification.error({ message: 'Hiển thị bài viết thất bại' });
      }
    } else {
      const response = await postContentService.disable(value?.id);
      if (response?.status === 204) {
        notification.success({ message: 'Ẩn bài viết thành công' });
        onReload();
      } else {
        notification.error({ message: 'Ẩn  bài viết thất bại' });
      }
    }
  };

  const createHashtag = async (hashtag: string) => {
    await hashTagsServices.create({
      name: hashtag,
    });
  };

  const onChangeHashTag = (value: any[]) => {
    for (const tag of value) {
      if (listDataHashTag?.indexOf(tag) == -1) {
        createHashtag(tag);
      }
    }
  };

  const handleError = (title: string) => {
    switch (title) {
      case 'position_duplicate':
        notification.error({ message: 'Vị trí đã tồn tại' });
        break;
      case 'hashtag_not_found':
        notification.error({ message: 'Không tìm thấy hashtag' });
        break;
      case 'general_post_duplicate':
        notification.error({ message: 'Tiêu đề bài viết đã tồn tại' });
        break;

      default:
        notification.error({ message: 'Có lỗi xảy ra vui lòng kiểm tra lại' });
    }
  };

  const fetchHashtags = async () => {
    const response = await hashTagsServices.get();
    if (response?.status === 200) {
      setListDataHashTag(response.data);
    }
  };

  useEffect(() => {
    if (!isOpenModal) {
      setIdDetail(0);
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (isOpenModal) {
      fetchHashtags();
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (isLoading) {
      fetchdata();
    }
  }, [isLoading]);

  const fetchCategoryStructure = async () => {
    const response = await categoryServices.getStructure({
      platform: platform,
    });
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      setCategoryStructure(response?.data);
    }
  };

  useEffect(() => {
    fetchDataCourse();
    fetchDataPhase();
    fetchDataCategories();
    fetchDataTopic();
    fetchCategoryStructure();
    getAllPosts();
  }, []);

  useEffect(() => {
    if (idDetail !== 0) {
      fetchPostDetail();
    }
  }, [idDetail]);

  const fetchPostDetail = async () => {
    const response = await postContentService.details(idDetail);
    if (response?.status === 200) {
      setPostDetail(response?.data);
      handleOnChangeModal();
    } else {
      handleError(response.data.title);
    }
  };

  const fetchdata = async () => {
    const response = await postContentService.get({
      ...filterListPosts,
      courseContentPlatform: platform,
    });
    const { data } = response;
    if (response?.status === 200) {
      setData(data?.data);
      setIsLoading(false);
      setMetadata({
        pageCount: data?.totalPages,
        page: data?.page,
        size: data?.size,
        total: data?.total,
      });
    } else {
      setIsLoading(false);
    }
  };

  const fetchDataCourse = async () => {
    const response = await courseService.get();
    const { data } = response;
    setCourse(data?.data);
  };

  const fetchDataPhase = async () => {
    const response = await phaseService.get();
    const { data } = response;
    setPhase(data?.data);
  };

  const fetchDataCategories = async () => {
    const response = await categorieServices.get();
    const { data } = response;
    setCategories(data?.data);
  };

  const fetchDataTopic = async () => {
    const response = await topicService.get();
    const { data } = response;
    setTopic(data?.data);
  };

  const onReload = () => {
    setIsLoading(true);
    getAllPosts();
  };

  const onSearch = (e: any) => {
    setFilterListPosts((cur) => ({
      ...cur,
      query: e.target.value.replace(/^\s+/, ''),
    }));
  };

  const handleOnKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      setIsLoading(true);
      setFilterListPosts((cur) => ({
        ...cur,
        page: 0,
      }));
    }
  };

  const onHideModal = () => {
    setItemSelected(undefined);
    setPostDetail(undefined);
    setIdDetail(0);
    setIsOpenModal(false);
  };

  const handleSubmitFilter = async (values: SubmitFormInterface) => {
    setIsLoading(true);
    setFilterListPosts((cur: FilterPostsType) => ({
      ...cur,
      ...values,
      page: 0,
      size: 20,
    }));
    onCloseModalFilter();
  };

  const onResetFilter = () => {
    boxFilterRef.current?.onReset();
    setIsLoading(true);
    setFilterListPosts(defaultFilters);
  };

  const renderFormat = (type: number) => {
    switch (type) {
      case 1:
        return <>Bài viết</>;
      case 2:
        return <>Video</>;
      case 3:
        return <>Audio</>;
      default:
        break;
    }
  };

  const columns: ColumnsType<columnsType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      fixed: 'left',
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'name',
      width: '20%',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      render: (value: string) => <div className="text-esclipse">{value}</div>,
      ellipsis: true,
    },
    {
      title: 'Định dạng',
      dataIndex: 'format',
      key: 'format',
      width: 120,
      render: (value: any) => renderFormat(value),
    },
    {
      title: 'Chủ đề',
      dataIndex: 'courseSection',
      key: 'courseSection',
      width: 150,
      render: (topic: TopicInterface) => <>{topic?.name}</>,
    },
    {
      title: 'Chuyên mục',
      dataIndex: 'category',
      key: 'category',
      width: 130,
      render: (category: Category) => <>{category?.name}</>,
    },
    {
      title: 'Khoá học',
      dataIndex: 'course',
      key: 'course',
      width: 130,
      render: (course: Course) => <>{course?.name}</>,
    },
    {
      title: 'Danh mục',
      dataIndex: 'stage',
      key: 'stage',
      width: 130,
      render: (stage: Course) => <>{stage?.name}</>,
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      width: 130,
      render: (value: string) => (
        <>{moment(value).format('DD/MM/YYYY HH:mm')}</>
      ),
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 140,
      render: (value: string) => <>{value}</>,
    },
    {
      title: 'Thao tác',
      width: 140,
      render: (value: string) => {
        return (
          <BoxActions
            handleEditModal={handleEditModal}
            value={value}
            handleDeleteModal={handleDeleteModal}
          />
        );
      },
    },
  ];

  if (isOpenModal) {
    return (
      <ModalAddNews
        platform={platform}
        dataDetail={postDetail}
        itemDetails={itemSelected}
        type={modalType}
        handleOnChangeModal={handleCloseModal}
        onChangeHashTag={onChangeHashTag}
        listDataHashTag={listDataHashTag}
        onReload={onReload}
        onHideModal={onHideModal}
        categoryStructure={categoryStructure}
        allPosts={allPosts}
      />
    );
  }
  return (
    <div>
      <BoxFilter
        ref={boxFilterRef}
        phases={phase}
        courses={course}
        categories={categories}
        topics={topic}
        platform={platform}
        onSubmitFilter={handleSubmitFilter}
        onClose={onCloseModalFilter}
      />
      <div className="flex">
        <div className="w-96 pr-2">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Nhập tên bài viết"
            className="w-full"
            value={filterListPosts.query}
            onChange={onSearch}
            onKeyDown={handleOnKeyDown}
          />
        </div>
        <Button htmlType="submit" onClick={onOpenModalFilter}>
          Bộ lọc
        </Button>
        <Button onClick={onResetFilter} className="ml-2">
          Reset
        </Button>
        <div className="flex w-full justify-end">
          <Button
            type="primary"
            onClick={() => {
              handleOnChangeModal();
              setModalType('Thêm mới');
            }}
          >
            &#43; Thêm bài viết
          </Button>
        </div>
      </div>
      <Table
        rowKey={'id'}
        dataSource={data}
        columns={columns}
        className="myTablePost mt-2"
        scroll={{
          y: windowSize?.height - GLOBAL_HEIGHT - 70,
          x: 1400,
        }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filterListPosts.page + 1,
          total: metadata?.total,
          defaultPageSize: filterListPosts.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
      />
    </div>
  );
};

export default PostNews;
