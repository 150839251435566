import { APPLICATION_ACTION_TYPE } from './action';
import { Action } from './appContext';

export const reducer = (prevState: AppStorage, action: Action) => {
  switch (action.type) {
    case APPLICATION_ACTION_TYPE.UPDATE_USER_INFOR:
      return {
        ...prevState,
        userInfor: action.payload,
      };
    default:
      return prevState;
  }
};
