import { DatePicker } from 'antd';
import { DATE_FORMAT } from 'utils/constants';

type Props = {
  onChangeDate?: (_: any, value: string[]) => void;
};

export const HeaderOrder = ({ onChangeDate }: Props) => {
  return (
    <div className="flex my-2 items-center">
      <div>
        <div className="text-sm font-normal decoration-[#9d9d9d]">
          Danh sách khách hàng đăng kí mua hàng qua web
        </div>
      </div>
      <div className="w-48" />
      <div className="flex items-center">
        <div className="text-sm font-bold  mt-1.5">Khoảng thời gian</div>
        <div className="w-16" />
        <DatePicker.RangePicker
          allowClear
          format={DATE_FORMAT}
          placeholder={['Từ ngày', 'Đến ngày']}
          onChange={onChangeDate}
        />
      </div>
    </div>
  );
};
