import { Button, Modal } from 'antd';

type Props = {
  onHideModal?: () => void;
  onFinishConfirm?: () => void;
};

const ModalConfirm = ({ onHideModal, onFinishConfirm }: Props) => {
  const onFinish = () => {
    onFinishConfirm?.();
  };

  return (
    <>
      <Modal
        open={true}
        footer={null}
        closable={false}
        width={350}
        onOk={onFinish}
        onCancel={onHideModal}
        centered
      >
        <div className="text-base font-semibold text-center my-1">
          XÁC NHẬN ĐƠN HÀNG
        </div>
        <div className="text-sm text-normal text-center my-5">
          Bạn đã kiểm tra và chắc chắn xác nhận đơn hàng này đã được khách hàng
          thanh toán?
        </div>
        <div className="flex justify-center">
          <Button type="default" onClick={onHideModal}>
            Huỷ
          </Button>
          <div style={{ width: 20 }} />
          <Button type="primary" onClick={onFinish}>
            Xác nhận
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalConfirm;
