import { Col, Form, Input, Row, Select, Space } from 'antd';
import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import { LANDSCAPE, PORTRAIT } from 'containers/posts/constants';
import UploadFileChunks from 'components/UploadFileChunks';

type Props = {
  category: string;
  handleChangeOrientation: (value: 'landscape' | 'portrait') => void;
  onDeleteVideo: () => void;
  videoUrl: string | undefined;
  uploadFileSuccessVideo: (value: any) => void;
  orientation: 'landscape' | 'portrait';
};

const ViewVideo: React.FC<Props> = ({
  category,
  handleChangeOrientation,
  onDeleteVideo,
  videoUrl,
  uploadFileSuccessVideo,
  orientation,
}) => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={5}>
        <Form.Item
          name="orientation"
          label="Loại video"
          // rules={[{ required: true, message: 'Vui lòng chọn loại video' }]}
          initialValue={orientation}
        >
          <Select
            allowClear
            placeholder="Chọn loại..."
            onChange={handleChangeOrientation}
          >
            <Select.Option value={LANDSCAPE}>Video ngang</Select.Option>
            <Select.Option value={PORTRAIT}>Video dọc</Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <Col span={5}>
        <Form.Item
          name="postTime"
          label="Thời gian (giây)"
          // rules={[{ validator: validateNumber }]}
        >
          <Input placeholder="Nhập thời gian..." />
        </Form.Item>
      </Col>

      <div>
        {videoUrl ? (
          <div
            style={{
              width: '600px',
              display: 'flex',
              height: '300px',
              marginBottom: '60px',
            }}
          >
            <div>
              <p>* Video</p>{' '}
              <video style={{ width: '100%', height: '100%' }} controls>
                <source src={videoUrl} type="video/mp4" />
              </video>
            </div>
            <Space>
              <DeleteOutlined
                style={{ fontSize: '2em' }}
                onClick={onDeleteVideo}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            name="video"
            type={'VIDEO' as any}
            accept="video/*"
            maxSize={500 * 1000 * 10000}
            description="(Tải file mp4... Kích thước không quá 500MB...)"
            category={category}
            uploadFileSuccess={uploadFileSuccessVideo}
            title="Video"
            orientation={orientation}
            isRequired
          />
        )}
      </div>
    </Row>
  );
};

export default ViewVideo;
