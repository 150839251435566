import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Image,
  Input,
  Modal,
  Select,
  Space,
} from 'antd';
import UploadFileChunks from 'components/UploadFileChunks';
import { getCDNUrl } from 'utils';
import { DISPLAY_POPUP, SCREEN_POPUP } from 'utils/enum';

export interface IModalPopupProps {
  isModalOpen: boolean;
  handleOk: (value: any) => void;
  handleChangePopup: () => void;
  form: FormInstance<any>;
  type: 'Chỉnh sửa' | 'Thêm mới';
  onDeleteImageBanner: () => void;
  image: string | undefined;
  handleSuccessUploadFile: (value: any) => void;
}

export default function ModalPopup({
  isModalOpen,
  handleChangePopup,
  handleOk,
  type,
  form,
  onDeleteImageBanner,
  image,
  handleSuccessUploadFile,
}: IModalPopupProps) {
  return (
    <Modal
      open={isModalOpen}
      onCancel={handleChangePopup}
      closeIcon={<></>}
      footer={null}
      className="mymodal"
      centered
      width={700}
    >
      <div
        style={{
          backgroundColor: 'blue',
          margin: '-20px 0px 10px -24px',
          height: '40px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: '#fff',
          fontSize: '18px',
          padding: '0 5px',
        }}
      >
        <div></div>
        <div>{type} POP-UP</div>
        <div onClick={handleChangePopup} style={{ cursor: 'pointer' }}>
          <CloseOutlined />
        </div>
      </div>

      <Form form={form} layout={'vertical'} onFinish={handleOk}>
        <div style={{ height: '550px', overflow: 'auto' }}>
          <Form.Item
            label="Tên Pop-up"
            name={'name'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên pop-up' },
              {
                max: 150,
                message: 'Vui lòng không nhập quá 150 ký tự',
              },
            ]}
          >
            <Input placeholder="Nhập tên Pop-up..." />
          </Form.Item>
          <Form.Item
            label="Hiển thị"
            name={'display'}
            rules={[{ required: true, message: 'Vui lòng chọn nơi hiển thị' }]}
          >
            <Select placeholder="Chọn nơi hiển thị" options={DISPLAY_POPUP} />
          </Form.Item>
          <Form.Item
            label="Màn hình"
            name={'screen'}
            rules={[
              { required: true, message: 'Vui lòng chọn màn hình hiển thị' },
            ]}
          >
            <Select
              placeholder="Chọn màn hình hiển thị"
              options={SCREEN_POPUP}
            />
          </Form.Item>

          <Form.Item
            label="Gán Links"
            name={'url'}
            rules={[
              { required: true, message: 'Vui lòng nhập đường link cho popup' },
              {
                pattern: /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/,
                message: 'Link URL phải có định dạng là một Link!',
              },
            ]}
          >
            <Input placeholder="Nhập đường link..." />
          </Form.Item>

          <Form.Item
            label="Thời gian hiện thị (Từ -> Đến)"
            name={'time'}
            rules={[
              { required: true, message: 'Vui lòng chọn thời gian hiển thị' },
            ]}
          >
            <DatePicker.RangePicker
              format={'DD/MM/YYYY'}
              placeholder={['Từ', 'Đến']}
            />
          </Form.Item>

          {image ? (
            <div>
              <p>Ảnh*</p>
              <Space>
                <Image width={200} src={getCDNUrl(image)} />
                <DeleteOutlined
                  style={{ fontSize: '44px' }}
                  onClick={onDeleteImageBanner}
                />
              </Space>
            </div>
          ) : (
            <UploadFileChunks
              name="image"
              type={'IMAGE' as any}
              accept="image/*"
              description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
              category={'popup'}
              uploadFileSuccess={handleSuccessUploadFile}
              title="Ảnh"
            />
          )}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <Button type="primary" htmlType="submit">
            {type}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
