import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input, Modal, Space, notification } from 'antd';
import UploadFileChunks from 'components/UploadFileChunks';
import { categoryServices } from 'network/services/categories';
import { FC, useEffect, useState } from 'react';
import { getCDNUrl } from 'utils';

type Props = {
  data: any;
  handleCloseModal: () => void;
  onReload: () => void;
};

const ModalCreateCategory: FC<Props> = ({
  data,
  onReload,
  handleCloseModal,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const handleOk = () => {};
  const [imageIdStr, setImageIdStr] = useState<string>('');

  const onFinish = async (values: any) => {
    console.log('onFinish', values);
    setLoading(true);
    let response = null;
    if (data) {
      response = await categoryServices.update(data.id, {
        ...values,
        image: imageIdStr,
      });
    } else {
      response = await categoryServices.create({
        ...values,
        image: imageIdStr,
      });
    }

    const typeModal = data ? 'Cập nhật' : 'Tạo';
    if (response.status === 200) {
      notification.success({ message: `${typeModal} chuyên mục thành công` });
      setLoading(false);
      handleCloseModal();
      onReload();
    } else {
      notification.error({ message: `${typeModal} chuyên mục thất bại` });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const uploadFileSuccess = (file: any) => {
    setImageIdStr(file.idStr);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data?.name,
        description: data?.description,
      });
      const imgId = data?.image?.split('attachments/');
      console.log('imgId', imgId);
      setImageIdStr(imgId[1]);
    }
  }, [data]);

  const onDeleteImage = () => {
    setImageIdStr('');
  };

  return (
    <Modal
      title={data ? 'Chỉnh sửa chuyên mục' : 'Thêm mới chuyên mục'}
      open={true}
      footer={null}
      onOk={handleOk}
      onCancel={handleCloseModal}
    >
      <Form
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Tên chuyên mục"
          name="name"
          rules={[{ required: true, message: 'Vui lòng nhập Tên chuyên mục!' }]}
        >
          <Input placeholder="Nhập tên chuyên mục" />
        </Form.Item>
        {imageIdStr ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p>* Ảnh</p>
            <Space>
              <Image width={200} src={getCDNUrl(imageIdStr)} />
              <DeleteOutlined
                style={{ fontSize: '44px' }}
                onClick={onDeleteImage}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            name="image"
            type={'IMAGE' as any}
            accept="image/*"
            description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
            category={'pregnant_education_category'}
            uploadFileSuccess={uploadFileSuccess}
          />
        )}
        <Form.Item
          style={{ marginTop: 12 }}
          label="Mô tả"
          name="description"
          rules={[{ max: 150, message: 'Mô tả không quá 150 ký tự' }]}
        >
          <Input.TextArea placeholder="Nhập mô tả không quá 150 ký tự..." />
        </Form.Item>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            {data ? 'Chỉnh sửa' : 'Thêm mới'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalCreateCategory;
