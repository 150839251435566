import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification, Typography } from 'antd';
import { authService } from 'network/services/auth';
import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
import LogoMomEdu from './logo/LogoMomedu';
import { DURATION, LOCAL_STORAGE_KEYS } from 'utils/constants';
import { initApi } from 'network';
import { customersServices } from 'network/services/customers';
import './style.scss';

type Props = {};
type LoginData = {
  loading: boolean;
};

const Login: React.FC<Props> = () => {
  const [loginData, setLoginData] = useState<LoginData>({ loading: false });

  const onFinish = async (values: LoginInput) => {
    setLoginData({ loading: true });
    const successCallback = async (res: any = {}) => {
      const { access_token } = res?.data || {};
      localStorage.setItem(LOCAL_STORAGE_KEYS.accessToken, access_token);
      initApi(access_token);
      const resp = await customersServices.getInfo();
      if (resp?.status === 200) {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.userInfo,
          JSON.stringify(resp.data)
        );
        window.location.href = '/';
        notification.success({
          message: 'Thành công',
          description: 'Đăng nhập thành công',
          duration: DURATION,
        });
        window.location.reload();
      }
    };
    const failCallback = (err: any) => {
      const {
        response: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          data: {},
        },
      } = err || {};
      notification.error({
        message: 'Đăng nhập thất bại',
        description: 'Tài khoản hoặc mật khẩu không chính xác',
        duration: 4.5,
      });
    };
    await authService.login(values, successCallback, failCallback);
    setLoginData({ loading: false });
  };

  const onFinishFailed = () => {};

  return (
    <div id="login-page">
      <div className="login-content">
        <Typography.Title
          level={4}
          style={{
            color: '#F48F3B',
            textAlign: 'center',
            fontSize: '40px',
            marginBottom: '50px',
          }}
        >
          WELCOME
        </Typography.Title>

        <Form
          name="login-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="label">Tên đăng nhập</div>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập' }]}
            style={{ marginBottom: '35px' }}
          >
            <Input
              style={{
                height: '45px',
                borderColor: '#FFA500',
              }}
              prefix={<UserOutlined />}
              placeholder="Tên đăng nhập"
            />
          </Form.Item>
          <div className="label">Mật khẩu</div>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
          >
            <Input.Password
              style={{ height: '45px', borderColor: '#FFA500' }}
              prefix={<LockOutlined />}
              placeholder="Nhập mật khẩu"
            />
          </Form.Item>

          <Form.Item noStyle>
            <div className="submit-btn">
              <Button
                type="primary"
                htmlType="submit"
                loading={loginData.loading}
                style={{
                  height: '51px',
                  width: '182px',
                  background: '#F48F3B',
                  fontSize: '20px',
                  fontWeight: '600',
                  marginTop: '20px',
                  marginBottom: '70px',
                }}
              >
                Đăng nhập
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <div className="logo">
        <LogoMomEdu />
      </div>
    </div>
  );
};

export default Login;
