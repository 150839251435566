import {
  BackwardOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Image, Input, Space, message } from 'antd';
import TextEditor from 'components/TextEditor';
import UploadFileChunks from 'components/UploadFileChunks';
import { RESPONSE_CODE } from 'network/constants';
import IphoneScreen from 'assets/image/iphoneScreen.png';
import React, { useEffect } from 'react';
import { appBabyTrackerServices } from 'network/services/appBabyTracker';

type Props = {
  onHideModal: () => void;
  isEdit: boolean;
  detail: any;
};

export const CreateModal = ({ onHideModal, isEdit, detail }: Props) => {
  const [value, setValue] = React.useState('');
  const [form] = Form.useForm();
  const [thumbnail, setThumbnail] = React.useState<string | undefined>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        categoryId: detail?.category,
        topicId: detail?.topic,
        title: detail?.title,
        chapter: detail?.chapter,
        description: detail?.des,
      });
      setThumbnail(detail?.img);
      setValue(detail?.content);
    }
  }, [isEdit]);

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
      content: value,
      image: thumbnail,
    };
    setLoading(true);
    if (isEdit) {
      const params = {
        ...values,
        content: value,
        image: thumbnail,
        rowNum: Number(detail?.id) + 1,
        id: detail?.id,
      };
      const response = await appBabyTrackerServices.updateDataGoogleSheet(
        params
      );
      if (response?.status === RESPONSE_CODE.SUCCESS) {
        message.success('Cập nhật thành công!!');
        onHideModal?.();
      } else {
        message.error(response?.data);
        setLoading(false);
      }
    } else {
      const response = await appBabyTrackerServices.updateDataGoogleSheet(
        params
      );
      if (response?.status === RESPONSE_CODE.SUCCESS) {
        message.success('Thêm thành công!!');
        form.resetFields();
        setThumbnail('');
        setValue('');
        setLoading(false);
        onHideModal?.();
      } else {
        message.error(response?.data);
        setLoading(false);
      }
    }
  };

  const uploadFileThumbnailSuccess = (file: { linkUrl: string }) => {
    setThumbnail(file.linkUrl);
  };

  const onDeleteThumbnail = () => {
    setThumbnail('');
  };
  return (
    <div>
      <Button onClick={onHideModal}>
        <BackwardOutlined />
      </Button>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <div className="flex flex-col">
          <div className="flex flex-row space-x-2.5">
            <Form.Item label="Category ID:" name="categoryId" required>
              <Input placeholder="Nhập category id..." />
            </Form.Item>
            <Form.Item label="Topic ID:" name="topicId" required>
              <Input placeholder="Nhập topic id..." />
            </Form.Item>
            <Form.Item label="Title:" name="title" required>
              <Input placeholder="Nhập title..." />
            </Form.Item>
            <Form.Item label="Chapter:" name="chapter" required>
              <Input placeholder="Nhập chapter..." />
            </Form.Item>
          </div>
          <div className="w-[calc(45%)]">
            <Form.Item label="Description:" name="description" required>
              <Input placeholder="Nhập description..." />
            </Form.Item>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: 'calc(60% - 8px)',
            }}
          >
            {thumbnail ? (
              <div>
                <p>Image</p>
                <Space className="mt-5 mb-5">
                  <Image width={100} src={thumbnail} />
                  <DeleteOutlined
                    style={{ fontSize: '44px' }}
                    onClick={onDeleteThumbnail}
                  />
                  <a>{thumbnail}</a>
                </Space>
              </div>
            ) : (
              <UploadFileChunks
                key={'image-thumbnail'}
                name="thumbnail"
                type={'IMAGE' as any}
                accept="image/*"
                description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
                category={'general_post'}
                uploadFileSuccess={uploadFileThumbnailSuccess}
                title="Image"
              />
            )}
          </div>
          <view className="mb-5">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {`${isEdit ? 'Sửa' : 'Thêm'}`} dữ liệu
            </Button>
          </view>
          <div className="flex">
            <div className="flex-2" style={{ maxWidth: '70%' }}>
              <TextEditor
                placeholder={'Nhập nội dung cần chuyển đổi'}
                value={value}
                onChange={setValue}
                style={{ height: '36em' }}
              />
            </div>
            <div className="flex flex-col flex-1 ml-20">
              <div
                className="overflow-y-auto"
                style={{
                  width: '20em',
                  height: '43em',
                  backgroundImage: `url(${IphoneScreen})`,
                  backgroundSize: 'cover',
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: value,
                  }}
                  className="my-14 ml-5 mr-2"
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
