import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const NOTIFICATION_URL = '/api/banners';

const get = (params: NotiGetParamsInterface) => {
  deleteParamsNotUsing(params);
  const url = getQueryString(`${NOTIFICATION_URL}?sort=id:desc`, params);
  return request(url);
};

const create = (body?: any) => {
  return request(NOTIFICATION_URL, 'post', body);
};

const detail = (id: number) => {
  return request(NOTIFICATION_URL + `/${id}`);
};

const update = (id: number, body: any) => {
  const url = NOTIFICATION_URL + `/${id}`;
  return request(url, 'patch', body);
};

const setPositions = (id: number) => {
  const url = NOTIFICATION_URL + `/${id}/clear-position`;
  return request(url, 'put');
};

export const bannersServices = {
  get,
  create,
  update,
  detail,
  setPositions,
};
