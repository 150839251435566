import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  notification,
} from 'antd';
import UploadFileChunks from 'components/UploadFileChunks';
import { packageService } from 'network/services/package';
import React from 'react';
import { PACKAGES } from 'utils/enum';
import { formatPrice, validateNumber } from 'utils/helper';

type Props = {
  onHideModal: () => void;
  onReload: () => void;
  type?: string;
  dataDetail: PackageInterface | undefined;
  course: CourseInterface[];
};

export const ModalCreatePackage: React.FC<Props> = ({
  onHideModal,
  type,
  dataDetail,
  onReload,
  course,
}) => {
  const [form] = Form.useForm();
  const [imageIdStrBanner, setImageIdStrBanner] = React.useState<
    string | undefined
  >('');
  const [status, setStatus] = React.useState<boolean | undefined>(false);
  const [isCombo, setIsCombo] = React.useState<boolean | undefined>(false);
  const [upcomingRelease, setUpcomingRelease] = React.useState<
    boolean | undefined
  >(false);
  const [newlyPackage, setNewlyPackage] = React.useState<boolean | undefined>(
    true
  );
  const [courseData, setCourseData] = React.useState<PackageInterface[]>([]);

  const fetchDataCourse = async () => {
    const response = await packageService.get();
    if (response.status === 200) {
      setCourseData(response?.data?.content);
    }
  };

  React.useEffect(() => {
    if (type === 'Chỉnh sửa') {
      form.setFieldsValue({
        code: dataDetail?.code,
        name: dataDetail?.name,
        courseId: dataDetail?.course?.id,
        originalPrice: formatPrice(dataDetail?.originalPrice),
        actualPrice: formatPrice(dataDetail?.actualPrice),
        inAppPrice: formatPrice(dataDetail?.inAppPrice),
        position: dataDetail?.position,
        duration: dataDetail?.duration,
        subscriptionId: dataDetail?.subscriptionId,
        type: dataDetail?.type,
        childVipPackageIds: dataDetail?.childVipPackageIds,
      });
      setImageIdStrBanner(dataDetail?.avatar);
      setStatus(dataDetail?.status);
      setIsCombo(dataDetail?.isCombo);
      setUpcomingRelease(dataDetail?.upcomingRelease);
      setNewlyPackage(dataDetail?.newlyPackage);
    } else {
      form.resetFields();
    }
  }, [dataDetail]);

  const renderTitleHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'hsl(215deg 100% 54%)',
          color: '#fff',
          margin: '-20px -24px 20px -24px',
          padding: '15px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        {`${type} Gói khoá học`}
      </div>
    );
  };
  React.useEffect(() => {
    fetchDataCourse();
  }, []);

  const onSubmit = async (value: PackageParams) => {
    const params = {
      ...value,
      avatar: imageIdStrBanner,
      originalPrice: value.originalPrice.replace(/\D/g, ''),
      actualPrice: value.actualPrice.replace(/\D/g, ''),
      inAppPrice: value.inAppPrice.replace(/\D/g, ''),
      status: status,
      isCombo: isCombo,
      upcomingRelease: upcomingRelease,
      newlyPackage: newlyPackage,
    };
    if (params.childVipPackageIds?.includes(dataDetail?.id!)) {
      notification.error({ message: 'Không thể thêm gói này vào chính nó' });
      return;
    }
    if (type === 'Thêm mới') {
      const response = await packageService.create(params);
      if (response.status === 200) {
        notification.success({ message: `${type} Gói thành công` });
        onHideModal();
        onReload();
      }
    } else {
      const response = await packageService.update(dataDetail?.id, params);
      if (response.status === 200) {
        notification.success({ message: `${type} Gói thành công` });
        onHideModal();
        onReload();
      } else {
        notification.error({ message: `${type} Gói thất bại` });
        notification.error({ message: `${response?.data}` });
      }
    }
  };

  const formSelectChildPackage = () => {
    return (
      <Form.Item
        label="Khoá học áp dụng"
        name="childVipPackageIds"
        rules={[{ required: true, message: 'Vui lòng chọn khoá học' }]}
      >
        <Select
          placeholder="Chọn khoá học..."
          mode="tags"
          allowClear
          style={{ width: '100%', height: '30%' }}
          options={courseData.map((item: PackageInterface) => ({
            value: item.id,
            label: `${item.code} - ${item.name} - ${renderDuration(
              item.duration
            )}`,
          }))}
        />
      </Form.Item>
    );
  };
  const renderDuration = (duration: string) => {
    const packageItem = PACKAGES.find((item) => item.id === duration);
    return packageItem ? packageItem.value : '';
  };

  const onDeleteImageBanner = () => {
    setImageIdStrBanner('');
  };

  const uploadFileSuccessBanner = (file: { linkUrl: string }) => {
    setImageIdStrBanner(file.linkUrl);
  };

  const handleSelectStatus = (value: boolean) => {
    setStatus(value);
  };

  const handleSelectIsCombo = (value: boolean) => {
    setIsCombo(value);
  };

  const handleSelectUpcomingRelease = (value: boolean) => {
    setUpcomingRelease(value);
  };
  const handleSelectNewlyPackage = (value: boolean) => {
    setNewlyPackage(value);
  };
  return (
    <Modal
      title={renderTitleHeader()}
      open={true}
      footer={null}
      onOk={onHideModal}
      onCancel={onHideModal}
      width={1000}
      closeIcon={<CloseOutlined style={{ color: '#fff', fontSize: '20px' }} />}
    >
      <Form form={form} layout="horizontal" onFinish={onSubmit}>
        <Form.Item
          label="Mã gói:"
          name="code"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          rules={[{ message: 'Vui lòng nhập mã gói!', required: true }]}
        >
          <Input
            disabled={type === 'Chỉnh sửa' ? true : false}
            placeholder="Nhập mã gói..."
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="Tên gói:"
          name="name"
          rules={[{ message: 'Vui lòng nhập tên gói!', required: true }]}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          colon={false}
        >
          <Input placeholder="Nhập tên gói..." style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="Khoá học:"
          name="courseId"
          rules={[{ required: true, message: 'Vui lòng chọn khoá học!' }]}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          colon={false}
        >
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Chọn khoá học"
            options={course.map((item: CourseInterface) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Giá gốc:"
          name="originalPrice"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          rules={[
            {
              validator: validateNumber,
              required: true,
            },
          ]}
        >
          <Input
            placeholder="Nhập giá gốc..."
            onChange={(e: any) => {
              const { value } = e.target;
              const formatted = value
                .replace(/\D/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
              form.setFieldsValue({
                originalPrice: formatted,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Giá bán:"
          name="actualPrice"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          rules={[{ validator: validateNumber, required: true }]}
        >
          <Input
            placeholder="Nhập giá bán..."
            style={{ width: '100%' }}
            onChange={(e: any) => {
              const { value } = e.target;
              const formatted = value
                .replace(/\D/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
              form.setFieldsValue({
                actualPrice: formatted,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Giá In-App:"
          name="inAppPrice"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          rules={[{ validator: validateNumber, required: true }]}
        >
          <Input
            placeholder="Nhập giá In-App..."
            style={{ width: '100%' }}
            onChange={(e: any) => {
              const { value } = e.target;
              const formatted = value
                .replace(/\D/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
              form.setFieldsValue({
                inAppPrice: formatted,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Thứ tự:"
          name="position"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          rules={[
            {
              required: true,
              validator: validateNumber,
            },
          ]}
        >
          <Input placeholder="Nhập Thứ tự..." style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="Thời gian:"
          name="duration"
          rules={[
            { required: true, message: 'Vui lòng chọn thời gian sử dụng!' },
          ]}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          colon={false}
        >
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Chọn thời gian sử dụng..."
            options={PACKAGES.map((item: any) => ({
              value: item.id,
              label: item.value,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Sub ID:"
          name="subscriptionId"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          rules={[{ message: 'Vui lòng nhập ID!' }]}
        >
          <Input placeholder="Subscription ID..." style={{ width: '100%' }} />
        </Form.Item>

        <div style={{ display: 'flex' }}>
          <Form.Item
            label="Ẩn:"
            name="status"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 18 }}
            style={{ marginLeft: 'auto' }}
          >
            <Switch checked={status} onChange={handleSelectStatus} />
          </Form.Item>
          <Form.Item
            label="Khóa học Combo:"
            name="status"
            labelCol={{ span: 20 }}
            wrapperCol={{ span: 18 }}
            style={{ marginLeft: 'auto' }}
          >
            <Switch checked={isCombo} onChange={handleSelectIsCombo} />
          </Form.Item>
          <Form.Item
            label="Sắp ra mắt:"
            name="status"
            labelCol={{ span: 15 }}
            wrapperCol={{ span: 18 }}
            style={{ marginLeft: 'auto' }}
          >
            <Switch
              checked={upcomingRelease}
              onChange={handleSelectUpcomingRelease}
            />
          </Form.Item>
          <Form.Item
            label="Mới ra mắt:"
            name="status"
            labelCol={{ span: 15 }}
            wrapperCol={{ span: 18 }}
            style={{ marginLeft: 'auto' }}
          >
            <Switch
              checked={newlyPackage}
              onChange={handleSelectNewlyPackage}
            />
          </Form.Item>
        </div>
        {isCombo && formSelectChildPackage()}
        <Form.Item
          label="Type:"
          name="type"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
        >
          <Input placeholder="Type..." style={{ width: '100%' }} />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {imageIdStrBanner ? (
            <div className={'center-item'} style={{ marginBottom: 20 }}>
              <Space>
                <Image width={200} src={imageIdStrBanner} />
                <DeleteOutlined
                  style={{ fontSize: '44px' }}
                  onClick={onDeleteImageBanner}
                />
              </Space>
            </div>
          ) : (
            <UploadFileChunks
              name="banner"
              type={'IMAGE' as any}
              accept="image/*"
              description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
              category={'general_topic'}
              uploadFileSuccess={uploadFileSuccessBanner}
              title="Ảnh Banner"
              isRequired={true}
            />
          )}
        </div>
      </Form>

      <div className={'center-item'}>
        <Button type="primary" onClick={form.submit}>
          {type}
        </Button>
      </div>
    </Modal>
  );
};
