import { Divider, Space, Tag } from 'antd';
import moment from 'moment';

const columns = (
  onConfirm: (id: number) => void,
  onCancel: (id: number) => void,
  handleShowModalCustomerCare: (id: number) => void
): any => {
  const renderPaymentStatus = (paymentStatus: number, obj: any) => {
    switch (paymentStatus) {
      case 0:
        return (
          <Tag color={'warning'} className="decoration-[#FF8A00] text-[10px]">
            {obj?.paymentMethod !== 'IAP'
              ? 'Chờ thanh toán'
              : 'Chờ xác thực từ Apple và Google'}
          </Tag>
        );
      case 1:
        return (
          <Tag color={'success'} className="decoration-green-500 text-[10px]">
            {obj?.paymentMethod !== 'IAP'
              ? 'Đã thanh toán'
              : ' Đã được xác thực từ Apple và Google'}
          </Tag>
        );
      case 2:
        return (
          <Tag color={'error'} className="decoration-[#EE596A] text-[10px]">
            Xác thực lỗi từ Apple và Google
          </Tag>
        );
      default:
        break;
    }
  };

  const renderStatus = (status: number) => {
    switch (status) {
      case 0:
        return (
          <Tag color={'warning'} className="decoration-[#FF8A00] text-[10px]">
            Đã đăng kí
          </Tag>
        );
      case 1:
        return (
          <Tag color={'success'} className="decoration-green-500 text-[10px]">
            Thành công
          </Tag>
        );
      case 2:
        return (
          <Tag color={'error'} className="decoration-[#EE596A] text-[10px]">
            Đã huỷ
          </Tag>
        );
      default:
        break;
    }
  };

  const renderCustomerCareAction = (id: number) => {
    return (
      <Space>
        <a onClick={handleShowModalCustomerCare.bind(null, id)}>Hành động</a>
      </Space>
    );
  };

  const renderCustomerStatus = (status: number, id: number) => {
    switch (status) {
      case 0:
        return (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Tag color={'warning'} className="decoration-[#FF8A00] text-[10px]">
              Chưa chăm sóc
            </Tag>
            {renderCustomerCareAction(id)}
          </div>
        );
      case 1:
        return (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Tag color={'blue'} className="decoration-green-500 text-[10px]">
              Đang chăm sóc
            </Tag>
            {renderCustomerCareAction(id)}
          </div>
        );
      case 2:
        return (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Tag
              color={'green-inverse'}
              className="decoration-[#EE596A] text-[12px]"
            >
              KH đã đồng ý
            </Tag>
          </div>
        );
      case 3:
        return (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Tag
              color={'red-inverse'}
              className="decoration-[#EE596A] text-[12px]"
            >
              KH không đồng ý
            </Tag>
          </div>
        );
      default:
        break;
    }
  };

  const renderOption = (status: number, id: number) => {
    switch (status) {
      case 0:
        return (
          <Space>
            <a onClick={onConfirm.bind(null, id)}>Xác nhận</a>
            <Divider type="vertical" style={{ borderColor: '#1677ff' }} />
            <a onClick={onCancel.bind(null, id)}>Huỷ</a>
          </Space>
        );
      case 1:
        return (
          <div className="flex justify-center">
            <a onClick={onCancel.bind(null, id)}>Huỷ</a>
          </div>
        );
      case 2:
        return;
      default:
        break;
    }
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: '5%',
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      width: '15%',
    },
    {
      title: 'Tên khách hàng ',
      dataIndex: 'customerInfo',
      width: '20%',
      render: (customerInfo: CustomerInfoInterface) => {
        return customerInfo?.customerName;
      },
    },
    {
      title: 'SĐT',
      dataIndex: 'customerInfo',
      width: '10%',
      render: (customerInfo: CustomerInfoInterface) => {
        return customerInfo?.phone;
      },
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'customerInfo',
      width: '15%',
      render: (customerInfo: CustomerInfoInterface) => {
        return customerInfo?.address;
      },
    },
    {
      title: 'Ngày dự sinh',
      dataIndex: 'customerInfo',
      width: '15%',
      render: (customerInfo: CustomerInfoInterface) => {
        return customerInfo?.estimateDueDate;
      },
    },
    {
      title: 'Mặt hàng',
      dataIndex: 'orderItems',
      width: '20%',
      render: (orderItems: OrderItemsInterface) => {
        if (Array.isArray(orderItems) && orderItems.length > 0) {
          return orderItems[0]?.itemName;
        }
        return null;
      },
    },
    {
      title: 'Giá trị',
      dataIndex: 'orderItems',
      width: '10%',
      render: (orderItems: OrderItemsInterface) => {
        if (Array.isArray(orderItems) && orderItems.length > 0) {
          return orderItems[0]?.price?.toLocaleString(0);
        }
        return null;
      },
    },
    {
      title: 'Voucher',
      dataIndex: 'voucherCode',
      width: '10%',
    },
    {
      title: 'Ngày đăng kí',
      dataIndex: 'orderItems',
      width: '15%',
      render: (orderItems: OrderItemsInterface) => {
        if (Array.isArray(orderItems) && orderItems.length > 0) {
          return orderItems[0]?.createdAt
            ? moment(orderItems[0]?.createdAt).format('DD/MM/YYYY | HH:mm:ss')
            : '--';
        }
        return null;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: '12%',
      render: (status: number) => renderStatus(status),
    },
    {
      title: 'Trạng thái thanh toán ',
      dataIndex: 'paymentStatus',
      width: '18%',
      render: (paymentStatus: number, obj: any) =>
        renderPaymentStatus(paymentStatus, obj),
    },

    {
      title: 'Hình thức thanh toán',
      dataIndex: 'paymentMethod',
      width: '18%',
    },
    {
      title: 'Người cập nhật',
      dataIndex: 'modifiedBy',
      width: '15%',
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedAt',
      width: '15%',
      render: (modifiedAt: Date | string) =>
        modifiedAt ? moment(modifiedAt).format('DD/MM/YYYY | HH:mm:ss') : 'N/A',
    },
    {
      title: 'Thao tác',
      dataIndex: 'status',
      width: '13%',
      render: (status: number, id: number) => {
        return renderOption(status, id);
      },
    },
    {
      title: 'Trạng thái chăm sóc ',
      dataIndex: 'customerCareStatus',
      width: '15%',
      render: (customerCareStatus: number, id: number) =>
        renderCustomerStatus(customerCareStatus, id),
      fixed: 'right',
    },
  ];
};

export default columns;
