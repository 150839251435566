import React, { useEffect } from 'react';

import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useState } from 'react';
import { ADMIN } from 'utils/constants';
import { validateEmail, validateName } from 'utils/helper';

const STATUS = [
  {
    value: 1,
    label: 'Đang hoạt động',
  },
  {
    value: 2,
    label: 'Ngừng hoạt động',
  },
];

const { Option } = Select;

type Props = {
  name?: string;
  initialValues?: ActionAccountInput;
  submitText?: string;
  onFinish: (values: any) => void;
  onFinishFailed: (error: any) => void;
  listRole: RoleInterface[];
  isEdit?: boolean;
};

const FormAccount: React.FC<Props> = ({
  name,
  initialValues,
  submitText,
  onFinish,
  onFinishFailed,
  listRole,
  isEdit,
}) => {
  const [role, setRole] = useState<RoleE>();
  const [form] = Form.useForm();
  const handleChangeRole = (value: RoleE) => {
    setRole(value);
  };

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  return (
    <Form
      name={name}
      layout="vertical"
      autoComplete="off"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="username"
            label="Tên đăng nhập"
            rules={[
              { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
              { max: 60, message: 'Không được nhập quá 60 ký tự!' },
              { validator: !isEdit ? validateEmail : undefined },
            ]}
          >
            <Input
              placeholder="Nhập tên đăng nhập"
              disabled={!!initialValues?.username}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Mật khẩu"
            rules={[
              {
                required: submitText === 'Lưu' ? false : true,
                message: 'Vui lòng nhập mật khẩu!',
              },
              { min: 8, message: 'Mật khẩu phải có ít nhất 8 ký tự' },
            ]}
          >
            <Input.Password
              disabled={submitText === 'Lưu' ? true : false}
              defaultValue={
                submitText === 'Lưu' ? 'Exsoft12345' : 'Exsoft12345'
              }
            />
          </Form.Item>

          <Form.Item
            name="managerId"
            label="Người phụ trách"
            rules={[{ required: false }]}
          >
            <Select allowClear placeholder="" disabled={role === ADMIN}>
              <Option value="male">male</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Họ và tên"
            name="fullname"
            rules={[
              { required: true, message: 'Vui lòng nhập họ và tên!' },
              { max: 30, message: 'Không được nhập quá 30 ký tự!' },
              { validator: validateName },
            ]}
          >
            <Input placeholder="Nhập họ và tên" />
          </Form.Item>

          <Form.Item name="role" label="Roles" rules={[{ required: true }]}>
            <Select
              allowClear
              placeholder=""
              onChange={handleChangeRole}
              options={listRole.map((item: RoleInterface) => ({
                value: item.code,
                label: item.name,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="status"
            label="Trạng thái tài khoản"
            rules={[{ required: true }]}
          >
            <Select allowClear placeholder="">
              {STATUS.map(({ label, value }: Status) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <div className="center-item" style={{ width: '100%' }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {submitText}
            </Button>
          </Form.Item>
        </div>
      </Row>
    </Form>
  );
};

export default FormAccount;
