import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Row, message } from 'antd';
import saveAs from 'file-saver';
import moment from 'moment';
import { customersServices } from 'network/services/customers';
import React, { FC, useState } from 'react';
import { isAdmin } from 'utils';

type Props = {
  onSearch: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onShowModal: () => void;
  inputPlaceHolder: string;
  btnTitle: string;
  subItem?: any;
  filters?: any;
  isMobile?: boolean;
};

const ActionsBar: FC<Props> = ({
  onSearch,
  onShowModal,
  inputPlaceHolder,
  btnTitle,
  subItem,
  filters,
  isMobile = false,
}) => {
  const [isLoadingBtnExport, setIsLoadingBtnExport] = useState(false);

  const handleExport = async () => {
    setIsLoadingBtnExport(true);
    const resp = await customersServices.handleExport(filters);
    setIsLoadingBtnExport(false);
    const { data } = resp;
    if (resp?.status === 200) {
      message.success('Export thành công!');
      const fileName = `DANH_SACH_KHACH_HANG_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    } else {
      message.error('Có lỗi trong quá trình export!');
    }
  };

  return (
    <Row className="mb-2">
      <Input
        prefix={<SearchOutlined />}
        placeholder={inputPlaceHolder}
        className="w-80 mr-2 max-md:mb-2 max-md:w-full"
        onChange={onSearch}
      />
      {subItem}
      {isAdmin() && (
        <Button
          type="primary"
          loading={isLoadingBtnExport}
          disabled={isLoadingBtnExport}
          onClick={handleExport}
          className="mr-2"
          size={isMobile ? 'small' : 'middle'}
        >
          Export
        </Button>
      )}
      <Button
        type="primary"
        onClick={onShowModal}
        size={isMobile ? 'small' : 'middle'}
      >
        {btnTitle}
      </Button>
    </Row>
  );
};

export default ActionsBar;
