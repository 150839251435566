import { dashboardService } from 'network/services/dashboard';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ChartDashBoard } from './chart';
import { Button } from 'antd';
type Props = {};

const Dashboard: React.FC<Props> = () => {
  const [dashboardData, setDashboardData] = React.useState<any>({});
  const [isLoadingApi, setIsLoadingApi] = React.useState(false);
  const [isReload, setIsReload] = React.useState(false);
  const [unitDate, setUnitDate] = React.useState('month');
  const fetchDashboard = async (unitDate: string = 'month') => {
    const resp = await dashboardService.get(unitDate);
    if (resp.status === 200) {
      setDashboardData(resp.data);
      setIsLoadingApi(false);
    }
  };

  useEffect(() => {
    setIsLoadingApi(true);
    fetchDashboard(unitDate);
  }, [unitDate, isReload]);
  const handleClick = (unit: string) => {
    setUnitDate(unit);
  };
  const handleRefresh = async () => {
    dashboardService.refresh();
    setIsReload(!isReload);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Dashboard</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      <Button
        type={unitDate === 'month' ? 'primary' : 'default'}
        onClick={handleClick.bind(this, 'month')}
        className="mr-2"
      >
        Tháng
      </Button>

      <Button
        type={unitDate === 'week' ? 'primary' : 'default'}
        onClick={handleClick.bind(this, 'week')}
        className="mr-2"
      >
        Tuần
      </Button>

      <Button onClick={handleRefresh}>Làm mới</Button>

      {!isLoadingApi && (
        <ChartDashBoard
          data={dashboardData?.data}
          page={dashboardData?.page}
          size={dashboardData?.size}
          total={dashboardData?.total}
        />
      )}
    </>
  );
};

export default Dashboard;
