import { DrawerForm } from '@ant-design/pro-components';
import { Form, Select } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';

export interface CategoryFiltersRef {
  setOpenModal: (isOpen: boolean) => void;
  onReset: () => void;
}

export interface SubmitFormInterface {}

interface CategoryFiltersProps {
  onSubmitFilter: (values: SubmitFormInterface) => Promise<boolean | void>;
  courses: any;
  phase: any;
}
export const CategoryFilters = forwardRef<
  CategoryFiltersRef,
  CategoryFiltersProps
>((props, ref) => {
  const { onSubmitFilter, courses, phase } = props;

  useImperativeHandle(ref, () => ({
    setOpenModal,
    onReset,
  }));

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const onReset = () => {
    form.setFieldsValue({
      code: undefined,
      name: undefined,
      id: undefined,
      stageId: undefined,
      courseId: undefined,
    });
  };

  const setOpenModal = (isOpen: boolean) => {
    setIsOpenModal(isOpen);
  };

  const [form] = useForm();

  return (
    <DrawerForm
      onOpenChange={setIsOpenModal}
      title={'Bộ lọc'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      form={form}
      open={isOpenModal}
      submitTimeout={2000}
      onFinish={onSubmitFilter}
    >
      <Form.Item label="Mã chuyên mục" name={'code'}>
        <TextArea
          placeholder="Nhập mã chuyên mục"
          allowClear
          autoSize={{ minRows: 1, maxRows: 3 }}
          onChange={(e: any) => {
            const trimmedValue = e.target.value.replace(/^\s+/, '');
            form.setFieldsValue({
              code: trimmedValue,
            });
          }}
        />
      </Form.Item>

      <Form.Item label="Tên chuyên mục" name={'name'}>
        <TextArea
          placeholder="Nhập tên chuyên mục"
          allowClear
          autoSize={{ minRows: 1, maxRows: 3 }}
          onChange={(e: any) => {
            const trimmedValue = e.target.value.replace(/^\s+/, '');
            form.setFieldsValue({
              name: trimmedValue,
            });
          }}
        />
      </Form.Item>

      <Form.Item label="Danh mục" name={'stageId'}>
        <Select
          allowClear
          placeholder="Tất cả"
          options={phase.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </Form.Item>
      <Form.Item label="Khóa học" name={'courseId'}>
        <Select
          allowClear
          placeholder="Tất cả"
          options={courses.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </Form.Item>
    </DrawerForm>
  );
});
