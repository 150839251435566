import { Popover } from 'antd';
import { FC, useState } from 'react';
import './style.scss';

type Props = {
  openModal: (opt: string) => void;
};

const Header: FC<Props> = ({ openModal }) => {
  const [open, setOpen] = useState<boolean>(false);

  const onPressOpt = (opt: string) => {
    openModal(opt);
    setOpen(false);
  };

  const content = (
    <div>
      <div
        onClick={onPressOpt?.bind(null, 'category')}
        className="flex border-solid border border-[#D9D9D9] rounded pl-2 pr-2 mb-2 cursor-pointer text-semibold text-[16px]"
      >
        + Thêm mới Danh mục
      </div>
      <div
        onClick={onPressOpt?.bind(null, 'topic')}
        className="flex border-solid border border-[#D9D9D9] rounded pl-2 pr-2 cursor-pointer text-[black] text-semibold text-[16px]"
      >
        + Thêm mới Chuyên mục
      </div>
    </div>
  );

  return (
    <div
      className="flex flex-1 justify-end ml-[15px] mr-[15px] mb-[20px] mt-[15px] h-[30px]"
      onClick={setOpen?.bind(null, !open)}
    >
      <Popover
        content={content}
        title={null}
        trigger="click"
        placement="bottomRight"
        open={open}
      >
        <div className="bg-[#2C67DA] flex items-center pl-4 pr-4 h-full rounded text-[#fff] text-normal cursor-pointer">
          Thêm mới
        </div>
      </Popover>
    </div>
  );
};

export default Header;
