import { Button, Modal, notification } from 'antd';
import { customersServices } from 'network/services/customers';
import * as React from 'react';
export interface IModalSubmitProps {
  handleIsModalSubmit: () => void;
  phoneNumber: string;
  params: Array<{
    vipPackageId: null;
    activatedTime: null;
  }>;
  id: number;
  onHideModal: () => void;
  onReload: () => void;
  vipPackages: Array<{
    id: number;
    actualPrice: number;
    code: string;
    duration: string;
    name: string;
    originalPrice: number;
    position: number;
  }>;
}

export default function ModalSubmit({
  handleIsModalSubmit,
  phoneNumber,
  params,
  id,
  onHideModal,
  onReload,
}: IModalSubmitProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleSubmit = async () => {
    setLoading(true);
    const response = await customersServices.upgradeVip(id, params);
    if (response.status === 200) {
      notification.success({ message: 'Nâng cấp thành công' });
      onHideModal();
      onReload();
      setLoading(false);
    } else {
      notification.error({ message: response?.data });
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Xác nhận nâng cấp"
      open={true}
      onCancel={handleIsModalSubmit}
      footer={null}
      centered
    >
      <p style={{ textAlign: 'center' }}>
        Bạn chắc chắn muốn nâng cấp cho tài khoản{' '}
        <strong>{`${phoneNumber}`}</strong>
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '12px',
          marginTop: '30px',
        }}
      >
        <Button onClick={handleIsModalSubmit}>Huỷ bỏ</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          Đồng ý
        </Button>
      </div>
    </Modal>
  );
}
