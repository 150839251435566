export default function AvatarLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15 7.5C14.0729 7.5 13.1666 7.77492 12.3958 8.28999C11.6249 8.80506 11.0241 9.53714 10.6693 10.3937C10.3145 11.2502 10.2217 12.1927 10.4026 13.102C10.5834 14.0113 11.0299 14.8465 11.6854 15.5021C12.341 16.1576 13.1762 16.6041 14.0855 16.7849C14.9948 16.9658 15.9373 16.873 16.7938 16.5182C17.6504 16.1634 18.3824 15.5626 18.8975 14.7917C19.4126 14.0209 19.6875 13.1146 19.6875 12.1875C19.6875 10.9443 19.1936 9.75201 18.3146 8.87294C17.4355 7.99386 16.2432 7.5 15 7.5Z"
        fill="black"
      />
      <path
        d="M15 1.875C12.4041 1.875 9.86654 2.64477 7.70815 4.08696C5.54975 5.52915 3.86749 7.579 2.87409 9.97728C1.88069 12.3756 1.62077 15.0146 2.1272 17.5606C2.63363 20.1066 3.88367 22.4452 5.71923 24.2808C7.55479 26.1163 9.89345 27.3664 12.4394 27.8728C14.9854 28.3792 17.6244 28.1193 20.0227 27.1259C22.421 26.1325 24.4709 24.4503 25.913 22.2919C27.3552 20.1335 28.125 17.5959 28.125 15C28.121 11.5203 26.737 8.18416 24.2764 5.72361C21.8158 3.26305 18.4798 1.87897 15 1.875ZM22.4925 23.3681C22.4738 22.1384 21.9728 20.9653 21.0974 20.1015C20.222 19.2377 19.0423 18.7523 17.8125 18.75H12.1875C10.9577 18.7523 9.77798 19.2377 8.90259 20.1015C8.0272 20.9653 7.52619 22.1384 7.50751 23.3681C5.80741 21.8501 4.6085 19.8514 4.06954 17.6369C3.53057 15.4223 3.67697 13.0963 4.48935 10.9667C5.30173 8.83723 6.74177 7.00469 8.61879 5.71178C10.4958 4.41887 12.7213 3.72659 15.0005 3.72659C17.2797 3.72659 19.5051 4.41887 21.3822 5.71178C23.2592 7.00469 24.6992 8.83723 25.5116 10.9667C26.324 13.0963 26.4704 15.4223 25.9314 17.6369C25.3924 19.8514 24.1935 21.8501 22.4934 23.3681H22.4925Z"
        fill="black"
      />
    </svg>
  );
}
