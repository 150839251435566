import { Button, Input, Modal, Select, Space, notification } from 'antd';
import React, { useState } from 'react';

import { Role } from 'common/utils/enum';
import { accountsService } from 'network/services/accounts';
import FormAccount from '../FormAccount';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { STAFF_STATUS } from 'utils/enum';
import { makePassWord } from 'utils';

type Props = {
  onReload: () => void;
  onSelectStatus: (value?: 1 | 2) => void;
  onSearch: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  listRole: RoleInterface[];
};

export const renderTitle = (title: string) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'hsl(215deg 100% 54%)',
        color: '#fff',
        margin: '-20px -24px 20px -24px',
        padding: '15px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      }}
    >
      {title}
    </div>
  );
};

const Actions: React.FC<Props> = ({
  onReload,
  onSelectStatus,
  onSearch,
  listRole,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const initialValues = {
    password: makePassWord(8),
    role: Role.USER as RoleE,
    status: 1,
  };

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  const handleHideModal = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values: ActionAccountInput) => {
    const response = await accountsService.addStaff({
      ...values,
      username: values.username?.replace(/^\s+/, ''),
      fullname: values?.fullname?.replace(/^\s+/, ''),
    });
    if (response.status === 200) {
      onReload();
      handleHideModal();
      notification.success({ message: 'Thành công' });
    } else {
      notification.error({ message: 'Thất bại' });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="box-actions">
      <Space>
        <Input
          prefix={<SearchOutlined />}
          placeholder={'Tên đăng nhập, Họ và tên'}
          className={'box-search'}
          onChange={onSearch}
        />
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Chọn trạng thái"
          onChange={onSelectStatus}
          options={STAFF_STATUS}
        />
      </Space>
      <Button type="primary" onClick={handleShowModal}>
        Thêm mới
      </Button>
      <Modal
        title={renderTitle('Thêm mới tài khoản')}
        open={isModalOpen}
        footer={null}
        onOk={handleHideModal}
        onCancel={handleHideModal}
        closeIcon={
          <CloseOutlined style={{ color: '#fff', fontSize: '20px' }} />
        }
      >
        <FormAccount
          isEdit={false}
          name="add-account"
          initialValues={initialValues}
          submitText="Thêm mới"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          listRole={listRole}
        />
      </Modal>
    </div>
  );
};

export default Actions;
