import * as Emoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { htmlToMarkdown } from './Parser';

Quill.register('modules/emoji', Emoji);

export interface EditorContentChanged {
  html: string;
  markdown: string;
}

export interface EditorProps {
  value?: EditorContentChanged;
  onChange?: (changes: EditorContentChanged) => void;
}

const TOOLBAR_OPTIONS = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ align: [] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ direction: 'rtl' }],
  [{ color: [] }, { background: [] }],
  ['link', 'image', 'video'],
  ['clean'],
  ['emoji'],
];

const Editor: React.FC<EditorProps> = (props) => {
  const [value, setValue] = useState<string | undefined>('');
  const reactQuillRef = useRef<ReactQuill>(null);

  const onChange = (content: string) => {
    setValue(content);

    if (props.onChange) {
      props.onChange({
        html: content,
        markdown: htmlToMarkdown(content),
      });
    }
  };

  useEffect(() => {
    setValue(props?.value?.html);
  }, [props?.value?.html]);

  return (
    <ReactQuill
      ref={reactQuillRef}
      theme="snow"
      placeholder="Nhập nội dung..."
      modules={{
        'toolbar': {
          container: TOOLBAR_OPTIONS,
        },
        'emoji-toolbar': true,
        'emoji-textarea': true,
        'emoji-shortname': true,
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default Editor;
