import { Form, Input, Select, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { roadMap280Service } from 'network/services/roadMap280';
import { filterOptions } from 'common/utils/filterOptions';

import './style.scss';
import { topicsService } from 'network/services/topics';
import { postsServices } from 'network/services/postsNewVersion';
import { ROADMAP_280_TYPE } from 'utils/enum';
import { AxiosResponse } from 'axios';

type Props = {
  categoryId: number;
  title: string;
  restField: any;
  index: number;
  remove: (idx: number) => void;
  length: number;
  typePractice: number;
  position?: number;
};

type PregnancyType = {
  id: number;
  name: string;
  type?: number;
};

type PracticesFormListState = {
  category?: number;
  topic?: number;
  categories: Category[];
  topics: Topic[];
  posts: any;
};

const { Option } = Select;

const pregnancyType: PregnancyType[] = [
  { id: 1, name: 'Thai giáo trực tiếp' },
  { id: 2, name: 'Thai giáo gián tiếp' },
];

const PracticeForm: FC<Props> = ({
  categoryId,
  title,
  restField,
  index,
  remove,
  length,
  typePractice,
}) => {
  const [practicesFormListState, setPracticesFormListState] =
    useState<PracticesFormListState>({
      category: categoryId,
      topic: undefined,
      categories: [],
      topics: [],
      posts: [],
    });
  const [typePracticeState, setTypePracticeState] =
    useState<number>(typePractice);

  const handleChangeCategory = (category: number) => {
    setPracticesFormListState((prev) => ({ ...prev, category }));
  };

  const handleChangeTopic = (topic: number) => {
    setPracticesFormListState((prev) => ({ ...prev, topic }));
  };
  const fetchCategories = async () => {
    const successCallback = (res: any = {}) => {
      setPracticesFormListState((prev) => ({
        ...prev,
        categories: res?.data,
      }));
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const failCallback = (_err: any) => {};
    await roadMap280Service.getCategories(successCallback, failCallback);
  };

  const fetchCategoriesIndirect = async () => {
    const resp = await topicsService.getListCategories('');
    if (resp.status === 200) {
      setPracticesFormListState((prev) => ({
        ...prev,
        categories: resp.data,
      }));
    }
  };

  useEffect(() => {
    if (typePracticeState === ROADMAP_280_TYPE.TG_TT) {
      fetchCategories();
    }
    if (typePracticeState === ROADMAP_280_TYPE.TG_GT) {
      fetchCategoriesIndirect();
    }
  }, [typePractice]);

  useEffect(() => {
    if (typePracticeState === ROADMAP_280_TYPE.TG_TT) {
      (async function () {
        const successCallback = (res: AxiosResponse) => {
          setPracticesFormListState((prev) => ({ ...prev, topics: res?.data }));
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const failCallback = () => {};
        await roadMap280Service.getTopics(
          { categoryId: practicesFormListState.category },
          successCallback,
          failCallback
        );
      })();
    }
    if (typePracticeState) {
      (async function () {
        const resp = await topicsService.getListTopics({
          categoryId: practicesFormListState.category,
          page: 0,
          size: 500,
        });
        if (resp.status === 200) {
          setPracticesFormListState((prev) => ({ ...prev, topics: resp.data }));
        }
      })();
    }
  }, [practicesFormListState.category]);

  useEffect(() => {
    if (typePracticeState) {
      (async function () {
        const successCallback = (res: any = {}) => {
          setPracticesFormListState((prev) => ({ ...prev, posts: res?.data }));
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const failCallback = () => {};
        await roadMap280Service.getPosts(
          {
            categoryId: practicesFormListState.category,
            topicId: practicesFormListState.topic,
          },
          successCallback,
          failCallback
        );
      })();
    }
    if (typePracticeState) {
      (async function () {
        const resp = await postsServices.get({
          categoryId: practicesFormListState.category,
          topicId: practicesFormListState.topic,
          page: 0,
          size: 500,
          courseId: 1,
        });
        if (resp.status === 200) {
          setPracticesFormListState((prev) => ({ ...prev, posts: resp.data }));
        }
      })();
    }
  }, [practicesFormListState.category, practicesFormListState.topic]);

  const handleChangePregnancyType = (value: number) => {
    // form.setFieldValue([index, 'type'], value);
    setTypePracticeState(value);

    if (value === ROADMAP_280_TYPE.TG_TT) {
      fetchCategories();
    }
    if (value === ROADMAP_280_TYPE.TG_GT) {
      fetchCategoriesIndirect();
    }
  };

  return (
    <div className="post-item">
      <div className="post-item-left">
        <Typography.Title level={5}>{title}</Typography.Title>
        <Form.Item
          {...restField}
          name={[index, 'position']}
          label="Hoạt động thứ"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số nguyên dương lớn hơn 0',
              pattern: /^[1-99]*$/,
            },
          ]}
        >
          <Input.TextArea rows={1} placeholder="Nhập số thứ tự" />
        </Form.Item>
        <Form.Item
          {...restField}
          name={[index, 'type']}
          label="Chọn thai giáo trực tiếp, gián tiếp"
          rules={[
            {
              required: true,
              message: 'Chọn thai giáo trực tiếp, gián tiếp',
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Chọn thai giáo trực tiếp, gián tiếp"
            menuItemSelectedIcon={
              <CheckOutlined style={{ color: '#1677ff' }} />
            }
            onSelect={handleChangePregnancyType}
          >
            {pregnancyType.map(({ id, name }: PregnancyType) => (
              <Option key={id} value={id} name={name}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form form={form}> */}
        <Form.Item
          {...restField}
          name={[index, 'categoryId']}
          label="Chuyên mục"
          rules={[{ required: true, message: 'Vui lòng nhập chuyên mục' }]}
        >
          <Select
            allowClear
            placeholder="Chọn chuyên mục..."
            menuItemSelectedIcon={
              <CheckOutlined style={{ color: '#1677ff' }} />
            }
            onSelect={handleChangeCategory}
          >
            {practicesFormListState.categories.map(
              ({ id, name, originalName }: Category) => (
                <Option key={id} value={id}>
                  {typePracticeState === ROADMAP_280_TYPE.TG_TT
                    ? name
                    : originalName}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name={[index, 'topicId']}
          label="Chủ đề"
          rules={[{ required: true, message: 'Vui lòng chọn chủ đề!' }]}
        >
          <Select
            allowClear
            placeholder="Chọn chủ đề..."
            menuItemSelectedIcon={
              <CheckOutlined style={{ color: '#1677ff' }} />
            }
            onSelect={handleChangeTopic}
          >
            {practicesFormListState.topics.map(({ id, name }: Topic) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={[index, 'postId']}
          label="Bài Post"
          rules={[{ required: true, message: 'Vui lòng chọn bài post!' }]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Chọn bài post..."
            menuItemSelectedIcon={
              <CheckOutlined style={{ color: '#1677ff' }} />
            }
            filterOption={(input: string, option: any) =>
              filterOptions(input, option)
            }
          >
            {practicesFormListState.posts.map(({ id, postName }: any) => {
              return (
                <Option key={id} value={id} name={postName}>
                  {postName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {/* </Form> */}
      </div>
      <DeleteOutlined
        style={{
          fontSize: 24,
          color: length <= 1 ? 'GrayText' : '',
          marginTop: '30px',
          marginLeft: '15px',
        }}
        onClick={() => {
          if (length > 1) remove(index);
        }}
      />
    </div>
  );
};

export default PracticeForm;
