import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const HOT_LEAD_URL = '/api/hotlead';

const get = (params: NotiGetParamsInterface) => {
  deleteParamsNotUsing(params);
  const url = getQueryString(HOT_LEAD_URL, params);
  return request(url);
};

const filterByCondition = (type: string, params: filterHotLeadProps) => {
  const temp_url = `${HOT_LEAD_URL}/filter-by-${type}`;
  deleteParamsNotUsing(params);
  const url = getQueryString(temp_url, params);
  return request(url);
};

const getDetail = (id: string) => {
  const url = getQueryString(`${HOT_LEAD_URL}/${id}`);
  return request(url);
};

const updateStatus = (id: string, status: number) => {
  const body = {
    status: status,
  };
  const url = getQueryString(`${HOT_LEAD_URL}/${id}`);
  return request(url, 'patch', body);
};
const handleExport = () => {
  const url = getQueryString(`${HOT_LEAD_URL}/export`);

  return request(
    url,
    'GET',
    {},
    {
      responseType: 'blob',
    }
  );
};

export const hotLeadServices = {
  get,
  filterByCondition,
  getDetail,
  updateStatus,
  handleExport,
};
