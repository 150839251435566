import { Table } from 'antd';
import { useWindowSize } from 'hook';
import React from 'react';
import {
  DATE_FORMAT,
  DEFAULT_PAGE_SIZE,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
} from 'utils/constants';
import ModalConfirm from './ModalConfirm';
import ModalCancel from './ModalCancel';
import { ordersServices } from 'network/services/orders';
import { Helmet } from 'react-helmet';
import AdminIcon from 'assets/image/adminIcon.jpg';
import columns from './OrderColumns';
import { HeaderOrder } from './header';
import { isNullOrEmpty } from 'utils/stringUtils';
import moment from 'moment';
import ModalHotLead from './ModalHotLead';

const Orders = () => {
  const windowSize = useWindowSize();
  const defaultFilters = {
    size: DEFAULT_PAGE_SIZE,
    page: 0,
    query: '',
  };
  const [isShowModalConfirm, setIsShowModalConfirm] =
    React.useState<boolean>(false);
  const [isShowModalCancel, setIsShowModalCancel] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [filters, setFilters] = React.useState(defaultFilters);
  const [data, setData] = React.useState<[]>([]);
  const [metadata, setMetadata] = React.useState<MetadataInterface | undefined>(
    undefined
  );
  const [selectedRow, setSelectedRow] = React.useState<any>();
  const [dates, setDates] = React.useState<string[]>([]);
  const [isShowModalHotLead, setIsShowModalHotLead] =
    React.useState<boolean>(false);

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const onConfirmOrder = async () => {
    const response = await ordersServices.confirm(selectedRow?.id as any);
    if (response?.status === 200) {
      setIsLoading(true);
      setIsShowModalConfirm(false);
    }
  };

  const onConfirm = (id: number) => {
    setSelectedRow(id);
    setIsShowModalConfirm(true);
  };

  const onCancel = (id: number) => {
    setSelectedRow(id);
    setIsShowModalCancel(true);
  };

  const onHideModalConfirm = () => {
    setIsShowModalConfirm(false);
  };

  const onHideModalCancel = () => {
    setIsShowModalCancel(false);
  };

  const onConfirmCancel = async () => {
    const response = await ordersServices.cancel(selectedRow?.id as any);
    if (response?.status === 200) {
      setIsLoading(true);
      setIsShowModalCancel(false);
    }
  };

  const fetchData = async () => {
    const params = {
      ...filters,
      fromTime: !isNullOrEmpty(dates[0])
        ? moment(dates[0], DATE_FORMAT).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      toTime: !isNullOrEmpty(dates[1])
        ? moment(dates[1], DATE_FORMAT).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        : undefined,
    };
    const response = await ordersServices.get(params);
    const { data, headers } = response;
    if (response?.status === 200) {
      setData(data?.data);
      setIsLoading(false);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: data?.total,
      });
    } else {
      setIsLoading(false);
    }
  };

  const handleShowModalCustomerCare = (id: number) => {
    setIsShowModalHotLead(true);
    setSelectedRow(id);
  };

  const handleCloseModalHotLead = () => {
    setIsShowModalHotLead(false);
  };

  React.useEffect(() => {
    if (isLoading || dates) {
      fetchData();
    }
  }, [isLoading, dates, isShowModalHotLead]);

  const handleSelectDate = (value: string[]) => {
    setDates(value);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Orders</title>
        <link rel="icon" href={AdminIcon} />
        <meta name="description" content="Customers" />
      </Helmet>
      <HeaderOrder
        onChangeDate={(_: any, value: string[]) => handleSelectDate(value)}
      />
      <Table
        rowKey={'id'}
        columns={columns(onConfirm, onCancel, handleShowModalCustomerCare)}
        dataSource={data}
        scroll={{
          y: windowSize?.height - GLOBAL_HEIGHT - 84,
          x: 2500,
        }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filters.page + 1,
          total: metadata?.total,
          defaultPageSize: filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total?.toLocaleString()}</div>
          ),
        }}
      />
      {isShowModalConfirm && (
        <ModalConfirm
          onHideModal={onHideModalConfirm}
          onFinishConfirm={onConfirmOrder}
        />
      )}
      {isShowModalCancel && (
        <ModalCancel
          onHideModal={onHideModalCancel}
          onFinishCancel={onConfirmCancel}
        />
      )}
      {isShowModalHotLead && (
        <ModalHotLead
          orderId={selectedRow?.id}
          onClose={handleCloseModalHotLead}
          callback={setIsLoading}
          currentCustomerCareStatus={selectedRow?.customerCareStatus}
        />
      )}
    </div>
  );
};
export default Orders;
