import { useManageCategory } from 'hook/useManageCategory';
import { FC, useRef } from 'react';
import { AddCategoryModal, AddCategoryModalRef } from './addCategory';
import ContentCategory from './content';
import Header from './header';
import './style.scss';

type Props = {};

const CategoryWeb: FC<Props> = ({}) => {
  const addCategoryRef = useRef<AddCategoryModalRef>(null);
  const { state, addMore } = useManageCategory();
  const data = state?.data?.filter((item) => item?.code === 'WEB-BLOGS');

  return (
    <div className="flex-1 w-full containerCategory overflow-hidden">
      <Header openModal={(opt: string) => addCategoryRef?.current?.open(opt)} />
      <ContentCategory data={data?.[0]?.children} loading={state?.loading} />
      <AddCategoryModal
        data={data?.[0]?.children}
        ref={addCategoryRef}
        onContinue={async (params) => {
          const result = await addMore(params);
          if (result) {
            addCategoryRef?.current?.close();
          }
        }}
        loading={state?.loading}
      />
    </div>
  );
};

export default CategoryWeb;
