import {
  Button,
  Divider,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import AdminIcon from 'assets/image/adminIcon.jpg';
import ActionsBar from 'components/ActionsBar';
import { useWindowSize } from 'hook';
import moment from 'moment';
import { RESPONSE_CODE } from 'network/constants';
import { courseService } from 'network/services/course';
import { customersServices } from 'network/services/customers';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE,
  DEFAULT_PAGE_SIZE,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
} from 'utils/constants';
import {
  CustomerFilters,
  CustomerFiltersRef,
  SubmitFormInterface,
} from './CustomerFilters';
import ModalCreateCustomer from './ModalCreateCustomer';
import { ModalListCourse } from './ModalListCourse';
import { ModalQuestion } from './ModalQuestion';
import { ModalResetPassword } from './ModalResetPassword';
import ModalUpgradeAccount from './ModalUpgradeAccount';
import { ModalChangeInfo } from './ModalChangeInfo';

const Customers: FC = () => {
  const defaultFilters = {
    size: DEFAULT_PAGE_SIZE,
    page: 0,
    query: '',
  };
  const windowSize = useWindowSize();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowModalUpgrade, setIsShowModalUpgrade] = useState<boolean>(false);
  const [isShowModalListCourse, setIsShowModalListCourse] =
    useState<boolean>(false);
  const [isShowModalQuestion, setIsShowModalQuestion] =
    useState<boolean>(false);

  const [itemSelected, setItemSelected] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [filters, setFilters] =
    useState<NotiGetParamsInterface>(defaultFilters);
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [courseData, setCourseData] = React.useState<PackageInterface[]>([]);
  const [password, setPassword] = React.useState<string>('');
  const [modalResetPass, setModalResetPass] = React.useState<boolean>(false);
  const [id, setId] = React.useState<number>(0);
  const customerFiltersRef = useRef<CustomerFiltersRef>(null);
  const [isShowModalChangeInfo, setIsShowModalChangeInfo] =
    useState<boolean>(false);

  const handleOpenModalListCourse = (id: number) => {
    setItemSelected(id);
    setIsShowModalListCourse(true);
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case 'Delete':
        return (
          <Tag color={'error'} style={{ color: 'red', fontSize: '10px' }}>
            Đã bị xoá
          </Tag>
        );
      case 'Actived':
        return (
          <Tag
            color={'success'}
            style={{ color: 'hsl(100deg 77% 44%)', fontSize: '10px' }}
          >
            Đang hoạt động
          </Tag>
        );
      default:
        break;
    }
  };

  const renderPlatform = (platformList: string[]) => {
    let platformString = '';
    platformList.forEach((platform, index) => {
      platformString +=
        platform + (index === platformList.length - 1 ? '' : ', ');
    });
    return platformString;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '2%',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'fullname',
      width: '5%',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      width: '5%',
    },
    {
      title: 'Ngày dự sinh',
      dataIndex: 'dueDate',
      width: '4%',
      render: (cell: any) => moment(cell).format('DD/MM/YYYY'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: '4%',
      render: (cell: any) => renderStatus(cell),
    },
    {
      title: 'Ngày đăng ký',
      dataIndex: 'addedTimestamp',
      width: '6%',
      render: (cell: any) => (
        <div
          style={{
            marginLeft: '10px',
          }}
        >
          {cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---------'}
        </div>
      ),
    },
    {
      title: 'Ngày kích hoạt',
      dataIndex: 'activatedTime',
      width: '6%',
      render: (cell: any) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---------',
    },
    {
      title: 'Nền tảng',
      dataIndex: 'platformList',
      width: '3%',
      render: (cell: any) => renderPlatform(cell),
    },
    {
      title: 'Khoá học đã mua',
      dataIndex: ['vipPackageList', 'id'],
      width: '5%',
      render: (_cell: any, record: any) => {
        if (record?.vipPackageList?.length > 0) {
          return (
            <Button
              type="link"
              onClick={() => handleOpenModalListCourse(record?.id)}
            >
              Xem danh sách
            </Button>
          );
        } else {
          return <div className="center-item">NULL</div>;
        }
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      width: '5%',
    },
    {
      title: 'Mã giới thiệu',
      dataIndex: 'refCode',
      width: '4%',
    },
    {
      title: 'Người giới thiệu',
      dataIndex: 'refBy',
      width: '4%',
    },
    {
      title: 'Mã đại lý giới thiệu',
      dataIndex: 'partnerCode',
      width: '5%',
    },
    {
      title: 'Số ngày học',
      dataIndex: 'totalDayPractice',
      width: '4%',
    },
    {
      title: 'Câu hỏi',
      dataIndex: 'id',
      width: '3%',
      render: (_cell: any, record: any) => (
        <a onClick={() => onPressQuestion(record)}>Xem câu hỏi</a>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: 'id',
      width: '10%',
      render: (_cell: any, record: any) => (
        <Row className="items-center">
          <a onClick={() => onUpdate(record)}>Nâng cấp</a>
          <Divider type="vertical" className="border-indigo-600" />
          <a onClick={() => handleConfirm(record)}>Reset Password</a>
          <Divider type="vertical" className="border-indigo-600" />
          <a onClick={() => onPressChangeInfo(record)}>Sửa thông tin</a>
        </Row>
      ),
    },
  ];

  const onPressChangeInfo = (record: any) => {
    setItemSelected(record);
    setIsShowModalChangeInfo(true);
  };

  const onPressQuestion = (record: any) => {
    setItemSelected(record?.id);
    console.log('record', record?.id);
    setIsShowModalQuestion(true);
  };

  const onUpdate = (record: any) => {
    setItemSelected(record);
    setIsShowModalUpgrade(true);
  };

  const onSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      query: e.target.value,
    }));
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const fetchData = async () => {
    const response = await customersServices.get(filters);
    const { data, headers } = response;
    if (response?.status === 200) {
      setIsLoading(false);
      setData(data);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      });
    } else {
      setIsLoading(false);
    }
  };

  const onExtend = () => {
    customerFiltersRef.current?.setOpenModal(true);
  };

  const onReset = () => {
    customerFiltersRef.current?.onReset();
    setFilters(defaultFilters);
    setIsLoading(true);
  };

  const onShowModal = () => {
    setIsShowModal(true);
  };

  const onHideModal = () => {
    setIsShowModal(false);
  };

  const onHideModalUpgrade = () => {
    setIsShowModalUpgrade(false);
  };

  const onReload = () => {
    setIsLoading(true);
  };

  const onSubmitFilter = (values: SubmitFormInterface) => {
    setIsLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { rangeRegisterDate, rangeActiveDate, ...data } = values;
    setFilters((cur) => ({
      ...cur,
      ...data,
      page: 0,
    }));
    customerFiltersRef.current?.setOpenModal(false);
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  React.useEffect(() => {
    fetchDataCourse();
  }, []);

  const fetchDataCourse = async () => {
    const params = {
      page: 0,
      size: 150,
    };
    const response = await courseService.get(params);
    const { data } = response;
    setCourseData(data?.data);
  };

  const handleCloseModalListCourse = () => {
    setIsShowModalListCourse(false);
  };

  const handleCloseModalQuestion = () => {
    setIsShowModalQuestion(false);
  };

  const handleConfirm = (record: any) => {
    Modal.confirm({
      title: `Bạn có muốn reset lại mật khẩu của ${record?.fullname} không?`,
      okText: 'Đồng ý',
      onOk: () => resetPassword(record?.id),
      cancelText: 'Hủy bỏ',
    });
  };

  const resetPassword = (id: number) => {
    setId(id);
    setModalResetPass(!modalResetPass);
  };

  const onChangeInput = (e: any) => {
    setPassword(e.target.value);
  };

  const handleUpdatePass = async () => {
    const res = await customersServices?.resetPassword(id, password);
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setModalResetPass(!modalResetPass);
      notification.success({ message: 'Cập nhật mật khẩu thành công !' });
    } else {
      setModalResetPass(!modalResetPass);
      notification.error({ message: 'Có lỗi xảy ra vui lòng kiểm tra lại' });
    }
  };

  let heightCustom = 40;
  const isMobile = windowSize.width < DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE;

  if (isMobile) {
    heightCustom = 100;
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Customers</title>
        <link rel="icon" href={AdminIcon} />
        <meta name="description" content="Customers" />
      </Helmet>
      <ActionsBar
        isMobile={isMobile}
        onSearch={onSearch}
        onShowModal={onShowModal}
        inputPlaceHolder={'User ID, Tên, Số điện thoại... '}
        btnTitle={'Tạo tài khoản'}
        subItem={
          <Space size={'small'} className="mr-2">
            <Button onClick={onExtend} size={isMobile ? 'small' : 'middle'}>
              Bộ lọc
            </Button>
            <Button onClick={onReset} size={isMobile ? 'small' : 'middle'}>
              Reset
            </Button>
          </Space>
        }
        filters={filters}
      />
      <CustomerFilters
        ref={customerFiltersRef}
        onSubmitFilter={onSubmitFilter}
      />
      <Table
        rowKey={'id'}
        dataSource={data}
        columns={columns}
        scroll={{
          y: windowSize?.height - GLOBAL_HEIGHT - heightCustom,
          x: 2400,
        }}
        locale={{ emptyText: 'Không có kết quả phù hợp' }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filters.page + 1,
          total: metadata?.total,
          defaultPageSize: filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => <div>{total.toLocaleString()} bản ghi</div>,
        }}
      />
      {isShowModal && (
        <ModalCreateCustomer onHideModal={onHideModal} onReload={onReload} />
      )}
      {isShowModalUpgrade && (
        <ModalUpgradeAccount
          itemSelected={itemSelected}
          onHideModal={onHideModalUpgrade}
          onReload={onReload}
          course={courseData}
        />
      )}
      {isShowModalListCourse && (
        <ModalListCourse
          onClose={handleCloseModalListCourse}
          id={itemSelected}
        />
      )}
      {isShowModalQuestion && (
        <ModalQuestion onClose={handleCloseModalQuestion} id={itemSelected} />
      )}
      <ModalResetPassword
        onChange={onChangeInput}
        modalResetPass={modalResetPass}
        handleCancel={() => setModalResetPass(!modalResetPass)}
        handleUpdatePass={handleUpdatePass}
      />
      {isShowModalChangeInfo && (
        <ModalChangeInfo
          onReload={onReload}
          onHideModal={() => setIsShowModalChangeInfo(false)}
          value={itemSelected}
        />
      )}
    </div>
  );
};

export default Customers;
