import React, { useEffect, useState } from 'react';

import { accountsService } from 'network/services/accounts';
import Actions from './components/Actions';
import TableC from './components/Table';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import AdminIcon from 'assets/image/adminIcon.jpg';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet';
import { RESPONSE_CODE } from 'network/constants';

const Accounts: React.FC = () => {
  const defaultFilters = {
    size: DEFAULT_PAGE_SIZE,
    page: 0,
    query: '',
  };
  const [filters, setFilters] = useState<StaffsParamsInterface>(defaultFilters);
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Account[]>([]);
  const [listRole, setListRole] = useState<RoleInterface[]>([]);

  const fetchAccounts = async () => {
    const response = await accountsService.staffs(filters);
    const { headers, data } = response;
    if (response.status === 200) {
      setData(data);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      });
    }
    setIsLoading(false);
  };

  const onSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFilters((cur) => ({ ...cur, query: e.target.value }));
      setIsLoading(true);
    },
    300
  );

  const onReload = () => {
    setIsLoading(true);
  };

  const onSelectStatus = (value?: 1 | 2) => {
    setFilters((cur) => ({ ...cur, status: value }));
    setIsLoading(true);
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  useEffect(() => {
    if (isLoading) {
      fetchAccounts();
    }
  }, [isLoading]);

  useEffect(() => {
    fetchListRole();
  }, []);

  const fetchListRole = async () => {
    const res = await accountsService.getRoleUser();
    const { data } = res;
    if (res?.status == RESPONSE_CODE.SUCCESS) {
      setListRole(data);
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={AdminIcon} />
        <title>MomEdu - Quản lý tài khoản</title>
        <meta name="description" content="Quản lý tài khoản" />
      </Helmet>
      <Actions
        onReload={onReload}
        onSearch={onSearch}
        onSelectStatus={onSelectStatus}
        listRole={listRole}
      />
      <TableC
        data={data}
        loading={isLoading}
        filters={filters}
        onPageChange={onPageChange}
        metadata={metadata}
        onReload={onReload}
        listRole={listRole}
      />
    </div>
  );
};

export default Accounts;
