import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const NOTIFICATION_URL = '/api/notifications-scheduler';

const get = (params: NotiGetParamsInterface) => {
  deleteParamsNotUsing(params);
  const url = getQueryString(NOTIFICATION_URL, params);
  return request(url);
};

const create = (body?: NotiCreateBodyInterface) => {
  return request(NOTIFICATION_URL, 'post', body);
};

const detail = (id: number) => {
  return request(NOTIFICATION_URL + `/${id}`);
};

const update = (id: number, body: NotiCreateBodyInterface) => {
  const url = NOTIFICATION_URL + `/${id}`;
  return request(url, 'patch', body);
};

const remove = (id: number) => {
  return request(NOTIFICATION_URL + `/${id}`, 'delete');
};
export const notificationServices = {
  get,
  create,
  update,
  detail,
  remove,
};
