import React, { FC, useEffect, useState } from 'react';
import { notification, Popover, Table, Tag } from 'antd';
import { bannersServices } from 'network/services/banners';
import ActionsBar from 'components/ActionsBar';
import { useWindowSize } from 'hook';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST } from 'utils/constants';
import ModalCreateBanner from './ModalCreateBanner';
import moment from 'moment';
import { debounce } from 'lodash';
import AdminIcon from 'assets/image/adminIcon.jpg';
import { Helmet } from 'react-helmet';

import './style.scss';

const Banners: FC = () => {
  const defaultFilters = {
    size: DEFAULT_PAGE_SIZE,
    page: 0,
    query: '',
  };
  const windowSize = useWindowSize();

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<NotificationItem[]>([]);
  const [notiSelected, setDataNotiSelected] = useState<
    NotificationItem | undefined
  >(undefined);
  const [filters, setFilters] =
    useState<NotiGetParamsInterface>(defaultFilters);
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '2%',
    },
    {
      title: 'Tên chương trình',
      dataIndex: 'name',
      width: '15%',
      render: (cell: any) => (
        <>
          {cell.length > 80 ? (
            <Popover content={<div style={{ width: '300px' }}>{cell}</div>}>
              <div
                style={{
                  width: '90%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {cell}
              </div>
            </Popover>
          ) : (
            <div
              style={{
                width: '90%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {cell}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Số thứ tự',
      dataIndex: 'position',
      width: '3%',
      render: (cell: any) => (
        <div
          style={{
            width: '99%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {cell}
        </div>
      ),
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'timestampFrom',
      width: '5%',
      render: (cell: any) => (cell ? moment(cell).format('DD/MM/YYYY') : '---'),
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'timestampTo',
      width: '5%',
      render: (cell: any) =>
        cell ? moment(cell).utc().format('DD/MM/YYYY') : '---',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: '5%',
      render: (cell: any) => (
        <Tag color={getTypeStatus(cell).color}>{getTypeStatus(cell).title}</Tag>
      ),
    },
    {
      title: 'Đường dẫn',
      dataIndex: 'url',
      width: '10%',
      render: (cell: any) => (
        <div
          style={{
            width: '99%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {cell}
        </div>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: 'id',
      width: '4%',
      render: (cell: any) => <a onClick={() => onEdit(cell)}>Chỉnh sửa</a>,
    },
  ];

  // const setPosition = async (id: number) => {
  //   const response = await bannersServices.setPositions(id);
  //   setIsLoading(true);
  //   if (response.status === 200) {
  //     notification.success({ message: 'Chuyển thứ tự thành công' });
  //     setIsLoading(false);
  //   } else {
  //     notification.error({ message: response?.error });
  //     setIsLoading(false);
  //   }
  // };

  const getTypeStatus = (status: string) => {
    return {
      color: status === 'INACTIVE' ? 'red' : 'green',
      title: status === 'INACTIVE' ? 'Ngưng hoạt động' : 'Đang hoạt động',
    };
  };

  const onEdit = async (id: number) => {
    const response = await bannersServices.detail(id);
    if (response.status === 200) {
      setDataNotiSelected(response.data);
      setIsShowModal(true);
    } else {
      notification.error({ message: response?.error });
    }
  };

  const onSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setIsLoading(true);
      setFilters((cur) => ({
        ...cur,
        query: e.target.value.replace(/^\s+/, ''),
        page: 0,
      }));
    },
    300
  );

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const fetchData = async () => {
    const response = await bannersServices.get(filters);
    const { data, headers } = response;
    if (response?.status === 200) {
      setIsLoading(false);
      setData(data);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      });
    } else {
      setIsLoading(false);
    }
  };

  const onShowModal = () => {
    setIsShowModal(true);
  };

  const onHideModal = () => {
    setDataNotiSelected(undefined);
    setIsShowModal(false);
  };

  const onReload = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Banner</title>
        <link rel="icon" href={AdminIcon} />
        <meta name="description" content="Banner" />
      </Helmet>
      <ActionsBar
        onSearch={onSearch}
        onShowModal={onShowModal}
        inputPlaceHolder={'Tên chương trình...'}
        btnTitle={'Thêm mới'}
      />
      <Table
        rowKey={'id'}
        className="ant-table"
        dataSource={data}
        columns={columns as any}
        scroll={{ y: windowSize?.height - 200, x: 1600 }}
        locale={{ emptyText: 'Không có kết quả phù hợp' }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filters.page + 1,
          total: metadata?.total,
          defaultPageSize: filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
      />
      {isShowModal && (
        <ModalCreateBanner
          data={notiSelected}
          onHideModal={onHideModal}
          onReload={onReload}
        />
      )}
    </div>
  );
};

export default Banners;
