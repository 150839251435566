import { Button, Table, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useRef } from 'react';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST } from 'utils/constants';
import { ModalCreateCourse } from './modal';
import { courseService } from 'network/services/course';
import moment from 'moment';
import BoxActions from 'containers/Course/BoxActions';
import { phaseService } from 'network/services/phase';
import { useWindowSize } from 'hook';
import { personalService } from 'network/services/personal';
import {
  CourseFilters,
  CourseFiltersRef,
  SubmitFormInterface,
} from './CourseFilters';

const defaultFilters = {
  size: DEFAULT_PAGE_SIZE,
  page: 0,
};

type ColumnsProps = {
  title?: string;
  dataIndex?: string;
  width?: string;
  fixed?: string;
  render?: () => void;
};

export const Course = () => {
  const windowSize = useWindowSize();
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const [itemSelected, setItemSelected] = React.useState<
    CourseInterface | undefined
  >(undefined);
  const [modalType, setModalType] = React.useState<'Thêm mới' | 'Chỉnh sửa'>(
    'Thêm mới'
  );
  const [filters, setFilters] = React.useState(defaultFilters);
  const [data, setData] = React.useState<[]>([]);
  const [metadata, setMetadata] = React.useState<MetadataInterface | undefined>(
    undefined
  );
  const [phase, setPhase] = React.useState<[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [personal, setPersonal] = React.useState<PersonalInterface[]>([]);

  const courseFiltersRef = useRef<CourseFiltersRef>(null);
  const columns: ColumnsType<ColumnsProps> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '4%',
    },
    {
      title: 'Mã khoá học',
      dataIndex: 'code',
      width: '10%',
    },
    {
      title: 'Tên khoá học',
      dataIndex: 'name',
      width: '15%',
    },
    {
      title: 'Danh mục khoá học',
      dataIndex: 'stage',
      width: '15%',
      render: (stage: StageInterface) => {
        return stage.name;
      },
    },
    {
      title: 'Khoá học nổi  bật',
      dataIndex: 'isImportantCourse',
      width: '10%',
      render: (isImportantCourse: boolean) => {
        if (isImportantCourse === true) {
          return 'Có';
        } else {
          return 'Không';
        }
      },
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '15%',
      render: (modifiedDate: string) => {
        return moment(modifiedDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'modifiedBy',
      width: '15%',
    },
    {
      title: 'Thao tác',
      width: '10%',
      render: (value: string) => (
        <BoxActions
          handleEditModal={handleEditModal}
          value={value}
          handleDeleteModal={handleShowHidden}
        />
      ),
    },
  ];

  const handleEditModal = (value: CourseInterface) => {
    setItemSelected(value);
    setIsShowModal(true);
    setModalType('Chỉnh sửa');
  };

  const handleShowHidden = async (value: CourseInterface) => {
    if (value?.isDisable) {
      const response = await courseService.enable(value?.id);
      if (response?.status === 200) {
        notification.success({ message: `Cập nhật Khoá học thành công` });
        onReload();
      }
    } else {
      const response = await courseService.disable(value?.id);
      if (response?.status === 200) {
        notification.success({ message: `Cập nhật Khoá học thành công` });
        onReload();
      }
    }
  };

  const handleShowModal = () => {
    setModalType('Thêm mới');
    setIsShowModal(true);
  };

  const onHideModal = () => {
    setItemSelected(undefined);
    setIsShowModal(false);
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const onReload = () => {
    setIsLoading(true);
  };

  const fetchdata = async () => {
    const response = await courseService.get(filters);
    const { data, totalPages, page, size, total } = response;

    if (response?.status === 200) {
      setData(data?.data);
      setIsLoading(false);
      setMetadata({
        pageCount: totalPages,
        page: page,
        size: size,
        total: total,
      });
    } else {
      setIsLoading(false);
    }
  };

  const fetchDataPhase = async () => {
    const response = await phaseService.get();
    const { data } = response;
    setPhase(data?.data);
  };

  const fetchDataPersonal = async () => {
    const response = await personalService.get();
    const { data } = response;
    setPersonal(data?.data);
  };

  React.useEffect(() => {
    if (isLoading) {
      fetchdata();
    }
  }, [isLoading]);

  React.useEffect(() => {
    fetchDataPhase();
    fetchDataPersonal();
  }, []);

  const onSubmitFilter = async (values: SubmitFormInterface) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      ...values,
    }));
    courseFiltersRef.current?.setOpenModal(false);
  };

  const handleReset = () => {
    setIsLoading(true);
    setFilters(defaultFilters);
    courseFiltersRef.current?.onReset();
  };

  const onOpenFilter = () => {
    courseFiltersRef.current?.setOpenModal(true);
  };

  return (
    <div>
      {!isShowModal && (
        <div className="flex justify-end mb-4">
          <Button onClick={onOpenFilter} className="mr-2">
            Bộ lọc
          </Button>
          <Button onClick={handleReset} className="mr-2">
            Reset
          </Button>
          <Button type="primary" onClick={handleShowModal}>
            &#43; Thêm khoá học
          </Button>
        </div>
      )}
      {isShowModal ? (
        <ModalCreateCourse
          dataDetail={itemSelected}
          onHideModal={onHideModal}
          modalType={modalType}
          phase={phase}
          onReload={onReload}
          personal={personal}
        />
      ) : (
        <>
          <Table
            rowKey={'id'}
            columns={columns}
            dataSource={data}
            scroll={{
              y: windowSize?.height - 265,
              x: 1400,
            }}
            pagination={{
              size: 'small',
              showSizeChanger: true,
              current: filters.page + 1,
              total: metadata?.total,
              defaultPageSize: filters.size,
              pageSizeOptions: PAGE_SIZE_LIST,
              onChange: onPageChange,
              showTotal: (total) => (
                <div>Tổng số bản ghi {total.toLocaleString()}</div>
              ),
            }}
          />
          <CourseFilters
            ref={courseFiltersRef}
            onSubmitFilter={onSubmitFilter}
            categories={phase}
          />
        </>
      )}
    </div>
  );
};
