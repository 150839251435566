import React, { useEffect, useRef, useState } from 'react';

import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Input, Radio, Row, Select, Space, Image } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';

import { roadMap280Service } from 'network/services/roadMap280';
import { usePostContext } from 'containers/posts/context';
import UploadC from '../Upload';
import ViewAudio from '../ViewAudio';
import ViewPost from '../ViewPost';
import ViewVideo from '../ViewVideo';
import { getCDNUrl } from 'utils';
import { hashTagsServices } from 'network/services/hashtagServices';
import { isNullOrEmpty } from 'utils/stringUtils';

const { Option } = Select;

type Props = {
  name?: string;
  initialValues?: any;
  uploadAttachment?: string;
  onFinish: (values: any) => void;
  onFinishFailed: (error: any) => void;
  type: string;
  html: string | undefined;
  id?: string;
  isPressConvert?: boolean;
  linkTTS?: string;
  setAttachmentIds?: (ids: string[]) => void;
};

type FormPostState = {
  format?: number;
  category?: number;
  categories: Category[];
  topics: Topic[];
  hashtags: any;
};

const FormPost: React.FC<Props> = ({
  name,
  initialValues,
  uploadAttachment,
  onFinish,
  onFinishFailed,
  type,
  html,
  id,
  isPressConvert,
  linkTTS,
  setAttachmentIds,
}) => {
  const { formCreatePost, idStr, setIdStr } = usePostContext();
  const [formPostState, setFormPostState] = useState<FormPostState>({
    format: initialValues.postFormat,
    category: initialValues.category,
    categories: [],
    topics: [],
    hashtags: [],
  });

  const currentHashTag = useRef<string[]>();

  const handleChangeFormat = (e: RadioChangeEvent) => {
    setFormPostState((prev) => ({ ...prev, format: e.target.value }));
  };

  const handleChangeCategory = (category: number) => {
    setFormPostState((prev) => ({ ...prev, category }));
  };

  const fetchCategories = async () => {
    const successCallback = (res: any = {}) => {
      setFormPostState((prev) => ({ ...prev, categories: res?.data }));
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const failCallback = () => {};
    await roadMap280Service.getCategories(successCallback, failCallback);
  };

  const fetchHashtags = async () => {
    const response = await hashTagsServices.get();
    if (response?.status === 200) {
      currentHashTag.current = response?.data;
      setFormPostState((prev) => ({ ...prev, hashtags: response?.data }));
    }
  };

  const createHashtag = async (hashtag: string) => {
    if (isNullOrEmpty(hashtag)) {
      return;
    }
    await hashTagsServices.create({
      name: hashtag,
    });
  };

  useEffect(() => {
    fetchCategories();
    fetchHashtags();
  }, []);

  useEffect(() => {
    (async function () {
      const successCallback = (res: any = {}) => {
        setFormPostState((prev) => ({ ...prev, topics: res?.data }));
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const failCallback = () => {};
      await roadMap280Service.getTopics(
        { categoryId: formPostState.category },
        successCallback,
        failCallback
      );
    })();
  }, [formPostState.category]);

  const handleUploadSuccess = (res: any) => {
    setAttachmentIds?.([res.id]);
  };

  const renderViewByFormat = (format?: number) => {
    switch (format) {
      case 1:
        return (
          <ViewPost
            type={type}
            html={html}
            id={id}
            isPressConvert={isPressConvert}
            linkTTS={linkTTS}
          />
        );
      case 2:
        return (
          <ViewVideo
            category={uploadAttachment ?? ''}
            onSuccess={handleUploadSuccess}
          />
        );
      case 3:
        return (
          <ViewAudio
            category={uploadAttachment ?? ''}
            duration={initialValues.postTime}
          />
        );
      default:
        break;
    }
  };

  const onDeleteImage = () => {
    setIdStr('');
  };

  const onChangeHashTag = (value: string[]) => {
    for (const tag of value) {
      if (currentHashTag.current?.indexOf(tag) == -1) {
        createHashtag(tag);
      }
    }
  };

  return (
    <Form
      name={name}
      layout="vertical"
      autoComplete="off"
      form={formCreatePost}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="postFormat"
        label="Định dạng"
        rules={[{ required: true, message: 'Vui lòng chọn định dạng!' }]}
      >
        <Radio.Group onChange={handleChangeFormat}>
          <Radio value={1}>Bài viết</Radio>
          <Radio value={2}>Video</Radio>
          <Radio value={3}>Audio</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="postName"
        label="Tiêu đề"
        rules={[
          { required: true, message: 'Vui lòng nhập tiêu đề!' },
          { max: 150, message: 'Tiêu đề không quá 150 ký tự' },
        ]}
      >
        <Input placeholder="Nhập tiêu đề..." />
      </Form.Item>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="categoryId"
            label="Chuyên mục"
            rules={[{ required: true, message: 'Vui lòng chọn chuyên mục!' }]}
          >
            <Select
              allowClear
              placeholder="Chọn chuyên mục..."
              menuItemSelectedIcon={
                <CheckOutlined style={{ color: '#1677ff' }} />
              }
              onChange={handleChangeCategory}
            >
              {formPostState.categories.map(({ id, name }: Category) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="topicId"
            label="Chủ đề"
            rules={[{ required: true, message: 'Vui lòng chọn chủ đề!' }]}
          >
            <Select
              allowClear
              placeholder="Chọn chủ đề..."
              menuItemSelectedIcon={
                <CheckOutlined style={{ color: '#1677ff' }} />
              }
            >
              {formPostState.topics.map(({ id, name }: Topic) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Hashtag" name="hashtag">
            <Select
              allowClear
              mode="tags"
              maxTagCount="responsive"
              placeholder="Nhập hashtag..."
              onChange={onChangeHashTag}
            >
              {formPostState.hashtags.map(({ id, name }: Topic) => (
                <Option key={id} value={name}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          {idStr ? (
            <div>
              <p>* Ảnh</p>
              <Space>
                <Image width={120} src={getCDNUrl(idStr)} />
                <DeleteOutlined
                  style={{ fontSize: '20px' }}
                  onClick={onDeleteImage}
                />
              </Space>
            </div>
          ) : (
            <UploadC
              name="image"
              type={'IMAGE' as any}
              accept="image/*"
              description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
              category={uploadAttachment ?? ''}
            />
          )}
        </Col>
      </Row>

      <Form.Item
        label="Mô tả"
        name="description"
        rules={[{ max: 150, message: 'Mô tả không quá 150 ký tự' }]}
      >
        <Input.TextArea placeholder="Nhập mô tả không quá 150 ký tự..." />
      </Form.Item>

      {renderViewByFormat(formPostState.format)}
    </Form>
  );
};

export default FormPost;
