import { Button, Table, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import AdminIcon from 'assets/image/adminIcon.jpg';
import saveAs from 'file-saver';
import { useWindowSize } from 'hook';
import moment from 'moment';
import { hotLeadServices } from 'network/services/hotLead';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  DEFAULT_PAGE_SIZE,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
} from 'utils/constants';
import { HotLeadFilters, HotLeadFiltersRef } from './hotLeadFilter';
import ModalDetail from './modalDetail';

type filterHotLeadParams = {
  status: string;
  name: string;
  phone: string;
  source: string;
  type: string;
};

const HotLead: FC = () => {
  const defaultFilters = {
    size: DEFAULT_PAGE_SIZE,
    page: 0,
    query: '',
  };
  const windowSize = useWindowSize();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isLoadingBtnExport, setIsLoadingBtnExport] = useState(false);
  const [itemSelected, setItemSelected] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filters, setFilters] =
    useState<NotiGetParamsInterface>(defaultFilters);
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const hotLeadFiltersRef = useRef<HotLeadFiltersRef>(null);

  console.log('isLoadingBtnExport', isLoadingBtnExport);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleExport = async () => {
    setIsLoadingBtnExport(true);
    const resp = await hotLeadServices.handleExport();
    setIsLoadingBtnExport(false);
    const { data } = resp;
    if (resp?.status === 200) {
      message.success('Export thành công!');
      const fileName = `DANH_SACH_HOT_LEAD_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    } else {
      message.error('Có lỗi trong quá trình export!');
    }
  };

  const renderStatus = (status: number) => {
    switch (status) {
      case 0:
        return (
          <Tag color={'error'} style={{ color: 'red', fontSize: '10px' }}>
            Chưa xử lý
          </Tag>
        );
      case 1:
        return (
          <Tag color={'yellow'} style={{ color: '#FFCD2A', fontSize: '10px' }}>
            Đang xử lý
          </Tag>
        );
      case 2:
        return (
          <Tag
            color={'success'}
            style={{ color: 'hsl(100deg 77% 44%)', fontSize: '10px' }}
          >
            Đã xử lý
          </Tag>
        );
      default:
        break;
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'id',
      width: '3%',
    },
    {
      title: 'ID',
      dataIndex: 'userId',
      width: '4%',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'fullname',
      width: '12%',
    },
    {
      title: 'Câu hỏi',
      dataIndex: 'question',
      width: '21%',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: '7%',
      render: (cell: any) => renderStatus(cell),
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      width: '10%',
    },
    {
      title: 'Screen',
      dataIndex: 'source',
      width: '12%',
    },
    {
      title: 'Ngày liên hệ',
      dataIndex: 'createdDate',
      width: '11%',
      render: (cell: any) => moment(cell).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '11%',
      render: (cell: any) => moment(cell).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      title: 'Thao tác',
      dataIndex: 'id',
      width: '8%',
      render: (record: number) => (
        <a onClick={() => onUpdate(record)}>Xem chi tiết</a>
      ),
    },
  ];

  const onUpdate = (record: number) => {
    setItemSelected(record);
    onShowModal();
  };

  const onSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(e?.target?.value);
    const searchData = dataSearch.filter((elm) => {
      return (
        (elm.fullname?.toUpperCase() || '').includes(
          e?.target?.value?.toUpperCase()
        ) ||
        (elm.phoneNumber?.toUpperCase() || '').includes(
          e?.target?.value?.toUpperCase()
        )
      );
    });
    setData(searchData);
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const fetchData = async (
    type: string | undefined,
    filtersProps: filterHotLeadProps | undefined
  ) => {
    const response =
      type && filtersProps
        ? await hotLeadServices.filterByCondition(type, filtersProps)
        : await hotLeadServices.get(filters);
    const { data } = response;
    if (response?.status === 200) {
      setIsLoading(false);
      setData(data?.content);
      setDataSearch(data?.content);
      searchValue?.length > 0 && onSearch(searchValue as any);
      setMetadata({
        pageCount: data?.totalPages,
        page: data?.number,
        size: data?.size,
        total: data?.totalElements,
      });
    } else {
      setIsLoading(false);
    }
  };

  const onOpenFilters = () => {
    hotLeadFiltersRef.current?.setOpenModal(true);
  };

  const onShowModal = () => {
    setIsShowModal(true);
  };

  const onHideModal = () => {
    setIsShowModal(false);
  };

  useEffect(() => {
    fetchData(undefined, undefined);
  }, []);

  const onFilter = (data: filterHotLeadParams, type: string | undefined) => {
    const temp = {
      size: filters?.size,
      page: filters?.page,
      ...data,
    };
    fetchData(type, temp);
    hotLeadFiltersRef.current?.setOpenModal(false);
  };

  const callbackUpdate = () => {
    fetchData(undefined, undefined);
  };

  useEffect(() => {
    if (isLoading) {
      fetchData(undefined, undefined);
    }
  }, [isLoading]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Hot Lead</title>
        <link rel="icon" href={AdminIcon} />
        <meta name="description" content="HotLead" />
      </Helmet>
      <Button onClick={onOpenFilters}>Bộ lọc</Button>
      <Button
        onClick={fetchData.bind(null, undefined, undefined)}
        className="ml-2"
      >
        Reset
      </Button>
      <Button onClick={handleExport} type="primary" className="ml-2">
        Export
      </Button>
      <HotLeadFilters ref={hotLeadFiltersRef} onFilter={onFilter} />
      <Table
        className="mt-4"
        rowKey={'id'}
        dataSource={data}
        columns={columns}
        scroll={{
          y: windowSize?.height - GLOBAL_HEIGHT - 40,
          x: 1400,
        }}
        locale={{ emptyText: 'Không có kết quả phù hợp' }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filters.page + 1,
          total: metadata?.total,
          defaultPageSize: filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
      />

      {isShowModal && (
        <ModalDetail
          onClose={onHideModal}
          selectedItem={itemSelected}
          callback={callbackUpdate}
        />
      )}
    </div>
  );
};

export default HotLead;
