import { DrawerForm } from '@ant-design/pro-components';
import { Form, Select } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';

export interface CourseFiltersRef {
  setOpenModal: (isOpen: boolean) => void;
  onReset: () => void;
}

export interface SubmitFormInterface {
  code?: number;
  name?: string;
  id?: number;
  stageId?: number;
}

interface CourseFiltersProps {
  onSubmitFilter: (values: SubmitFormInterface) => Promise<boolean | void>;
  categories: any;
}
export const CourseFilters = forwardRef<CourseFiltersRef, CourseFiltersProps>(
  (props, ref) => {
    const { onSubmitFilter, categories } = props;

    useImperativeHandle(ref, () => ({
      setOpenModal,
      onReset,
    }));

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const onReset = () => {
      form.setFieldsValue({
        code: undefined,
        name: undefined,
        id: undefined,
        stageId: undefined,
      });
    };

    const setOpenModal = (isOpen: boolean) => {
      setIsOpenModal(isOpen);
    };

    const [form] = useForm();

    return (
      <DrawerForm
        onOpenChange={setIsOpenModal}
        title={'Bộ lọc'}
        resize={{
          maxWidth: window.innerWidth * 0.8,
          minWidth: 500,
        }}
        form={form}
        open={isOpenModal}
        submitTimeout={2000}
        onFinish={onSubmitFilter}
      >
        <Form.Item label="Mã khóa học" name={'code'}>
          <TextArea
            placeholder="Nhập mã khoá học"
            allowClear
            autoSize={{ minRows: 1, maxRows: 3 }}
            onChange={(e: any) => {
              const trimmedValue = e.target.value.replace(/^\s+/, '');
              form.setFieldsValue({
                code: trimmedValue,
              });
            }}
          />
        </Form.Item>

        <Form.Item label="Tên khóa học" name={'name'}>
          <TextArea
            placeholder="Nhập tên khoá học"
            allowClear
            autoSize={{ minRows: 1, maxRows: 3 }}
            onChange={(e: any) => {
              const trimmedValue = e.target.value.replace(/^\s+/, '');
              form.setFieldsValue({
                name: trimmedValue,
              });
            }}
          />
        </Form.Item>

        <Form.Item label="Danh mục" name={'stageId'}>
          <Select
            allowClear
            placeholder="Tất cả"
            options={categories.map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
          />
        </Form.Item>
      </DrawerForm>
    );
  }
);
