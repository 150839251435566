import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { FORMAT_TYPE_280N } from 'utils/enum';
import { FilterInterface } from '..';

export interface IBoxFilterProps {
  categories: any[];
  topics: any[];
  filters: FilterInterface;
  onSearchPostName: (e: any) => void;
  onSelectCategory: (value: number) => void;
  onSelectTopic: (value: number) => void;
  onSelectPostFormat: (value: number) => void;
  onReset: () => void;
  onSearch: () => void;
  handleOnKeyDown: (e: any) => void;
  onShowModal: () => void;
}

export default function BoxFilter({
  topics,
  categories,
  filters,
  onSearchPostName,
  onSelectCategory,
  onSelectTopic,
  onSelectPostFormat,
  handleOnKeyDown,
  onReset,
  onSearch,
  onShowModal,
}: IBoxFilterProps) {
  return (
    <div className="mb-2 flex justify-between py-2">
      <div className="flex w-full">
        <div className="w-96 pr-2">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Tìm tiêu đề bài viết"
            className="w-full"
            allowClear
            onChange={onSearchPostName}
            value={filters.postName}
            onKeyDown={handleOnKeyDown}
          />
        </div>

        <Select
          className="w-60 pr-2"
          allowClear
          options={categories?.map((item: any) => ({
            label: item.originalName,
            value: item.id,
          }))}
          placeholder={'Chọn chuyên mục'}
          value={filters.categoryId}
          onChange={onSelectCategory}
        />
        <Select
          className="w-60 pr-2"
          allowClear
          options={topics?.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          placeholder={'Chọn chủ đề'}
          value={filters.topicId}
          onChange={onSelectTopic}
        />
        <Select
          className="w-48 pr-2"
          allowClear
          options={FORMAT_TYPE_280N.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          placeholder={'Chọn định dạng'}
          value={filters.postFormat}
          onChange={onSelectPostFormat}
        />

        <Button type="primary" onClick={onSearch} className="mr-2">
          Cập nhật
        </Button>
        <Button type="default" onClick={onReset}>
          Reset
        </Button>
      </div>
      <Button type="primary" onClick={onShowModal}>
        Thêm bài viết
      </Button>
    </div>
  );
}
