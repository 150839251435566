import { Dispatch, createContext, useReducer } from 'react';
import { reducer } from './reducer';
import { initialState } from './state';

export type Action = {
  type: string;
  payload: any;
};

type ContextType = {
  state: AppStorage;
  dispatch: Dispatch<Action>;
};

// Create a new context
export const AppContext = createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

export const AppProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppContext;
