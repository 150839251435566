import React, { useState } from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Form, message, Progress, Space, Spin, Upload } from 'antd';
import { systemsServices } from 'network/services/systems';

import './styles.scss';

enum Type {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

type Props = {
  name: string;
  type: Type;
  accept?: string;
  maxSize?: number;
  description?: string;
  category: string;
  orientation?: 'landscape' | 'portrait';
  uploadFileSuccess: (file: any) => void;
  title?: string;
  clearData?: () => void;
  isRequired?: boolean;
  beforeUpload?: (file: any) => boolean;
};
const CHUNK_SIZE = 1048576 * 250; //its 250MB, increase the number measure in mb

const UploadFileChunks: React.FC<Props> = ({
  beforeUpload,
  name,
  type,
  accept,
  category,
  orientation,
  maxSize = 10 * 1000 * 1000 * 1000,
  description,
  uploadFileSuccess,
  title = 'Banner hiển thị',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isRequired,
}) => {
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const [numberChunk, setNumberChunk] = useState(0);
  const [totalChunk, setTotalChunk] = useState(0);

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return [];
    }
    return [];
  };

  function splitFile(file: File, chunkSize: number) {
    const chunks = [];
    const fileSize = file.size;
    let offset = 0;

    while (offset < fileSize) {
      const chunk = file.slice(offset, offset + chunkSize);
      chunks.push(chunk);
      offset += chunkSize;
    }

    return chunks;
  }

  const handleCustomRequest = async (options?: any) => {
    const { onSuccess, onError, file } = options;
    const isValidFile = file.size <= maxSize;
    file.arrayBuffer().then((buffer: any) => {
      console.log(buffer);
    });
    const fileChunked = splitFile(file, CHUNK_SIZE);
    const totalChunks = fileChunked.length;
    setTotalChunk(totalChunks);
    if (isValidFile) {
      for (let i = 0; i < fileChunked.length; i++) {
        setNumberChunk(i);
        const formData = new FormData();
        if (category) {
          formData.append('category', category);
        }
        formData.append('file', fileChunked[i]);
        if (type === 'VIDEO' && orientation) {
          formData.append('orientation', orientation);
        }
        formData.append('fileName', file.name);
        formData.append('totalChunks', totalChunks.toString());
        formData.append('chunkNumber', i.toString());
        formData.append('type', file.type);

        setIsLoadingApi(true);
        const response = await systemsServices.uploadChunk(formData);
        setIsLoadingApi(false);
        if (response.status === 200) {
          if (i === fileChunked.length - 1) {
            onSuccess(response.data);
            uploadFileSuccess(response.data);
          }
        } else {
          onError('Error');
        }
      }
    } else {
      onError('Error');
    }
  };

  const renderLabel = () => {
    return (
      <Space size={8}>
        <span>{title}</span>
        <CloudUploadOutlined />
      </Space>
    );
  };

  const onChange = (info: any) => {
    const {
      file: { status, name, size },
    } = info || {};
    switch (status) {
      case 'done':
        message.success(`File ${name} đã được tải lên thành công`);
        break;
      case 'error':
        message.error(
          size <= maxSize
            ? `File ${name} đã được tải lên thất bại`
            : `File ${name} có kích thước ảnh vượt quá 100KB`
        );
        break;
      default:
        break;
    }
  };

  const propsUpload = {
    beforeUpload,
    accept,
    onChange,
    customRequest: handleCustomRequest,
  };

  return (
    <>
      {isLoadingApi ? (
        <div>
          <Spin tip="Loading" size="small">
            <div style={stylesSpin} />
          </Spin>
          <Progress percent={(numberChunk / totalChunk) * 100} />
        </div>
      ) : (
        <Form.Item
          name={name}
          label={renderLabel()}
          valuePropName="fileList"
          rules={[
            {
              required: isRequired ? false : true,
              message: `Vui lòng tải lên file ${name}`,
            },
          ]}
          getValueFromEvent={getFile}
        >
          <Upload {...propsUpload}>
            <p>{description}</p>
          </Upload>
        </Form.Item>
      )}
    </>
  );
};

const stylesSpin = {
  padding: '50px',
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '4px',
};

export default UploadFileChunks;
