import { Button } from 'antd';
import React from 'react';

type Props = {
  handleChangePopup: () => void;
  setTypeModal: React.Dispatch<'Thêm mới' | 'Chỉnh sửa'>;
};

const Header = ({ handleChangePopup, setTypeModal }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div></div>
      <div
        style={{
          marginTop: '10px',
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            handleChangePopup();
            setTypeModal('Thêm mới');
          }}
        >
          Thêm Pop-up
        </Button>
      </div>
    </div>
  );
};

export default Header;
