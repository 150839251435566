import { getQueryString } from 'utils/stringUtils';
import { request } from '..';

const HASH_TAGS_URL = '/api/hashtags';

const get = () => {
  return request(HASH_TAGS_URL);
};

const create = (body?: HashTagCreateParamsInterface) => {
  const url = getQueryString(HASH_TAGS_URL);
  return request(url, 'post', body);
};

export const hashTagsServices = {
  get,
  create,
};
