import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  notification,
} from 'antd';

import UploadFileChunks from 'components/UploadFileChunks';
import { categorieServices } from 'network/services/categorie';
import React from 'react';
import { validateNumber } from 'utils/helper';

type FormPostState = {
  stage: StageInterface[];
  course: CourseInterface[];
};

export type CategoriesParams = {
  banner: string;
  code: string;
  courseId: number;
  description: string;
  icon: string;
  name: string;
  stageId: number;
};

type Props = {
  dataDetail?: CategoriesInterface | undefined;
  onHideModal: () => void;
  onReload: () => void;
  modalType?: string;
  course: [];
  phase: [];
};

export const ModalCreateCategories = ({
  dataDetail,
  onHideModal,
  onReload,
  course,
  phase,
  modalType,
}: Props) => {
  const [form] = Form.useForm();
  const [imageIdStr, setImageIdStr] = React.useState<string>('');
  const [imageIdStrBanner, setImageIdStrBanner] = React.useState<string>('');
  const [formPostState, setFormPostState] = React.useState<FormPostState>({
    stage: [],
    course: [],
  });
  const [filter, setFilter] = React.useState<number | undefined>(
    dataDetail?.stage?.id
  );

  const initialValues = {
    code: dataDetail?.code || undefined,
    name: dataDetail?.name || undefined,
    stageId: dataDetail?.stage?.id || undefined,
    courseId: dataDetail?.course?.id || undefined,
    description: dataDetail?.description || undefined,
    position: dataDetail?.position || undefined,
  };

  React.useEffect(() => {
    if (filter) {
      const filterCourse = course.filter(
        (item: CourseInterface) => item.stage.id === filter
      );
      setFormPostState((prev) => ({ ...prev, course: filterCourse }));
    }
  }, [filter]);

  React.useEffect(() => {
    if (dataDetail) {
      setImageIdStr(dataDetail?.icon);
      setImageIdStrBanner(dataDetail?.banner);
    }
  }, [dataDetail]);

  const renderTitleHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'hsl(215deg 100% 54%)',
          color: '#fff',
          margin: '-20px -24px 20px -24px',
          padding: '15px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        {`${modalType} Chuyên mục`}
      </div>
    );
  };

  const onDeleteImage = () => {
    setImageIdStr('');
  };

  const uploadFileSuccess = (file: { linkUrl: string }) => {
    setImageIdStr(file.linkUrl);
  };

  const uploadFileSuccessBanner = (file: { linkUrl: string }) => {
    setImageIdStrBanner(file.linkUrl);
  };

  const onDeleteImageBanner = () => {
    setImageIdStrBanner('');
  };

  const handleSelectStage = (value: number) => {
    setFilter(value);
    form.setFieldValue('courseId', null);
  };

  const onFinish = async (value: CategoriesParams) => {
    const params = {
      ...value,
      banner: imageIdStrBanner,
      icon: imageIdStr,
    };
    if (modalType === 'Thêm mới') {
      const response = await categorieServices.create(params);
      if (response.status === 200) {
        notification.success({ message: `${modalType} Chuyên mục thành công` });
        onHideModal();
        onReload();
      }
    } else {
      const response = await categorieServices.update(dataDetail?.id, params);
      if (response.status === 200) {
        notification.success({ message: `${modalType} Chuyên mục thành công` });
        onHideModal();
        onReload();
      }
    }
  };

  return (
    <Modal
      title={renderTitleHeader()}
      open={true}
      footer={null}
      onOk={onHideModal}
      onCancel={onHideModal}
      width={500}
      closeIcon={<CloseOutlined style={{ color: '#fff', fontSize: '20px' }} />}
    >
      <Form
        initialValues={initialValues}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Mã chuyên mục"
          name="code"
          rules={[{ message: 'Vui lòng nhập mã chuyên mục!', required: true }]}
        >
          <Input
            placeholder="Nhập mã chuyên mục..."
            maxLength={250}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="Tên chuyên mục"
          name="name"
          rules={[{ message: 'Vui lòng nhập tên chuyên mục!', required: true }]}
        >
          <Input
            placeholder="Nhập tên chuyên mục..."
            maxLength={250}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="Chọn danh mục"
          name="stageId"
          rules={[{ required: true, message: 'Vui lòng chọn danh mục!' }]}
        >
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Chọn danh mục"
            onChange={handleSelectStage}
            options={phase.map((item: PhaseInterface) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Chọn các khoá học"
          name="courseId"
          rules={[{ required: true, message: 'Vui lòng chọn khoá học!' }]}
        >
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Chọn khoá học"
            options={formPostState.course.map((item: CourseInterface) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Thứ tự trên chuyên mục"
          name="position"
          rules={[{ required: true, validator: validateNumber }]}
        >
          <Input placeholder="Nhập thứ tụ..." style={{ width: '100%' }} />
        </Form.Item>
        {imageIdStr ? (
          <div>
            <p>Icon</p>
            <Space>
              <Image width={200} src={imageIdStr} />
              <DeleteOutlined
                style={{ fontSize: '44px' }}
                onClick={onDeleteImage}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            isRequired={true}
            title={'Icon'}
            name="image"
            type={'IMAGE' as any}
            accept="image/*"
            description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
            category={'banner'}
            uploadFileSuccess={uploadFileSuccess}
          />
        )}

        {imageIdStrBanner ? (
          <div>
            <p>Ảnh Banner *</p>
            <Space>
              <Image width={200} src={imageIdStrBanner} />
              <DeleteOutlined
                style={{ fontSize: '44px' }}
                onClick={onDeleteImageBanner}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            name="banner"
            type={'IMAGE' as any}
            accept="image/*"
            description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
            category={'general_topic'}
            uploadFileSuccess={uploadFileSuccessBanner}
            title="Ảnh Banner"
            isRequired={true}
          />
        )}

        <Form.Item label="Mô tả" name="description">
          <Input.TextArea
            placeholder="Nhập mô tả không quá 150 ký tự..."
            maxLength={150}
            style={{ width: '100%', height: 100 }}
          />
        </Form.Item>
        <div className={'center-item'}>
          <Button type="primary" htmlType="submit">
            {modalType}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
