import {
  CaretDownOutlined,
  CaretUpOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { FC, useState } from 'react';
import './style.scss';
import { useWindowSize } from 'hook';
import { DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE } from 'utils/constants';
type Props = { data: PeriodProps[]; loading: boolean };

const ContentCategory: FC<Props> = ({ data, loading }) => {
  const [focusIds, setFocusIds] = useState<number[]>([]);
  const [focusIdsCategory, setFocusIdsCategory] = useState<string[]>([]);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE;
  const returnColumn = (
    data: {
      name: string;
      totalPost?: number;
      createdBy?: string;
      createdDate?: string;
      id: number;
      code: string;
    },
    type: string,
    showBorderBot?: boolean
  ) => {
    return (
      <div
        onClick={
          type === 'period'
            ? onPressPeriod?.bind(null, data?.id)
            : onPressPeriodCategory?.bind(null, data?.id + data?.name)
        }
        className={['cellCategory w-[full]', showBorderBot && 'borderBot'].join(
          ' '
        )}
      >
        <div className="flex items-center globalText flex-[1.5]">
          {type === 'period' && (
            <div className="pl-3">
              {focusIds?.includes(data?.id) ? (
                <CaretUpOutlined className="mr-2" />
              ) : (
                <CaretDownOutlined className="mr-2" />
              )}
              {data?.name}
            </div>
          )}
        </div>
        <div className="flex items-center globalText flex-[1.5]">
          {type === 'category' && (
            <div>
              {focusIdsCategory?.includes(data?.id + data?.name) ? (
                <CaretUpOutlined className="mr-2" />
              ) : (
                <CaretDownOutlined className="mr-2" />
              )}
              {data?.name}
            </div>
          )}
        </div>
        <div className="flex items-center globalText flex-[2]">
          <div className="pl-5">{type === 'topic' && data?.name}</div>
        </div>
        <div className="flex items-center justify-center globalText globalTextNormal flex-[1.5]">
          {data?.totalPost}
        </div>
        <div className="flex items-center justify-center globalText globalTextNormal flex-[1.5]">
          {data?.createdBy}
        </div>
        <div className="flex items-center justify-center globalText globalTextNormal flex-[2]">
          {data?.createdDate}
        </div>
      </div>
    );
  };

  const onPressPeriod = (id: number) => {
    if (focusIds?.includes(id)) {
      setFocusIds(
        focusIds.filter(function (letter) {
          return letter !== id;
        })
      );
    } else {
      setFocusIds((oldArray) => [...oldArray, id]);
    }
  };
  const onPressPeriodCategory = (name: string) => {
    if (focusIdsCategory?.includes(name)) {
      setFocusIdsCategory(
        focusIdsCategory.filter(function (letter) {
          return letter !== name;
        })
      );
    } else {
      setFocusIdsCategory((oldArray) => [...oldArray, name]);
    }
  };

  const renderItem = (item: PeriodProps) => {
    return (
      <div key={item?.id}>
        {returnColumn(item, 'period', true)}
        {focusIds?.includes(item?.id) && (
          <div>
            {item?.children?.map((item2) => {
              return (
                <div key={item2?.id}>
                  {returnColumn(item2, 'category')}
                  {focusIdsCategory?.includes(item2?.id + item2?.name) && (
                    <div>
                      {item2?.children?.map((item3) => {
                        return returnColumn(item3, 'topic');
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="overflow-auto"
      style={{
        height: isMobile ? windowSize.height - 350 : windowSize.height - 150,
      }}
    >
      <div className="flex flex-1  h-[50px] header">
        <div className="flex items-center justify-center globalText flex-[1.5]">
          GIAI ĐOẠN
        </div>
        <div className="flex items-center justify-center globalText flex-[1.5]">
          DANH MỤC
        </div>
        <div className="flex items-center justify-center globalText flex-[2]">
          CHUYÊN MỤC
        </div>
        <div className="flex items-center justify-center globalText flex-[1.5]">
          SL BÀI VIẾT
        </div>
        <div className="flex items-center justify-center globalText flex-[1.5]">
          NGƯỜI TẠO
        </div>
        <div className="flex items-center justify-center globalText flex-[2]">
          NGÀY TẠO
        </div>
      </div>
      <div className="contentContainer">
        {loading ? (
          <div className="w-full flex items-center justify-center h-[200px] borderBot">
            <LoadingOutlined className="text-[30px] text-[#1890ff]" />
          </div>
        ) : (
          data?.map((item) => renderItem(item))
        )}
      </div>
    </div>
  );
};

export default ContentCategory;
