import { Button, Table, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST } from 'utils/constants';
import { ModalCreatePhase } from './modal';
import moment from 'moment';
import BoxActions from 'containers/Course/BoxActions';
import { phaseService } from 'network/services/phase';
import { useWindowSize } from 'hook';
import { PREGNANT_STAGE } from 'utils/enum';
import '../../styless.scss';

const defaultFilters = {
  size: DEFAULT_PAGE_SIZE,
  page: 0,
};

type ColumnsProps = {
  title?: string;
  dataIndex?: string;
  width?: string;
  fixed?: string;
  render?: () => void;
};

export const Phase = () => {
  const windowSize = useWindowSize();
  const [filter, setFilter] = React.useState(defaultFilters);
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const [itemSelected, setItemSelected] = React.useState<
    PhaseInterface | undefined
  >(undefined);
  const [modalType, setModalType] = React.useState<'Thêm mới' | 'Chỉnh sửa'>(
    'Thêm mới'
  );
  const [data, setData] = React.useState<[]>([]);
  const [metadata, setMetadata] = React.useState<MetadataInterface | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const handleEditModal = (value: PhaseInterface) => {
    setItemSelected(value);
    setIsShowModal(true);
    setModalType('Chỉnh sửa');
  };

  const handleShowHidden = async (value: PhaseInterface) => {
    if (value?.isDisable) {
      const response = await phaseService.enable(value?.id);
      if (response.status === 200) {
        notification.success({ message: `Cập nhật Danh mục thành công` });
        onReload();
      }
    } else {
      const response = await phaseService.disable(value?.id);
      if (response.status === 200) {
        notification.success({ message: `Cập nhật Danh mục thành công` });
        onReload();
      }
    }
  };

  const renderPregnantStage = (value: string) => {
    return PREGNANT_STAGE.find((item) => item.value === value)?.label;
  };

  const columns: ColumnsType<ColumnsProps> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '3%',
    },
    {
      title: 'Mã danh mục',
      dataIndex: 'code',
      width: '10%',
    },
    {
      title: 'Tên giai đoạn',
      dataIndex: 'pregnantStage',
      width: '10%',
      render: (pregnantStage: string) => {
        return renderPregnantStage(pregnantStage);
      },
    },
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      width: '15%',
    },
    {
      title: 'Số lượng khoá học',
      dataIndex: 'totalCourse',
      width: '15%',
      render: (totalCourse: number) => {
        if (totalCourse > 0) {
          return totalCourse;
        } else {
          return 0;
        }
      },
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '15%',
      render: (modifiedDate: string) => {
        return moment(modifiedDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'modifiedBy',
      width: '15%',
    },
    {
      title: 'Thao tác',
      width: '15%',
      render: (value: string) => {
        return (
          <BoxActions
            handleEditModal={handleEditModal}
            value={value}
            handleDeleteModal={handleShowHidden}
          />
        );
      },
    },
  ];

  const handleShowModal = () => {
    setModalType('Thêm mới');
    setIsShowModal(true);
  };

  const onHideModal = () => {
    setIsShowModal(false);
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilter((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const onReload = () => {
    setIsLoading(true);
  };

  const fetchdata = async () => {
    const response = await phaseService.get(filter);
    const { data } = response;
    if (response?.status === 200) {
      setData(data?.data);
      setIsLoading(false);
      setMetadata({
        pageCount: data?.totalPages,
        page: data?.page,
        size: data?.size,
        total: data?.total,
      });
    } else {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (isLoading) {
      fetchdata();
    }
  }, [isLoading]);

  return (
    <div>
      <div className="btnAdd">
        <Button type="primary" onClick={handleShowModal}>
          &#43; Thêm danh mục
        </Button>
      </div>
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={data}
        scroll={{
          y: windowSize?.height - 250,
          x: 1400,
        }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filter.page + 1,
          total: metadata?.total,
          defaultPageSize: filter.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
      />
      {isShowModal && (
        <ModalCreatePhase
          dataDetail={itemSelected}
          onHideModal={onHideModal}
          type={modalType}
          onReload={onReload}
        />
      )}
    </div>
  );
};
