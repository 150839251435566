import isArray from 'lodash/isArray';

export function getQueryString(requestUrl: string, params?: any): string {
  if (typeof params === 'object' && params) {
    const ret: string[] = [];
    for (const d of Object.keys(params)) {
      if (isArray(params[d])) {
        const arrayParams: string = `${d}=${params[d].join(',')}`;
        ret.push(arrayParams);
      } else {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(params[d]));
      }
    }

    if (ret.length > 0) {
      const retString = ret.join('&');
      if (requestUrl.includes('?')) {
        requestUrl = requestUrl + '&' + retString;
      } else {
        requestUrl = requestUrl + '?' + retString;
      }
    }
  }
  return requestUrl;
}

export function isNullOrEmpty(data: any | any[]): boolean {
  if (!data) {
    return true;
  }
  if (data instanceof Array) {
    return data.length === 0;
  }
  if (typeof data === 'number') {
    return data === 0;
  }
  if (typeof data === 'undefined') {
    return true;
  }
  if (typeof data === 'object') {
    return Object.keys(data).length === 0;
  }
  let output = data;
  if (typeof output !== 'string') {
    output = output.toString();
  }
  output = output.trim();

  return output.length <= 0;
}

export function deleteParamsNotUsing(params: any) {
  for (const key in params) {
    if (params[key] !== 0) {
      if (isNullOrEmpty(params[key])) {
        delete params[key];
      }
    }
  }
}

export function isLink(str: string) {
  var pattern = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/;
  return pattern.test(str);
}
