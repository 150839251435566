import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useWindowSize } from 'hook';
import moment from 'moment';
import { customersServices } from 'network/services/customers';
import React from 'react';

type Props = {
  onClose?: () => void;
  id: number;
};

export const ModalListCourse = ({ onClose, id = 0 }: Props) => {
  const [listPackage, setListPackage] = React.useState<ItemVipPackage[]>([]);
  const [selectedVipPackage, setSelectedVipPackage] =
    React.useState<ItemVipPackage>();
  const windowSize = useWindowSize();
  const getVipPackage = async () => {
    const resp = await customersServices.vipPackageDetails(id);
    setListPackage(resp?.data);
  };

  React.useEffect(() => {
    getVipPackage();
  }, [id]);

  const handleDelete = async () => {
    const resp = await customersServices.deleteVipPackage(
      id,
      selectedVipPackage?.id
    );
    if (resp.status === 200) {
      getVipPackage();
      message.success(
        `Xóa gói khoá học ${selectedVipPackage?.name} thành công!`
      );
    } else {
      message.error(
        `Xóa gói khoá học ${selectedVipPackage?.name} thất bại! \n ${resp.data}`
      );
    }
    setSelectedVipPackage(undefined);
  };
  const handleSelect = (vipPackageId: ItemVipPackage) => {
    setSelectedVipPackage(vipPackageId);
  };
  const renderDelete = (vipPackageId: ItemVipPackage) => {
    return (
      <DeleteOutlined
        className="iconDelete"
        type="delete"
        onClick={() => handleSelect(vipPackageId)}
      />
    );
  };

  const renderConfirm = () => {
    return (
      <div className="w-full flex items-center justify-center flex-col">
        <p>Bạn có chắc chắn muốn xóa gói khoá học </p>
        <p className="font-bold">{selectedVipPackage?.name}</p>
        <div>
          <Button onClick={handleDelete} className="mr-2">
            Xác nhận
          </Button>
          <Button onClick={() => setSelectedVipPackage(undefined)}>Hủy</Button>
        </div>
      </div>
    );
  };

  const displayNone = () => {
    return <div style={{ display: 'none' }}></div>;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '2%',
      render: (_cell: any, _record: any, index: number) => (
        <div>{index + 1}</div>
      ),
    },
    {
      title: 'Khoá học',
      dataIndex: 'course',
      width: '5%',
      render: (course: any) => <div>{course?.name}</div>,
    },
    {
      title: 'Gói khoá học',
      dataIndex: 'vipPackage',
      width: '5%',
      render: (vipPackage: any) => <div>{vipPackage?.name}</div>,
    },
    {
      title: 'Ngày kích hoạt',
      dataIndex: 'activatedTime',
      width: '5%',
      render: (cell: any) => moment(cell).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expiredTime',
      width: '5%',
      render: (cell: any) => moment(cell).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      title: 'Kích hoạt bởi',
      dataIndex: 'activatedStaff',
      width: '5%',
    },
    {
      title: 'Hành động',
      dataIndex: 'vipPackage',
      width: '3%',
      render: (vipPackage: any) => renderDelete(vipPackage),
    },
  ];
  return (
    <Modal
      open={true}
      destroyOnClose
      closable={false}
      width={1200}
      centered
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <Table
        rowKey={'id'}
        dataSource={listPackage}
        columns={columns}
        scroll={{
          y: windowSize?.height,
          x: 1100,
        }}
        footer={selectedVipPackage ? renderConfirm : displayNone}
      />
    </Modal>
  );
};
