import { FormInstance } from 'antd';
import { createContext, useContext } from 'react';

import { LANDSCAPE } from '../constants';

export type PostContent = {
  postId?: number;
  formCreatePost?: FormInstance;
  idStr?: string;
  attachmentIds?: string[];
  orientation?: string;
  videoUrl?: string;
  setVideoUrl?: (url?: string) => void;
  setIdStr: (value: string) => void;
  setAttachmentIds: (value: string[]) => void;
  setOrientation: (value: string) => void;
};

export const PostContext = createContext<PostContent>({
  formCreatePost: undefined,
  idStr: undefined,
  attachmentIds: undefined,
  orientation: LANDSCAPE,
  videoUrl: undefined,
  setVideoUrl: () => {},
  setIdStr: () => {},
  setAttachmentIds: () => {},
  setOrientation: () => {},
});

export const usePostContext = () => useContext(PostContext);
