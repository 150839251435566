export const initialState: AppStorage = {
  userInfor: {
    id: 1,
    avatar: '',
    fullname: '',
    role: {
      code: '',
      description: '',
      enable: false,
      id: 0,
      name: '',
    },
    status: 0,
    username: '',
  },
};
