import { Col, Form, Input, Row, Select, Space } from 'antd';
import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import UploadFileChunks from 'components/UploadFileChunks';
import { VIDEO_TYPES } from 'utils/enum';
// import { LANDSCAPE, PORTRAIT } from 'containers/posts/constants';

type Props = {
  category: string;
  onDeleteVideo: () => void;
  videoUrl: string | undefined;
  uploadFileSuccessVideo: (value: any) => void;
};

const ViewVideo: React.FC<Props> = ({
  category,
  onDeleteVideo,
  videoUrl,
  uploadFileSuccessVideo,
}) => {
  // const validateNumber = (rule: any, value: any, callback: any) => {
  //   if (isNaN(value) || value <= 0) {
  //     callback('Vui lòng nhập thời gian là số nguyên dương');
  //   } else {
  //     callback();
  //   }
  // };

  // const onBeforeUpload = (file: any) => {
  //   if (!['.mp4'].includes(file.type)) {
  //     message.error(`${file.name} is not a .mp4 file`);
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  return (
    <Row gutter={[12, 12]}>
      <Col span={5}>
        <Form.Item
          name="type"
          label="Loại video"
          rules={[{ required: true, message: 'Vui lòng chọn loại video' }]}
        >
          <Select allowClear placeholder="Chọn loại..." options={VIDEO_TYPES} />
        </Form.Item>
      </Col>

      <Col span={5}>
        <Form.Item name="duration" label="Thời gian (giây)">
          <Input placeholder="Nhập thời gian..." />
        </Form.Item>
      </Col>

      <div>
        {videoUrl ? (
          <div
            style={{
              width: '600px',
              display: 'flex',
              height: '300px',
              marginBottom: '60px',
            }}
          >
            <div>
              <p>* Video</p>{' '}
              <video style={{ width: '100%', height: '100%' }} controls>
                <source src={videoUrl} type="video/mp4" />
              </video>
            </div>
            <Space>
              <DeleteOutlined
                style={{ fontSize: '2em' }}
                onClick={onDeleteVideo}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            name="video"
            type={'VIDEO' as any}
            accept=".mp4"
            maxSize={500 * 1000 * 10000}
            description="(Tải file mp4... Kích thước không quá 500MB...)"
            category={category}
            // beforeUpload={onBeforeUpload}
            uploadFileSuccess={uploadFileSuccessVideo}
            title="Video"
            orientation={'landscape'}
          />
        )}
      </div>
    </Row>
  );
};

export default ViewVideo;
