import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const TOPICS_URL = '/api/general';

const getListTopics = (params: {
  page?: number;
  size?: number;
  categoryId?: number;
  courseId?: number;
}) => {
  deleteParamsNotUsing(params);
  const url = getQueryString(`${TOPICS_URL}/topics`, params);
  return request(url);
};

const addTopicsToList = (body: DataBodyTopics) => {
  return request(`${TOPICS_URL}/topics`, 'POST', body);
};

const editTopicsToList = (body: DataBodyTopics, id: number) => {
  return request(`${TOPICS_URL}/topics/${id}`, 'PATCH', body);
};

const getListCategories = (param: string) => {
  return request(`${TOPICS_URL}/categories${param}`);
};

const uploadFile = (body: ParamsUpload) => {
  return request('/api/attachments/upload', 'POST', body);
};

const getListCourse = () => {
  return request('/api/courses');
};

export const topicsService = {
  getListTopics,
  getListCategories,
  uploadFile,
  getListCourse,
  addTopicsToList,
  editTopicsToList,
};
