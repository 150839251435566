import { Button, notification } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { useRef } from 'react';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST } from 'utils/constants';
import { ModalCreateCategories } from './modal';
import BoxActions from 'containers/Course/BoxActions';
import { categorieServices } from 'network/services/categorie';
import moment from 'moment';
import { courseService } from 'network/services/course';
import { phaseService } from 'network/services/phase';
import { useWindowSize } from 'hook';
import { CategoryFilters, CategoryFiltersRef } from './CategoryFilters';

type ColumnsProps = {
  title?: string;
  dataIndex?: string;
  width?: string;
  fixed?: string;
  render?: () => void;
};

const defaultFilters = {
  size: DEFAULT_PAGE_SIZE,
  page: 0,
};

export const Categories: React.FC = () => {
  const windowSize = useWindowSize();
  const [filters, setFilters] = React.useState(defaultFilters);
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const [modalType, setModalType] = React.useState<'Thêm mới' | 'Chỉnh sửa'>(
    'Thêm mới'
  );
  const [itemSelected, setItemSelected] = React.useState<
    CategoriesInterface | undefined
  >(undefined);

  const [data, setData] = React.useState<[]>([]);
  const [metadata, setMetadata] = React.useState<MetadataInterface | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [phase, setPhase] = React.useState<[]>([]);
  const [course, setCourse] = React.useState<[]>([]);
  const categoryRef = useRef<CategoryFiltersRef>(null);

  React.useEffect(() => {
    if (isLoading) {
      fetchdata();
    }
  }, [isLoading]);

  const fetchdata = async () => {
    const response = await categorieServices.get(filters);
    const { data } = response;
    if (response?.status === 200) {
      setData(data?.data);
      setIsLoading(false);
      setMetadata({
        pageCount: data?.totalPages,
        page: data?.page,
        size: data?.size,
        total: data?.total,
      });
    } else {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDataCourse();
    fetchDataPhase();
  }, []);

  const fetchDataCourse = async () => {
    const response = await courseService.get();
    const { data } = response;
    setCourse(data?.data);
  };

  const fetchDataPhase = async () => {
    const response = await phaseService.get();
    const { data } = response;
    setPhase(data?.data);
  };

  const handleShowModal = () => {
    setIsShowModal(true);
    setModalType('Thêm mới');
  };

  const onHideModal = () => {
    setItemSelected(undefined);
    setIsShowModal(false);
  };

  const handleEditModal = (value: CategoriesInterface) => {
    setItemSelected(value);
    setIsShowModal(true);
    setModalType('Chỉnh sửa');
  };

  const handleShowHidden = async (value: CategoriesInterface) => {
    if (value?.isDisable) {
      const response = await categorieServices.enable(value?.id);
      if (response?.status === 200) {
        notification.success({ message: `Cập nhật Chuyên mục thành công` });
        onReload();
      }
    } else {
      const response = await categorieServices.disable(value?.id);
      if (response?.status === 200) {
        notification.success({ message: `Cập nhật Chuyên mục thành công` });
        onReload();
      }
    }
  };

  const onReload = () => {
    setIsLoading(true);
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const columns: ColumnsType<ColumnsProps> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
    },
    {
      title: 'Mã chuyên mục',
      dataIndex: 'code',
      width: '10%',
    },
    {
      title: 'Tên chuyên mục',
      dataIndex: 'name',
      width: '15%',
    },
    {
      title: 'Khoá học',
      dataIndex: 'course',
      width: '15%',
      render: (course: CourseInterface) => {
        return course?.name;
      },
    },
    {
      title: 'Danh mục',
      dataIndex: 'stage',
      width: '15%',
      render: (stage: StageInterface) => {
        return stage?.name;
      },
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '12%',
      render: (modifiedDate: string) => {
        return moment(modifiedDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'modifiedBy',
      width: '10%',
    },
    {
      title: 'Thao tác',
      width: '8%',
      render: (value: string) => {
        return (
          <BoxActions
            handleEditModal={handleEditModal}
            value={value}
            handleDeleteModal={handleShowHidden}
          />
        );
      },
    },
  ];

  const onSubmitFilter = async (values: any) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      ...values,
    }));
    categoryRef.current?.setOpenModal(false);
  };

  const handleReset = () => {
    setIsLoading(true);
    setFilters(defaultFilters);
    categoryRef.current?.onReset();
  };

  const onOpenFilter = () => {
    categoryRef.current?.setOpenModal(true);
  };

  return (
    <div>
      <div className="flex justify-end mb-4">
        <Button onClick={onOpenFilter} className="mr-2">
          Bộ lọc
        </Button>
        <Button onClick={handleReset} className="mr-2">
          Reset
        </Button>
        <Button type="primary" onClick={handleShowModal}>
          &#43; Thêm chuyên mục
        </Button>
      </div>

      <CategoryFilters
        ref={categoryRef}
        courses={course}
        phase={phase}
        onSubmitFilter={onSubmitFilter}
      />
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={data}
        scroll={{
          y: windowSize?.height - 265,
          x: 1500,
        }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filters.page + 1,
          total: metadata?.total,
          defaultPageSize: filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
      />
      {isShowModal && (
        <ModalCreateCategories
          dataDetail={itemSelected}
          onHideModal={onHideModal}
          modalType={modalType}
          onReload={onReload}
          phase={phase}
          course={course}
        />
      )}
    </div>
  );
};
