import { customersServices } from 'network/services/customers';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { LOCAL_STORAGE_KEYS } from 'utils/constants';

type Props = {};

const Home: React.FC<Props> = () => {
  const getProfile = async () => {
    const resp = await customersServices.getInfo();
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.userInfo,
      JSON.stringify(resp?.data)
    );
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Trang chủ</title>
        <meta name="description" content="Trang chủ" />
      </Helmet>
      <div>CHÚC BẠN MỘT NGÀY ĐẦY HỨNG KHỞI 🥰🥰🥰🥰</div>
    </>
  );
};

export default Home;
