import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  notification,
} from 'antd';
import UploadFileChunks from 'components/UploadFileChunks';
import { topicService } from 'network/services/topic';
import React from 'react';

type FormPostState = {
  stage: StageInterface[];
  course: CourseInterface[];
  categories: CategoriesInterface[];
};

export type TopicParams = {
  banner: string;
  icon: string;
  isDisable: boolean;
  categoryId?: number | undefined;
  description?: string | undefined;
  name?: string | undefined;
};

type Props = {
  dataDetail?: TopicInterface | undefined;
  onHideModal: () => void;
  onReload: () => void;
  modalType?: string;
  course: [];
  phase: [];
  categories: [];
};
export const ModalCreateTopic: React.FC<Props> = ({
  dataDetail,
  onHideModal,
  onReload,
  modalType,
  course,
  phase,
  categories,
}) => {
  const [form] = Form.useForm();
  const [imageIdStr, setImageIdStr] = React.useState<string>('');
  const [imageBanner, setImageBanner] = React.useState<string>('');

  const [formPostState, setFormPostState] = React.useState<FormPostState>({
    stage: [],
    course: [],
    categories: [],
  });

  const [filter, setFilter] = React.useState<number | undefined>();
  const [filterCategories, setFilterCategories] = React.useState<
    number | undefined
  >();

  React.useEffect(() => {
    if (dataDetail && modalType === 'Chỉnh sửa') {
      const filter: any = categories.find(
        (item: any) => item.id === dataDetail?.categoryId
      );
      if (filter) {
        form.setFieldsValue({
          name: dataDetail?.name || undefined,
          description: dataDetail?.description || undefined,
          categoryId: dataDetail?.categoryId,
          courseId: filter?.course?.id,
          stageId: filter?.stage?.id,
        });
      }
      setImageIdStr(dataDetail?.icon);
      setImageBanner(dataDetail?.banner);
      setFilter(filter?.stage?.id);
      setFilterCategories(filter?.course?.id);
    } else {
      form.resetFields();
    }
  }, [dataDetail]);

  React.useEffect(() => {
    if (filter) {
      const filterCourse = course.filter(
        (item: CourseInterface) => item.stage.id === filter
      );
      setFormPostState((prev) => ({ ...prev, course: filterCourse }));
      if (filterCourse) {
        const filtercategories = categories.filter(
          (item: CategoriesInterface) => item.course.id === filterCategories
        );
        setFormPostState((prev) => ({ ...prev, categories: filtercategories }));
      }
    }
  }, [filter, filterCategories]);

  const onDeleteImage = () => {
    setImageIdStr('');
  };

  const uploadFileSuccess = (file: { linkUrl: string }) => {
    setImageIdStr(file.linkUrl);
  };

  const uploadBannerSuccess = (file: { linkUrl: string }) => {
    setImageBanner(file.linkUrl);
  };

  const handleSelectStage = (value: number) => {
    setFilter(value);
    form.setFieldValue('courseId', null);
    form.setFieldValue('categoryId', null);
  };

  const handleSelectCourse = (value: number) => {
    setFilterCategories(value);
    form.setFieldValue('categoryId', null);
  };

  const onDeleteImageBanner = () => {
    setImageBanner('');
  };

  const renderTitleHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'hsl(215deg 100% 54%)',
          color: '#fff',
          margin: '-20px -24px 20px -24px',
          padding: '15px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        {`${modalType} Chủ đề`}
      </div>
    );
  };

  const onSubmit = async (value: TopicParams) => {
    const params = {
      ...value,
      banner: imageBanner,
      icon: imageIdStr,
      isDisable: false,
    };
    if (modalType === 'Thêm mới') {
      const response = await topicService.create(params);
      if (response.status === 201) {
        notification.success({ message: `${modalType} Chủ đề thành công` });
        onHideModal();
        onReload();
      } else {
        notification.error({ message: response.data });
      }
    } else {
      const response = await topicService.update(dataDetail?.id, params);
      if (response.status === 200) {
        notification.success({ message: `${modalType} Chủ đề thành công` });
        onHideModal();
        onReload();
      } else {
        notification.error({ message: response.data });
      }
    }
  };

  return (
    <Modal
      title={renderTitleHeader()}
      open={true}
      footer={null}
      onOk={onHideModal}
      onCancel={onHideModal}
      width={500}
      closeIcon={<CloseOutlined style={{ color: '#fff', fontSize: '20px' }} />}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="Tên chủ đề"
          name="name"
          rules={[{ message: 'Vui lòng nhập tên chủ đề!', required: true }]}
        >
          <Input
            placeholder="Nhập tên chủ đề..."
            maxLength={250}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="Giai đoạn"
          name="stageId"
          rules={[{ required: true, message: 'Vui lòng chọn giai đoạn!' }]}
        >
          <Select
            allowClear
            style={{ width: '100%' }}
            onChange={handleSelectStage}
            placeholder="Chọn giai đoạn"
            options={phase.map((item: PhaseInterface) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Khoá học"
          name="courseId"
          rules={[{ required: true, message: 'Vui lòng chọn khoá học!' }]}
        >
          <Select
            allowClear
            style={{ width: '100%' }}
            onChange={handleSelectCourse}
            placeholder="Chọn khoá học"
            options={formPostState.course.map((item: CourseInterface) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Chuyên mục"
          name="categoryId"
          rules={[{ required: true, message: 'Vui lòng chọn chuyên mục!' }]}
        >
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Chọn chuyên mục"
            options={formPostState.categories.map(
              (item: CategoriesInterface) => ({
                value: item.id,
                label: item.name,
              })
            )}
          />
        </Form.Item>

        {imageIdStr ? (
          <div>
            <p>Icon</p>
            <Space>
              <Image width={200} src={imageIdStr} />
              <DeleteOutlined
                style={{ fontSize: '44px' }}
                onClick={onDeleteImage}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            isRequired={true}
            title={'Icon'}
            name="icon"
            type={'IMAGE' as any}
            accept="image/*"
            description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
            category={'banner'}
            uploadFileSuccess={uploadFileSuccess}
          />
        )}

        {imageBanner ? (
          <div>
            <p>Ảnh banner</p>
            <Space>
              <Image width={200} src={imageBanner} />
              <DeleteOutlined
                style={{ fontSize: '44px' }}
                onClick={onDeleteImageBanner}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            title={'Ảnh Banner'}
            name="banner"
            type={'IMAGE' as any}
            accept="image/*"
            description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
            category={'banner'}
            uploadFileSuccess={uploadBannerSuccess}
          />
        )}

        <Form.Item label="Mô tả" name="description">
          <Input.TextArea
            placeholder="Nhập mô tả không quá 150 ký tự..."
            maxLength={150}
            style={{ width: '100%', height: 100 }}
          />
        </Form.Item>
        <div className={'center-item'}>
          <Button type="primary" htmlType="submit">
            {modalType}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
