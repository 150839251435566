// import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

type DashBoardProps = {
  data: {
    userCreatedCount: number;
    userVipCount: number;
    orderCount: number;
    date: string;
  }[];
  page: number;
  total: number;
  size: number;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Thống kê người dùng và đơn hàng',
    },
  },
};

export function ChartDashBoard(props: DashBoardProps) {
  const { data } = props;

  const labels = data?.map((item) => item.date);
  const dataChart = {
    labels,
    datasets: [
      {
        label: 'Số lượng khách hàng tạo mới',
        data: data?.map((item) => item.userCreatedCount),
        fill: false,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Số lượng khách hàng VIP',
        data: data?.map((item) => item.userVipCount),
        fill: false,
        borderColor: 'rgb(221,178,1)',
        backgroundColor: 'rgb(221,178,1,0.5)',
      },
      {
        label: 'Số lượng đơn hàng',
        data: data?.map((item) => item.orderCount),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgb(75, 192, 192,0.5)',
      },
    ],
  };

  return <Line options={options} data={dataChart} />;
}
