import { Button, Modal, Tag } from 'antd';

type Props = {
  onHideModal?: () => void;
  onFinishConfirm?: () => void;
};

const listTarget = [
  {
    id: 1,
    name: 'Tên khách hàng',
    syntax: '<customer-name>',
  },
  {
    id: 2,
    name: 'Số điện thoại khách hàng',
    syntax: '<customer-phone>',
  },
  {
    id: 3,
    name: 'Số ngày mang thai',
    syntax: '<customer-day-count-pregnant>',
  },
  {
    id: 4,
    name: 'Số tuần mang thai',
    syntax: '<customer-week-count-pregnant>',
  },
  {
    id: 5,
    name: 'Số tháng mang thai',
    syntax: '<customer-month-count-pregnant>',
  },
];

export const ModalHelp = ({ onHideModal, onFinishConfirm }: Props) => {
  const onFinish = () => {
    onFinishConfirm?.();
  };

  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <Modal
        open={true}
        footer={null}
        closable={false}
        width={350}
        onOk={onFinish}
        onCancel={onHideModal}
        centered
      >
        <div className="title">HƯỚNG DẪN</div>
        {listTarget.map((item) => {
          return (
            <div key={item.id} className="content">
              <div className="content-title">{item.name}</div>
              <div className="content-syntax">{item.syntax}</div>
              <Tag
                style={{ cursor: 'pointer', marginTop: '10px' }}
                color={'processing'}
                onClick={onCopy.bind(null, item.syntax)}
              >
                Copy
              </Tag>
            </div>
          );
        })}
        <div className="center-item">
          <div />
          <Button type="primary" onClick={onFinish}>
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};
