import { Input, Radio, RadioChangeEvent, Space, Typography } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { DrawerForm } from '@ant-design/pro-components';
import { useForm } from 'antd/es/form/Form';

export interface HotLeadFiltersRef {
  setOpenModal: (isOpen: boolean) => void;
}

export interface SubmitFormInterface {}

interface HotLeadFiltersProps {
  onFilter?: (data?: any, type?: string) => void;
}
export const HotLeadFilters = forwardRef<
  HotLeadFiltersRef,
  HotLeadFiltersProps
>((props, ref) => {
  const { onFilter } = props;
  const [form] = useForm();

  useImperativeHandle(ref, () => ({
    setOpenModal,
  }));

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const setOpenModal = (isOpen: boolean) => {
    setIsOpenModal(isOpen);
  };

  const [valueSort, setValueSort] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValueSort(e.target.value);
  };
  const [filter, setFilter] = useState<filterHotLeadParams>({
    status: '',
    name: '',
    phone: '',
    source: '',
    type: '',
  });

  const statusOptions = ['Chưa xử lý', 'Đang xử lý', 'Đã xử lý'];
  const sourceOptions = ['Bong bóng chat', 'Box Liên hệ'];

  const convertStatus = (status: string) => {
    switch (status) {
      case 'Chưa xử lý':
        return 0;
      case 'Đang xử lý':
        return 1;
      case 'Đã xử lý':
        return 2;
      default:
        return 1;
    }
  };

  const onChangeStatus = ({ target: { value } }: RadioChangeEvent) => {
    setFilter((cur: filterHotLeadParams) => ({
      ...cur,
      status: value,
    }));
  };
  const onChangeSource = ({ target: { value } }: RadioChangeEvent) => {
    setFilter((cur: filterHotLeadParams) => ({
      ...cur,
      source: value,
    }));
  };

  const onChangeName = (e: { target: { value: string } }) => {
    setFilter((cur: filterHotLeadParams) => ({
      ...cur,
      name: e?.target?.value,
    }));
  };

  const onChangePhone = (e: { target: { value: string } }) => {
    setFilter((cur: any) => ({
      ...cur,
      phone: e?.target?.value,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmitFilter = async (_values: any) => {
    switch (valueSort) {
      case 1:
        onFilter?.({ status: convertStatus(filter?.status) }, 'status');
        break;
      case 2:
        onFilter?.({ name: filter?.name }, 'name');
        break;
      case 3:
        onFilter?.({ phone: filter?.phone }, 'phone');
        break;
      case 4:
        onFilter?.(
          filter?.source === 'Box Liên hệ'
            ? { source: 'BoxLienHe' }
            : { source: 'BongBongChat' },
          'source'
        );
        break;
      case 5:
        onFilter?.({}, 'web');
    }
  };

  return (
    <DrawerForm
      onOpenChange={setIsOpenModal}
      title={'Bộ lọc'}
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      form={form}
      open={isOpenModal}
      submitTimeout={2000}
      onFinish={onSubmitFilter}
    >
      <div>
        <p>Bộ lọc:</p>
        <div style={{ paddingLeft: '20px' }}>
          <Radio.Group onChange={onChange} value={valueSort}>
            <Space direction="vertical">
              <Radio value={1}>Lọc theo status</Radio>
              {valueSort === 1 ? (
                <Radio.Group
                  options={statusOptions}
                  onChange={onChangeStatus}
                  value={filter?.status}
                  style={{
                    paddingLeft: '30px',
                  }}
                />
              ) : null}
              <Radio value={2}>Lọc theo tên</Radio>
              {valueSort === 2 ? (
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '25px',
                    alignItems: 'center',
                  }}
                >
                  <Typography>Nhập tên: </Typography>
                  <Input
                    style={{ width: 200, marginLeft: '10px', height: '30px' }}
                    onChange={onChangeName}
                    value={filter?.name}
                    allowClear
                  />
                </div>
              ) : null}
              <Radio value={3}>Lọc theo số điện thoại</Radio>
              {valueSort === 3 ? (
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '25px',
                    alignItems: 'center',
                  }}
                >
                  <Typography>Nhập số điện thoại: </Typography>
                  <Input
                    style={{
                      width: 200,
                      marginLeft: '10px',
                      height: '30px',
                    }}
                    allowClear
                    onChange={onChangePhone}
                    value={filter?.phone}
                  />
                </div>
              ) : null}
              <Radio value={4}>Lọc theo source</Radio>
              {valueSort === 4 ? (
                <Radio.Group
                  options={sourceOptions}
                  onChange={onChangeSource}
                  value={filter?.source}
                  style={{
                    paddingLeft: '30px',
                  }}
                />
              ) : null}
              <Radio value={5}>Lọc từ web</Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>
    </DrawerForm>
  );
});
