import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import { useWindowSize } from 'hook';
import { RESPONSE_CODE } from 'network/constants';
import { appBabyTrackerServices } from 'network/services/appBabyTracker';
import React, { useState } from 'react';
import { GLOBAL_HEIGHT } from 'utils/constants';
import { CreateModal } from './CreateModal';

type State = {
  isOpenModal: boolean;
  listPost: any[];
  isEdit: boolean;
  reload: boolean;
  detail: any;
};
export const AppBabyTracker = () => {
  const [state, setState] = useState<State>({
    isOpenModal: false,
    listPost: [],
    isEdit: false,
    reload: false,
    detail: {},
  });
  const windowSize = useWindowSize();
  let tableHeight = windowSize?.height - GLOBAL_HEIGHT + 5;

  React.useEffect(() => {
    fetchData();
  }, [state?.reload]);

  const fetchData = async () => {
    const res = await appBabyTrackerServices.getDataGoogleSheet();
    const data = res?.data?.valueRanges;
    const dataPost = data[0]?.values;
    if (dataPost?.length <= 0) return;
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      const propertyNames: string[] = dataPost[0].map((item: any) =>
        String(item)
      );
      const convertedValues: Record<string, string | number | boolean>[] = [];
      for (let i = 1; i < dataPost.length; i++) {
        const convertedObject: Record<string, string | number | boolean> = {};
        propertyNames.forEach((propertyName, index) => {
          convertedObject[propertyName] = dataPost[i][index];
        });
        convertedValues.push(convertedObject);
      }
      setState((prev) => ({
        ...prev,
        listPost: convertedValues,
      }));
    }
  };

  const handleOpenModalCreate = () => {
    setState((prev) => ({
      ...prev,
      isOpenModal: true,
      isEdit: false,
    }));
  };

  const handleCloseModalCreate = () => {
    setState((prev) => ({
      ...prev,
      isOpenModal: false,
      reload: !state?.reload,
    }));
  };

  const handleShowDetail = (data: any) => {  
    setState((prev) => ({
      ...prev,
      isOpenModal: true,
      isEdit: true,
      detail: data,
    }));
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '3%',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '6%',
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
      width: '6%',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (cell: any) => (
        <div
          style={{
            width: '99%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {cell}
        </div>
      ),
    },
    {
      title: 'Chapter',
      dataIndex: 'chapter',
      key: 'chapter',
      width: '6%',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      render: (cell: any) => (
        <div
          style={{
            width: '99%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {cell}
        </div>
      ),
    },
    {
      title: 'Image',
      dataIndex: 'img',
      key: 'img',
      render: (cell: any) => (
        <div
          style={{
            width: '99%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {cell}
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'des',
      key: 'des',
      render: (cell: any) => (
        <div
          style={{
            width: '99%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {cell}
        </div>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, record: any) => {
        return (
          <Tag
            onClick={handleShowDetail?.bind(null, record)}
            className="cursor-pointer"
          >
            Chỉnh sửa
          </Tag>
        );
      },
      width: '5%',
    },
  ];
  return (
    <div>
      {state?.isOpenModal ? (
        <CreateModal
          onHideModal={handleCloseModalCreate}
          isEdit={state?.isEdit}
          detail={state?.detail}
        />
      ) : (
        <>
          <div className="flex flex-1 justify-end mb-3">
            <Button
              key="button"
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleOpenModalCreate}
            >
              Thêm mới Post
            </Button>
          </div>

          <Table
            rowKey={'id'}
            dataSource={state?.listPost}
            columns={columns}
            pagination={{
              size: 'small',
              defaultPageSize: 20,
              showTotal: (total) => (
                <div>Tổng số bản ghi {total.toLocaleString()}</div>
              ),
            }}
            scroll={{ x: 1000, y: tableHeight }}
          />
        </>
      )}
    </div>
  );
};
