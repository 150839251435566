import { notification, Table, Tag, Image } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useWindowSize } from 'hook';
import moment from 'moment';
import { popupService } from 'network/services/popup';
import { useEffect, useState } from 'react';
import { PAGE_SIZE_LIST } from 'utils/constants';
import Header from './Header';
import ModalPopup from './Modal';
import './styles.scss';

const FormatDateTime = 'DD/MM/YYYY HH:mm';

const Popup = () => {
  const windowSize = useWindowSize();
  const [listPopup, setListPopup] = useState([]);
  const [form] = useForm();
  const [filterListPopup, setFilterListPopup] = useState({
    page: 0,
    size: 50,
  });
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [typeModal, setTypeModal] = useState<'Thêm mới' | 'Chỉnh sửa'>(
    'Thêm mới'
  );
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isLoadingAPI, setLoadingAPI] = useState(true);
  const [defaultDataDetailPopup, setDefaultDataDetailPopup] = useState<any>({});
  const [idDetail, setIdDetail] = useState<any>(0);
  const [image, setImage] = useState<string | undefined>(undefined);

  const fetchListPopup = async () => {
    const resp = await popupService.getListPopup(filterListPopup);
    setLoadingAPI(false);
    setMetadata({
      pageCount: resp.headers['x-page-count'],
      page: resp.headers['x-page-number'],
      size: resp.headers['x-page-size'],
      total: resp.headers['x-total-count'],
    });
    setListPopup(resp.data);
  };

  const fetchDetailDataPopup = async () => {
    const resp = await popupService.detailPopupToList(idDetail);
    setLoadingAPI(false);
    setDefaultDataDetailPopup(resp.data);
  };

  const handleChangeOpenModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleEditModal = (id: any) => {
    setTypeModal('Chỉnh sửa');
    handleChangeOpenModal();
    setIdDetail(id);
  };

  const handleSuccessUploadFile = (value: any) => {
    setImage(value.idStr);
  };

  const onDeleteImageBanner = () => {
    setImage(undefined);
  };

  const handleError = (title: string) => {
    switch (title) {
      case 'invalid_timestamp':
        notification.error({
          message:
            'Đã có Pop-up được thiết lập trong khoảng thời gian này. Vui lòng thử lại',
        });
        break;
      case 'popup_duplicate':
        notification.error({
          message: 'Pop-up đã tồn tại trên hệ thống. Vui lòng thử lại',
        });
        break;

      default:
        break;
    }
  };

  const onSubmit = async (values: any) => {
    const { time, url, ...data } = values;
    const transformData = {
      ...data,
      timestampFrom: new Date(time[0]).toISOString(),
      timestampTo: new Date(time[1]).toISOString(),
      image: image,
      url,
    };

    if (typeModal === 'Thêm mới') {
      const resp = await popupService.addPopupToList(transformData);
      if (resp?.status === 200) {
        setIsOpenModal(false);
        setLoadingAPI(true);
        notification.success({ message: 'Tạo Pop-up thành công' });
      } else {
        handleError(resp?.data?.title);
      }
    } else {
      const resp = await popupService.editPopupToList(transformData, idDetail);
      if (resp?.status === 200) {
        setIsOpenModal(false);
        setLoadingAPI(true);
        notification.success({ message: 'Chỉnh sửa Pop-up thành công' });
      } else {
        handleError(resp?.data?.title);
      }
    }
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setLoadingAPI(true);
    setFilterListPopup((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '2%',
    },
    {
      title: 'Tên Pop-up',
      dataIndex: 'name',
      key: 'name',
      width: '6%',
      render: (value: string) => <div className="text-esclipse">{value}</div>,
    },
    {
      title: 'Thời gian hiển thị',
      dataIndex: 'timestampFrom',
      key: 'timestampFrom',
      render: (_value: string, record: any) => (
        <div>{`${moment(record?.timestampFrom).format(
          FormatDateTime
        )} -> ${moment(record?.timestampTo).format(FormatDateTime)}`}</div>
      ),
      width: '8%',
    },
    {
      title: 'Hiển thị',
      dataIndex: 'display',
      key: 'display',
      render: (value: string) => <>{renderDisplay(value)}</>,
      width: '3%',
    },
    {
      title: 'Màn hình',
      dataIndex: 'screen',
      key: 'screen',
      render: (value: string) => <>{renderScreen(value)}</>,
      width: '3%',
    },
    {
      title: 'Ảnh',
      dataIndex: 'image',
      key: 'image',
      render: (value: string) => (
        <Image src={value} style={{ width: '50px', height: '50px' }} />
      ),
      width: '2%',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (value: string) => <div className="text-esclipse">{value}</div>,
      width: '5%',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (value: string) => <>{renderStatus(value)}</>,
      width: '3%',
    },
    {
      title: 'Thao tác',
      render: (value: any) => (
        <div
          className="text-esclipse"
          style={{
            cursor: 'pointer',
            color: 'blue',
          }}
          onClick={() => handleEditModal(value?.id)}
        >
          Chỉnh sửa
        </div>
      ),
      width: '3%',
    },
  ];

  useEffect(() => {
    fetchListPopup();
  }, [isLoadingAPI]);

  useEffect(() => {
    if (!isOpenModal) {
      setIdDetail(0);
      setDefaultDataDetailPopup({});
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (idDetail !== 0) {
      fetchDetailDataPopup();
    }
  }, [idDetail]);

  useEffect(() => {
    if (defaultDataDetailPopup) {
      form.setFieldsValue({
        display: defaultDataDetailPopup?.display,
        name: defaultDataDetailPopup?.name,
        screen: defaultDataDetailPopup?.screen,
        url: defaultDataDetailPopup?.url,
        time: [
          dayjs(defaultDataDetailPopup?.timestampFrom),
          dayjs(defaultDataDetailPopup?.timestampTo),
        ],
      });
      setImage(defaultDataDetailPopup?.image?.split('/')[5]);
    } else {
      form.resetFields();
    }
  }, [defaultDataDetailPopup]);

  return (
    <div>
      <Header
        handleChangePopup={handleChangeOpenModal}
        setTypeModal={setTypeModal}
      />
      <div
        style={{
          marginTop: '20px',
        }}
      >
        <Table
          dataSource={listPopup}
          columns={COLUMNS as ColumnsType<any>}
          scroll={{ x: 1400, y: windowSize?.height - 200 }}
          pagination={{
            size: 'small',
            showSizeChanger: true,
            current: filterListPopup.page + 1,
            total: metadata?.total,
            defaultPageSize: filterListPopup.size,
            pageSizeOptions: PAGE_SIZE_LIST,
            onChange: onPageChange,
            showTotal: (total) => (
              <div>Tổng số bản ghi {total.toLocaleString()}</div>
            ),
          }}
        />
      </div>

      {listPopup && (
        <ModalPopup
          isModalOpen={isOpenModal}
          handleOk={onSubmit}
          handleChangePopup={handleChangeOpenModal}
          form={form}
          type={typeModal}
          image={image}
          handleSuccessUploadFile={handleSuccessUploadFile}
          onDeleteImageBanner={onDeleteImageBanner}
        />
      )}
    </div>
  );
};

const renderScreen = (value: string) => {
  switch (value) {
    case 'HOME':
      return <div>Màn Home</div>;
    case 'INSIGHT':
      return <div>Màn cẩm nang</div>;
    default:
      break;
  }
};

const renderDisplay = (value: string) => {
  switch (value) {
    case 'ALL':
      return <div>Tất cả</div>;
    case 'APP':
      return <div>Trên APP</div>;
    case 'WEB':
      return <div>Trên Website</div>;
    default:
      break;
  }
};

const renderStatus = (value: string) => {
  switch (value) {
    case 'INACTIVE':
      return <Tag color={'error'}>Ngưng hoạt động</Tag>;
    case 'ACTIVE':
      return <Tag color={'success'}>Đang hoạt động</Tag>;
    case 'PENDING':
      return <Tag color={'processing'}>Chưa hoạt động</Tag>;

    default:
      break;
  }
};

export default Popup;
