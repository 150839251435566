import { PlusOutlined } from '@ant-design/icons';
import { Button, Select, message } from 'antd';
import TextEditor from 'components/TextEditor';
import { RESPONSE_CODE } from 'network/constants';
import { appPregnancyTrackerServices } from 'network/services/appPregnancyTracker';
import { FC, useEffect, useState } from 'react';
import IphoneScreen from 'assets/image/iphoneScreen.png';

const AppPregnancyTracker: FC = () => {
  const [value, setValue] = useState('');
  const [idxSelectedCategory, setIdxSelectedCategory] = useState<
    number | undefined
  >(undefined);
  const [idxSelectedWeek, setIdxSelectedWeek] = useState<number | undefined>(
    undefined
  );

  const [idxSelectedLanguage, setIdxSelectedLanguage] = useState<
    number | undefined
  >(undefined);

  const [weeks, setWeeks] = useState<any>(undefined);
  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeCategory = (value: number) => {
    setIdxSelectedCategory(value);
    setWeeks(data?.[value]?.values);
    setIdxSelectedWeek(undefined);
    if (value !== idxSelectedCategory) {
      setValue('');
    }
  };

  const onChangeWeek = (value: number) => {
    setIdxSelectedWeek(value);
  };

  const onChangeLanguage = (value: number) => {
    setIdxSelectedLanguage(value);
  };

  const fetchData = async () => {
    const response = await appPregnancyTrackerServices.getDataGoogleSheet();
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      setData(response?.data?.valueRanges);
      if (idxSelectedCategory !== undefined) {
        setWeeks(response?.data?.valueRanges?.[idxSelectedCategory]?.values);
      }
    }
  };

  const onUpdateData = async () => {
    setLoading(true);
    if (idxSelectedLanguage !== undefined && idxSelectedWeek !== undefined) {
      const body: any = {
        replacement: value,
        find: weeks?.[idxSelectedLanguage]?.[idxSelectedWeek],
      };
      if (body.replacement !== undefined && body.find !== undefined) {
        const response =
          await appPregnancyTrackerServices.updateDataGoogleSheet(body);
        if (response?.status === RESPONSE_CODE.SUCCESS) {
          message.success('Cập nhật thành công!!');
          await fetchData();
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (
      idxSelectedCategory !== undefined &&
      idxSelectedWeek !== undefined &&
      idxSelectedLanguage !== undefined
    ) {
      setValue(weeks?.[idxSelectedLanguage]?.[idxSelectedWeek]);
    }
  }, [idxSelectedCategory, idxSelectedWeek, idxSelectedLanguage, weeks]);

  const categories = data?.map((item: any, index: number) => ({
    value: index,
    label: item.range.replace('!A1:Z1000', ''),
  }));

  const weekData = weeks
    ? weeks?.[0]?.map((item: string, index: number) => ({
        value: index,
        label: item,
      }))
    : undefined;

  const getFirstKeys = (arr: any) => arr.map((innerArr: any) => innerArr[0]);

  const languages = weeks
    ? getFirstKeys(weeks)?.map((item: string, index: number) => ({
        value: index,
        label: item,
      }))
    : undefined;

  return (
    <div className="flex flex-col">
      <div className="flex mb-5 space-x-2.5">
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Chọn danh mục"
          onChange={onChangeCategory}
          value={idxSelectedCategory}
          options={categories}
        />
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Chọn tuần"
          onChange={onChangeWeek}
          value={idxSelectedWeek}
          disabled={idxSelectedCategory === undefined}
          options={weekData?.slice(1)}
        />
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Chọn ngôn ngữ"
          onChange={onChangeLanguage}
          value={idxSelectedLanguage}
          disabled={idxSelectedWeek === undefined}
          options={languages?.slice(1)}
        />
        <Button
          icon={<PlusOutlined />}
          loading={loading}
          onClick={onUpdateData}
          type="primary"
          disabled={
            idxSelectedWeek === undefined || idxSelectedLanguage === undefined
          }
        >
          Cập nhật dữ liệu
        </Button>
      </div>
      <div className="flex">
        <div className="flex-2" style={{ maxWidth: '70%' }}>
          <TextEditor
            placeholder={'Nhập nội dung cần chuyển đổi'}
            value={value}
            onChange={setValue}
            style={{ height: '36em' }}
          />
        </div>
        <div className="flex flex-col flex-1 ml-20">
          <div
            className="overflow-y-auto"
            style={{
              width: '20em',
              height: '43em',
              backgroundImage: `url(${IphoneScreen})`,
              backgroundSize: 'cover',
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: value,
              }}
              className="my-14 ml-5 mr-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppPregnancyTracker;
