import { topicsService } from 'network/services/topics';
import React, { useEffect, useState } from 'react';

type Props = {
  loadingAPI: boolean;
  setLoadingAPI: React.Dispatch<React.SetStateAction<boolean>>;
};

const useHandleListCourse = ({ loadingAPI, setLoadingAPI }: Props) => {
  const [listDataCourse, setListDataCourse] = useState<
    { id: number; name: string }[]
  >([]);

  const handleFetchListCourse = async () => {
    const resp = await topicsService.getListCourse();
    setLoadingAPI(false);
    setListDataCourse(resp.data);
  };

  useEffect(() => {
    if (loadingAPI) {
      handleFetchListCourse();
    }
  }, [loadingAPI]);

  return {
    listDataCourse,
  };
};

export default useHandleListCourse;
