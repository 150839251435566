import { BackwardOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { voucherService } from 'network/services/voucher';
import React from 'react';
import { APPLY, PACKAGES } from 'utils/enum';
import {
  formatPrice,
  validateNumber,
  validateNumberPrice,
  validateNumberVoucher,
} from 'utils/helper';
import { isNullOrEmpty } from 'utils/stringUtils';

const dateFormat = 'DD/MM/YYYY';

type Props = {
  id?: number | undefined;
  onHideModal: () => void;
  type?: string;
  course: PackageInterface[];
  onReload: () => void;
};

export const ModalCreateVoucher: React.FC<Props> = ({
  onHideModal,
  type,
  course,
  id,
  onReload,
}) => {
  const [form] = Form.useForm();
  const [voucherType, setVoucherType] = React.useState<number | undefined>(0);
  const [disable, setDisable] = React.useState<boolean | undefined>(false);
  const initialValues = {
    applyTo: 0,
  };
  const handleOnChangeTypeVoucher = (value: number) => {
    setVoucherType(value);
  };

  React.useEffect(() => {
    if (type === 'Chỉnh sửa') {
      getDataDetails();
    } else {
      form.setFieldsValue({
        voucherType: 0,
      });
    }
  }, [type, id]);

  const getDataDetails = async () => {
    const response = await voucherService.details(id);
    const { data } = response;
    if (response?.status === 200) {
      form.setFieldsValue({
        code: data?.code,
        applyTo: data?.applyTo,
        agencyCode: data?.agencyCode,
        voucherType: data?.voucherType,
        parValue: formatPrice(data?.parValue),
        amount: data?.amount,
        startDate: data?.startDate
          ? dayjs(moment(data?.startDate).utc().format(dateFormat), dateFormat)
          : undefined,
        expirationDate: data?.expirationDate
          ? dayjs(
            moment(data?.expirationDate).utc().format(dateFormat),
            dateFormat
          )
          : undefined,
        coursePackageIds: data?.coursePackageIds,
        discountRate: data?.discountRate || undefined,
        maximumDiscount: formatPrice(data?.maximumDiscount || undefined),
      });
      setVoucherType(data?.voucherType);
      setDisable(isNullOrEmpty(data?.agencyCode) ? true : false);
    }
  };

  const onChangeApply = (value: number) => {
    if (value === 1) {
      setDisable(!disable);
    } else {
      setDisable(!disable);
    }
  };

  const onSubmit = async (value: VoucherParams) => {
    const params = {
      ...value,
      maximumDiscount:
        voucherType === 1
          ? Number((value?.maximumDiscount as string)?.replace(/\D/g, ''))
          : 0,
      parValue:
        voucherType !== 1 ? (value?.parValue as string)?.replace(/\D/g, '') : 0,
      startDate: new Date(value.startDate).toISOString(),
      expirationDate: new Date(value.expirationDate).toISOString(),
      agencyCode: !disable ? value.agencyCode : '',
      amount: Number(value.amount),
      discountRate: voucherType === 1 ? Number(value.discountRate) : 0,
      voucherType: voucherType,
    };
    if (type === 'Thêm mới') {
      const response = await voucherService.create(params);
      if (response.status === 200) {
        notification.success({ message: `${type} Voucher thành công` });
        onHideModal();
        onReload();
      } else {
        notification.error({ message: response?.data });
      }
    } else {
      const response = await voucherService.update(id, params);
      if (response.status === 200) {
        notification.success({ message: `${type} Voucher thành công` });
        onHideModal();
        onReload();
      } else {
        notification.error({ message: response?.data });
      }
    }
  };

  const renderDuration = (duration: string) => {
    const packageItem = PACKAGES.find((item) => item.id === duration);
    return packageItem ? packageItem.value : '';
  };

  return (
    <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '10px' }}>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          marginBottom: '20px',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={() => {
            onHideModal();
          }}
        >
          <BackwardOutlined />
        </Button>
        <div
          style={{
            fontSize: '20px',
            fontWeight: '700',
          }}
        >
          {type?.toUpperCase()} VOUCHER
        </div>
      </div>
      <div>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Mã voucher:"
            name="code"
            rules={[{ message: 'Vui lòng nhập mã voucher!', required: true }]}
          >
            <Input
              disabled={type === 'Chỉnh sửa' && true}
              placeholder="Nhập mã voucher..."
              style={{ width: '100%' }}
              onChange={(e: any) => {
                const { value } = e.target;
                const formatted = value.toUpperCase();
                form.setFieldsValue({
                  code: formatted,
                });
              }}
            />
          </Form.Item>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                label="Áp dụng:"
                name="applyTo"
                rules={[{ message: 'Vui lòng chọn đại lý!', required: true }]}
              >
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  options={APPLY}
                  onChange={onChangeApply}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mã đại lý/CTV:"
                name="agencyCode"
                rules={[
                  {
                    message: 'Vui nhập mã đại lý!',
                    required: disable ? false : true,
                  },
                ]}
              >
                <Input
                  disabled={disable}
                  placeholder="Nhập mã đại lý/CTV..."
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Loại voucher"
            name="voucherType"
            rules={[{ required: true, message: 'Vui lòng chọn loại voucher' }]}
          >
            <Radio.Group
              onChange={(e) => handleOnChangeTypeVoucher(e.target.value)}
            >
              <Radio value={0}>Tiền mặt</Radio>
              <Radio value={1}>Phần trăm</Radio>
            </Radio.Group>
          </Form.Item>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              {voucherType === 0 ? (
                <Form.Item
                  label="Mệnh giá (Đơn vị VNĐ):"
                  name="parValue"
                  rules={[{ validator: validateNumberPrice, required: true }]}
                >
                  <Input
                    placeholder="Nhập mệnh giá..."
                    style={{ width: '100%' }}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      const formatted = value
                        .replace(/\D/g, '')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                      form.setFieldsValue({
                        parValue: formatted,
                      });
                    }}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Tỷ lệ giảm giá (Đơn vị %):"
                  name="discountRate"
                  rules={[
                    {
                      required: true,
                      validator: validateNumberVoucher,
                    },
                  ]}
                >
                  <Input
                    placeholder="Nhập tỉ lệ..."
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                label="Số lượng:"
                name="amount"
                rules={[
                  {
                    required: true,
                    validator: validateNumber,
                  },
                ]}
              >
                <Input
                  placeholder="Nhập số lượng..."
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          {voucherType === 1 && (
            <Form.Item
              label="Giảm tối đa ( Đơn vị VNĐ ):"
              name="maximumDiscount"
            >
              <Input
                placeholder="Nhập số tiền..."
                style={{ width: '50%' }}
                onChange={(e: any) => {
                  const { value } = e.target;
                  const formatted = value
                    .replace(/\D/g, '')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                  form.setFieldsValue({
                    maximumDiscount: formatted,
                  });
                }}
              />
            </Form.Item>
          )}

          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                label="Ngày hiệu lực"
                name="startDate"
                rules={[{ required: true, message: 'Vui lòng chọn ngày !' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  placeholder={'Chọn ngày'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ngày hết hạn"
                name="expirationDate"
                rules={[{ required: true, message: 'Vui lòng chọn ngày !' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  placeholder={'Chọn ngày'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Khoá học áp dụng"
            name="coursePackageIds"
            rules={[{ required: true, message: 'Vui lòng chọn khoá học' }]}
          >
            <Select
              placeholder="Chọn khoá học..."
              mode="tags"
              allowClear
              style={{ width: '100%' }}
              options={course?.map((item: PackageInterface) => ({
                value: item.id,
                label: `${item.code} - ${item.name} - ${renderDuration(
                  item.duration
                )}`,
              }))}
            />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button type="primary" htmlType="submit">
              {type}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
