import { Table } from 'antd';
import { useWindowSize } from 'hook';
import React from 'react';
import { GLOBAL_HEIGHT, PAGE_SIZE_LIST } from 'utils/constants';

import { Helmet } from 'react-helmet';
import AdminIcon from 'assets/image/adminIcon.jpg';
import columns from './ReportColumns';
import { HeaderReport } from './header';
import { isNullOrEmpty } from 'utils/stringUtils';
import moment from 'moment';
import { dashboardService } from 'network/services/dashboard';
import { debounce } from 'lodash';

const Report = () => {
  const windowSize = useWindowSize();
  const defaultFilters = {
    size: 50,
    page: 0,
    query: '',
  };
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [filters, setFilters] = React.useState(defaultFilters);
  const [data, setData] = React.useState<[]>([]);
  const [metadata, setMetadata] = React.useState<MetadataInterface | undefined>(
    undefined
  );

  const [dates, setDates] = React.useState<string[]>([]);
  const [readDates, setReadDates] = React.useState<string[]>([]);
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const fetchData = async () => {
    const params = {
      ...filters,
      fromDate: !isNullOrEmpty(dates[0])
        ? moment(dates[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
            .toString()
        : undefined,
      toDate: !isNullOrEmpty(dates[1])
        ? moment(dates[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()
        : undefined,
      readDateFrom: !isNullOrEmpty(readDates[0])
        ? moment(readDates[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
            .toString()
        : undefined,
      readDateTo: !isNullOrEmpty(readDates[1])
        ? moment(readDates[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
            .toString()
        : undefined,
      phoneNumber: phoneNumber,
    };
    const response = await dashboardService.getReport(params);
    const { data, headers } = response;
    if (response?.status === 200) {
      setData(data?.data);
      setIsLoading(false);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: data?.total,
      });
    } else {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (isLoading || dates || readDates || phoneNumber) {
      fetchData();
    }
  }, [isLoading, dates, readDates, phoneNumber]);

  const handleSelectDate = (value: string[]) => {
    setDates(value);
  };
  const handleSelectReadDate = (value: string[]) => {
    setReadDates(value);
  };
  const handleSelectPhoneNumber = debounce((e: any) => {
    setPhoneNumber(e.target.value);
  }, 500);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Báo cáo</title>ß
        <link rel="icon" href={AdminIcon} />
        <meta name="description" content="Customers" />
      </Helmet>
      <HeaderReport
        onChangeDate={(_: any, value: string[]) => handleSelectDate(value)}
        onChangeReadDate={(_: any, value: string[]) =>
          handleSelectReadDate(value)
        }
        onChangePhoneNumber={handleSelectPhoneNumber}
      />
      <Table
        rowKey={'id'}
        columns={columns()}
        dataSource={data}
        scroll={{
          y: windowSize?.height - GLOBAL_HEIGHT - 100,
          x: 1500,
        }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filters.page + 1,
          total: metadata?.total,
          defaultPageSize: filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total?.toLocaleString()}</div>
          ),
        }}
      />
    </div>
  );
};
export default Report;
