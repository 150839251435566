import React from 'react';
import HeaderVoucher from './header';
import { Table, notification } from 'antd';
import { useWindowSize } from 'hook';
import { ColumnsType } from 'antd/es/table';
import { ModalCreateVoucher } from './modal';
import { packageService } from 'network/services/package';
import { voucherService } from 'network/services/voucher';
import { GLOBAL_HEIGHT, PAGE_SIZE_LIST } from 'utils/constants';
import { APPLY } from 'utils/enum';
import { formatPrice } from 'utils/helper';
import moment from 'moment';
import BoxActions from './action';

type ColumnsProps = {
  title?: string;
  dataIndex?: string;
  width?: string;
  fixed?: string;
  render?: () => void;
};

const Voucher: React.FC = () => {
  const windowSize = useWindowSize();
  const [id, setId] = React.useState<number>();
  const [modalType, setModalType] = React.useState<'Thêm mới' | 'Chỉnh sửa'>(
    'Thêm mới'
  );
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [courseData, setCourseData] = React.useState<PackageInterface[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [metadata, setMetadata] = React.useState<MetadataInterface | undefined>(
    undefined
  );
  const [data, setData] = React.useState<[]>([]);
  const [filters, setFilter] = React.useState({
    page: 0,
    size: 20,
    sort: 'id:desc',
  });

  const columns: ColumnsType<ColumnsProps> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '3%',
    },
    {
      title: 'Mã voucher',
      dataIndex: 'code',
      width: '10%',
    },
    {
      title: 'Áp dụng',
      dataIndex: 'applyTo',
      width: '8%',
      render: (applyTo) => {
        const packageItem = APPLY.find((item) => item.value === applyTo);
        return packageItem ? packageItem.label : '';
      },
    },
    {
      title: 'Mã đại lý/CTV',
      dataIndex: 'agencyCode',
      width: '10%',
    },
    {
      title: 'Loại voucher',
      dataIndex: 'voucherType',
      width: '8%',
      render: (voucherType) => {
        if (voucherType === 0) {
          return 'Tiền mặt';
        } else if (voucherType === 1) {
          return 'Phần trăm';
        } else {
          return null;
        }
      },
    },
    {
      title: 'Giá voucher',
      dataIndex: ['parValue', 'voucherType', 'discountRate'],
      width: '8%',
      render: (_text: any, record: any) => {
        if (record?.voucherType === 0) {
          return `${formatPrice(record.parValue)} VNĐ`;
        } else if (record?.voucherType === 1) {
          return `${formatPrice(record.discountRate)} %`;
        } else {
          return null;
        }
      },
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expirationDate',
      width: '12%',
      render: (expirationDate: string) => {
        return moment(expirationDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Số lượng voucher',
      dataIndex: 'amount',
      width: '10%',
    },
    {
      title: 'Số lượng còn lại',
      dataIndex: 'remainingAmount',
      width: '10%',
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '13%',
      render: (modifiedDate: string) => {
        return moment(modifiedDate).format('DD/MM/YYYY | HH:mm:ss');
      },
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'modifiedBy',
      width: '9%',
    },
    {
      title: 'Thao tác',
      width: '13%',
      render: (value: string) => {
        return (
          <BoxActions
            handleEditModal={handleEditModal}
            value={value}
            activeVoucher={hanleActiveVoucher}
          />
        );
      },
    },
  ];

  const handleEditModal = (value: VoucherInterface) => {
    setId(value.id);
    setIsOpenModal(true);
    setModalType('Chỉnh sửa');
  };

  React.useEffect(() => {
    fetchDataCourse();
  }, []);

  React.useEffect(() => {
    if (isLoading) {
      fetchdata();
    }
  }, [isLoading]);

  const fetchDataCourse = async () => {
    const response = await packageService.get();
    const { data } = response;
    setCourseData(data?.content);
  };

  const fetchdata = async () => {
    const response = await voucherService.get(filters);
    const { data } = response;
    if (response?.status === 200) {
      setData(data?.data);
      setIsLoading(false);
      setMetadata({
        pageCount: data?.totalPages,
        page: data?.page,
        size: data?.size,
        total: data?.total,
      });
    } else {
      setIsLoading(false);
    }
  };

  const handleOnChangeModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const onHideModal = () => {
    setIsOpenModal(false);
    setId(0);
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilter((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const onReload = () => {
    setIsLoading(true);
  };

  const hanleActiveVoucher = async (value: VoucherInterface) => {
    const response = await voucherService.active(value.id, !value.activated);
    if (response?.status === 200) {
      notification.success({ message: `Cập nhật Voucher thành công` });
      onReload();
    }
  };

  return (
    <div>
      {isOpenModal ? (
        <ModalCreateVoucher
          id={id}
          onHideModal={onHideModal}
          type={modalType}
          course={courseData}
          onReload={onReload}
        />
      ) : (
        <>
          <HeaderVoucher
            handleOnChangeModal={handleOnChangeModal}
            setTypeModal={setModalType}
          />
          <Table
            rowKey={'id'}
            columns={columns}
            dataSource={data}
            scroll={{
              y: windowSize?.height - GLOBAL_HEIGHT - 50,
              x: 1600,
            }}
            pagination={{
              size: 'small',
              showSizeChanger: true,
              current: filters.page + 1,
              total: metadata?.total,
              defaultPageSize: filters.size,
              pageSizeOptions: PAGE_SIZE_LIST,
              onChange: onPageChange,
              showTotal: (total) => (
                <div>Tổng số bản ghi {total.toLocaleString()}</div>
              ),
            }}
          />
        </>
      )}
    </div>
  );
};
export default Voucher;
