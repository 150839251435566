import { getQueryString } from 'utils/stringUtils';
import { request } from '..';

const create = (params?: any) => {
  const url = getQueryString('/api/pregnant-education/categories');
  return request(url, 'post', params);
};

const categories = (params?: any) => {
  const url = getQueryString('/api/pregnant-education/categories', params);
  return request(url);
};

const detail = (id: number) => {
  return request(`/api/pregnant-education/categories/${id}`);
};

const update = (id: number, params: any) => {
  const url = `/api/pregnant-education/categories/${id}`;
  return request(url, 'patch', params);
};

const getStructure = (params?: CategoryStructureParams) => {
  const url = getQueryString('/api/course/content/category-structure', params);
  return request(url);
};

export const categoryServices = {
  create,
  update,
  detail,
  categories,
  getStructure,
};
