import { message } from 'antd';
import { RESPONSE_CODE } from 'network/constants';
import { categoryWebServices } from 'network/services/categorieWeb';
import React, { useEffect } from 'react';
import { isNullOrEmpty } from 'utils/stringUtils';

type ticketState = {
  data: CategoryStructureWeb[];
  loading: boolean;
};

export const useManageCategory = () => {
  const [state, setState] = React.useState<ticketState>({
    data: [],
    loading: false,
  });

  useEffect(() => {
    getCategoryStructure();
  }, []);

  const getCategoryStructure = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const res = await categoryWebServices.getCategoryNewsAndBlog();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        loading: false,
        data: res?.data,
      }));
    } else {
      message.error('Có lỗi xảy ra');
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const addMore = async (params: {
    name: string;
    courseId: number;
    categoryId: number;
  }) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const res = isNullOrEmpty(params?.categoryId)
      ? await categoryWebServices.addNewCategory(params)
      : await categoryWebServices.addNewTopic(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      message.success(
        isNullOrEmpty(params?.categoryId)
          ? 'Thêm danh mục thành công'
          : 'Thêm chuyên mục thành công'
      );
      getCategoryStructure();
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
      return true;
    } else {
      message.error('Có lỗi xảy ra');
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
      return true;
    }
  };

  return {
    state,
    addMore,
  };
};
