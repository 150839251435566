import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';
import { PhaseParams } from 'containers/Course/Tabs/phase/modal';

const get = (params?: DefaultFiltersInterface) => {
  if (!params?.page || !params?.size) {
    params = { ...params, page: 0, size: 1000 };
  }
  deleteParamsNotUsing(params);
  const url = getQueryString('/api/stage/list', params);
  return request(url);
};
const create = (params: PhaseParams) => {
  const url = '/api/stage/create';
  return request(url, 'post', params);
};
const update = (id: number | undefined, body: PhaseParams) => {
  const url = `/api/stage/update` + `/${id}`;
  return request(url, 'put', body);
};

const disable = (id: number) => {
  const url = `/api/stage/disable` + `/${id}`;
  return request(url, 'patch');
};

const enable = (id: number) => {
  const url = `/api/stage/enable` + `/${id}`;
  return request(url, 'patch');
};

export const phaseService = {
  get,
  create,
  update,
  disable,
  enable,
};
