import { topicsService } from 'network/services/topics';
import React, { useEffect, useState } from 'react';

type Props = {
  setLoadingAPI: React.Dispatch<React.SetStateAction<boolean>>;
  courseId: number;
};

const useHandleListCategories = ({
  setLoadingAPI,
  courseId,
}: Props) => {
  const [listDatacategories, setListDatacategories] = useState([]);

  const fetchListCategories = async (isSearch: boolean) => {
    const resp = await topicsService.getListCategories(
      isSearch ? '' : `?courseId=${courseId}`
    );
    setLoadingAPI(false);
    setListDatacategories(resp.data);
  };

  useEffect(() => {
    if (courseId !== 0) {
      fetchListCategories(false);
    } else {
      fetchListCategories(true);
    }
  }, [courseId]);

  return {
    listDatacategories,
  };
};

export default useHandleListCategories;
