import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, InputNumber } from 'antd';
import './styles.scss';

type Props = {
  onOpenModal: () => void;
  onSearch: (value: string | null) => void;
};

const Actions: React.FC<Props> = ({ onOpenModal, onSearch }) => {
  return (
    <div
      id="practices-actions"
      style={{
        marginBottom: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 10px',
      }}
    >
      <InputNumber
        prefix={<SearchOutlined />}
        placeholder="Ngày thực hành thai giáo"
        className={'box-search'}
        onChange={onSearch}
      />
      <Button type="primary" onClick={onOpenModal}>
        Thêm ngày
      </Button>
    </div>
  );
};

export default Actions;
