import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const CUSTOMERS_URL = '/api/customers';

const get = (params: any) => {
  deleteParamsNotUsing(params);
  const url = getQueryString(CUSTOMERS_URL, params);
  return request(url);
};

const getInfo = () => {
  const url = getQueryString('/api/profile');
  return request(url);
};

const create = (body?: CreateParamsCustomersInterface) => {
  return request(CUSTOMERS_URL, 'post', body);
};

const handleExport = (body: any) => {
  deleteParamsNotUsing(body);
  const url = getQueryString(`${CUSTOMERS_URL}/export`, body);

  return request(
    url,
    'POST',
    {},
    {
      responseType: 'blob',
    }
  );
};

const upgradeVip = (id: number, body: any) => {
  const url = `${CUSTOMERS_URL}/${id}/customer-vip/create`;
  return request(url, 'post', body);
};

const vipPackageDetails = (id: number) => {
  const url = `${CUSTOMERS_URL}/${id}/customer-vip`;
  return request(url, 'get');
};

const vipPackages = (id?: string | undefined) => {
  const VIP_PACKAGES_URL = '/api/categories/vip-packages/list-by-course-id';
  const url = getQueryString(VIP_PACKAGES_URL, {
    id,
  });

  return request(url, 'get');
};

const getListQuestion = (id: number) => {
  const url = `${CUSTOMERS_URL}/questions/${id}`;
  return request(url, 'get');
};

const resetPassword = (id: number, password: string) => {
  const body = {
    password: password,
  };
  const url = `${CUSTOMERS_URL}/${id}/change-password`;
  return request(url, 'post', body);
};

const changeInfo = (id: number, body: CustomerChangeInfo | CustomerInfo) => {
  const url = `${CUSTOMERS_URL}/${id}`;
  deleteParamsNotUsing(body);
  return request(url, 'patch', body);
};

const deleteVipPackage = (id: number, vipPackageId?: number) => {
  const url = `${CUSTOMERS_URL}/${id}/delete-vip-package/${vipPackageId}`;
  return request(url, 'delete');
};

export const customersServices = {
  get,
  create,
  upgradeVip,
  vipPackages,
  handleExport,
  getInfo,
  vipPackageDetails,
  getListQuestion,
  resetPassword,
  changeInfo,
  deleteVipPackage,
};
