import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';

const NOTIFICATION_URL = '/api/general';

const get = (params: any) => {
  deleteParamsNotUsing(params);
  const url = getQueryString(`${NOTIFICATION_URL}/posts`, params);
  return request(url);
};

const create = (body: any) => {
  return request(`${NOTIFICATION_URL}/posts`, 'POST', body);
};

const detail = (id: number) => {
  return request(NOTIFICATION_URL + `/posts/${id}`);
};

const update = (id: number, body: any) => {
  const url = NOTIFICATION_URL + `/posts/${id}`;
  return request(url, 'PATCH', body);
};

const deletePost = (id: number) => {
  const url = NOTIFICATION_URL + `/posts/${id}`;
  return request(url, 'DELETE');
};

const textToAudio = (params: AudioParams) => {
  const url = `/api/general/posts/text-to-speech`;
  return request(url, 'post', params);
};

export const postsServices = {
  get,
  create,
  update,
  detail,
  deletePost,
  textToAudio,
};
