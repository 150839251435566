import {
  Button,
  Divider,
  Modal,
  Space,
  Table,
  Typography,
  notification,
} from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Role } from 'common/utils';
import { useWindowSize } from 'hook';
import moment from 'moment';
import { accountsService } from 'network/services/accounts';
import { GLOBAL_HEIGHT, PAGE_SIZE_LIST, PASSWORD } from 'utils/constants';
import { renderTitle } from '../Actions';
import FormAccount from '../FormAccount';

type Props = {
  data: Account[];
  loading: boolean;
  filters: any;
  metadata: any;
  onPageChange: (page: number, size: number) => void;
  onReload: () => void;
  listRole: RoleInterface[];
};
type State = {
  data?: ActionAccountInput;
  userId?: number;
  loading: boolean;
};

const TableC: React.FC<Props> = ({
  data,
  loading,
  filters,
  metadata,
  onPageChange,
  onReload,
  listRole,
}) => {
  const windowSize = useWindowSize();
  const [isModalEditStaffOpen, setIsModalEditStaffOpen] =
    useState<boolean>(false);
  const [stateEditAccount, setStateEditAccount] = useState<State>({
    data: undefined,
    userId: undefined,
    loading: true,
  });

  const handleResetPassword = (id: number, username?: string) => {
    Modal.confirm({
      title: 'Xác nhận reset mật khẩu',
      content: (
        <span>
          Bạn có muốn reset lại mật khẩu của <strong>{username}</strong> không?
        </span>
      ),
      okText: 'Đồng ý',
      cancelText: 'Huỷ bỏ',
      async onOk() {
        const successCallback = () => {
          return new Promise((resolve) => {
            resolve(true);
          });
        };

        const failCallback = () => {
          return new Promise((reject) => {
            reject(false);
          });
        };
        await accountsService.resetPasswordStaff(
          String(id),
          {
            newPassword: PASSWORD,
          },
          successCallback,
          failCallback
        );
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleEditStaff = (id: number, data: ActionAccountInput) => {
    setIsModalEditStaffOpen(true);
    setStateEditAccount((prev: State) => ({
      ...prev,
      data,
      userId: id,
      loading: false,
    }));
  };

  const handleOkEditStaff = () => {
    setIsModalEditStaffOpen(false);
  };

  const handleCancelEditStaff = () => {
    setIsModalEditStaffOpen(false);
  };

  const onHideModal = () => {
    setIsModalEditStaffOpen(false);
    setStateEditAccount((prev: State) => ({
      ...prev,
      data: undefined,
      userId: undefined,
      loading: false,
    }));
  };

  const onFinish = async (values: ActionAccountInput) => {
    const params = { ...values };
    delete params.password;
    delete params.username;
    const response = await accountsService.editStaff(
      stateEditAccount.userId ?? 0,
      params
    );
    if (response?.status === 200) {
      notification.success({ message: 'Cập nhật thành công' });
      onHideModal();
      onReload();
    } else {
      notification.error({ message: 'Cập nhật thất bại' });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {}, [filters]);

  const columns: ColumnsType<Account> = [
    {
      title: 'STT',
      key: 'index',
      width: '4%',
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
      width: '15%',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'fullname',
      key: 'fullname',
      width: '15%',
    },
    {
      title: 'Roles',
      dataIndex: 'role',
      key: 'role',
      width: '10%',
      render: ({ name }) => <>{name}</>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '9%',
      render: (status) => (
        <span>
          <Typography.Text type={status === 1 ? 'success' : 'danger'} code>
            {status === 1 ? 'Đang hoạt động' : 'Ngừng hoạt động'}
          </Typography.Text>
        </span>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '10%',
      render: (createdAt) => (
        <span>
          {createdAt && moment(Number(createdAt)).format('DD/MM/YYYY HH:mm:ss')}
        </span>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: '15%',
      render: ({
        id,
        username,
        fullname,
        password,
        role,
        managerId,
        status,
      }) => (
        <Space size="middle">
          <Button
            type="link"
            disabled={role.code === Role.ADMIN}
            onClick={() => handleResetPassword(id, username)}
          >
            Reset password
          </Button>
          <Divider type="vertical" style={{ borderColor: '#1677ff' }} />
          <Button
            type="link"
            disabled={role.code === Role.ADMIN}
            onClick={() =>
              handleEditStaff(id, {
                username,
                fullname,
                password,
                role: role.name,
                managerId,
                status,
              })
            }
          >
            Chỉnh sửa
          </Button>
        </Space>
      ),
    },
  ];

  const tableProps: TableProps<Account> = {
    rowKey: 'id',
    loading,
    bordered: true,
    columns,
    dataSource: data,
  };

  return (
    <div id="account-table">
      <Table
        {...tableProps}
        scroll={{
          y: windowSize?.height - GLOBAL_HEIGHT - 40,
          x: 1400,
        }}
        locale={{ emptyText: 'Không có kết quả phù hợp' }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: filters.page + 1,
          total: metadata?.total,
          defaultPageSize: filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
      />
      <Modal
        title={renderTitle('Chỉnh sửa tài khoản')}
        open={isModalEditStaffOpen}
        footer={null}
        onOk={handleOkEditStaff}
        onCancel={handleCancelEditStaff}
        closeIcon={
          <CloseOutlined style={{ color: '#fff', fontSize: '20px' }} />
        }
      >
        <FormAccount
          isEdit={true}
          name="edit-account"
          initialValues={stateEditAccount.data}
          submitText="Lưu"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          listRole={listRole}
        />
      </Modal>
    </div>
  );
};

export default TableC;
