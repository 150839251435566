import { deleteParamsNotUsing, getQueryString } from 'utils/stringUtils';
import { request } from '..';
import { TopicParams } from 'containers/Course/Tabs/topic/modal';

const get = (params?: DefaultFiltersInterface) => {
  if (!params?.page || !params?.size) {
    params = { ...params, page: 0, size: 1000 };
  }
  deleteParamsNotUsing(params);
  const url = getQueryString('/api/section/list', params);
  return request(url);
};
const create = (params?: TopicParams) => {
  const url = '/api/section/create';
  return request(url, 'post', params);
};
const update = (id: number | undefined, body?: TopicParams) => {
  const url = `/api/section/update` + `/${id}`;
  return request(url, 'put', body);
};

const disable = (id: number) => {
  const url = `/api/section/disable` + `/${id}`;
  return request(url, 'patch');
};

const enable = (id: number) => {
  const url = `/api/section/enable` + `/${id}`;
  return request(url, 'patch');
};

export const topicService = {
  get,
  create,
  disable,
  enable,
  update,
};
