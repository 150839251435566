import { Button, Drawer, Form, Input, Select } from 'antd';
import React, { Ref, useEffect, useImperativeHandle } from 'react';
import { isNullOrEmpty } from 'utils/stringUtils';

type Props = {
  data: PeriodProps[];
  onContinue: (params: {
    name: string;
    courseId: number;
    categoryId: number;
  }) => void;
  loading: boolean;
};

export type AddCategoryModalRef = {
  open: (opt: string) => void;
  close: () => void;
};

const AddCategory = (
  { data, onContinue, loading }: Props,
  ref: Ref<AddCategoryModalRef>
) => {
  const [open, setOpen] = React.useState(false);
  const [opt, setOpt] = React.useState<string>('');
  const [periodCode, setPeriodCode] = React.useState<number>(-1);
  const [categoryData, setCategoryData] = React.useState<CategoryProps[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (periodCode !== -1) {
      const temp = data?.filter((item) => item?.id === periodCode);
      setCategoryData(isNullOrEmpty(temp) ? [] : temp?.[0]?.children);
    }
  }, [periodCode, data]);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (opt: string) => {
          setOpt(opt);
          setOpen(true);
        },
        close: () => {
          form.resetFields();
          setPeriodCode(-1);
          setOpen(false);
        },
      };
    },
    []
  );

  const onSubmit = () => {
    form
      .validateFields()
      ?.then(() => {
        const data = form.getFieldsValue();
        const params = {
          name: opt === 'topic' ? data?.topicName : data?.categoryName,
          courseId: data?.period,
          categoryId: opt === 'topic' ? data?.category : undefined,
        };
        onContinue(params);
      })
      .catch(() => {});
  };

  const onClose = () => {
    form.resetFields();
    setPeriodCode(-1);
    setOpen(false);
  };

  return (
    <Drawer
      onClose={onClose}
      open={open}
      width={450}
      title={
        <div className="flex justify-center items-center">
          Thêm mới {opt === 'topic' ? 'Chuyên mục' : 'Danh mục'}
        </div>
      }
      footer={
        <div className="flex items-center justify-center">
          <Button
            className="bg-[white] text-[black] w-[120px]"
            onClick={onClose}
          >
            Huỷ bỏ
          </Button>
          <Button
            type="primary"
            loading={loading}
            className="bg-[#1890ff] text-[white] w-[120px] ml-[20px]"
            onClick={onSubmit}
          >
            Đồng ý
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Giai đoạn"
          name="period"
          style={{ marginBottom: 5 }}
          required
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn giai đoạn',
            },
          ]}
        >
          <Select
            placeholder="Chọn giai đoạn"
            options={data?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            onChange={(value) => {
              setPeriodCode(value);
            }}
          />
        </Form.Item>
        {opt === 'topic' && (
          <Form.Item
            label="Danh mục"
            name="category"
            style={{ marginBottom: 5 }}
            required
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn danh mục',
              },
            ]}
          >
            <Select
              placeholder="Chọn danh mục"
              options={categoryData?.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))}
            />
          </Form.Item>
        )}
        {opt === 'topic' ? (
          <Form.Item
            label="Tên Chuyên mục"
            name="topicName"
            style={{ marginBottom: 5 }}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên chuyên mục',
              },
            ]}
          >
            <Input placeholder="Nhập tên chuyên mục" />
          </Form.Item>
        ) : (
          <Form.Item
            label="Tên Danh mục"
            name="categoryName"
            style={{ marginBottom: 5 }}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên danh mục',
              },
            ]}
          >
            <Input placeholder="Nhập tên danh mục" />
          </Form.Item>
        )}
      </Form>
    </Drawer>
  );
};
export const AddCategoryModal = React.forwardRef(AddCategory);
