export const POST_FORMAT = {
  post: 1,
  video: 2,
  autio: 3,
};

export const ROLES = {
  ADMIN: 'ADMIN',
  // USER: 'USER',
  CONTENT: 'CONTENT',
  MARKETING: 'MARKETING',
  SALE_MANAGER: 'SALE_MANAGER',
  OPERATION: 'OPERATION',
  ACCOUNTING: 'ACCOUNTING',
  BD: 'BD',
  BI: 'BI',
};

export const STAFF_STATUS = [
  { value: 1, label: 'Đang hoạt động' },
  { value: 2, label: 'Ngừng hoạt động' },
];

export const FETAL_DAY = [
  { value: 0, label: '0 tháng' },
  { value: 1, label: '1 tháng' },
  { value: 2, label: '2 tháng' },
  { value: 3, label: '3 tháng' },
  { value: 4, label: '4 tháng' },
  { value: 1, label: '5 tháng' },
  { value: 1, label: '6 tháng' },
  { value: 1, label: '7 tháng' },
  { value: 1, label: '8 tháng' },
  { value: 1, label: '9 tháng' },
];

export const TYPE_ACCOUNT = [
  { value: 'free', label: 'Free' },
  { value: 'trial', label: 'Dùng thử' },
  { value: 'vip', label: 'Vip' },
  { value: 'expired', label: 'Hết hạn' },
];

export const PLATFORM = [
  { value: 'android', label: 'Android' },
  { value: 'ios', label: 'IOS' },
];

export const FORMAT_TYPE_280N = [
  { id: 1, name: 'Bài viết' },
  { id: 2, name: 'Video' },
  { id: 3, name: 'Audio' },
];

export const DISPLAY_POPUP = [
  {
    value: 'ALL',
    label: 'All (App + Website)',
  },
  {
    value: 'APP',
    label: 'App',
  },
  {
    value: 'WEB',
    label: 'Website',
  },
];

export const SCREEN_POPUP = [
  {
    value: 'HOME',
    label: 'Trang chủ',
  },
  {
    value: 'INSIGHT',
    label: 'Cẩm nang',
  },
];

export const LIST_DISPLAY_PAGE = [
  {
    value: 'home',
    name: 'Trang chủ',
  },
  {
    value: 'profile',
    label: 'Trang cá nhân',
  },
];

export const STATUS_HOT_LEAD_CODE = {
  NO_PROCESS: 0,
  PROCESSING: 1,
  DONE_PROCESSING: 2,
};

export const CUSTOMER_CARE_STATUS = {
  NEW: 0,
  CONTACTING: 1,
  CONFIRMED: 2,
  CANCELLED: 3,
};

export const TYPE_POST_COURSE = [
  {
    value: 'THEORY',
    label: 'THEORY',
  },
  {
    value: '280_DAYS',
    label: '280_DAYS',
  },
];

export const VIDEO_TYPES = [
  {
    value: 'HORIZONTAL_VIDEO',
    label: 'Video Ngang',
  },
  {
    value: 'VERTICAL_VIDEO',
    label: 'Video Dọc',
  },
];

export const PACKAGES = [
  { value: '3 ngày', id: 'P3D' },
  { value: '5 ngày', id: 'P5D' },
  { value: '1 tháng', id: 'P1M' },
  { value: '3 tháng', id: 'P3M' },
  { value: '6 tháng', id: 'P6M' },
  { value: '1 năm', id: 'P280D' },
  { value: 'Trọn đời', id: 'P1200M' },
];

export const VOICE = [
  {
    value: 'banmaiace',
    label: 'Ban Mai (Nữ miền Bắc)',
  },
  {
    value: 'linhsanace',
    label: 'Linh San (Nữ miền Nam)',
  },
  {
    value: 'ngoclamace',
    label: 'Ngọc Lam (Nữ miền Trung)',
  },
];

export const SPEED = [
  {
    value: '-1',
    label: '-1x',
  },
  {
    value: '-0.5',
    label: '-0.5x',
  },
  {
    value: '0',
    label: 'Chuẩn',
  },
  {
    value: '0.5',
    label: '0.5x',
  },
  {
    value: '1',
    label: '1x',
  },
];

export const APPLY = [
  {
    value: 0,
    label: 'Đại lý/CTV',
  },
  {
    value: 1,
    label: 'Toàn bộ',
  },
];

export const ROADMAP_280_TYPE = {
  TG_TT: 1,
  TG_GT: 2,
};

export const PREGNANT_STAGE = [
  {
    value: 'mangthai',
    label: 'Mang thai',
  },
  {
    value: 'sausinh',
    label: 'Sau sinh',
  },
  {
    value: 'sausinh0_3',
    label: 'Sau sinh 0-3 tháng',
  },
  {
    value: 'sausinh3_6',
    label: 'Sau sinh 3-6 tháng',
  },
];

export const GTM_PLUS_7_OFFSET = 7 * 60 * 60 * 1000;

export const PREGNANCY_TRACKER_CATEGORIES = [
  {
    value: '1',
    label: 'Your Baby',
  },
  {
    value: '2',
    label: 'Your Body',
  },
  {
    value: '3',
    label: 'Advice',
  },
];
