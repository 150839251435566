import { getQueryString, isNullOrEmpty } from 'utils/stringUtils';
import { Api, request } from '..';

export const getPosts = (
  variables: any,
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) =>
  Api.get(
    '/api/roadmap-280day/posts',
    {
      ...variables,
      size: 2500,
    },
    successCallback,
    failCallback
  );

export const getCategories = (
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) =>
  Api.get(
    '/api/roadmap-280day/categories',
    undefined,
    successCallback,
    failCallback
  );

export const getTopics = (
  variables: any,
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) =>
  Api.get(
    '/api/roadmap-280day/topics',
    variables,
    successCallback,
    failCallback
  );

export const getHashtags = (
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) => Api.get('/api/hashtags', undefined, successCallback, failCallback);

export const uploadAttachments = (
  variables: any,
  successCallback?: (res: any) => void,
  failCallback?: (err: any) => void
) =>
  Api.post('/api/attachments/upload', variables, successCallback, failCallback);

const categories = () => {
  return request('/api/roadmap-280day/categories');
};

const createPractice = (params: any) => {
  const url = '/api/roadmap-280day/practices';
  return request(url, 'post', params);
};

const updatePractice = (id: number, params: any) => {
  const url = `/api/roadmap-280day/practices/${id}`;
  return request(url, 'patch', params);
};

const detailPractice = (id: number) => {
  const url = getQueryString(`/api/roadmap-280day/practices/${id}`);
  return request(url);
};

const routePregnancy = (params?: any) => {
  if (isNullOrEmpty(params?.dayPractice)) {
    delete params?.dayPractice;
  }
  const url = getQueryString('/api/roadmap-280day/practices', params);
  return request(url);
};

export const roadMap280Service = {
  getPosts,
  getCategories,
  getTopics,
  getHashtags,
  uploadAttachments,
  categories,
  createPractice,
  updatePractice,
  routePregnancy,
  detailPractice,
};
