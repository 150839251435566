import { DownCircleTwoTone } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Space,
  Tag,
  notification,
} from 'antd';

import { ordersServices } from 'network/services/orders';

import * as React from 'react';
import { CUSTOMER_CARE_STATUS } from 'utils/enum';

export interface IModalDatialProps {
  onClose?: () => void;
  orderId: number;
  callback?: (boolean: boolean) => void;
  currentCustomerCareStatus: number;
}

export default function ModalHotLead({
  onClose,
  orderId,
  callback,
  currentCustomerCareStatus,
}: IModalDatialProps) {
  const [currentStatus, setCurrentStatus] = React.useState<number>(
    currentCustomerCareStatus
  );

  const choseStatus = (number: number) => {
    switch (number) {
      case CUSTOMER_CARE_STATUS.NEW:
        setCurrentStatus(CUSTOMER_CARE_STATUS.NEW);
        break;
      case CUSTOMER_CARE_STATUS.CONTACTING:
        setCurrentStatus(CUSTOMER_CARE_STATUS.CONTACTING);
        break;
      case CUSTOMER_CARE_STATUS.CONFIRMED:
        setCurrentStatus(CUSTOMER_CARE_STATUS.CONFIRMED);
        break;
      case CUSTOMER_CARE_STATUS.CANCELLED:
        setCurrentStatus(CUSTOMER_CARE_STATUS.CANCELLED);
        break;
    }
  };

  const statusTags = [
    <Tag color={'warning'} key={0}>
      Chưa chăm sóc
    </Tag>,
    <Tag color={'blue'} key={1}>
      Đang chăm sóc
    </Tag>,
    <Tag color={'green'} key={2}>
      KH đã đồng ý
    </Tag>,
    <Tag color={'red'} key={3}>
      KH không đồng ý
    </Tag>,
  ];
  console.log('orderId', orderId);

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <button className="btnChange" onClick={choseStatus.bind(null, 0)}>
          {statusTags[0]}
        </button>
      ),
    },
    {
      key: '1',
      label: (
        <button className="btnChange" onClick={choseStatus.bind(null, 1)}>
          {statusTags[1]}
        </button>
      ),
    },
    {
      key: '2',
      label: (
        <button className="btnChange" onClick={choseStatus.bind(null, 2)}>
          {statusTags[2]}
        </button>
      ),
    },
    {
      key: '3',
      label: (
        <button className="btnChange" onClick={choseStatus.bind(null, 3)}>
          {statusTags[3]}
        </button>
      ),
    },
  ];

  const renderStatus = (status: number) => {
    switch (status) {
      case CUSTOMER_CARE_STATUS.NEW:
        return statusTags[0];
      case CUSTOMER_CARE_STATUS.CONTACTING:
        return statusTags[1];
      case CUSTOMER_CARE_STATUS.CONFIRMED:
        return statusTags[2];
      case CUSTOMER_CARE_STATUS.CANCELLED:
        return statusTags[3];
      default:
        break;
    }
  };

  const onChange = async () => {
    callback?.(true);
    const response = await ordersServices.changeCustomerCareStatus(
      orderId,
      currentStatus
    );
    if (response?.status === 200) {
      notification.success({ message: 'Thay đổi thành công.' });
      callback?.(false);
      onClose?.();
    } else {
      notification.error({
        message: 'Có lỗi xảy ra. Thay đổi thất bại.',
      });
    }
  };

  return (
    <Modal
      title="Trạng thái tư vấn"
      open={true}
      onCancel={onClose}
      footer={null}
      centered
      width={350}
    >
      <div style={{ marginTop: '22px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {3 === 3 ? (
            <div style={{ marginTop: '-13px' }}>
              <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space style={{}}>
                    {renderStatus(currentStatus)}
                    <DownCircleTwoTone />
                  </Space>
                </a>
              </Dropdown>
            </div>
          ) : (
            <p style={{ fontWeight: 'bold' }}>{''}</p>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '12px',
          marginTop: '30px',
        }}
      >
        <Button onClick={onClose}>Huỷ bỏ</Button>
        <Button
          type="primary"
          onClick={onChange}
          disabled={currentStatus === currentCustomerCareStatus}
        >
          Thay đổi
        </Button>
      </div>
    </Modal>
  );
}
