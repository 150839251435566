import { ROLES } from './enum';
import { ROUTERS } from './routers';

export const LOCAL_STORAGE_KEYS = {
  accessToken: 'access_token',
  userInfo: 'user_info',
};
export const PAGE_SIZE_LIST = [10, 20, 50, 100, 200];

export const DEFAULT_PAGE_SIZE = 50;

export const CATEGORY_TYPE = {
  pregnancyEducation: 1,
  postsTG: 2,
  practice: 3,
};

export const DATE_FORMAT = 'DD/MM/YYYY';

export const REPEAT_VALUES = ['none', 'daily', 'weekly', 'monthly'];

export const OBJECT_ASSIGNMENT = [
  'free',
  'trial',
  'vip',
  'expired',
  'trial_1d',
  'trial_2d',
  'trial_3d',
  'trial_4d',
  'trial_5d',
  'specific',
  'unregister',
];

export const GLOBAL_HEIGHT = 64 + 56 + 48;

export const DELAY = 869;

export const DURATION = 4.5;

export const PAGE_SIZE = 20;

export const USER = 'USER';
export const ADMIN = 'ADMIN';

export const DEFAULT = 'DEFAULT';
export const CONFIRM = 'CONFIRM';

export const PASSWORD = 'Exsoft12345';

export const DEFAULT_MAX_SCREEN_SIZE_OF_MOBILE = 768;

export const ROUTERS_ROLE = [
  // Trang chủ
  {
    scene: ROUTERS.HOME.key,
    roles: [
      ROLES.ADMIN,
      ROLES.ACCOUNTING,
      ROLES.BD,
      ROLES.BI,
      ROLES.CONTENT,
      ROLES.MARKETING,
      ROLES.OPERATION,
      ROLES.SALE_MANAGER,
    ],
  },
  {
    scene: ROUTERS.ACCOUNTS.key,
    roles: [ROLES.ADMIN],
  },
  { scene: ROUTERS.PREGNANCY_TRACKER.key, roles: [ROLES.ADMIN, ROLES.CONTENT] },
  { scene: ROUTERS.BABY_TRACKER.key, roles: [ROLES.ADMIN, ROLES.CONTENT] },
  {
    scene: ROUTERS.REPORTS.key,
    roles: [ROLES.ADMIN, ROLES.BI],
  },
  {
    scene: ROUTERS.DASHBOARD.key,
    roles: [ROLES.ADMIN, ROLES.BI],
  },
  // CMS
  {
    scene: ROUTERS.CMS.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.COURSES.key,
    roles: [ROLES.ADMIN],
  },
  {
    scene: ROUTERS.PACKAGES.key,
    roles: [ROLES.ADMIN, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.POSTS_MANAGER.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.POSTS_NEW.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.POSTS_TG.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.POSTS_TG_280N.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.POSTS.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.ROAD_MAP_PRACTICES.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.NOTIFICATIONS.key,
    roles: [ROLES.ADMIN, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.BANNER.key,
    roles: [ROLES.ADMIN, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.POP_UP.key,
    roles: [ROLES.ADMIN, ROLES.MARKETING],
  },

  //CMS Website
  {
    scene: ROUTERS.CMS_WEB.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },
  {
    scene: ROUTERS.MANAGER_POSTS_WEBSITE.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },

  {
    scene: ROUTERS.MANAGER_CATEGORY_WEBSITE.key,
    roles: [ROLES.ADMIN, ROLES.CONTENT, ROLES.MARKETING],
  },

  // CRM
  {
    scene: ROUTERS.CRM.key,
    roles: [ROLES.ADMIN, ROLES.SALE_MANAGER],
  },
  {
    scene: ROUTERS.ORDERS.key,
    roles: [ROLES.ADMIN, ROLES.SALE_MANAGER],
  },
  {
    scene: ROUTERS.CUSTOMERS.key,
    roles: [ROLES.ADMIN, ROLES.SALE_MANAGER],
  },
  {
    scene: ROUTERS.HOT_LEAD.key,
    roles: [ROLES.ADMIN, ROLES.SALE_MANAGER],
  },

  // Affiliate
  {
    scene: ROUTERS.AFFILIATE.key,
    roles: [ROLES.ADMIN, ROLES.BD],
  },
  {
    scene: ROUTERS.VOUCHERS.key,
    roles: [ROLES.ADMIN, ROLES.BD],
  },
];
