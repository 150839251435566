import { Button } from 'antd';

type Props = { type: 'Chỉnh sửa' | 'Thêm mới' };

const FooterModalPosts = ({ type }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button type="primary" htmlType="submit">
        {type}
      </Button>
    </div>
  );
};

export default FooterModalPosts;
