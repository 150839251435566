import { Divider, notification, Space, Table, Tag } from 'antd';
import ActionsBar from 'components/ActionsBar';
import { useWindowSize } from 'hook';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import {
  DEFAULT_PAGE_SIZE,
  OBJECT_ASSIGNMENT,
  PAGE_SIZE_LIST,
  REPEAT_VALUES,
} from 'utils/constants';
import { Helmet } from 'react-helmet';
import AdminIcon from 'assets/image/adminIcon.jpg';
import ConfigNotiPage from './ConfigNotiPage';
import { notificationServices } from 'network/services/notificationNew';
import { debounce } from 'lodash';

const Notifications: FC = () => {
  const defaultFilters = {
    size: DEFAULT_PAGE_SIZE,
    page: 0,
    query: '',
    sort: 'modifiedDate:desc',
  };
  const windowSize = useWindowSize();

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<NotificationItem[]>([]);
  const [itemSelected, setItemSelected] = useState<number | undefined>(
    undefined
  );
  const [filters, setFilters] =
    useState<NotiGetParamsInterface>(defaultFilters);
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );

  const renderRepeatContents = (value: string) => {
    switch (value) {
      case REPEAT_VALUES[0]:
        return <>Không lặp lại</>;
      case REPEAT_VALUES[1]:
        return <>Hàng ngày</>;
      case REPEAT_VALUES[2]:
        return <>Hàng tuần</>;
      case REPEAT_VALUES[3]:
        return <>Hàng tháng</>;

      default:
        break;
    }
  };

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '2%',
    },
    {
      title: 'Thông báo',
      dataIndex: 'title',
      width: '10%',
    },
    {
      title: 'Lặp lại',
      dataIndex: 'repetitionInterval',
      width: '3%',
      render: (cell: string) => <>{renderRepeatContents(cell)}</>,
    },
    {
      title: 'Đối tượng nhận',
      dataIndex: 'customerTypes',
      render: (cell: string) => (
        <>
          {cell.split(',').map((item: string) => {
            switch (item) {
              case OBJECT_ASSIGNMENT[0]:
                return <Tag color={'default'}>Free</Tag>;
              case OBJECT_ASSIGNMENT[1]:
                return <Tag color={'processing'}>Trial</Tag>;
              case OBJECT_ASSIGNMENT[2]:
                return <Tag color={'gold'}>VIP</Tag>;
              case OBJECT_ASSIGNMENT[3]:
                return <Tag color={'error'}>Expired</Tag>;
              case OBJECT_ASSIGNMENT[4]:
                return <Tag color={'default'}>Trial Ngày 1</Tag>;
              case OBJECT_ASSIGNMENT[5]:
                return <Tag color={'processing'}>Trial Ngày 2</Tag>;
              case OBJECT_ASSIGNMENT[6]:
                return <Tag color={'gold'}>Trial Ngày 3</Tag>;
              case OBJECT_ASSIGNMENT[7]:
                return <Tag color={'error'}>Expired Ngày 1</Tag>;
              case OBJECT_ASSIGNMENT[8]:
                return <Tag color={'default'}>Expired Ngày 2</Tag>;
              case OBJECT_ASSIGNMENT[9]:
                return <Tag color={'processing'}>Khách hàng cụ thể</Tag>;
              case OBJECT_ASSIGNMENT[10]:
                return <Tag color={'gold'}>Khách hàng chưa đăng kí</Tag>;
              default:
                break;
            }
          })}
        </>
      ),
      width: '5%',
    },
    {
      title: 'Trạng thái gửi',
      dataIndex: 'isSent',
      render: (value: boolean) => (
        <Tag color={value ? 'success' : 'error'}>
          {value ? 'Đã gửi' : 'Chưa gửi'}
        </Tag>
      ),
      width: '3%',
    },
    {
      title: 'Hiệu lực thông báo',
      dataIndex: 'isValid',
      render: (value: boolean) => (
        <Tag color={value ? 'success' : 'error'}>
          {value ? 'Vẫn còn hiệu lực' : 'Đã hết hiệu lực'}
        </Tag>
      ),
      width: '4%',
    },
    {
      title: 'Thời gian gửi',
      dataIndex: 'sendingTime',
      width: '4%',
      render: (cell: any) => moment(cell).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Thời gian gửi kế tiếp',
      dataIndex: 'nextSendTime',
      width: '4%',
      render: (cell: any) => moment(cell).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'createdBy',
      width: '3%',
    },
    {
      title: 'Thao tác',
      dataIndex: 'id',
      width: '4%',
      render: (cell: any, record: NotificationItem) => (
        <Space>
          <a onClick={() => onEdit(record)}>Chỉnh sửa</a>
          <Divider type="vertical" style={{ borderColor: '#1677ff' }} />
          <a onClick={() => onDelete(cell)}>Xóa</a>
        </Space>
      ),
    },
  ];

  const onEdit = (record: NotificationItem) => {
    setItemSelected(record.id);
    setIsShowModal(true);
  };

  const onDelete = async (id: number) => {
    const response = await notificationServices.remove(id);
    if (response?.status === 204) {
      notification.success({ message: 'Xóa bài thông báo thành công' });
      onReload();
    } else {
      notification.error({ message: 'Xóa bài thông báo thất bại' });
    }
  };

  const onSearch = debounce((
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      query: e.target.value.replace(/^\s+/, ''),
      page: 0,
    }));
  }, 300);

  const onPageChange = (mPage: number, mSize: number) => {
    setIsLoading(true);
    setFilters((cur) => ({
      ...cur,
      page: mPage ? mPage - 1 : 0,
      size: mSize,
    }));
  };

  const fetchData = async () => {
    const response = await notificationServices.get(filters);
    const { data, headers } = response;
    if (response?.status === 200) {
      setIsLoading(false);
      setData(data);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      });
    } else {
      setIsLoading(false);
    }
  };

  const onShowModal = () => {
    setIsShowModal(true);
  };

  const onHideModal = () => {
    setIsShowModal(false);
    setItemSelected(undefined);
  };

  const onReload = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Thông báo</title>
        <link rel="icon" href={AdminIcon} />
        <meta name="description" content="Thông báo" />
      </Helmet>
      {isShowModal ? (
        <ConfigNotiPage
          onHideModal={onHideModal}
          itemSelected={itemSelected}
          onReload={onReload}
        />
      ) : (
        <div>
          <ActionsBar
            onSearch={onSearch}
            onShowModal={onShowModal}
            inputPlaceHolder={'Nhập thông báo...'}
            btnTitle={'Thêm thông báo'}
          />
          <Table
            rowKey={'id'}
            dataSource={data}
            columns={columns as any}
            scroll={{ y: windowSize?.height - 220, x: 1500 }}
            locale={{ emptyText: 'Không có kết quả phù hợp' }}
            pagination={{
              size: 'small',
              showSizeChanger: true,
              current: filters.page + 1,
              total: metadata?.total,
              defaultPageSize: filters.size,
              pageSizeOptions: PAGE_SIZE_LIST,
              onChange: onPageChange,
              showTotal: (total) => (
                <div>Tổng số bản ghi {total.toLocaleString()}</div>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Notifications;
