import { Col, Form, Row, Select, Space } from 'antd';
import React from 'react';

import { LANDSCAPE, PORTRAIT } from 'containers/posts/constants';
import { usePostContext } from 'containers/posts/context';

import { DeleteOutlined } from '@ant-design/icons';
import UploadFileChunks from 'components/UploadFileChunks';

type Props = {
  category?: string;
  onSuccess: (url: any) => void;
};

const ViewVideo: React.FC<Props> = ({ category, onSuccess }) => {
  const { videoUrl, setVideoUrl, setOrientation } = usePostContext();

  const handleChangeOrientation = (value: string) => {
    setOrientation(value);
  };

  const onDeleteVideo = () => {
    setVideoUrl && setVideoUrl(undefined);
  };
  const onUploadVideo = (url: any) => {
    onSuccess(url);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item
          name="orientation"
          label="Loại video"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Select
            allowClear
            placeholder="Chọn chuyên mục..."
            onChange={handleChangeOrientation}
          >
            <Select.Option value={LANDSCAPE}>Video ngang</Select.Option>
            <Select.Option value={PORTRAIT}>Video dọc</Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <Col span={12}></Col>
      <Col span={12}>
        {videoUrl ? (
          <div>
            <p>* Video</p>
            <Space>
              <video style={{ width: '46.875em', height: '31.25em' }} controls>
                <source src={videoUrl} type="video/mp4" />
              </video>
              <DeleteOutlined
                style={{ fontSize: '3em' }}
                onClick={onDeleteVideo}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            category={category || ''}
            name="attachmentIds"
            type={'VIDEO' as any}
            accept="video/*"
            maxSize={500 * 1000 * 10000}
            description="(Tải file mp4... Kích thước không quá 500MB...)"
            uploadFileSuccess={onUploadVideo}
            title="Video "
          />
        )}
      </Col>
    </Row>
  );
};

export default ViewVideo;
