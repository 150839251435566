import { request } from 'network';

const getDataGoogleSheet = (): Promise<any> => {
  return request('/api/google-sheets/get-sheet-data', 'GET');
};

const updateDataGoogleSheet = (body: {
  replacement: string;
  find: string;
}): Promise<any> => {
  return request('/api/google-sheets/update-sheet-data', 'POST', body);
};

export const appPregnancyTrackerServices = {
  getDataGoogleSheet,
  updateDataGoogleSheet,
};
