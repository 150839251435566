import React from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Form, Space } from 'antd';

import PracticeForm from './PracticeForm';
import { ROADMAP_280_TYPE } from 'utils/enum';

type Props = {
  posts: any;
  handleSetLengthPractices: (value: number) => void;
};

const PracticesFormList: React.FC<Props> = ({
  posts,
  handleSetLengthPractices,
}) => {
  return (
    <Form.List name="posts">
      {(fields, { add, remove }) => {
        handleSetLengthPractices(fields.length);

        return (
          <>
            <Space>
              <>Thêm mới hoạt động </>
              <PlusCircleOutlined
                style={{ fontSize: 20 }}
                onClick={() => add(undefined, 0)}
              />
            </Space>
            {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              fields.map(({ key, name, ...restField }, index) => {
                const typePractice =
                  posts?.[index]?.type === 'TG_TT'
                    ? ROADMAP_280_TYPE.TG_TT
                    : ROADMAP_280_TYPE.TG_GT;
                return (
                  <PracticeForm
                    key={key}
                    title={``}
                    restField={restField}
                    index={index}
                    remove={remove}
                    categoryId={posts?.[index]?.category?.id ?? 1}
                    length={fields.length}
                    typePractice={typePractice}
                    position={posts?.[index]?.position}
                  />
                );
              })
            }
          </>
        );
      }}
    </Form.List>
  );
};

export default PracticesFormList;
