import { Form } from 'antd';
import React from 'react';

import Editor from '../Editor';

const ViewPost: React.FC = () => {
  return (
    <Form.Item
      name="content"
      label="Nội dung"
      rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}
    >
      <Editor />
    </Form.Item>
  );
};

export default ViewPost;
