import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Image,
  Space,
  Select,
} from 'antd';
import { bannersServices } from 'network/services/banners';
import { FC, useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { getCDNUrl } from 'utils';
import moment from 'moment';
import dayjs from 'dayjs';
import { CloseOutlined } from '@ant-design/icons';
import { LIST_DISPLAY_PAGE } from 'utils/enum';
import UploadFileChunks from 'components/UploadFileChunks';

type Props = {
  data: any | undefined;
  onHideModal: () => void;
  onReload: () => void;
};

const dateFormat = 'DD/MM/YYYY';

const ModalCreateBanner: FC<Props> = ({ data, onHideModal, onReload }) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: data?.name ?? undefined,
    content: data?.content ?? undefined,
    timestampFrom: data?.timestampFrom
      ? dayjs(moment(data?.timestampFrom).utc().format(dateFormat), dateFormat)
      : undefined,
    timestampTo: data?.timestampTo
      ? dayjs(moment(data?.timestampTo).utc().format(dateFormat), dateFormat)
      : undefined,
    url: data?.url ?? undefined,
    displayPage: data?.displayPage ?? undefined,
    position: data?.position ?? undefined,
  };

  const [imageIdStr, setImageIdStr] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    const timestampFrom = values.timestampFrom.startOf('day').format();
    const timestampTo = values.timestampTo.endOf('day').format();

    const inputDateFrom = moment(timestampFrom);
    const inputDateTo = moment(timestampTo);

    const outputDateFrom = inputDateFrom.format('YYYY-MM-DDTHH:mm:ss[Z]');
    const outputDateTo = inputDateTo.format('YYYY-MM-DDTHH:mm:ss[Z]');

    const params = {
      ...values,
      image: imageIdStr,
      timestampFrom: outputDateFrom,
      timestampTo: outputDateTo,
    };

    setLoading(true);
    let response = null;
    if (data) {
      response = await bannersServices.update(data.id, params);
    } else {
      response = await bannersServices.create(params);
    }

    const typeModal = data ? 'Cập nhật' : 'Tạo';
    if (response.status === 200) {
      notification.success({ message: `${typeModal} banner thành công` });
      setLoading(false);
      onHideModal();
      onReload();
    } else {
      notification.error({ message: `${typeModal} banner thất bại` });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const uploadFileSuccess = (file: any) => {
    setImageIdStr(file.idStr);
  };

  const onDeleteImage = () => {
    setImageIdStr('');
  };

  const renderTitleHeader = (data: string) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'hsl(215deg 100% 54%)',
          color: '#fff',
          margin: '-20px -24px 20px -24px',
          padding: '15px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        {data ? 'Chỉnh sửa Banner' : 'Thêm mới Banner'}
      </div>
    );
  };

  useEffect(() => {
    if (data) {
      const imgId = data?.image?.split('attachments/');
      setImageIdStr(imgId[1]);
    }
  }, [data]);

  return (
    <Modal
      title={renderTitleHeader(data)}
      open={true}
      footer={null}
      onOk={onHideModal}
      onCancel={onHideModal}
      width={500}
      closeIcon={<CloseOutlined style={{ color: '#fff', fontSize: '20px' }} />}
    >
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Tên chương trình"
          name="name"
          rules={[
            { required: true, message: 'Vui lòng nhập tên chương trình!' },
          ]}
        >
          <Input
            placeholder="Nhập tên chương trình..."
            maxLength={250}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <div style={{ width: '100%', display: 'flex', gap: '10px' }}>
          <Form.Item
            label="Thời gian từ"
            name="timestampFrom"
            rules={[
              { required: true, message: 'Vui lòng nhập thời gian bắt đầu!' },
            ]}
            style={{ width: '100%' }}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'DD/MM/YYYY'}
              placeholder={'Chọn thời gian bắt đầu'}
            />
          </Form.Item>
          <Form.Item
            label="Thời gian đến"
            name="timestampTo"
            rules={[
              { required: true, message: 'Vui lòng nhập thời gian đến!' },
            ]}
            style={{ width: '100%' }}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'DD/MM/YYYY'}
              placeholder={'Chọn thời gian kết thúc'}
            />
          </Form.Item>
        </div>

        {imageIdStr ? (
          <div>
            <p>Banner hiển thị *</p>
            <Space>
              <Image width={200} src={getCDNUrl(imageIdStr)} />
              <DeleteOutlined
                style={{ fontSize: '44px' }}
                onClick={onDeleteImage}
              />
            </Space>
          </div>
        ) : (
          <UploadFileChunks
            name="image"
            type={'IMAGE' as any}
            accept="image/*"
            description="(Tải file png, jpeg, jpg... Không quá 10MB...)"
            category={'banner'}
            uploadFileSuccess={uploadFileSuccess}
          />
        )}

        <Form.Item
          style={{ marginTop: 12 }}
          label="Hiển thị"
          name="displayPage"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn nơi để hiện thị',
            },
          ]}
        >
          <Select options={LIST_DISPLAY_PAGE} placeholder="Chọn nơi hiển thị" />
        </Form.Item>

        <Form.Item
          style={{ marginTop: 12 }}
          label="Vị trí"
          name="position"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số nguyên dương!',
              pattern: /^[1-9]*$/,
            },
          ]}
        >
          <Input placeholder="Nhập vị trí" />
        </Form.Item>

        <Form.Item
          style={{ marginTop: 12 }}
          label="Link URL"
          name="url"
          rules={[
            {
              required: true,
              pattern: /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/,
              message: 'Link URL phải có định dạng là một Link!',
            },
          ]}
        >
          <Input placeholder="Nhập link URL..." />
        </Form.Item>

        <div className={'center-item'}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {data ? 'Chỉnh sửa' : 'Thêm mới'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalCreateBanner;
