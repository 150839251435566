import { DrawerForm } from '@ant-design/pro-components';
import { Form, Select } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';

export interface TopicFiltersRef {
  setOpenModal: (isOpen: boolean) => void;
  onReset: () => void;
}

export interface SubmitFormInterface {}

interface TopicFiltersProps {
  onSubmitFilter: (values: SubmitFormInterface) => Promise<boolean | void>;
  categories: any;
}

export const TopicFilters = forwardRef<TopicFiltersRef, TopicFiltersProps>(
  (props, ref) => {
    const { onSubmitFilter, categories } = props;

    useImperativeHandle(ref, () => ({
      setOpenModal,
      onReset,
    }));

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const onReset = () => {
      form.setFieldsValue({
        code: undefined,
        name: undefined,
        id: undefined,
        stageId: undefined,
        courseId: undefined,
      });
    };

    const setOpenModal = (isOpen: boolean) => {
      setIsOpenModal(isOpen);
    };

    const [form] = useForm();

    return (
      <DrawerForm
        onOpenChange={setIsOpenModal}
        title={'Bộ lọc'}
        resize={{
          maxWidth: window.innerWidth * 0.8,
          minWidth: 500,
        }}
        form={form}
        open={isOpenModal}
        submitTimeout={2000}
        onFinish={onSubmitFilter}
      >
        <Form.Item label="Tên chủ đề" name={'name'}>
          <TextArea
            placeholder="Nhập tên chủ đề"
            allowClear
            autoSize={{ minRows: 1, maxRows: 3 }}
          />
        </Form.Item>

        <Form.Item label="Id chủ đề" name={'id'}>
          <TextArea
            placeholder="Nhập id chủ đề"
            allowClear
            autoSize={{ minRows: 1, maxRows: 3 }}
          />
        </Form.Item>

        <Form.Item label="Chuyên mục" name={'categoryId'}>
          <Select
            allowClear
            placeholder="Tất cả"
            options={categories.map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
          />
        </Form.Item>
      </DrawerForm>
    );
  }
);
