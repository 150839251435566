import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import './style.scss';
import SiderApp from 'components/Sider';
import Header from 'components/Header';

type Props = {
  children?: JSX.Element;
};

const { Content } = Layout;

const AppLayout: React.FC<Props> = ({ children }) => {
  return (
    <div id="app-layout">
      <Layout>
        <SiderApp />
        <Content>
          <Header />
          <div className="app-layout-content">
            {children}
            <Outlet />
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default AppLayout;
