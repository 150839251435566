import { Button, Form, Input, Modal, notification } from 'antd';
import DatePicker, { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import moment from 'moment';
import { customersServices } from 'network/services/customers';
import { useState } from 'react';
import { GTM_PLUS_7_OFFSET } from 'utils/enum';

type Props = {
  onHideModal: () => void;
  onReload: () => void;
  value: CustomerInfo;
};

export function ModalChangeInfo({ onHideModal, onReload, value }: Props) {
  const id = value.id;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  form.setFieldsValue({
    fullname: value.fullname,
    dueDate: value?.dueDate ? dayjs(value?.dueDate) : undefined,
    babyName: value?.babyName,
    dateOfBirth: value?.dateOfBirth ? dayjs(value?.dateOfBirth) : undefined,
  });

  const onFinish = async (values: CustomerInfo) => {
    const date = new Date(values.dueDate);
    // date.setUTCHours(0, 0, 0, 0);
    date.setTime(date.getTime() + GTM_PLUS_7_OFFSET);
    const dateOfBirth = values?.dateOfBirth
      ? new Date(values?.dateOfBirth)
      : undefined;
    if (dateOfBirth) {
      dateOfBirth.setUTCHours(0, 0, 0, 0);
      dateOfBirth.setTime(dateOfBirth.getTime());
    }
    console.log('dueDate', date.toISOString().slice(0, 19).replace('T', ' '));
    console.log(
      'sinhnhat',
      dateOfBirth?.toISOString().slice(0, 19).replace('T', ' ')
    );
    const params: CustomerChangeInfo | CustomerInfo = {
      ...values,
      fullname: values.fullname?.replace(/^\s+/, ''),
      dueDate: date.toISOString().slice(0, 19).replace('T', ' '),
      dateOfBirth: dateOfBirth?.toISOString().slice(0, 19).replace('T', ' '),
    };

    setLoading(true);
    const response = await customersServices.changeInfo(id, params);

    if (response.status === 200) {
      notification.success({ message: 'Sửa thông tin khách hàng thành công' });
      setLoading(false);
      onHideModal();
      onReload();
    } else {
      notification.error(response?.data);
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current < moment().startOf('day') ||
        current > moment().add(280, 'days').endOf('day'))
    );
  };
  const disabledDateOfBirth: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().startOf('day');
  };

  return (
    <Modal
      title={'Sửa thông tin khách hàng'}
      open={true}
      footer={null}
      onOk={onHideModal}
      onCancel={onHideModal}
      width={400}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Tên khách hàng"
          name="fullname"
          rules={[
            { required: true, message: 'Vui lòng nhập Tên khách hàng!' },
            {
              max: 15,
              message: 'Vui lòng không nhập quá 15 ký tự!',
            },
          ]}
        >
          <Input placeholder="Nhập Tên khách hàng..." />
        </Form.Item>
        <Form.Item
          label="Ngày dự sinh"
          name="dueDate"
          rules={[{ required: true, message: 'Vui lòng nhập Ngày dự sinh!' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            format={'DD/MM/YYYY'}
            placeholder={'Chọn Ngày dự sinh'}
            disabledDate={disabledDate}
            onFocus={(e) => (e.target.readOnly = true)}
          />
        </Form.Item>
        <Form.Item
          label="Tên em bé"
          name="babyName"
          rules={[
            { required: false, message: 'Vui lòng nhập Tên em bé!' },
            {
              max: 15,
              message: 'Vui lòng không nhập quá 15 ký tự!',
            },
          ]}
        >
          <Input placeholder="Nhập Tên em bé..." />
        </Form.Item>
        <Form.Item
          label="Ngày thực sinh"
          name="dateOfBirth"
          rules={[
            { required: false, message: 'Vui lòng nhập Ngày thực sinh!' },
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            format={'DD/MM/YYYY'}
            placeholder={'Chọn ngày thực sinh'}
            disabledDate={disabledDateOfBirth}
          />
        </Form.Item>

        <div className="center-item">
          <Button type="primary" htmlType="submit" loading={loading}>
            Chỉnh sửa
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
