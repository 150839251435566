type Props = {
  handleEditModal: (value: any) => void;
  value: any;
  handleDeleteModal: (value: any) => void;
};

const BoxActions = ({ handleEditModal, value, handleDeleteModal }: Props) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: 5,
          color: 'blue',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => handleEditModal(value)}
        >
          Chỉnh sửa
        </div>{' '}
        |
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => handleDeleteModal(value)}
        >
          {value?.status === 1 ? 'Hiển thị' : 'Ẩn'}
        </div>
      </div>
    </div>
  );
};

export default BoxActions;
