import { request } from 'network';
import { getQueryString } from 'utils/stringUtils';

const getDataGoogleSheet = () => {
  const params = {
    type: 'post',
  };
  const url = getQueryString(`/api/google-sheets/get-naply-post`, params);
  return request(url);
};

const updateDataGoogleSheet = (params: any): Promise<any> => {
  return request('/api/google-sheets/update-naply-post', 'PATCH', params);
};

export const appBabyTrackerServices = {
  getDataGoogleSheet,
  updateDataGoogleSheet,
};
