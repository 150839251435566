import { Button, DatePicker, Form, Input, Modal, notification } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { customersServices } from 'network/services/customers';
import { FC, useState } from 'react';

type Props = {
  onHideModal: () => void;
  onReload: () => void;
};
const ModalCreateCustomer: FC<Props> = ({ onHideModal, onReload }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    const params = {
      ...values,
      dueDate: new Date(values.dueDate).toISOString(),
    };
    console.log('onFinish', params);
    setLoading(true);
    const response = await customersServices.create(params);

    if (response.status === 200) {
      notification.success({ message: 'Tạo khách hàng thành công' });
      setLoading(false);
      onHideModal();
      onReload();
    } else {
      notification.error({
        message:
          response.data.title === 'customer_duplicate' ? (
            <>
              Số điện thoại <strong>{values.phoneNumber}</strong> đã tồn tại
              trên hệ thống! Vui lòng thử lại!
            </>
          ) : (
            'Tạo khách hàng thất bại'
          ),
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current < moment().startOf('day') ||
        current > moment().add(280, 'days').endOf('day'))
    );
  };

  const validatePhoneNumber = (_rule: any, value: any, callback: any) => {
    const phoneNumberRegex = /^(03|05|07|08|09)+([0-9]{8})\b/;
    if (value && !phoneNumberRegex.test(value)) {
      callback('Vui lòng nhập đúng định dạng số điện thoại!');
    } else {
      callback();
    }
  };

  return (
    <Modal
      title={'Thêm mới khách hàng'}
      open={true}
      footer={null}
      onOk={onHideModal}
      onCancel={onHideModal}
      width={400}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Số điện thoại"
          name="phoneNumber"
          rules={[
            { required: true, message: 'Vui lòng nhập Số điện thoại!' },
            {
              validator: validatePhoneNumber,
            },
          ]}
        >
          <Input placeholder="Nhập Số điện thoại..." />
        </Form.Item>

        <Form.Item
          label="Tên khách hàng"
          name="fullname"
          rules={[
            { required: true, message: 'Vui lòng nhập Tên khách hàng!' },
            {
              max: 15,
              message: 'Vui lòng không nhập quá 15 ký tự!',
            },
          ]}
        >
          <Input placeholder="Nhập Tên khách hàng..." />
        </Form.Item>

        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[
            { required: true, message: 'Vui lòng nhập Mật khẩu!' },
            {
              min: 6,
              message: 'Vui lòng nhập mật khẩu lớn hơn 6 ký tự!',
            },
          ]}
        >
          <Input placeholder="Nhập Mật khẩu..." />
        </Form.Item>
        <Form.Item
          label="Ngày dự sinh"
          name="dueDate"
          rules={[{ required: true, message: 'Vui lòng nhập Ngày dự sinh!' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            format={'DD/MM/YYYY'}
            placeholder={'Chọn Ngày dự sinh'}
            disabledDate={disabledDate}
            onFocus={(e) => (e.target.readOnly = true)}
          />
        </Form.Item>

        <div className="center-item">
          <Button type="primary" htmlType="submit" loading={loading}>
            Thêm mới
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalCreateCustomer;
