import { Button, Divider, notification, Space, Table } from 'antd';
import { useWindowSize } from 'hook';
import moment, { Moment } from 'moment';
import { categoryServices } from 'network/services/categories';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST } from 'utils/constants';
import ModalCreateCategory from './modalCreateCategory';

type State = {
  loading: boolean;
  isOpenModal: boolean;
  data: any;
  filters: any;
  metadata: any;
  categorySelected: any;
};

const CategoryOfManuals = () => {
  const defaultFilters = {
    page: 0,
    size: DEFAULT_PAGE_SIZE,
  };
  const windowSize = useWindowSize();
  const [UIState, setUIState] = useState<State>({
    loading: true,
    data: [],
    filters: defaultFilters,
    metadata: undefined,
    isOpenModal: false,
    categorySelected: undefined,
  });

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '2%',
    },
    {
      title: 'name',
      dataIndex: 'name',
      width: '7%',
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '4%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY HH:mm:ss') : '---',
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'modifiedBy',
      width: '3%',
    },
    {
      title: 'Thao tác',
      dataIndex: 'id',
      width: '5%',
      render: (cell: number, record: any) => (
        <Space>
          <a onClick={() => onEdit(cell)}>Chỉnh sửa</a>
          <Divider type="vertical" style={{ borderColor: '#1677ff' }} />
          <a onClick={() => onShowOrHide(record)}>
            {record?.hidden ? 'Hiển thị' : 'Ẩn'}
          </a>
        </Space>
      ),
    },
  ];

  const onEdit = async (id: number) => {
    const response = await categoryServices.detail(id);
    setUIState((cur) => ({
      ...cur,
      isOpenModal: true,
      categorySelected: response?.data,
    }));
  };

  const onShowOrHide = async (record: any) => {
    const response = await categoryServices.update(record?.id, {
      hidden: !record?.hidden,
    });
    if (response.status === 200) {
      notification.success({ message: 'Cập nhật thành công' });
      onReload();
    } else {
      notification.error({ message: 'Cập nhật thất bại' });
    }
  };

  const addCategory = () => {
    setUIState((cur) => ({
      ...cur,
      isOpenModal: true,
    }));
  };

  const fetchData = async () => {
    const response = await categoryServices.categories(UIState.filters);
    const { headers } = response;
    if (response.status === 200) {
      setUIState((cur) => ({
        ...cur,
        data: response.data,
        loading: false,
        metadata: {
          pageCount: headers['x-page-count'],
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
      }));
    }
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
      filters: {
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const handleCloseModal = () => {
    setUIState((cur) => ({
      ...cur,
      isOpenModal: false,
      categorySelected: undefined,
    }));
  };

  const onReload = () => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
    }));
  };

  useEffect(() => {
    if (UIState.loading) {
      fetchData();
    }
  }, [UIState.loading]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MomEdu - Chuyên mục</title>
        <meta name="description" content="Chuyên mục" />
      </Helmet>
      <Button
        type="primary"
        onClick={addCategory}
        style={{ marginBottom: '12px', float: 'right', marginRight: '10px' }}
      >
        Thêm chuyên mục
      </Button>
      <Table
        rowKey={'id'}
        dataSource={UIState.data}
        columns={columns}
        scroll={{ y: windowSize?.height - 230, x: 800 }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: UIState.filters.page + 1,
          total: UIState?.metadata?.total,
          defaultPageSize: UIState.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
      />
      {UIState.isOpenModal && (
        <ModalCreateCategory
          data={UIState.categorySelected}
          onReload={onReload}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default CategoryOfManuals;
