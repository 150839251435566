import { Divider, notification, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';

import AdminIcon from 'assets/image/adminIcon.jpg';
import ActionsBar from 'components/ActionsBar';
import { useWindowSize } from 'hook';
import { pregnantEducationService } from 'network/services/pregnantEducation';
import { Helmet } from 'react-helmet';
import { getPostFormat } from 'utils';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST } from 'utils/constants';
import ModalCreateEducationPost from './components/ModalCreateEducationPost';

import { debounce } from 'lodash';
import moment from 'moment';

type UIState = {
  data: any[];
  loading: boolean;
  metadata: any;
  filters: any;
  isShowModal: boolean;
  editPostData: any;
};

const PregnantEducation: React.FC = () => {
  const defaultFilters = {
    page: 0,
    size: DEFAULT_PAGE_SIZE,
  };
  const windowSize = useWindowSize();
  const [UIState, setUIState] = useState<UIState>({
    data: [],
    loading: true,
    metadata: undefined,
    filters: defaultFilters,
    isShowModal: false,
    editPostData: undefined,
  });

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '3%',
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'postName',
      width: '15%',
    },
    {
      title: 'Chuyên mục',
      dataIndex: 'category',
      width: '8%',
      render: (cell: any) => cell?.name,
    },
    {
      title: 'Định dạng',
      dataIndex: 'postFormat',
      width: '5%',
      render: (cell: number) => getPostFormat(cell),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'modifiedDate',
      width: '8%',
      render: (cell: number) =>
        cell ? moment(cell).format('DD/MM/YYYY HH:mm:ss') : '--',
    },
    {
      title: 'Cập nhật bởi',
      dataIndex: 'modifiedBy',
      width: '6%',
    },
    {
      title: 'Thao tác',
      dataIndex: 'id',
      width: '8%',
      render: (cell: any) => (
        <Space>
          <a onClick={() => onEdit(cell)}>Chỉnh sửa</a>
          <Divider type="vertical" style={{ borderColor: '#1677ff' }} />
          <a onClick={() => onDeletePost(cell)}>Xóa</a>
        </Space>
      ),
    },
  ];

  const onEdit = async (postId: number) => {
    const response = await pregnantEducationService.detail(postId);
    setUIState((cur) => ({
      ...cur,
      isShowModal: true,
      editPostData: response.data,
    }));
  };

  const onDeletePost = async (postId: number) => {
    const response = await pregnantEducationService.deletePost(postId);
    if (response.status === 204) {
      notification.success({
        message: 'Xóa bài viết thành công',
      });
      onReload();
    } else {
      notification.error({
        message: 'Xóa bài viết thất bại',
      });
    }
  };

  const onFetchData = async () => {
    const response = await pregnantEducationService.posts(UIState.filters);
    const { data, headers } = response;
    if (response?.status === 200) {
      setUIState((cur) => ({
        ...cur,
        loading: false,
        data,
        metadata: {
          pageCount: headers['x-page-count'],
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
      }));
    } else {
      setUIState((cur) => ({
        ...cur,
        loading: false,
      }));
    }
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
      filters: {
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const onShowModal = () => {
    setUIState((cur) => ({
      ...cur,
      isShowModal: true,
    }));
  };

  const onHideModal = () => {
    setUIState((cur) => ({
      ...cur,
      isShowModal: false,
      editPostData: undefined,
    }));
  };

  const onReload = () => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
    }));
  };

  const onSearch = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUIState((cur) => ({
        ...cur,
        loading: true,
        filters: {
          ...cur.filters,
          page: 0,
          postName: e.target.value,
        },
      }));
    },
    300
  );

  useEffect(() => {
    if (UIState.loading) {
      onFetchData();
    }
  }, [UIState.loading]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={AdminIcon} />
        <title>MomEdu - Bài post cẩm nang</title>
        <meta name="description" content="Bài post cẩm nang" />
      </Helmet>
      <ActionsBar
        onSearch={onSearch}
        onShowModal={onShowModal}
        inputPlaceHolder={'Tên bài post...'}
        btnTitle={'Thêm bài post'}
      />
      <Table
        rowKey={'id'}
        dataSource={UIState.data}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: UIState.filters.page + 1,
          total: UIState?.metadata?.total,
          defaultPageSize: UIState.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{
          y: windowSize?.height,
          x: 1400,
        }}
      />

      {UIState.isShowModal && (
        <ModalCreateEducationPost
          onHideModal={onHideModal}
          onReload={onReload}
          data={UIState.editPostData}
        />
      )}
    </div>
  );
};

export default PregnantEducation;
