import { DownCircleTwoTone } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Space,
  Tag,
  notification,
} from 'antd';
import { hotLeadServices } from 'network/services/hotLead';
import moment from 'moment';
import * as React from 'react';
import { STATUS_HOT_LEAD_CODE } from 'utils/enum';
import './style.scss';

export interface IModalDatialProps {
  onClose?: () => void;
  selectedItem?: any;
  callback?: () => void;
}

type infosProps = { id: number; label: string; value: string };
export default function ModalDetail({
  onClose,
  selectedItem,
  callback,
}: IModalDatialProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>();
  const [currentStatus, setCurrentStatus] = React.useState<number>(
    data?.status
  );

  console.log('selectedItem', selectedItem, isLoading);

  const chosseStatus = (number: number) => {
    switch (number) {
      case STATUS_HOT_LEAD_CODE.NO_PROCESS:
        setCurrentStatus(STATUS_HOT_LEAD_CODE.NO_PROCESS);
        break;
      case STATUS_HOT_LEAD_CODE.PROCESSING:
        setCurrentStatus(STATUS_HOT_LEAD_CODE.PROCESSING);
        break;
      case STATUS_HOT_LEAD_CODE.DONE_PROCESSING:
        setCurrentStatus(STATUS_HOT_LEAD_CODE.DONE_PROCESSING);
        break;
    }
  };

  const statusTags = [
    <Tag color={'error'} style={{ fontSize: '10px' }} key={0}>
      Chưa xử lý
    </Tag>,
    <Tag
      color={'yellow'}
      style={{ color: '#FFCD2A', fontSize: '10px' }}
      key={1}
    >
      Đang xử lý
    </Tag>,
    <Tag
      color={'success'}
      style={{ color: 'hsl(100deg 77% 44%)', fontSize: '10px' }}
      key={2}
    >
      Đã xử lý
    </Tag>,
  ];

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <button className="btnChange" onClick={chosseStatus.bind(null, 0)}>
          {statusTags[0]}
        </button>
      ),
    },
    {
      key: '1',
      label: (
        <button className="btnChange" onClick={chosseStatus.bind(null, 1)}>
          {statusTags[1]}
        </button>
      ),
    },
    {
      key: '2',
      label: (
        <button className="btnChange" onClick={chosseStatus.bind(null, 2)}>
          {statusTags[2]}
        </button>
      ),
    },
  ];

  const renderStatus = (status: number) => {
    switch (status) {
      case STATUS_HOT_LEAD_CODE.NO_PROCESS:
        return statusTags[0];
      case STATUS_HOT_LEAD_CODE.PROCESSING:
        return statusTags[1];
      case STATUS_HOT_LEAD_CODE.DONE_PROCESSING:
        return statusTags[2];
      default:
        break;
    }
  };

  const fetchData = async () => {
    const response = await hotLeadServices.getDetail(selectedItem);
    const { data } = response;

    if (response?.status === 200) {
      setIsLoading(false);
      setData(data);
      setCurrentStatus(data?.status);
    } else {
      setIsLoading(false);
    }
  };

  // const handleModalOptions = () => {
  //   setToggleModalOptions(!toggleModalOptions);
  // };

  React.useEffect(() => {
    fetchData();
  }, []);

  const infos = [
    { id: 1, label: 'User Id:', value: data?.userId },
    { id: 2, label: 'Họ và tên:', value: data?.fullname },
    {
      id: 3,
      label: 'Trạng thái:',
      value: data?.status,
    },

    { id: 4, label: 'Số điện thoại:', value: data?.phoneNumber },
    { id: 5, label: 'Màn hình:', value: data?.source },
    {
      id: 6,
      label: 'Ngày liên hệ:',
      value: moment(data?.createdDate).format('DD/MM/YYYY | HH:mm:ss'),
    },
    {
      id: 7,
      label: 'Ngày sửa đổi:',
      value: moment(data?.modifiedDate).format('DD/MM/YYYY | HH:mm:ss'),
    },
  ];

  const onChange = async () => {
    const response = await hotLeadServices.updateStatus(
      data?.id,
      currentStatus
    );
    if (response?.status === 200) {
      notification.success({ message: 'Thay đổi thành công.' });
      callback?.();
      onClose?.();
    } else {
      notification.error({
        message: 'Có lỗi xảy ra. Thay đổi thất bại.',
      });
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Chi tiết"
      open={true}
      onCancel={onClose}
      footer={null}
      centered
      width={350}
    >
      <div style={{ marginTop: '22px' }}>
        {infos?.map((item: infosProps) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            key={item.id}
          >
            <p style={{ width: 120 }}>{item?.label}</p>
            {item?.id === 3 ? (
              <div style={{ marginTop: '-13px' }}>
                <Dropdown menu={{ items }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space style={{}}>
                      {renderStatus(currentStatus)}
                      <DownCircleTwoTone />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            ) : (
              <p style={{ fontWeight: 'bold' }}>{item?.value}</p>
            )}
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '12px',
          marginTop: '30px',
        }}
      >
        <Button onClick={onClose}>Huỷ bỏ</Button>
        <Button
          type="primary"
          onClick={onChange}
          disabled={currentStatus === data?.status}
        >
          Thay đổi
        </Button>
      </div>
    </Modal>
  );
}
