import { Button } from 'antd';
import React from 'react';

type Props = {
  handleOnChangeModal: () => void;
  setTypeModal: React.Dispatch<React.SetStateAction<'Thêm mới' | 'Chỉnh sửa'>>;
};

const HeaderPackage = ({ handleOnChangeModal, setTypeModal }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
      }}
    >
      <div></div>
      <div
        style={{
          marginTop: '10px',
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            handleOnChangeModal();
            setTypeModal('Thêm mới');
          }}
        >
          &#43; Thêm gói
        </Button>
      </div>
    </div>
  );
};

export default HeaderPackage;
